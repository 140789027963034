import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-name-header',
  templateUrl: './system-name-header.component.html',
  styleUrls: ['./system-name-header.component.scss']
})
export class SystemNameHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
