import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TicketService } from 'apps/intake/src/shared/services/ticket.service';
import { SharedDisclosureDocumentsUploadRequest } from '@apis/shared/models/shared-disclosure-documents-upload-request.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-traffic-disclosure-upload-complete',
  templateUrl: './traffic-disclosure-upload-complete.component.html',
  styleUrls: ['./traffic-disclosure-upload-complete.component.scss']
})
export class TrafficDisclosureUploadComplete implements OnInit {
  sharedDisclosureDocumentsUploadRequest: SharedDisclosureDocumentsUploadRequest;
  title: string;
  message: string ="";
  errors: string[] = [];
    
  constructor(private ticketService: TicketService,
              private readonly spinner: NgxSpinnerService,
              private router: Router) { }

  ngOnInit(): void {
    this.title = "Processing..";

    //Get shared disclosure documents upload request context
    this.sharedDisclosureDocumentsUploadRequest = this.ticketService.getSharedDisclosureDocumentsUploadRequestContext();

    //Route to dashboard if it is an invalid request
    if (!this.sharedDisclosureDocumentsUploadRequest || this.sharedDisclosureDocumentsUploadRequest.ticketNumbers.length == 0)
      this.router.navigateByUrl('/'); 
    else
    {
      this.spinner.show(); 
      this.ticketService.addSharedDisclosureDocumentsAsync(this.sharedDisclosureDocumentsUploadRequest).subscribe(
        (response: any) => {
          this.title = "Disclosure Added";
          this.message = "Your disclosure has been added to the selected violation."; 
          this.errors = null;
          this.ticketService.resetSharedDisclosureDocumentsUploadRequestContext();
          this.spinner.hide(); 
        },
        (error: any) => {
          this.title = "Adding Disclosure Failed";
          this.message = "Unable to add disclosure due to the following critical errors."; 
          this.showErrors(error);
          this.spinner.hide(); 
        }
      );
    }   
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errors = error.error;
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errors.push(error.error);
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errors.push(error.error.error);
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errors.push(error.error.errors);  
    else    
      this.errors.push("Something went wrong with the submission. Please try again later.");
  }
}
