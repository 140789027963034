import { Component, Input, OnInit } from '@angular/core';
import { RegistryAgentInformation } from '../../models/registry-agent-information.model';

@Component({
  selector: 'app-registry-agent-information',
  templateUrl: './registry-agent-information.component.html',
  styleUrls: ['./registry-agent-information.component.scss']
})
export class RegistryAgentInformationComponent implements OnInit {
  @Input() registryAgentInformation: RegistryAgentInformation;
  constructor() { }

  ngOnInit(): void {
  }

}
