import { Pipe, PipeTransform } from '@angular/core';
import { Detachment } from '@apis/shared/models/detachment.model';

@Pipe({
    name: 'detachmentsFilter',
    pure: false
})
export class DetachmentsFilterPipe implements PipeTransform {
    transform(detachments: Detachment[], agencyId: Number): any {
        if (!detachments || !agencyId) {
            return null;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return detachments.filter(item => item.agencyId == agencyId);
    }
}