


export class OccurrenceLocation {
    public occurrenceLocationId: number = 0;
    public onStreet: string;
    public atStreet: string;
    public betweenStreets: string;
    public directionOfTravel: string;
    public latitude: number;
    public longitude: number;
    public addressOrHighwayNumber: string;
    public relativeLocation: string;
    public directionTypeId: number;
    public locationTypeId: number = 0;
    public additionalLocationDetails: string;
    public payCentreTypeId: number;

    constructor(init?: Partial<OccurrenceLocation>) {
        
        Object.assign(this, init);
    }
}
