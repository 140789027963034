import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { LookupService } from '@apis/shared/services/lookup.service';
import { RolePermission } from '@apis/models/role-permission.model';
import { Constants } from '@apis/shared/helpers/constants';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloakService: KeycloakService,
    protected readonly lookupService: LookupService
  ) {
    super(router, keycloakService);
  }
 
  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloakService.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (route.data.permission == undefined && route.data.roles == undefined) {
      return true;
    }
    
    // $$$$$$$$$ Temporary allow roles compatibility for Intake portal until all permissions are not setup $$$$$$$$$$
    if (route.data.roles != undefined) 
    {
      var userRoles = route.data.roles;
    
      // Allow the user to proceed if all the required roles are present.
      if (userRoles.find(r => this.roles.includes(r)) == null)
      {
        // redirect account activation pending URL if not even PoliceOfficer role is defined
        if (userRoles.find(r => this.roles.includes("PoliceOfficer")) == null)
          this.router.navigateByUrl('/account-activation-pending');
        else
          this.router.navigateByUrl('/unauthorized');  

        return false;
      } else {
        return true;
      }
    }

    // Get Pemission From Local Storage otherwise from DB
    var requiredRoles:RolePermission[];
    requiredRoles =await this.lookupService.getUserRolePermission().toPromise();   

    // Check user has permission to navigate selected page or default landing page.
    if(requiredRoles.find(x=>x.permissionName == route.data.permission) == null)
    {
      // If default landing page is not allowed, then change it by using availble landing page in side navigation resource
      let changedLandingPage = requiredRoles?.find(x=>x.resourceName==Constants.Resource.SIDE_NAV_COMPONENT)?.permissionName;
      if(changedLandingPage!=null) {
        this.router.navigateByUrl(changedLandingPage.toLowerCase());
        return true;
      } else {
        // redirect to unauthorized page
        this.router.navigateByUrl('/unauthorized');
        return false;
      }
    } else {
      return true;
    }
  }
}
