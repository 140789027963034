import { StopInformation } from "./stop-information.model";

export class NapViewModel {
  public stopInformationId: number;
  public includeCheckbox: boolean;
  public isLessThan2Hours: boolean;
  public occurrenceDateTime: string;
  public issueDate: Date;
  public contraventionNumber: string;
  public contraventionTypeId: string;
  public contraventionTypeName: string;
  public recipientName: string;
  public issuingOfficer: string
  public description: string;
  public policeFileNumber: string;
  public apisStatus: string;
  public contraventionStatusTypeId: number;
  public contraventionStatusTypeName: string;
  public hasDocumentsWithPendingSubmissions: boolean;
  public hasDocumentsRequiringRedaction: boolean;
  public isReServeRequired?: boolean;
  public reServeDate?: Date;
  public isSelected: boolean;
  public vehicleSeizureId?: number;
  public contraventionId?: number;
  public isSeizure: boolean;
  public stopInformation: StopInformation;
  public isNoticeCancellationRequestSubmitted: boolean;
  public noticeCancellationRequestDecisionTypeId: number;
  public isEmergencySubmission?: boolean;
  public isPaperSubmission?: boolean;
  public reviewNumber: string;

  // properties to support checkbox manipulation
  public cbId: string;

  constructor(init?: Partial<NapViewModel>) {
      
      Object.assign(this, init);
  }
}

