import { Component, Input, OnInit } from '@angular/core';
import { DocumentType } from '../../models/types/document-type.model';
import { LocalStorageService } from '../../services/local-storage.service';
import { BloodSampleTypes, DocumentTypes, DriverDeclinedToTestTypes } from '../../enums/app.enum';

@Component({
  selector: 'app-impairment-screening-alert',
  templateUrl: './impairment-screening-alert.component.html',
  styleUrls: ['./impairment-screening-alert.component.scss'],
  inputs: ['ImpairmentScreeningMethodId'],
})
export class ImpairmentScreeningAlertComponent implements OnInit {
  @Input() ImpairmentScreeningMethodId: any;
  @Input() DriverDeclinedToTestId: number;
  @Input() isTechCertificateVisible: boolean;
  @Input() BloodSampleTypeId: number;
  documentTypes: DocumentType[];
  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {

   //Get Types
   if (this.DriverDeclinedToTestId == DriverDeclinedToTestTypes.No)
   {
    switch(this.ImpairmentScreeningMethodId) {
      case "1": //SFST
        this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => [3,4].includes(x.id));
        break;
      case "2": //ASD
        this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => x.id == 79);
        break;
      case "3": //ADSE
        this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => [6,119].includes(x.id));
        break;
      case "4": //DRE
        this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => [7,8,9].includes(x.id));
        break;
      case "5": //AI
        if(this.isTechCertificateVisible)  
          this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => [10,11,12,13,14,15,16,17,18].includes(x.id));
        else
          this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => [10,11,12,13,14,15,16,17].includes(x.id)); 
        break;
      case "6": //Blood Sample
        if (this.BloodSampleTypeId == BloodSampleTypes.HospitalRecords)
          this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => x.id == DocumentTypes.HospitalRecords);
        else
          this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => x.id == DocumentTypes.LaboratoryResults);
        break;
      case "9": //Urine Sample
        this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => x.id == 48);
      break;
    }
   }
    else if (this.DriverDeclinedToTestId == DriverDeclinedToTestTypes.FailedToComply)
    {
      switch(this.ImpairmentScreeningMethodId) {
        case "1":  case "6": case "9": //SFST, Blood Sample, Urine Sample
          this.documentTypes = null;
          break;
        case "2": //ASD
          this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => x.id == 79);
          break;
        case "3": //ADSE
          this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => [6,119].includes(x.id));
          break;
        case "4": //DRE
          this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => [7,8,9].includes(x.id));
          break;
        case "5": //AI
          this.documentTypes = this.localStorageService.getDocumentTypes().filter(x => [10,11,12,13,14,15,16].includes(x.id));
          break;
      }
    }
    else
    {
      this.documentTypes = null;
    }
  }
}
