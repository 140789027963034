export class CountryProvinceModel {
    public countryId: number = 0;
    public provinceId: number = 0;
    public province: string;
    public countryLabel: string;
    public provinceLabel: string;
    public isCountryRequired: boolean = true;
    public isProvinceRequired: boolean = true;
    public controlSuffixText: string = "";
    public isSubmitClicked: boolean = false;
    public isDisabled: boolean = false;
    
    constructor(init?: Partial<CountryProvinceModel>) {
        Object.assign(this, init);
    }
}