<div class="d-flex flex-column flex-md-row justify-content-end">
  <div class="d-flex align-items-center mr-auto">
    <button (click)="onPrevWeekClick()" class="mr-1 m-0 p-0 goa-button button-icon" [disabled]="isFirstWeekOfSchedule">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
          </svg>
    </button>
    <button (click)="onNextWeekClick()" class="m-0 p-0 goa-button button-icon" [disabled]="isLastWeekOfSchedule">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
    </button>
    <h3 class="ml-3 my-3">{{activeWeekStartDate | date:'MMM dd, yyyy'}} - {{activeWeekEndDate | date:'MMM dd, yyyy'}}</h3>
  </div>
  <div class="d-flex align-items-center">
    <button (click)="onRefreshClick()" class="goa-button goa--secondary m-0 ml-auto">
      Refresh
    </button>
  </div>
</div>

<div *ngIf="schedule" class="d-flex flex-column flex-md-row cal-week-view">
  <ng-template [ngIf]="hasAvailability === false">
    <div class="h6">
      There are no available review dates. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="email:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
    </div>
  </ng-template>
  <ng-template [ngIf]="hasAvailability === true">
    <ng-template ngFor let-availableInfo [ngForOf]="activeWeekAvailabilityList">
      <div class="d-flex flex-column cal-day-column">
        <div class="cal-day-header">
          <b>{{ availableInfo.date | date:'EEEE' }}</b>
          <br>
          <span>{{ availableInfo.date | date:'MMMM d' }}</span>
        </div>
        <div class="cal-timeslot" 
          [ngClass]="{ 'cal-timeslot-selected': isSelectedTimeSlot(availableInfo.date, timeSlot) }" 
          *ngFor="let timeSlot of availableInfo.timeSlots" 
          (click)="onTimeSlotSelected(availableInfo.date, timeSlot)">
          {{ toTime(timeSlot) | date:'shortTime' }}
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>