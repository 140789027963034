import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'apps/intake/src/environments/environment';
import { AppUpdateService } from '@apis/shared/services/app-update.service';
import * as alertifyjs from 'alertifyjs';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

    constructor(
      private router: Router,
      private appUpdateService: AppUpdateService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let newHeaders = request.headers.set("client-version", environment.clientVersion);
      newHeaders = newHeaders.set("service-worker-status", this.appUpdateService.swUpdate?.isEnabled ? "ServiceWorkerEnabled" : "ServiceWorkerDisabled");
      request = request.clone({
        headers: newHeaders
      });

      return next.handle(request)
        .pipe(
            tap({
                error: (res) => {
                  if (res.status == "503") {
                    this.router.navigate(['/scheduled-maintenance']);
                  }
                  else if (res.status == "409") { // Client out-of-date
                    alertifyjs.alert("System Update","A new application version is available. System reload is required.")
                                .set({'label':'Reload','Modal':true,'transition':'zoom','movable':false,'closable':false,'onok':function() {
                                  localStorage.clear();
                                  document.location.reload();
                                }});
                  }
                  //if (res.statusText == "Unknown Error")
                  //    this.router.navigate(['/internal-server-error'])
                  throwError(res);
                }
              })
        )
    }

}
