import { Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript';
import { Document } from '@apis/shared/models/document.model';
import { NoticeCancellationReasonType } from '@apis/shared/models/types/notice-cancellation-reason-type.model';
import { NoticeCancellationRequest } from '@apis/shared/models/notice-cancellation-request.model';
import { NoticeCancellationRequestMapping } from '@apis/shared/models/notice-cancellation-request-mapping.model';
import { RequestDecisionTypes } from '@apis/shared/enums/app.enum';

@Component({
  selector: 'app-cancel-irs-seizure-modal',
  templateUrl: './cancel-irs-seizure-confirmation-modal.component.html',
  styleUrls: ['./cancel-irs-seizure-confirmation-modal.component.scss']
})
export class CancelIrsSeizureConfirmationModalComponent implements OnInit, AfterViewInit {
  noticeCancellationRequest: NoticeCancellationRequest;
  notice: NoticeCancellationRequestMapping;
  isSubmitClicked: boolean = false;
  bodyElement: JQuery<HTMLElement>;
  confirmButton: JQuery<HTMLElement>;
  username: string;
  confirmModalOverlay: JQuery<HTMLElement>;
  isSingleNotice: boolean = false;

  close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor( private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService) { }

  ngAfterViewInit(): void {    
    this.confirmButton = $(".confirm-button");
  }

  ngOnInit(): void {        
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.confirmModalOverlay = $(".confirm-modal-overlay");
    this.username = this.localStorageService.getUser().displayName;

    this.isSingleNotice = this.noticeCancellationRequest.noticeCancellationRequestMappings?.length == 1;
    if (this.isSingleNotice) {
      this.notice = this.noticeCancellationRequest.noticeCancellationRequestMappings[0];
    }
  }
   
  onCloseIconClick(): void {        
    this.bodyElement.removeClass("overflow-hidden");
    this.close.emit(null);
  }

  onCancelClick(): void {
    this.bodyElement.removeClass("overflow-hidden");
    this.close.emit(null);
  }

  onConfirmClick() {
    this.bodyElement.removeClass("overflow-hidden");
    this.close.emit(true);
  } 
}
