import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[appMVIDNumber]'
})
export class MVIDNumberDirective {
  @Input('appMVIDNumber') isApplicable: boolean;
  
  get control() {
    return this.ngControl.control;
  }
  
  constructor(
    public ngControl: NgControl
  ) { }

  // add "-" at the right spot of mvid number
  @HostListener('focusout') onBlur() {
        var value = this.control.value;
        if (this.isApplicable && value && value.length > 6)
        {   
            value = value.replace(/-/g, "");
            const controlValue = value.slice(0, value.length-5) + "-" + value.slice(value.length-5);
            this.control.setValue(controlValue);
        }
    }
}