
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { Event } from '../models/event.model';
import { APP_CONFIG } from "../../app-config";

@Injectable({
    providedIn: "root"
})
export class EventService {
    private apiUrl: string = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;

    constructor(
        private readonly httpClient: HttpClient,
        @Inject(APP_CONFIG) public appConfig: any)
    { }

    postEventAsync(event: Event): Observable<Event> {
        return this.httpClient.post(`${this.apiUrl}/events`, event)
            .pipe(
                map(response => new Event(response))
            );
    }

    putEventAsync(event: Event): Observable<Event> {
        return this.httpClient.put(`${this.apiUrl}/events`, event)
            .pipe(
                map(response => new Event(response))
            );
    }

    getEventsByReviewIdAsync(reviewId: number): Observable<Event[]> {
        return this.httpClient.get(`${this.apiUrl}/events`, {
            params: { 
                reviewId:reviewId.toString() 
            }
        }).pipe(
            map((response: any) => Array.from(response))
        );
    }
    
    getEventByIdAsync(eventId: number): Observable<Event> {
        return this.httpClient.get(`${this.apiUrl}/events/${eventId}`, {
            params: { 
                eventId: eventId.toString() 
            }
        }).pipe(
            map(response => new Event(response))
        );
    }       

    deleteEventAsync(eventId: number): Observable<object> {
        return this.httpClient.delete(`${this.apiUrl}/events/${eventId}`, {
            params: { 
                eventId: eventId.toString() 
            }
        });
    }       
}
