import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Recipient } from '@apis/shared/models/recipient.model';
//import { IssuerSubmission } from '@apis/shared/models/issuer-submission.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { Document } from '@apis/shared/models/document.model';
import { ContraventionTest } from '@apis/shared/models/contravention-test.model';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { DriverDeclinedToTestTypes, RecipientTypes } from '@apis/shared/enums/app.enum';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { IntakeUser } from 'apps/intake/src/shared/models/intake-user.model';
import { RecipientIdentification } from '@apis/shared/models/recipient-identification.model';
import { RecipientContact } from '@apis/shared/models/recipient-contact.model';
import { Vehicle } from '@apis/shared/models/vehicle.model';


@Component({
  selector: 'app-legacy-submission-print',
  templateUrl: './legacy-submission-print.component.html',
  styleUrls: ['./legacy-submission-print.component.scss']
})
export class LegacySubmissionPrintComponent implements OnInit {
  @Input() issuerSubmission: StopInformation;

  //Report Related Stuff - Start
  isDriverSectionVisible = true;
  isContraventionSectionVisible = true;
  isVehicleSeizureSectionVisible = true;
  isSupportingDocumentsSectionVisible = true;
  isPoliceServiceSectionVisible = true;
  isCollapsed = false;

  recipientInformation: SectionDetailsModel[] = [];
  recipientIdentificationInformation: SectionDetailsModel[] = [];
  recipientContactInformation: SectionDetailsModel[] = [];
  contraventionInformation: SectionDetailsModel[] = [];
  contraventionType: SectionDetailsModel[] = [];
  stopInformation: SectionDetailsModel[] = [];
  witnessInformation: SectionDetailsModel[] = [];
  contraventionGrounds: SectionDetailsModel[] = [];
  impairmentScreeningInformation: SectionDetailsModel[] = [];
  impairmentScreeningDetails: SectionDetailsModel[] = [];
  additionalScreeningInformation: SectionDetailsModel[][] = [];
  roadsideAppealInformation: SectionDetailsModel[] = [];
  roadsideAppealDetails: SectionDetailsModel[] = [];
  reasonableGrounds: SectionDetailsModel[] = [];
  seizureInformation: SectionDetailsModel[] = [];
  vehicleInformation: SectionDetailsModel[] = [];
  registeredOwnerInformation: SectionDetailsModel[] = [];
  towLotInformation: SectionDetailsModel[] = [];
  supportingDocuments: Document[] = [];
  policeServiceInformation: SectionDetailsModel[] = [];
  reServiceInformation: SectionDetailsModel[] = [];
  policeNarrativeDetails: SectionDetailsModel[] = [];
  declarationInformation: SectionDetailsModel[] = [];

  recipient: Recipient;

  isNoVehicleSeizureMade: boolean = false;
  reportNumber: string = "";
  newFormatDate: Date;

  // new model (P2) types
  contraventionTypes: ContraventionType[];
  //issuerSubmission: StopInformation;
  user: IntakeUser;
  policeOfficerFullName: string;
  recipientIdentification: RecipientIdentification;
  recipientContact: RecipientContact;
  isRecipientSectionVisible: boolean;
  vehicle: Vehicle;
  isVehicleSectionVisible: boolean;
  recipientType: string;
  isIrs: boolean;
  isSdp: boolean;



  //Report Related Stuff - End

  contravention: Contravention;
  vehicleSeizure: VehicleSeizure;

  constructor(private intakeService: IntakeService,
              private localStorageService: LocalStorageService,
              private datePipe: DatePipe,
              private router: Router,
              private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {
    this.loadSubmissionReport();
  }

  canShowDocument(document: Document) : boolean {
    if (document.isDeleted===true || document.contentGuid===null)
      return false;

    return true;
  }


  loadSubmissionReport() {

    //Get Types
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    var irsTypeIds = this.contraventionTypes.filter(x=>x.isIRS).map(s=>s.id);
    var speedingTypeIds = this.contraventionTypes.filter(x=>x.isSpeedingOffence).map(s=>s.id);

    //Get issuer submission object (stopInformation)
    // this is passed in as an @Input
    //this.issuerSubmission = this.intakeService.getStopInformationModel();

    //Get logged in user's name
    this.user = this.localStorageService.getUser();
    this.policeOfficerFullName = `${this.user?.firstName} ${this.user?.lastName}`;
    
    //Recipient Information
    this.recipient = this.issuerSubmission.recipient;
    this.recipientIdentification = this.issuerSubmission.recipient.recipientIdentification;
    this.recipientContact = this.issuerSubmission.recipient.recipientContact;
    this.isRecipientSectionVisible = this.issuerSubmission.recipient.recipientTypeId != RecipientTypes.RegisteredOwner;

    this.isContraventionSectionVisible = this.issuerSubmission.contraventions.length>0;
    //this.pageHeading = `Review & Submit ${this.stopInformation.contraventions.length>0?"Contravention":"Seizure"} Details`;
    
    //Vehicle Information
    this.vehicle = this.issuerSubmission.contraventions.length>0?this.issuerSubmission.contraventions[0].vehicle:(this.issuerSubmission.vehicleSeizures.length>0?this.issuerSubmission.vehicleSeizures[0].vehicle:null);
    this.isVehicleSeizureSectionVisible = this.issuerSubmission.vehicleSeizures.length>0 || this.issuerSubmission.contraventions.filter(x=> x.vehicleSeizure).length>0;
    this.isVehicleSectionVisible = this.issuerSubmission.recipient.recipientTypeId != RecipientTypes.NotInVehicle && !this.isVehicleSeizureSectionVisible;

    //Supporting Documents
    if (this.issuerSubmission.documents.length>0)
      this.supportingDocuments = this.issuerSubmission.documents;
    else if (this.issuerSubmission.contraventions.length>0)
      this.supportingDocuments = this.issuerSubmission.contraventions[0].documents;
    else if (this.issuerSubmission.vehicleSeizures.length>0)
      this.supportingDocuments = this.issuerSubmission.vehicleSeizures[0].documents;

    switch (+this.recipient.recipientTypeId)
    {
      case RecipientTypes.Driver:
        this.recipientType = "Driver";
        break;
      case RecipientTypes.RegisteredOwner:
        this.recipientType = "Registered Owner";
        break;
      case RecipientTypes.Passenger:
        this.recipientType = "Passenger";
        break;
      case RecipientTypes.NotInVehicle:
        this.recipientType = "Not In Vehicle";
        break;
    }


    // get the irscontravention
    this.contravention = null;
    this.issuerSubmission.contraventions.forEach(contravention => {

      if (irsTypeIds.includes(+contravention.contraventionTypeId)) //IRS Contravention
      {
        //Get Contravention Object
        this.contravention = contravention
      }
    });
    if (this.contravention!==null) {

      this.isIrs = true;
      this.isSdp = false;
      //Check if Vehicle Seizure was made or not
      this.isNoVehicleSeizureMade = this.contravention.isNoVehicleSeizureMade;
      this.vehicleSeizure = this.contravention.vehicleSeizure;
      this.recipient = this.contravention.recipient;
      this.reportNumber = `IRS Contravention #${this.contravention?.contraventionNumber}`;

      if (!this.contravention.isNoVehicleSeizureMade)
        this.reportNumber = `${this.reportNumber} / Seizure #${this.contravention?.vehicleSeizure?.seizureNumber}`;

      this.reportNumber = `${this.reportNumber} / File #${this.contravention?.policeFileNumber}`;
    }
    else if (this.issuerSubmission.vehicleSeizures.length>0) //SDP Seizure
    {
      this.isIrs = false;
      this.isSdp = true;

      //Get Vehicle Seizure Object
      this.isContraventionSectionVisible = false;
      this.vehicleSeizure = this.vehicleSeizure??this.issuerSubmission.vehicleSeizures[0];
      this.recipient = this.vehicleSeizure.recipient;
      this.reportNumber = `Seizure #${this.vehicleSeizure?.seizureNumber} / File #${this.vehicleSeizure?.policeFileNumber}`;
    }


    // Driver Section
    if (this.isDriverSectionVisible)
    {
      this.recipientInformation.push(new SectionDetailsModel('Surname', this.recipient.lastName));
      this.recipientInformation.push(new SectionDetailsModel('First Name', this.recipient.firstName));
      this.recipientInformation.push(new SectionDetailsModel('Middle Name', this.recipient.otherName));

      this.recipientIdentificationInformation.push(new SectionDetailsModel('Driver Licence Number', this.recipient.recipientIdentification?.identificationNumber));
      this.recipientIdentificationInformation.push(new SectionDetailsModel('Issuing Country', this.recipient.recipientIdentification?.issuingCountryId == null? "" : this.localStorageService.getCountryTypes().find(x=> x.id == this.recipient.recipientIdentification?.issuingCountryId)?.name));
      this.recipientIdentificationInformation.push(new SectionDetailsModel('Issuing State/Province', (this.recipient.recipientIdentification?.issuingCountryId == 40 || this.recipient.recipientIdentification?.issuingCountryId == 236)? (this.recipient.recipientIdentification?.issuingProvinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.recipient.recipientIdentification?.issuingProvinceId)?.name) : this.recipient.recipientIdentification?.issuingProvinceOther));
      this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Class', this.recipient.recipientIdentification?.driversLicenceClassTypeId == null ? this.recipient.recipientIdentification?.driversLicenceClass : this.localStorageService.getDriversLicenceClassTypes().find(x=>x.id == this.recipient.recipientIdentification?.driversLicenceClassTypeId)?.name));
      this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Status', this.localStorageService.getDriversLicenceStatusTypes().find(x=>x.id == this.recipient.recipientIdentification?.driversLicenceStatusTypeId)?.name));
      this.recipientIdentificationInformation.push(new SectionDetailsModel('Licence Expiry', this.datePipe.transform(this.recipient.recipientIdentification?.identificationExpiryDate, 'dd MMM, yyyy')));
      this.recipientIdentificationInformation.push(new SectionDetailsModel('Is GDL/Novice Driver', this.recipient.recipientIdentification?.isGDLDriver == null? "" : (this.recipient.recipientIdentification?.isGDLDriver? "Yes" : "No")));
      this.recipientIdentificationInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.recipient.recipientIdentification?.dateOfBirth, 'dd MMM, yyyy')));
      this.recipientIdentificationInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x=>x.id == this.recipient.recipientIdentification?.genderId)?.name));

      this.recipientContactInformation.push(new SectionDetailsModel('Address 1', this.recipient.recipientContact?.addressLine1));
      this.recipientContactInformation.push(new SectionDetailsModel('Address 2', this.recipient.recipientContact?.addressLine2));
      this.recipientContactInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x=> x.id == this.recipient.recipientContact?.countryId)?.name));
      this.recipientContactInformation.push(new SectionDetailsModel('Province/State', (this.recipient.recipientContact?.countryId == 40 || this.recipient.recipientContact?.countryId == 236)? (this.recipient.recipientContact?.provinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.recipient.recipientContact?.provinceId)?.name) : this.recipient.recipientContact?.province));
      this.recipientContactInformation.push(new SectionDetailsModel('City',this.recipient.recipientContact?.city));
      this.recipientContactInformation.push(new SectionDetailsModel('Postal Code',this.recipient.recipientContact?.postalCode));
      this.recipientContactInformation.push(new SectionDetailsModel('Residence Phone', this.recipient.recipientContact?.phoneNumber1));
      this.recipientContactInformation.push(new SectionDetailsModel('Business Phone', this.recipient.recipientContact?.phoneNumber2));
      this.recipientContactInformation.push(new SectionDetailsModel('Address Different from Moves', this.recipient.recipientContact?.isAddressDifferentFromMOVES? "Yes" : "No"));
    }

    // Contravention Section
    if (this.isContraventionSectionVisible)
    {
      // Contravention Information
      this.contraventionInformation.push(new SectionDetailsModel('Contravention Number', this.contravention.contraventionNumber));
      this.contraventionInformation.push(new SectionDetailsModel('Occurrence Date', this.datePipe.transform(this.contravention.occurrenceDate, 'dd MMM, yyyy')));
      this.contraventionInformation.push(new SectionDetailsModel('Occurrence Time', this.contravention.occurrenceTime));
      this.contraventionInformation.push(new SectionDetailsModel('Issuance Date', this.datePipe.transform(this.contravention.issueDate, 'dd MMM, yyyy')));
      this.contraventionInformation.push(new SectionDetailsModel('Location At or Near', this.localStorageService.getLocationTypes().find(x => x.id == this.contravention?.stopInformation?.occurrenceLocation?.locationTypeId)?.name));
      this.contraventionInformation.push(new SectionDetailsModel('Additional Location Information', this.contravention?.stopInformation?.occurrenceLocation?.additionalLocationDetails, true));

      this.contraventionType.push(new SectionDetailsModel('Contravention Type', this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId)?.name));

      if (this.contravention.contraventionTypeId == 1 || this.contravention.contraventionTypeId == 9 || this.contravention.contraventionTypeId == 10 || this.contravention.contraventionTypeId == 11)
        this.contraventionType.push(new SectionDetailsModel('Contravention Details', Array.prototype.map.call(this.localStorageService.getContraventionDetailTypes().filter(x => this.contravention.contraventionDetails.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") ));
      else
        this.contraventionType.push(new SectionDetailsModel('Contravention Details', this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId)?.description, true));

      const occurrenceYear = new Date(this.contravention.occurrenceDate).getFullYear();
      const victimFineSurchargePercentage = this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId)?.victimFineSurchargePercentage;
      const victimFineSurchargeAmount = occurrenceYear > 2020
                                        ? (this.contravention.fine.fineAmount * victimFineSurchargePercentage) / 100
                                        : 0;

      if (victimFineSurchargeAmount > 0) {
        this.contraventionType.push(new SectionDetailsModel('Contravention Fine Amount', `${this.currencyPipe.transform(this.contravention.fine.fineAmount)} + ${this.currencyPipe.transform(victimFineSurchargeAmount)} VFS`));
      } else {
        this.contraventionType.push(new SectionDetailsModel('Contravention Fine Amount', `${this.currencyPipe.transform(this.contravention.fine.fineAmount)}`));
      }

      // Stop Information
      this.stopInformation.push(new SectionDetailsModel('Time 1st Observed', this.contravention.stopInformation?.firstObservedTime));
      this.stopInformation.push(new SectionDetailsModel('1st Observed Location', this.contravention.stopInformation?.firstObservedLocation));
      this.stopInformation.push(new SectionDetailsModel('Observed by', this.localStorageService.getObservedByTypes().find(x => x.id == this.contravention.stopInformation?.observedByTypeId)?.name));
      this.stopInformation.push(new SectionDetailsModel('Circumstances for Stop', this.contravention.stopInformation?.circumstanceTypeId == 4? this.contravention.stopInformation?.circumstanceOther : this.localStorageService.getCircumstanceTypes().find(x => x.id == this.contravention.stopInformation?.circumstanceTypeId)?.name));
      this.stopInformation.push(new SectionDetailsModel('Time of Stop', this.contravention.stopInformation?.stopTime));

      //Witness Information
      this.contravention.stopInformation?.witnesses.forEach(witness => {
        this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' First Name', witness.firstName));
        this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' Last Name', witness.lastName));
        this.witnessInformation.push(new SectionDetailsModel('Witness ' + witness.witnessSequence + ' DOB', witness.dateOfBirth == null? "-": this.datePipe.transform(witness.dateOfBirth, 'dd MMM, yyyy')));
      });

      //Contravention Grounds
      this.contraventionGrounds.push(new SectionDetailsModel('Suspected Impairment Type', this.localStorageService.getSuspectedImpairmentTypes().find(x => x.id == this.contravention.impairmentScreeningInformation?.suspectedImpairmentTypeId)?.name));
      this.contraventionGrounds.push(new SectionDetailsModel('Was a Screening Method Used?', this.localStorageService.getContraventionGroundsTypes().find(x => x.id == this.contravention.contraventionGroundsTypeId)?.name, true));

      if (this.contravention.contraventionGroundsTypeId == 1) //Impairment Screening Information
      {
        this.impairmentScreeningInformation.push(new SectionDetailsModel('Reasonable Suspicion or Mas?', this.localStorageService.getImpairmentScreeningTypes().find(x => x.id == this.contravention.impairmentScreeningInformation?.impairmentScreeningTypeId)?.name));

        if (this.contravention.impairmentScreeningInformation?.impairmentScreeningTypeId == 1) //Reasonable Suspicion
        {
          this.impairmentScreeningInformation.push(new SectionDetailsModel('Reasonable Suspicion Based On', Array.prototype.map.call(this.localStorageService.getSuspicionTypes().filter(x => this.contravention.impairmentScreeningInformation?.suspicionTypes.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") + (this.contravention.impairmentScreeningInformation?.suspicionTypes.split(',').includes("5")? "-" + this.contravention.impairmentScreeningInformation?.suspicionOther:"")));
        }

        this.impairmentScreeningInformation.push(new SectionDetailsModel('Impairment Screening Method', this.localStorageService.getTestTypes().find(x => x.id == this.contravention.impairmentScreeningInformation?.impairmentTests[0]?.testTypeId)?.name));

        this.DisplayTestResults(this.contravention.impairmentScreeningInformation?.impairmentTests[0], this.impairmentScreeningDetails, false);
      }
      else if (this.contravention.contraventionGroundsTypeId == 2) // Reasonable Grounds
      {
        this.reasonableGrounds.push(new SectionDetailsModel('Describe in Detail What Led to Your Grounds',this.contravention.reasonableGroundsDetails, true));
        this.reasonableGrounds.push(new SectionDetailsModel('Evidence of Consumption',this.contravention.consumptionEvidence, true));
      }

      if (this.contravention.isReasonableGroundsToBelieve) {
        this.reasonableGrounds.push(new SectionDetailsModel('By checking this box, the undersigned officer has reasonable grounds to believe that the recipient named in this Notice of Administrative Penalty did contravene the section of the Traffic Safety Act specified herein', "Yes", true));
      }

      this.contravention.impairmentScreeningInformation?.impairmentTests.forEach((test, index) => {
        if (test.testSequence != 0) //Avoid first test as that is impairment screening test
        {
          var sectionDtails: SectionDetailsModel[]=[];
          this.DisplayTestResults(test, sectionDtails, true);
          this.additionalScreeningInformation.push(sectionDtails);
        }
      });

      if (this.contravention?.roadsideAppeal)
      {
        this.roadsideAppealInformation.push(new SectionDetailsModel('Was Recipient Presented with Roadside Appeal Information?', this.contravention.roadsideAppeal?.isRecipientPresentedWithInformation? "Yes": "No"));
        if(!this.contravention.roadsideAppeal?.isRecipientPresentedWithInformation)
          this.roadsideAppealInformation.push(new SectionDetailsModel('If No, Explain', this.contravention.roadsideAppeal?.appealOptionsNotPresentedReason, true));

        this.roadsideAppealInformation.push(new SectionDetailsModel('Did Driver Request a Roadside Appeal?', this.contravention.roadsideAppeal?.hasDriverRequestedRoadsideAppeal? "Yes": "No"));

        if(this.contravention.roadsideAppeal?.hasDriverRequestedRoadsideAppeal)
        {
          this.roadsideAppealInformation.push(new SectionDetailsModel('Roadside Appeal Method', this.localStorageService.getTestTypes().find(x => x.id == this.contravention.roadsideAppeal?.roadsideTests[0].testTypeId)?.name));

          if(this.contravention.roadsideAppeal?.roadsideTests[0].testTypeId == 8) //No Appeal Provided
            this.roadsideAppealInformation.push(new SectionDetailsModel('If Appeal Was Requested But Not Provided, Explain Why Not', this.contravention.roadsideAppeal?.roadsideTests[0].appealNotProvidedReason, true));

            this.DisplayTestResults(this.contravention.roadsideAppeal?.roadsideTests[0], this.roadsideAppealDetails, false, true);
        }
      }

      this.policeNarrativeDetails.push(new SectionDetailsModel('', this.contravention.additionalNotes, true));
    }

    //Vehicle Seizure Information
    if (this.isVehicleSeizureSectionVisible)
    {
      if(this.isNoVehicleSeizureMade) //Applicable to contravention only
      {
        this.seizureInformation.push(new SectionDetailsModel('Vehicle Seizure Made?', "No"));
        if (this.contravention.vehicle)
        {
          this.seizureInformation.push(new SectionDetailsModel("Plate Doesn't Match/Stolen Plate", this.contravention.vehicle.isStolenPlate? "Yes": "No"));
          this.seizureInformation.push(new SectionDetailsModel("No Plate", this.contravention.vehicle.isNoPlate? "Yes": "No"));
          this.seizureInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.contravention.vehicle.licensedCountryId)?.name));
          this.seizureInformation.push(new SectionDetailsModel('Licensed State/Province', (this.contravention.vehicle.licensedCountryId == 40 || this.contravention.vehicle.licensedCountryId == 236)? (this.contravention.vehicle.licensedProvinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.contravention.vehicle.licensedProvinceId)?.name) : this.contravention.vehicle.licensedProvince));
          this.seizureInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.contravention.vehicle.vehicleIdentificationNumber));
        }
        this.seizureInformation.push(new SectionDetailsModel('No Vehicle Seizure Details', this.contravention.noVehicleSeizureDetails, true));
      }
      else
      {
        // Vehicle Seizure Section
        this.seizureInformation.push(new SectionDetailsModel('Seizure Number', this.vehicleSeizure?.seizureNumber));
        this.seizureInformation.push(new SectionDetailsModel('Seizure Type', this.localStorageService.getSeizureTypes().find(x => x.id == this.vehicleSeizure?.seizureTypeId)?.name));
        this.seizureInformation.push(new SectionDetailsModel('Seizure Date', this.datePipe.transform(this.vehicleSeizure?.seizureDate, 'dd MMM, yyyy')));
        this.seizureInformation.push(new SectionDetailsModel('Seizure Time', this.vehicleSeizure?.seizureTime));
        this.seizureInformation.push(new SectionDetailsModel('Immediate Seizure Duration', this.vehicleSeizure.seizureDuration.toString() + ' ' + this.vehicleSeizure.seizureDurationType));
        this.seizureInformation.push(new SectionDetailsModel('Vehicle Release Date', this.datePipe.transform(this.vehicleSeizure?.vehicleReleaseDate, 'dd MMM, yyyy')));
        this.seizureInformation.push(new SectionDetailsModel('Location of Seizure', this.localStorageService.getLocationTypes().find(x => x.id == this.vehicleSeizure?.locationTypeId)?.name));
        this.seizureInformation.push(new SectionDetailsModel('Additional Location Information', this.vehicleSeizure?.additionalLocationDetails, true));

        this.vehicleInformation.push(new SectionDetailsModel('Licence Plate Number', this.vehicleSeizure?.vehicle?.licencePlateNumber));
        this.vehicleInformation.push(new SectionDetailsModel("Plate Doesn't Match/Stolen Plate", this.vehicleSeizure?.vehicle?.isStolenPlate? "Yes": "No"));
        this.vehicleInformation.push(new SectionDetailsModel("No Plate", this.vehicleSeizure?.vehicle?.isNoPlate? "Yes": "No"));
        this.vehicleInformation.push(new SectionDetailsModel('Vehicle Identification Number', this.vehicleSeizure?.vehicle?.vehicleIdentificationNumber));
        this.vehicleInformation.push(new SectionDetailsModel('Vehicle Registration Status', this.localStorageService.getVehicleRegistrationStatusTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.vehicleRegistrationStatusTypeId)?.name));
        this.vehicleInformation.push(new SectionDetailsModel('Client Type', this.localStorageService.getClientTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.clientTypeId)?.name));
        this.vehicleInformation.push(new SectionDetailsModel('Licensed Country', this.localStorageService.getCountryTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.licensedCountryId)?.name));
        this.vehicleInformation.push(new SectionDetailsModel('Licensed State/Province', (this.vehicleSeizure?.vehicle?.licensedCountryId == 40 || this.vehicleSeizure?.vehicle?.licensedCountryId == 236)? (this.vehicleSeizure?.vehicle?.licensedProvinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.vehicleSeizure?.vehicle?.licensedProvinceId)?.name) : this.vehicleSeizure?.vehicle?.licensedProvince));
        this.vehicleInformation.push(new SectionDetailsModel('Make', this.vehicleSeizure?.vehicle?.make));
        this.vehicleInformation.push(new SectionDetailsModel('Model', this.vehicleSeizure?.vehicle?.model));
        this.vehicleInformation.push(new SectionDetailsModel('Year', this.vehicleSeizure?.vehicle?.year?.toString()));
        this.vehicleInformation.push(new SectionDetailsModel('Colour', this.vehicleSeizure?.vehicle?.colour));

        this.registeredOwnerInformation.push(new SectionDetailsModel('Driver Licence Number', this.vehicleSeizure?.vehicle?.registeredOwner?.driverLicenceNumber));
        this.registeredOwnerInformation.push(new SectionDetailsModel('M.V.I.D', this.vehicleSeizure?.vehicle?.registeredOwner?.motorVehicleIdentificationNumber));
        this.registeredOwnerInformation.push(new SectionDetailsModel('Surname', this.vehicleSeizure?.vehicle?.registeredOwner?.lastName));
        this.registeredOwnerInformation.push(new SectionDetailsModel('First Name', this.vehicleSeizure?.vehicle?.registeredOwner?.firstName));
        this.registeredOwnerInformation.push(new SectionDetailsModel('Middle Name', this.vehicleSeizure?.vehicle?.registeredOwner?.otherName));
        this.registeredOwnerInformation.push(new SectionDetailsModel('Gender', this.localStorageService.getGenderTypes().find(x=>x.id == this.vehicleSeizure?.vehicle?.registeredOwner?.genderId)?.name));
        this.registeredOwnerInformation.push(new SectionDetailsModel('Date of Birth', this.datePipe.transform(this.vehicleSeizure?.vehicle?.registeredOwner?.dateOfBirth, 'dd MMM, yyyy')));
        this.registeredOwnerInformation.push(new SectionDetailsModel('Telephone Number', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.phoneNumber1));
        this.registeredOwnerInformation.push(new SectionDetailsModel('Address 1', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine1));
        this.registeredOwnerInformation.push(new SectionDetailsModel('Address 2', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.addressLine2));
        this.registeredOwnerInformation.push(new SectionDetailsModel('Country', this.localStorageService.getCountryTypes().find(x => x.id == this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.countryId)?.name));
        this.registeredOwnerInformation.push(new SectionDetailsModel('Province/State', (this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 40 || this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 236)? (this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId == null? "" : this.localStorageService.getProvinceTypes().find(x=> x.id == this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.provinceId)?.name) : this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.province));
        this.registeredOwnerInformation.push(new SectionDetailsModel('City', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.city));
        this.registeredOwnerInformation.push(new SectionDetailsModel('Postal Code', this.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.postalCode));

        this.towLotInformation.push(new SectionDetailsModel('Towing Company', this.vehicleSeizure?.towCompanyName));
        this.towLotInformation.push(new SectionDetailsModel('Name of Towing Company', this.vehicleSeizure?.towCompanyName));
        this.towLotInformation.push(new SectionDetailsModel('Tow Lot Address', this.vehicleSeizure?.towLotAddress));
        this.towLotInformation.push(new SectionDetailsModel('City/Town/Village', this.vehicleSeizure?.towLotCity));
        this.towLotInformation.push(new SectionDetailsModel('Postal Code', this.vehicleSeizure?.towLotPostalCode));
        this.towLotInformation.push(new SectionDetailsModel('Telephone Number', this.vehicleSeizure?.towLotPhoneNumber));
        this.towLotInformation.push(new SectionDetailsModel('Fax Number', this.vehicleSeizure?.towLotFaxNumber));
        this.towLotInformation.push(new SectionDetailsModel('Received By (Towing Company Representative)', this.vehicleSeizure?.towLotRepresentative));
      }
    }

    
    var declarationTypes = this.localStorageService.getDeclarationTypes();

    var officerSubmissionDeclaration = declarationTypes?.find(d => d.id == this.issuerSubmission.officerSubmissionDeclarationTypeId).description;

    // Police Service Section
    if (this.isIrs) // Impaired Contravention
    {
      var detachment = this.localStorageService.getDetachments().find(x => x.detachmentId == this.contravention.policeServiceInformation[0].detachmentId);

      this.policeServiceInformation.push(new SectionDetailsModel('Name of Police Officer', this.contravention.policeServiceInformation[0].policeOfficerName));
      this.policeServiceInformation.push(new SectionDetailsModel('Police File Number', this.contravention.policeFileNumber));
      this.policeServiceInformation.push(new SectionDetailsModel('Regimental Number', this.contravention.policeServiceInformation[0].regimentalNumber));
      this.policeServiceInformation.push(new SectionDetailsModel('Police Service', detachment?.agency?.agencyName));
      this.policeServiceInformation.push(new SectionDetailsModel('Detachment', detachment.detachmentName));

      //Declarations
      this.declarationInformation.push(new SectionDetailsModel('Full Name of Officer', this.contravention.policeOfficerFullName));
      this.declarationInformation.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(this.contravention.submissionDate, 'dd MMM, yyyy')));
      this.declarationInformation.push(new SectionDetailsModel(officerSubmissionDeclaration, this.contravention.isTrueInformation? "Yes": "No", true));

      this.reServiceInformation.push(new SectionDetailsModel('Re-Service', this.contravention.isReServeRequired? "Yes" : "No"));
      this.reServiceInformation.push(new SectionDetailsModel('Re-Service Date', this.datePipe.transform(this.contravention.reServeDate, 'dd MMM, yyyy')));
      this.reServiceInformation.push(new SectionDetailsModel('Re-Service Method', this.localStorageService.getReServeMethodTypes().find(x => x.id == this.contravention.reServeMethodTypeId)?.name));
    }
    else if (this.isSdp) // Vehicle Seizure
    {
      var detachment = this.localStorageService.getDetachments().find(x => x.detachmentId == this.vehicleSeizure.policeServiceInformation[0].detachmentId);

      this.policeServiceInformation.push(new SectionDetailsModel('Name of Police Officer', this.vehicleSeizure.policeServiceInformation[0].policeOfficerName));
      this.policeServiceInformation.push(new SectionDetailsModel('Police File Number', this.vehicleSeizure.policeFileNumber));
      this.policeServiceInformation.push(new SectionDetailsModel('Regimental Number', this.vehicleSeizure.policeServiceInformation[0].regimentalNumber));
      this.policeServiceInformation.push(new SectionDetailsModel('Police Service', detachment?.agency?.agencyName));
      this.policeServiceInformation.push(new SectionDetailsModel('Detachment', detachment.detachmentName));

      //Declarations
      this.declarationInformation.push(new SectionDetailsModel('Full Name of Officer', this.vehicleSeizure.policeOfficerFullName));
      this.declarationInformation.push(new SectionDetailsModel('Date of Submission', this.datePipe.transform(this.vehicleSeizure.submissionDate, 'dd MMM, yyyy')));
      this.declarationInformation.push(new SectionDetailsModel(officerSubmissionDeclaration, this.vehicleSeizure.isTrueInformation? "Yes": "No", true));

      this.reServiceInformation.push(new SectionDetailsModel('Re-Service', this.vehicleSeizure.isReServeRequired? "Yes" : "No"));
      this.reServiceInformation.push(new SectionDetailsModel('Re-Service Date', this.datePipe.transform(this.vehicleSeizure.reServeDate, 'dd MMM, yyyy')));
      this.reServiceInformation.push(new SectionDetailsModel('Re-Service Method', this.localStorageService.getReServeMethodTypes().find(x => x.id == this.vehicleSeizure.reServeMethodTypeId)?.name));

      //Police Narrative
      this.policeNarrativeDetails.push(new SectionDetailsModel('', this.vehicleSeizure?.additionalNotes, true));
     }

  }

  DisplayTestResults(test: ContraventionTest, section: SectionDetailsModel[], isTypeRequired: boolean, isRoadsideAppeal: boolean = false) {
    if (test == null) return;

    this.newFormatDate = this.localStorageService.getDriverDeclinedToTestTypes().find(x => x.id == DriverDeclinedToTestTypes.FailedToComply)?.effectiveDate;
    var isNewFormat = new Date(this.contravention.submissionDate) >= new Date(this.newFormatDate);
    var isMas = this.contravention.impairmentScreeningInformation?.impairmentScreeningTypeId == 2 && !isRoadsideAppeal;

    if (isTypeRequired)
      section.push(new SectionDetailsModel('Screening Method', this.localStorageService.getTestTypes().find(x => x.id == test.testTypeId)?.name));

    switch (test.testTypeId.toString())
    {
      case "1": //SFST
        section.push(new SectionDetailsModel(`Time of SFST ${(isRoadsideAppeal && isNewFormat)?"Request":"Demand"}`, test.standardizedFieldSobrietyTest?.testDemandTime));
        section.push(new SectionDetailsModel('Time SFST Test Started', test.standardizedFieldSobrietyTest?.testStartedTime));
        section.push(new SectionDetailsModel('SFST Results', this.localStorageService.getTestResultTypes().find(x => x.id == test.standardizedFieldSobrietyTest?.testResultTypeId)?.name));
        break;
      case "2": //ASD
        section.push(new SectionDetailsModel('ASD Model', test.approvedScreeningDeviceTest?.testModelTypeId == 2? test.approvedScreeningDeviceTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.approvedScreeningDeviceTest?.testModelTypeId)?.name));
        if (!isRoadsideAppeal || test.approvedScreeningDeviceTest?.testDemandTime) {
          section.push(new SectionDetailsModel(`Time of ${(isMas && isNewFormat)?"MAS":"ASD"} Demand`, test.approvedScreeningDeviceTest?.testDemandTime));
        }
        section.push(new SectionDetailsModel(`Time ${(isMas && isNewFormat)?"MAS":"ASD"} Test Started`, test.approvedScreeningDeviceTest?.testStartedTime));
        section.push(new SectionDetailsModel(`${(isMas && isNewFormat)?"MAS":"ASD"} Results`, this.localStorageService.getTestResultTypes().find(x => x.id == test.approvedScreeningDeviceTest?.testResultTypeId)?.name));
        break;
      case "3": //ADSE
        section.push(new SectionDetailsModel('ADSE Model', test.drugScreeningEquipmentTest?.testModelTypeId == 5? test.drugScreeningEquipmentTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.drugScreeningEquipmentTest?.testModelTypeId)?.name));
        section.push(new SectionDetailsModel(`Time of ADSE ${(isRoadsideAppeal && isNewFormat)?"Request":"Demand"}`, test.drugScreeningEquipmentTest?.testDemandTime));
        section.push(new SectionDetailsModel('Time ADSE Test Started', test.drugScreeningEquipmentTest?.testStartedTime));
        section.push(new SectionDetailsModel('ADSE Results', Array.prototype.map.call(this.localStorageService.getTestResultTypes().filter(x => test.drugScreeningEquipmentTest?.testResultTypeId?.split(',').includes(x.id.toString())), function(item) { return item.name; }).join(", ") + (test.drugScreeningEquipmentTest?.testResultTypeId?.split(',').includes("10")? "-" + test.drugScreeningEquipmentTest?.testResultOther:"")));
        break;
      case "4": //DRE
        section.push(new SectionDetailsModel(`Time of DRE ${(isRoadsideAppeal && isNewFormat)?"Request":"Demand"}`, test.drugRecognitionEvaluationTest?.testDemandTime));
        section.push(new SectionDetailsModel('Time DRE Test Started', test.drugRecognitionEvaluationTest?.testStartedTime));
        break;
      case "5": //AI
        section.push(new SectionDetailsModel('AI Model', test.approvedInstrumentTest?.testModelTypeId == 7? test.approvedInstrumentTest?.testModelOther : this.localStorageService.getTestModelTypes().find(x => x.id == test.approvedInstrumentTest?.testModelTypeId)?.name));
        section.push(new SectionDetailsModel(`Time of 1st Breath AI ${(isRoadsideAppeal && isNewFormat)?"Request":"Demand"}`, test.approvedInstrumentTest?.firstBreathDemandTime));
        section.push(new SectionDetailsModel('1st Evidentiary Test Result', test.approvedInstrumentTest?.firstEvidentiaryTestResult));
        section.push(new SectionDetailsModel(`Time of 2nd Breath AI ${(isRoadsideAppeal && isNewFormat)?"Request":"Demand"}`, test.approvedInstrumentTest?.secondBreathDemandTime));
        section.push(new SectionDetailsModel('2nd Evidentiary Test Result', test.approvedInstrumentTest?.secondEvidentiaryTestResult));
        break;
      case "6": //Blood Sample
        section.push(new SectionDetailsModel('Blood Sample Time', test.bloodSampleTest?.bloodSampleTime));
        section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.bloodSampleTest?.labTypeId)?.name));
        section.push(new SectionDetailsModel('Blood Sample Result', test.bloodSampleTest?.bloodSampleResult));
        break;
      case "9": //Urine Sample
        section.push(new SectionDetailsModel('Urine Sample Time', test.urineSampleTest?.urineSampleTime));
        section.push(new SectionDetailsModel('Sent to Provincial or National Lab?', this.localStorageService.getLabTypes().find(x => x.id == test.urineSampleTest?.labTypeId)?.name));
        section.push(new SectionDetailsModel('Urine Sample Result', test.urineSampleTest?.urineSampleResult));
        break;
    }

    if (test.testTypeId != 7 && test.testTypeId != 8) //No Screening Method and No Appeal
    {
      section.push(new SectionDetailsModel((isRoadsideAppeal && isNewFormat)?"Decline?":"Refusal?", 
        ((isRoadsideAppeal || !isNewFormat) && test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
        ?"Yes":this.localStorageService.getTypeItemDescriptionById(test.driverDeclinedToTestTypeId, TypeTable.DriverDeclinedToTestType)));
      
      if (test.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal)
        section.push(new SectionDetailsModel(`Time of ${(isRoadsideAppeal && isNewFormat)?"Decline":"Refusal"}`, test.refusalTime));
    }
  }

  getDocumentTypeName(documentTypeId: number) : string {
    return this.localStorageService.getTypeItemDescriptionById(documentTypeId, TypeTable.DocumentType);
  }
}
