import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appSelectValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: SelectRequiredValidatorDirective,
        multi: true
    }]
})
export class SelectRequiredValidatorDirective implements Validator {
    @Input('appSelectValidator') isRequired: boolean;
    
    validate(control: AbstractControl) {
        if (this.isRequired)
            return (control.value == "" || control.value == "0" || control.value == "null" || control.value == null )? {'defaultSelected': true} : null;
        else
            return null;    
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('isRequired' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }

}