


export class ApprovedInstrumentTest {
    public approvedInstrumentTestId: number = 0;
    public testModelTypeId: number = 0;
    public testModelOther: string;
    public firstBreathDemandTime: string;
    public firstEvidentiaryTestResult: string;
    public secondBreathDemandTime: string;
    public secondEvidentiaryTestResult: string;
    public thirdBreathDemandTime: string;
    public thirdEvidentiaryTestResult: string;
    public testStartedTime: string;

    constructor(init?: Partial<ApprovedInstrumentTest>) {
        
        Object.assign(this, init);
    }
}
