

import { ContraventionTest } from './contravention-test.model';

export class RoadsideAppeal {
    public roadsideAppealId: number = 0;
    public contraventionId: number = 0;
    public isRecipientPresentedWithInformation: boolean;
    public appealOptionsNotPresentedReason: string;
    public hasDriverRequestedRoadsideAppeal: boolean;
    public roadsideTests: ContraventionTest[] = [];
    public roadsideAppealOutcomeTypeId: number = null;
    public additionalNotes: string;

    constructor(init?: Partial<RoadsideAppeal>) {
        
        Object.assign(this, init);
    }
}
