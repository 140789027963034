import { AsyncValidator, AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ConnectivityService } from 'apps/intake/src/shared/services/connectivity.service';

@Directive({
    selector: '[isFutureDate]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: FutureDateValidatorDirective,
        multi: true
    }]
})
export class FutureDateValidatorDirective implements AsyncValidator {
  @Input('isFutureDate') isRequired: boolean;
  constructor(private intakeService: IntakeService,
              private connectivityService: ConnectivityService) {}
    validate(control: AbstractControl): Observable<ValidationErrors | null> {
      if (control.value == null || !this.isRequired || !this.connectivityService.isUserOnline())
        return of(null);
      
      const chkDate = new Date(new Date(control.value).getFullYear(), new Date(control.value).getMonth(), new Date(control.value).getDate()); 
      const result = this.intakeService.getCurrentMSTDateAsync()
      .pipe(
          map((currentDate) => {
            const todayDate = new Date(currentDate.toString());
            return ((chkDate > new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate())) ? {'FutureDate': true} : null);
          })
      );
      return result;
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('isRequired' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }
}