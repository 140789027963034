import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { Recipient } from '@apis/shared/models/recipient.model';
import { RecipientIdentification } from '@apis/shared/models/recipient-identification.model';
import { RecipientContact } from '@apis/shared/models/recipient-contact.model';
import { CountryProvinceModel } from '@apis/shared/models/country-province.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DriversLicenceClassType } from '@apis/shared/models/types/drivers-licence-class-type.model';
import { DriversLicenceStatusType } from '@apis/shared/models/types/drivers-licence-status-type.model';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { OperatorInformation } from '@apis/shared/models/operator-information.model';
import { OperatorSearch } from '../../../../shared/models/operator-search.model';
import { AgGridAngular } from 'ag-grid-angular';
import * as moment from 'moment';
import { MovesService } from 'apps/intake/src/shared/services/moves.service';
import { DriversLicenceScanningService } from 'apps/intake/src/shared/services/driverslicencescanning.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { IdentificationType } from '@apis/shared/models/types/identification-type.model';
import { ContraventionTypes, DriverLicenceClassification, DriversLicenceStatusTypes, IdentificationTypes, IntakeAppProcess, LicencePresentedClassification, RecipientTypes, SubmissionProgrssStates } from '@apis/shared/enums/app.enum';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { ConnectivityService } from 'apps/intake/src/shared/services/connectivity.service';
import { environment } from "../../../../environments/environment";
import { getDate } from 'date-fns';
import { MovesRecipientContact } from '@apis/shared/models/moves-recipient-contact.model';
import { ConditionCodeType } from '@apis/shared/models/types/condition-code-type.model';
import { TicketService } from 'apps/intake/src/shared/services/ticket.service';
import { Constants } from '@apis/shared/helpers/constants';
import { ModdedTitleCasePipe } from '@apis/shared/pipes/moddedTitleCase.pipe';



@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-recipient-information',
  templateUrl: './recipient-information.component.html',
  styleUrls: ['./recipient-information.component.scss'],
})
export class RecipientInformationComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  stopInformation: StopInformation;
  contravention: Contravention;
  vehicleSeizure: VehicleSeizure;
  recipient: Recipient;
  recipientIdentification: RecipientIdentification;
  recipientContact: RecipientContact;
  movesRecipientContact: MovesRecipientContact;
  isAlbertaDriverLicense: boolean = true;
  intIsGDLDriver: number = 0;
  recipientContactCountryProvince: CountryProvinceModel;
  recipientContactCountryProvinceMoves: CountryProvinceModel;
  driverLicenceCountryProvince: CountryProvinceModel;
  identificationCountryProvince: CountryProvinceModel;
  driverLicenceClassTypes: DriversLicenceClassType[];
  driverLicenceStatusTypes: DriversLicenceStatusType[];
  genderTypes: GenderType[];
  identificationTypes: IdentificationType[];
  conditionCodeTypes: ConditionCodeType[];
  
  dateOfBirthPickerConfig: Partial<BsDatepickerConfig>;
  licenceExpiryPickerConfig: Partial<BsDatepickerConfig>;
  isSubmitClicked: boolean = false;
  isSearchClicked: boolean = false;

  movesModalOverlay: JQuery<HTMLElement>;
  bodyElement: JQuery<HTMLElement>;
  
  licenceScannerModalOverlay: JQuery<HTMLElement>;

  minimumAgeDate: Date;
  operatorSearch: OperatorSearch = new OperatorSearch();;
  operatorInformation: OperatorInformation[];
  errorMessage: string;
  licenceScanErrorMessage: string;
  RecipientTypes = RecipientTypes;
  DriverLicenceClassification = DriverLicenceClassification;
  LicencePresentedClassification = LicencePresentedClassification;
  IdentificationTypes = IdentificationTypes;
  
  isVerbal: boolean = false;
  isRecipientTypeSelectionRequired: boolean = false;
  isProductionEnvironment: boolean = false;
  isOnlyMVIDRecord: boolean = false;

  selectedConditionCodes: number[];
  availableTSA: boolean = false;
  ticketsHealthStatusMessage: string = "";

  columnDetails = [ 
    { headerName: "First Name", field: "firstName", width: 170, checkboxSelection: true },
    { headerName: "Middle Name", field: "middleName", width: 170  },
    { headerName: "Last Name", field: "lastName", width: 170 },
    { headerName: "Date of Birth", field: "dob", width: 150, cellRenderer: (data) => { return moment(data.value).format('DD MMM, YYYY') } },
    { headerName: "Gender", field: "gender", width: 116, suppressSizeToFit: true, cellRenderer: (data) => { return this.getGender(data.value) } },
  ];

  constructor(private intakeService: IntakeService, 
              private movesService: MovesService,
              private driversLicenceScanningService: DriversLicenceScanningService,
              private localStorageService: LocalStorageService,
              private connectivityService: ConnectivityService,
              private readonly spinner: NgxSpinnerService,
              private router: Router,
              private datePipe: DatePipe,
              public titleCasePipe: TitleCasePipe,
              public moddedTitleCasePipe: ModdedTitleCasePipe,
              private ticketService: TicketService) { 
                //Check if TSA feature is allowed or not
                this.availableTSA = environment.availableTSA && this.ticketService.hasTrafficTicketPermissions();

                this.dateOfBirthPickerConfig = Object.assign({}, 
                  {
                    containerClass: 'theme-dark-blue', 
                    showWeekNumbers: false,
                    dateInputFormat: 'YYYY/MM/DD',
                    minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
                    maxDate: new Date(2099,11,31), // 2099/12/31 - Month is 0 based index
                    isAnimated: true,
                    customTodayClass: 'custom-today-class'
                  });
                
                this.licenceExpiryPickerConfig = Object.assign({}, 
                  {
                    containerClass: 'theme-dark-blue', 
                    showWeekNumbers: false,
                    dateInputFormat: 'YYYY/MM/DD',
                    minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
                    maxDate: new Date(2099,11,31), // 2099/12/31 - Month is 0 based index
                    isAnimated: true,
                    customTodayClass: 'custom-today-class'
                  });  
              }

  ngOnInit(): void {
    // Check the health of Ticket Service
    this.checkTicketServiceHealth();

    this.isProductionEnvironment = (environment.name.length >0 && environment.name.toLowerCase() === 'production')? true: false;

    // for offline, set the current Intake App status
    this.localStorageService.setIntakeAppProcess(IntakeAppProcess.CreatingContravention);

    this.spinner.hide(); 
    this.bodyElement = $(document.body);
    this.movesModalOverlay = $(".moves-modal-overlay");
    this.licenceScannerModalOverlay = $(".licence-scanner-modal-overlay");

    //Get latest date where recipient could be 12 years old, for birth date validation
    this.minimumAgeDate = new Date(new Date().getFullYear() - 12, new Date().getMonth(), new Date().getDate());

    //Check if recipient type selection is required
    this.isRecipientTypeSelectionRequired = this.localStorageService.getContraventionTypes().filter(x => x.isApplicableToRegisteredOwner || x.isApplicableToPassenger || x.isApplicableToNotInVehicle).length > 0;

    //Get Types
    this.driverLicenceClassTypes = this.localStorageService.getDriversLicenceClassTypes().filter(x => x.provinceId == 1); //Show only Alberta licence classes
    this.driverLicenceStatusTypes = this.localStorageService.getDriversLicenceStatusTypes();
    this.genderTypes = this.localStorageService.getGenderTypes();
    this.identificationTypes = this.localStorageService.getIdentificationTypes().filter(x => ![IdentificationTypes.DriversLicence, IdentificationTypes.Verbal].includes(x.id));
    this.conditionCodeTypes = this.localStorageService.getConditionCodeTypes();

    //Get stop information object
    this.stopInformation = this.intakeService.getStopInformationModel();

    //Check if this step in the process is allowed or not.
    if (!this.stopInformation?.progressId || this.stopInformation.progressId < SubmissionProgrssStates.RecipientInformation || this.stopInformation.stopInformationId > 0)
      this.router.navigateByUrl('/');

    this.recipient = this.stopInformation.recipient;
    this.recipientIdentification = this.recipient.recipientIdentification;
    this.recipientContact = this.recipient.recipientContact;
    this.movesRecipientContact = this.recipient.movesRecipientContact;
    
    this.isAlbertaDriverLicense = this.recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.Alberta;

    if (this.stopInformation.operatorInformation)
      this.isOnlyMVIDRecord = !this.stopInformation.operatorInformation.operatorNumber;

    //Initialize Driver Contact country province control
    this.recipientContactCountryProvince = new CountryProvinceModel();
    this.recipientContactCountryProvince.countryId = this.recipientContact?.countryId;
    this.recipientContactCountryProvince.provinceId = this.recipientContact?.provinceId;
    this.recipientContactCountryProvince.province = this.recipientContact?.province;
    this.recipientContactCountryProvince.isCountryRequired = true;
    this.recipientContactCountryProvince.isProvinceRequired = true;
    this.recipientContactCountryProvince.countryLabel = "Country";
    this.recipientContactCountryProvince.provinceLabel = "State/Province";
    this.recipientContactCountryProvince.controlSuffixText = "Contact";
    this.recipientContactCountryProvince.isDisabled = this.isRecipientReadOnly() && !this.recipientContact.isAddressDifferentFromMOVES;

    //Initialize Moves Driver Contact country province control
    this.recipientContactCountryProvinceMoves = new CountryProvinceModel();
    this.recipientContactCountryProvinceMoves.countryId = this.movesRecipientContact?.countryId;
    this.recipientContactCountryProvinceMoves.provinceId = this.movesRecipientContact?.provinceId;
    this.recipientContactCountryProvinceMoves.province = this.movesRecipientContact?.province;
    this.recipientContactCountryProvinceMoves.isCountryRequired = false;
    this.recipientContactCountryProvinceMoves.isProvinceRequired = false;
    this.recipientContactCountryProvinceMoves.countryLabel = "Country";
    this.recipientContactCountryProvinceMoves.provinceLabel = "State/Province";
    this.recipientContactCountryProvinceMoves.controlSuffixText = "MovesContact";
    this.recipientContactCountryProvinceMoves.isDisabled = true;

    //Initialize Driver licence country province control
    this.driverLicenceCountryProvince = new CountryProvinceModel();
    this.driverLicenceCountryProvince.countryId = this.recipientIdentification?.issuingCountryId;
    this.driverLicenceCountryProvince.provinceId = this.recipientIdentification?.issuingProvinceId;
    this.driverLicenceCountryProvince.province = this.recipientIdentification?.issuingProvinceOther;
    this.driverLicenceCountryProvince.isCountryRequired = !this.recipient?.isNoLicenceProduced;
    this.driverLicenceCountryProvince.isProvinceRequired = !this.recipient?.isNoLicenceProduced && (this.driverLicenceCountryProvince.countryId == 40 || this.driverLicenceCountryProvince.countryId == 236); //Canada or USA
    this.driverLicenceCountryProvince.countryLabel = "Issuing Country";
    this.driverLicenceCountryProvince.provinceLabel = "Issuing State/Province";
    this.driverLicenceCountryProvince.controlSuffixText = "Licence";
    this.driverLicenceCountryProvince.isDisabled = this.isAlbertaDriverLicense;

    //Initialize Identification country province control
    this.identificationCountryProvince = new CountryProvinceModel();
    this.identificationCountryProvince.countryId = this.recipientIdentification?.issuingCountryId;
    this.identificationCountryProvince.provinceId = this.recipientIdentification?.issuingProvinceId;
    this.identificationCountryProvince.province = this.recipientIdentification?.issuingProvinceOther;
    this.identificationCountryProvince.isCountryRequired = false;
    this.identificationCountryProvince.isProvinceRequired = false;
    this.identificationCountryProvince.countryLabel = "Issuing Country";
    this.identificationCountryProvince.provinceLabel = "Issuing State/Province";
    this.identificationCountryProvince.controlSuffixText = "Identification";
    this.identificationCountryProvince.isDisabled = false;

    switch(this.recipientIdentification?.isGDLDriver){
      case true:{
        this.intIsGDLDriver = 1;
        break;
      }
      case false:{
        this.intIsGDLDriver = 2;
        break;
      }
      default:{
        this.intIsGDLDriver = 0;
      }
    }

    //Set Licence Class based on the issuing country/province
    if (this.recipientIdentification.issuingCountryId == 40) //Canada
    {
      this.driverLicenceClassTypes = this.localStorageService.getDriversLicenceClassTypes().filter(x => x.provinceId == this.recipientIdentification.issuingProvinceId); 
    }

    this.selectedConditionCodes = this.recipientIdentification.conditionCodes?.split(',').map(x=> +x);
    
    //scroll to top with tab change
    window.scroll(0,0);
  }

  onConditionCodesChange(selectedConditionCodesValue: number[])
  {
    this.recipientIdentification.conditionCodes = selectedConditionCodesValue.toString();
  }

  filterConditionCodeTypes(provinceId)
  {
    return this.conditionCodeTypes.filter(x => x.provinceId == provinceId);
  }

  public onDriverLicenceClassificationChange() {
    this.clearRecipientInformation();
    this.recipientIdentification.licencePresentedClassificationId = null;
    switch (this.recipientIdentification.driversLicenceClassificationId) {
      case 1:{
        this.isAlbertaDriverLicense = true;
        this.recipient.isNoLicenceProduced = false;
        break;
      }
      case 2:{
        this.isAlbertaDriverLicense = false;
        this.recipient.isNoLicenceProduced = false;
        break;
      }
      case 3:{
        this.recipient.isNoLicenceProduced = true;
        this.isAlbertaDriverLicense = false;

        // When No MVID and DL history found in MOVES option is selected then auto select the default Licence Presented Classification
        this.recipientIdentification.licencePresentedClassificationId = LicencePresentedClassification.LicenceFoundViaLookup;
        this.onLicencePresentedClassificationChange();

        break;
      }
    }
    
    this.onIsAlbertaLicenceChecked(this.isAlbertaDriverLicense);
    this.NoLicenceProducedChange();
  }

  public onGDLDriverChange(event) {
    switch(event.target.value){
      case "1":{
        this.recipientIdentification.isGDLDriver = true;
        break;
      }
      case "2":{
        this.recipientIdentification.isGDLDriver = false;
        break;
      }
      default:{
        this.recipientIdentification.isGDLDriver = null;
      }
    }
  }

  public refreshContactCountryProvince(e) {
    this.recipientContact.countryId = e.countryId;
    this.recipientContact.provinceId = e.provinceId;
    this.recipientContact.province = e.province;
  }

  public refreshIdentificationCountryProvince(e) {
    this.recipientIdentification.issuingCountryId = e.countryId;
    this.recipientIdentification.issuingProvinceId = e.provinceId;
    this.recipientIdentification.issuingProvinceOther = e.province;
    
    //Set Licence Class based on the issuing country/province
    if (this.recipientIdentification.licencePresentedClassificationId != LicencePresentedClassification.Other)
    {
      if (this.recipientIdentification.issuingCountryId == 40) //Canada
      {
        this.driverLicenceClassTypes = this.localStorageService.getDriversLicenceClassTypes().filter(x => x.provinceId == this.recipientIdentification.issuingProvinceId); 
        this.recipientIdentification.driversLicenceClassTypeId = null;
      }

      this.isAlbertaDriverLicense = this.recipientIdentification.issuingProvinceId == 1;
    }

    //Check if province is required or not
    this.driverLicenceCountryProvince.isProvinceRequired = this.driverLicenceCountryProvince.countryId == 40 || this.driverLicenceCountryProvince.countryId == 236; //Canada or USA
  }

  public NoLicenceProducedChange()
  {
    if (this.recipient.isNoLicenceProduced)
    {
      this.driverLicenceCountryProvince.isCountryRequired = false;
      this.driverLicenceCountryProvince.isProvinceRequired = false;
    }
    else
    {
      this.driverLicenceCountryProvince.isCountryRequired = true;
      if (this.driverLicenceCountryProvince.countryId == 40 || this.driverLicenceCountryProvince.countryId == 236) //Canada or USA
        this.driverLicenceCountryProvince.isProvinceRequired = true;
    }
  }

  onLicencePresentedClassificationChange()
  {
    this.isVerbal = null;
    switch(this.recipientIdentification.licencePresentedClassificationId){
      case LicencePresentedClassification.LicencePresented:{
        this.recipient.isNoLicenceProduced = false;
        this.recipientIdentification.identificationTypeId = IdentificationTypes.DriversLicence;
        break;
      }
      case LicencePresentedClassification.LicenceFoundViaLookup:{
        this.recipient.isNoLicenceProduced = true;
        this.recipientIdentification.identificationTypeId = IdentificationTypes.DriversLicence;
        break;
      }
      case LicencePresentedClassification.Other:{
        this.recipient.isNoLicenceProduced = true;
        this.recipientIdentification.driversLicenceClassificationId = null;
        this.recipientIdentification.identificationTypeId=null;
        this.clearRecipientInformation();
        break;
      }
    }
  }

  onRecipientTypeChange()
  {
    var selectedRecipientTypeId = this.recipient.recipientTypeId;

    this.stopInformation.recipient = new Recipient();
    this.stopInformation.recipient.recipientIdentification = new RecipientIdentification();
    this.stopInformation.recipient.recipientContact = new RecipientContact();

    if (selectedRecipientTypeId != RecipientTypes.RegisteredOwner)
    {
      //Set Defaults
      this.stopInformation.recipient.recipientIdentification.issuingCountryId = 40; //Canada
      this.stopInformation.recipient.recipientIdentification.issuingProvinceId = 1; //Alberta
      
      this.stopInformation.recipient.recipientContact.countryId = 40; //Canada
      this.stopInformation.recipient.recipientContact.provinceId = 1; //Alberta
    }

    this.recipient = this.stopInformation.recipient;
    this.recipientIdentification = this.recipient.recipientIdentification;
    this.recipientContact = this.recipient.recipientContact;

    this.recipient.recipientTypeId = selectedRecipientTypeId;
 }

  onVerbalClassificationChange()
  {
    this.recipientIdentification.identificationTypeId=null;
    this.clearRecipientInformation();
    if (this.isVerbal)
      this.recipientIdentification.identificationTypeId = IdentificationTypes.Verbal;
    else
      this.recipientIdentification.identificationTypeId = null;
  }

  onLicenceClassChange()
  {
    if (this.recipientIdentification.driversLicenceClassTypeId != 5) {
      this.recipientIdentification.isGDLDriver = false;
      this.intIsGDLDriver = 2; //No
    }
    else
    {
      this.recipientIdentification.isGDLDriver = null;
      this.intIsGDLDriver = 0; //Select
    }
  }

  onSubmitClick(isValid: boolean, routeLink: string)
  {
    this.isSubmitClicked = true;
    this.recipientContactCountryProvince.isSubmitClicked = true;
    this.driverLicenceCountryProvince.isSubmitClicked = true;
    this.identificationCountryProvince.isSubmitClicked = true;
    if (isValid) {
      //Reset Driver's Licence Class
      if (this.recipientIdentification.issuingCountryId == 40) //Canada
        this.recipientIdentification.driversLicenceClass = null;
      else
        this.recipientIdentification.driversLicenceClassTypeId = null;  

      if (this.recipientContact?.postalCode)
        this.recipientContact.postalCode = this.recipientContact.postalCode.toUpperCase();

      this.stopInformation.progressId = SubmissionProgrssStates.ContraventionSelection;
       
      //Assign updated recipient information to every contravention
      this.stopInformation.contraventions.forEach(contravention => {
        contravention.recipient = this.stopInformation.recipient;        
      });

      if (!this.recipientIdentification.isGDLDriver && this.stopInformation.contraventions.filter(x=>x.contraventionTypeId == ContraventionTypes.IRSZeroNovice)?.length>0)
        this.stopInformation.contraventions.find(x=>x.contraventionTypeId == ContraventionTypes.IRSZeroNovice).contraventionTypeId = null;

      this.intakeService.saveStopInformationContext();
      this.router.navigateByUrl(routeLink);
    } 
    else
      window.scroll(0,0);  
  }

  onSearchMOVES()
  {
    this.operatorSearch = new OperatorSearch();
    this.operatorInformation = null;
    this.showHideModal(true);
  }

  async onScanDriversLicence()
  {
    await this.showHideLicenceModal(true);  
    try{   
        var result = await this.driversLicenceScanningService.onDataRead();
        if (result && result.lastName && result.lastName?.length > 0)
          {
            this.autoFillLicenceScanInfo(result);
            //MOVES call to lookup AB licence status and other recent details 
            if(result.operatorNumber.length > 0 && this.isAlbertaDriverLicense)
            {
              this.onBlurCallMoves(result.operatorNumber, true, true);
            }
          }
         await this.showHideLicenceModal(false);        
    }
    catch (error) {
      await this.showHideLicenceModal(false);      
    }
  }

  showMOVESModal()
  {
    this.onSearchMOVES();
  }

  showHideModal(show?: boolean): void {
    if (show) {
      this.errorMessage = null;
      this.bodyElement.addClass("overflow-hidden");
      this.movesModalOverlay.addClass("modal-show");
    } 
    else 
    {
      this.bodyElement.removeClass("overflow-hidden");
      this.movesModalOverlay.removeClass("modal-show");
      this.isSearchClicked = false;
    }
  }

  async showHideLicenceModal(show?: boolean, byUser?: boolean): Promise<void> {
    if (show) {
      this.licenceScanErrorMessage = null;
      this.bodyElement.addClass("overflow-hidden");
      this.licenceScannerModalOverlay.addClass("modal-show");
    } 
    else 
    {
      this.bodyElement.removeClass("overflow-hidden");
      this.licenceScannerModalOverlay.removeClass("modal-show");
      if(byUser)
        await this.driversLicenceScanningService.onCancelScanning();
    }
  }

  onCallSearchMOVES(isValid: boolean)
  {
    this.isSearchClicked = true;
    if (isValid)
    {
      this.connectivityService.getOnlineStatusAsync().then( isOnline => {
        if (isOnline) {
          this.spinner.show(); 
          this.movesService.getOperatorAsync(this.operatorSearch.operatorNumber?.trim())
          .subscribe(result => {
            this.spinner.hide(); 
            if (result.licenseStatus?.toLowerCase() == 'unsure')
            {
              this.operatorInformation = null;
              this.errorMessage = "Driver's licence status is 'Unsure'. Please try later or contact MOVES."
            }
            else
            {
              this.operatorInformation = [];
              this.operatorInformation.push(result);
              this.errorMessage = null;
            }
          },
          error => {
            this.showErrors(error);
            this.operatorInformation = null;
            this.spinner.hide(); 
          }); 
        }
      });
    }
  }

  showErrors(error: any) {
    if (error?.status == 400 && error?.error && Array.isArray(error?.error))
        this.errorMessage = error.error[0].error;
    else if (error?.status == 400 && (typeof error?.error === 'string' || error?.error instanceof String))
        this.errorMessage = error.error;
    else if (error?.status == 400 && (typeof error?.error?.error === 'string' || error?.error?.error instanceof String))
        this.errorMessage = error.error.error;
    else    
        this.errorMessage = "Something went wrong while calling MOVES service. Please try again later.";
  }

  onSelectMatch()
  {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    if (selectedNodes.length > 0)
    {
      this.autoFillMovesInfo(selectedNodes[0].data)
      this.showHideModal(false);
    }
  }

  autoFillMovesInfo(operatorInfo: OperatorInformation)
  {
    this.stopInformation.operatorInformation = operatorInfo;

    this.recipient.firstName =  operatorInfo.firstName;
    this.recipient.otherName =  operatorInfo.middleName;
    this.recipient.lastName =  operatorInfo.lastName;
    this.recipient.height = operatorInfo.height;
    this.recipient.weight = operatorInfo.weight;
    this.recipient.hairColor = operatorInfo.hairColor;
    this.recipient.eyeColor = operatorInfo.eyeColor;

    this.recipientIdentification.dateOfBirth = new Date(operatorInfo.dob);
    this.recipientIdentification.identificationNumber = operatorInfo.operatorNumber;
    this.recipientIdentification.motorVehicleIdentificationNumber = operatorInfo.mvid;
    this.recipientIdentification.identificationExpiryDate = new Date(operatorInfo.expiry);
    this.recipientIdentification.isGDLDriver = operatorInfo.gdlDriver;
    this.isOnlyMVIDRecord = !operatorInfo.operatorNumber;
    
    switch(this.recipientIdentification?.isGDLDriver){
      case true:{
        this.intIsGDLDriver = 1;
        break;
      }
      case false:{
        this.intIsGDLDriver = 2;
        break;
      }
      default:{
        this.intIsGDLDriver = 0;
      }
    }
    
    this.recipientIdentification.issuingCountryId = 40 //Always Canada in MOVES
    this.recipientIdentification.issuingProvinceId = 1 //Always Alberta in MOVES
    this.recipientIdentification.issuingProvinceOther = "";
    this.driverLicenceCountryProvince.countryId = 40 
    this.driverLicenceCountryProvince.provinceId = 1 
    this.driverLicenceCountryProvince.province = "";
    
    this.recipientIdentification.restrictions = operatorInfo.restrictTexts.map(s => s.ffRestrictText).join("\n");
    this.selectedConditionCodes = operatorInfo.conditionCodes.map(s => this.filterConditionCodeTypes(1).find(x => x.code == s.code)?.id);
    this.recipientIdentification.conditionCodes = this.selectedConditionCodes?.join(",");
    

    this.recipientIdentification.genderId = this.localStorageService.getGenderTypes().find(x=> x.code == operatorInfo.gender)?.id;
    this.recipientIdentification.driversLicenceClassTypeId = operatorInfo.class?.length>0? this.localStorageService.getDriversLicenceClassTypes().find(x => x.code == operatorInfo.class.substring(0,1))?.id : null;
    this.recipientIdentification.driversLicenceStatusTypeId = operatorInfo.licenseStatus? this.localStorageService.getDriversLicenceStatusTypes().find(x=> x.name == operatorInfo.licenseStatus)?.id : DriversLicenceStatusTypes.Unknown;
    
    this.recipientContact.addressLine1 = operatorInfo.addressLine1;
    this.recipientContact.addressLine2 = operatorInfo.addressLine2;
    this.recipientContact.city = operatorInfo.city;

    this.recipientContact.countryId = this.localStorageService.getCountryTypes().find(x=> x.name == operatorInfo.country)?.id;
    this.recipientContactCountryProvince.countryId = this.recipientContact.countryId;

    if (this.recipientContact.countryId == 40 || this.recipientContact.countryId == 236) // Canada or USA
    {
      this.recipientContact.provinceId = this.localStorageService.getProvinceTypes().find(x=> x.code == operatorInfo.province)?.id;
      this.recipientContact.province = "";

      this.recipientContactCountryProvince.provinceId = this.recipientContact.provinceId;
      this.recipientContactCountryProvince.province = "";
    }
    else
    {
      this.recipientContact.province = operatorInfo.province;
      this.recipientContact.provinceId = null;
      
      this.recipientContactCountryProvince.provinceId = null
      this.recipientContactCountryProvince.province = this.recipientContact.province;
    }
    this.recipientContact.postalCode = operatorInfo.postalCode;
    this.recipientContact.phoneNumber1 = operatorInfo.homePhone;
    this.recipientContact.phoneNumber2 = operatorInfo.businessPhone;
  }

  autoFillLicenceScanInfo(operatorInfo: OperatorInformation)
  {
    this.stopInformation.operatorInformation = operatorInfo;

    this.recipient.firstName =  operatorInfo.firstName;
    this.recipient.otherName =  operatorInfo.middleName;
    this.recipient.lastName =  operatorInfo.lastName;
    this.recipient.height = operatorInfo.height;
    this.recipient.weight = operatorInfo.weight;
    this.recipient.hairColor = operatorInfo.hairColor;
    this.recipient.eyeColor = operatorInfo.eyeColor;

    this.recipientIdentification.dateOfBirth = new Date(operatorInfo.dob);
    this.recipientIdentification.identificationNumber = operatorInfo.operatorNumber;
    this.recipientIdentification.motorVehicleIdentificationNumber = operatorInfo.mvid;
    this.recipientIdentification.identificationExpiryDate = new Date(operatorInfo.expiry);
    this.recipientIdentification.isGDLDriver = operatorInfo.gdlDriver;
    this.isOnlyMVIDRecord = !operatorInfo.operatorNumber;
    
    switch(this.recipientIdentification?.isGDLDriver){
      case true:{
        this.intIsGDLDriver = 1;
        break;
      }
      case false:{
        this.intIsGDLDriver = 2;
        break;
      }
      default:{
        this.intIsGDLDriver = 0;
      }
    }
    
    
    let countryId = this.localStorageService.getCountryTypes().find(x=> x.code == operatorInfo.issuingCountry);
    let provienceId = this.localStorageService.getProvinceTypes().find(x=> x.code == operatorInfo.issuingProvince);
      
    this.recipientIdentification.issuingProvinceOther = operatorInfo.issuingProvince;
    this.recipientIdentification.issuingCountryId = countryId ? countryId.id : null;
    this.recipientIdentification.issuingProvinceId = provienceId ? provienceId.id : null;

    this.driverLicenceCountryProvince.countryId = countryId ? countryId.id : null;
    this.driverLicenceCountryProvince.provinceId = provienceId ? provienceId.id : null; 
    this.driverLicenceCountryProvince.province = operatorInfo.issuingProvince;
    
    this.recipientIdentification.genderId = this.localStorageService.getGenderTypes().find(x=> x.code == operatorInfo.gender)?.id;
    this.recipientIdentification.driversLicenceClassTypeId = operatorInfo.class?.length>0? this.localStorageService.getDriversLicenceClassTypes().find(x => x.code == operatorInfo.class.substring(0,1))?.id : null;
    this.recipientIdentification.driversLicenceStatusTypeId = operatorInfo.licenseStatus? this.localStorageService.getDriversLicenceStatusTypes().find(x=> x.name == operatorInfo.licenseStatus)?.id : DriversLicenceStatusTypes.Unknown;
    
    this.recipientContact.addressLine1 = operatorInfo.addressLine1;
    this.recipientContact.addressLine2 = operatorInfo.addressLine2;
    this.recipientContact.city = operatorInfo.city;

    if (operatorInfo.country && operatorInfo.country.length > 1) {
      this.recipientContact.countryId = this.localStorageService.getCountryTypes().find(x=> x.code == operatorInfo.country.slice(0,2))?.id;
      this.recipientContactCountryProvince.countryId = this.recipientContact.countryId;
    }

    if (this.recipientContact.countryId == 40 || this.recipientContact.countryId == 236) // Canada or USA
    {
      this.recipientContact.provinceId = this.localStorageService.getProvinceTypes().find(x=> x.code == operatorInfo.province)?.id;
      this.recipientContact.province = "";

      this.recipientContactCountryProvince.provinceId = this.recipientContact.provinceId;
      this.recipientContactCountryProvince.province = "";

      this.recipientContactCountryProvince.countryId = this.recipientContact.countryId;
    }
    else
    {
      this.recipientContact.province = operatorInfo.province;
      this.recipientContact.provinceId = null;
      
      this.recipientContactCountryProvince.provinceId = null
      this.recipientContactCountryProvince.province = this.recipientContact.province;
    }
    this.recipientContact.postalCode = operatorInfo.postalCode;
    this.recipientContact.phoneNumber1 = operatorInfo.homePhone;
    this.recipientContact.phoneNumber2 = operatorInfo.businessPhone;
  }

  getGender(gender: any) {
    switch (gender)
    {
      case "M":
        return "Male";
      case "F":
        return "Female";
      case "X":
        return "Other/Unknown";
      default:
        return "";      
    }
  }

  onNoFixedAddressChecked(isChecked)
  {
    this.recipientContactCountryProvince.isCountryRequired = !isChecked;
    this.recipientContactCountryProvince.isProvinceRequired = !isChecked;
    if (isChecked && !this.isRecipientReadOnly())
      this.recipientContactCountryProvince.countryId = null;
  }

  onIsAlbertaLicenceChecked(isChecked)
  {
    this.driverLicenceCountryProvince.isDisabled = isChecked;
    this.recipientIdentification.issuingProvinceOther = null;
    this.recipientIdentification.driversLicenceClass = null;

    if (isChecked) {
      this.driverLicenceCountryProvince.countryId = 40; //Canada
      this.driverLicenceCountryProvince.provinceId = 1; //Alberta
      this.driverLicenceCountryProvince.province = null;

      this.recipientIdentification.issuingCountryId = 40;
      this.recipientIdentification.issuingProvinceId = 1;
    
      this.driverLicenceClassTypes = this.localStorageService.getDriversLicenceClassTypes().filter(x => x.provinceId == 1); 
      this.recipientIdentification.driversLicenceClassTypeId = null;
    } else {
      this.driverLicenceCountryProvince.countryId = null;

      this.recipientIdentification.issuingCountryId = null;
      this.recipientIdentification.issuingProvinceId = null;
      this.recipientIdentification.issuingProvinceOther = null;
    }
  }

  onBlurCallMoves(dlOrMVIDNo, isValid: boolean, isDriversLicence: boolean)
  {
    if (isValid && this.isAlbertaDriverLicense && (!this.stopInformation.operatorInformation || this.stopInformation.operatorInformation.operatorNumber != this.recipientIdentification.identificationNumber || this.stopInformation.operatorInformation.mvid != this.recipientIdentification.motorVehicleIdentificationNumber))
    {
      this.connectivityService.getOnlineStatusAsync().then( isOnline => {
        if (isOnline) {

          //Fix the format before calling MOVES service
          if ((isDriversLicence && dlOrMVIDNo.length > 3) || (!isDriversLicence && dlOrMVIDNo.length > 6))
          {   
            dlOrMVIDNo = dlOrMVIDNo.replace(/-/g, "").trim();
            dlOrMVIDNo = dlOrMVIDNo.slice(0, dlOrMVIDNo.length-(isDriversLicence?3:5)) + "-" + dlOrMVIDNo.slice(dlOrMVIDNo.length-(isDriversLicence?3:5));
          }
          
          this.spinner.show(); 
          this.movesService.getOperatorAsync(dlOrMVIDNo?.trim())
          .subscribe(result => {
            this.errorMessage = null;
            if (result.licenseStatus?.toLowerCase() != 'unsure')
            {
              this.recipient.movesLookupDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')
              this.recipientContactCountryProvince.isDisabled = true;
              this.recipientContact.isNoFixedAddress = false;
              this.autoFillMovesInfo(result)
            }
            this.spinner.hide(); 
          },
          error => {
            this.showErrors(error);
            this.clearRecipientInformation();
            this.spinner.hide(); 
          }); 
    
        } 
      });

    }
  }

  onAddressDifferentFromMovesChange()
  {
    if(!this.isRecipientReadOnly()) return;
    
    if (this.recipientContact.isAddressDifferentFromMOVES)
    {
      //Preserve moves lookup address information first
      this.recipient.movesRecipientContact = new MovesRecipientContact()
      this.movesRecipientContact = this.recipient.movesRecipientContact;

      this.movesRecipientContact.addressLine1 = this.recipientContact.addressLine1;
      this.movesRecipientContact.addressLine2 = this.recipientContact.addressLine2;
      this.movesRecipientContact.addressLine3 = this.recipientContact.addressLine3;
      this.movesRecipientContact.countryId = this.recipientContact.countryId;
      this.movesRecipientContact.provinceId = this.recipientContact.provinceId;
      this.movesRecipientContact.province = this.recipientContact.province;
      this.movesRecipientContact.city = this.recipientContact.city;
      this.movesRecipientContact.postalCode = this.recipientContact.postalCode;
      this.movesRecipientContact.phoneNumber1 = this.recipientContact.phoneNumber1;
      this.movesRecipientContact.phoneNumber2 = this.recipientContact.phoneNumber2;
      this.movesRecipientContact.phoneNumber3 = this.recipientContact.phoneNumber3;
      this.movesRecipientContact.emailAddress = this.recipientContact.emailAddress;

      //Initialize Moves Driver Contact country province control
      this.recipientContactCountryProvinceMoves = new CountryProvinceModel();
      this.recipientContactCountryProvinceMoves.countryId = this.movesRecipientContact.countryId;
      this.recipientContactCountryProvinceMoves.provinceId = this.movesRecipientContact.provinceId;
      this.recipientContactCountryProvinceMoves.province = this.movesRecipientContact.province;
      this.recipientContactCountryProvinceMoves.isCountryRequired = false;
      this.recipientContactCountryProvinceMoves.isProvinceRequired = false;
      this.recipientContactCountryProvinceMoves.countryLabel = "Country";
      this.recipientContactCountryProvinceMoves.provinceLabel = "State/Province";
      this.recipientContactCountryProvinceMoves.controlSuffixText = "MovesContact";
      this.recipientContactCountryProvinceMoves.isDisabled = true;

      this.recipientContactCountryProvince.isDisabled = false;

      //Clear Recipient Address information to manually enter it
      this.clearRecipientContactInformation();
    }
    else
    {
      //Restore all driver contact information to it's original state
      this.recipientContact.addressLine1 = this.movesRecipientContact.addressLine1;
      this.recipientContact.addressLine2 = this.movesRecipientContact.addressLine2;
      this.recipientContact.addressLine3 = this.movesRecipientContact.addressLine3;
      this.recipientContact.countryId = this.movesRecipientContact.countryId;
      this.recipientContact.provinceId = this.movesRecipientContact.provinceId;
      this.recipientContact.province = this.movesRecipientContact.province;
      this.recipientContact.city = this.movesRecipientContact.city;
      this.recipientContact.postalCode = this.movesRecipientContact.postalCode;
      this.recipientContact.phoneNumber1 = this.movesRecipientContact.phoneNumber1;
      this.recipientContact.phoneNumber2 = this.movesRecipientContact.phoneNumber2;
      this.recipientContact.phoneNumber3 = this.movesRecipientContact.phoneNumber3;
      this.recipientContact.emailAddress = this.movesRecipientContact.emailAddress;

      this.movesRecipientContact = null;
      this.recipient.movesRecipientContact = null;

      this.recipientContactCountryProvince.isDisabled = true;
      this.recipientContactCountryProvince.countryId = this.recipientContact.countryId;
      this.recipientContactCountryProvince.provinceId = this.recipientContact.provinceId;
      this.recipientContactCountryProvince.province = this.recipientContact.province;
    }
  }

  isRecipientReadOnly()
  {
    return this.recipient.movesLookupDate && this.recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.Alberta
  }

  private clearRecipientInformation()
  {
    this.recipientIdentification.identificationNumber="";
    this.recipientIdentification.motorVehicleIdentificationNumber="";
    this.recipient.firstName = "";
    this.recipient.otherName = "";
    this.recipient.lastName = "";
    this.recipientIdentification.issuingCountryId=null;
    this.recipientIdentification.issuingProvinceId=null;
    this.identificationCountryProvince.countryId=null;
    this.identificationCountryProvince.provinceId=null;
    this.identificationCountryProvince.province="";
    this.recipientIdentification.issuingProvinceOther="";
    this.recipientIdentification.driversLicenceClassTypeId=null;
    this.recipientIdentification.driversLicenceStatusTypeId=null;
    this.recipientIdentification.identificationExpiryDate=null;
    this.recipientIdentification.isGDLDriver=null;
    this.recipientIdentification.dateOfBirth=null;
    this.recipientIdentification.conditionCodes="";
    this.selectedConditionCodes=null;
    this.recipientIdentification.restrictions="";
    this.recipientIdentification.genderId=null;
    this.recipient.eyeColor="";
    this.recipient.hairColor="";
    this.recipient.weight="";
    this.recipient.height="";
    this.recipient.movesLookupDate = null;
    this.recipientContact.isAddressDifferentFromMOVES = false;
    this.recipientContact.isNoFixedAddress = false;
    this.recipientContactCountryProvince.isDisabled = false;
    this.recipient.movesRecipientContact = null;
    this.clearRecipientContactInformation();
  }

  private clearRecipientContactInformation()
  {
    this.recipientContact.addressLine1 = "";
    this.recipientContact.addressLine2 = "";
    this.recipientContact.addressLine3 = "";
    this.recipientContact.countryId = null;
    this.recipientContact.provinceId = null;
    this.recipientContact.province = "";
    this.recipientContact.city = "";
    this.recipientContact.postalCode = "";
    this.recipientContact.phoneNumber1 = "";
    this.recipientContact.phoneNumber2 = "";
    this.recipientContact.phoneNumber3 = "";
    this.recipientContact.emailAddress = "";

    this.recipientContactCountryProvince.countryId = null;
    this.recipientContactCountryProvince.provinceId = null;
    this.recipientContactCountryProvince.province = "";
  }

  private checkTicketServiceHealth()
  {
    this.ticketService.getTicketServiceHealthCheck()        
    .subscribe((result: string) => {
      if (result.toLowerCase() != Constants.Status.HEALTHCHECK_HEALTHY_TEXT)
        this.ticketsHealthStatusMessage = Constants.Intake.Messages.TICKETS_HEALTH_STATUS_MESSAGE;
      else
        this.ticketsHealthStatusMessage = "";        
    }, () => {
      this.ticketsHealthStatusMessage = Constants.Intake.Messages.TICKETS_HEALTH_STATUS_MESSAGE;
    });
  }
}
