import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgModel, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocationType } from '@apis/shared/models/types/location-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { Fine } from '@apis/shared/models/fine.model';
import { ContraventionDetailTypes, ContraventionTypes, IdentificationTypes, IntakeAppProcess, OffenceTypes, RecipientTypes, SubmissionProgrssStates } from '@apis/shared/enums/app.enum';
import { MovesService } from 'apps/intake/src/shared/services/moves.service';
import { ConnectivityService } from 'apps/intake/src/shared/services/connectivity.service';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { IntakeUser } from 'apps/intake/src/shared/models/intake-user.model';
import { PayCentreType } from '@apis/shared/models/types/pay-centre-type.model';
import { environment } from 'apps/intake/src/environments/environment';
import { TicketService } from 'apps/intake/src/shared/services/ticket.service';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-contravention-selection',
  templateUrl: './contravention-selection.component.html',
  styleUrls: ['./contravention-selection.component.scss']
})
export class ContraventionSelectionComponent implements OnInit {
  stopInformation: StopInformation;
  contraventions: Contravention[];
  locationControl = new UntypedFormControl();
  confirmModalOverlay: JQuery<HTMLElement>;
  locationTypes: any;
  selectedLocationType: LocationType;
  filteredLocations: Observable<string[]>;
  occurrenceDate: Date;
  occurrenceTime: string;
  datePickerConfig: Partial<BsDatepickerConfig>;
  occurrenceDatePickerConfig: Partial<BsDatepickerConfig>;
  currentDateTime: Date;
  isFutureOccurrenceDateTime: boolean = false;
  isSubmitClicked: boolean = false;

  Constants = Constants;
  errorMessage: string;
  RecipientTypes = RecipientTypes;
  irsDetailsSelected: string[];
  descriptionsSelectedText ='';
  user: IntakeUser;
  payCentreTypes: PayCentreType[];
  availableTSA: boolean = false;
  contraventionTypes: ContraventionType[];

  @ViewChild("occurrenceDateControl") occurrenceDateControl: NgModel;
  @ViewChild("occurrenceTimeControl") occurrenceTimeControl: NgModel;

  constructor(private intakeService: IntakeService,
              private movesService: MovesService,
              private connectivityService: ConnectivityService,
              private localStorageService: LocalStorageService,
              private router: Router,
              private ticketService: TicketService) { 
                //Check if TSA feature is allowed or not
                this.availableTSA = environment.availableTSA && this.ticketService.hasTrafficTicketPermissions();
                
                this.datePickerConfig = Object.assign({}, 
                  {
                    containerClass: 'theme-dark-blue', 
                    showWeekNumbers: false,
                    dateInputFormat: 'YYYY/MM/DD',
                    minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
                    maxDate: new Date(2099,11,31), // 2099/12/31 - Month is 0 based index
                    isAnimated: true,
                    customTodayClass: 'custom-today-class'
                  })

                this.occurrenceDatePickerConfig = Object.assign({}, 
                  {
                    containerClass: 'theme-dark-blue', 
                    showWeekNumbers: false,
                    dateInputFormat: 'YYYY/MM/DD',
                    minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
                    isAnimated: true,
                    customTodayClass: 'custom-today-class'
                  })  
                //Get Location Types
                this.locationTypes = this.localStorageService.getLocationTypes();
                this.locationControl.setValidators(this.validateLocations(this.locationTypes));
              }

  ngOnInit(): void {

    // for offline, set the current Intake App status
    this.localStorageService.setIntakeAppProcess(IntakeAppProcess.CreatingContravention);
    this.confirmModalOverlay = $(".confirm-modal-overlay");

    //Get Types
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    this.locationTypes = this.localStorageService.getLocationTypes();
    this.payCentreTypes = this.localStorageService.getPayCentreTypes();
    this.filteredLocations = this.locationControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterLocation(value))
    );

    //Get user profile
    this.user = this.localStorageService.getUser();

    //Get stop information object
    this.stopInformation = this.intakeService.getStopInformationModel();
    this.contraventions = this.stopInformation.contraventions;

    //Check if this step in the process is allowed or not.
    if (!this.stopInformation?.progressId || this.stopInformation.progressId < SubmissionProgrssStates.ContraventionSelection || this.stopInformation.stopInformationId > 0)
      this.router.navigateByUrl('/contravention/submission/recipient-information');
    
    this.occurrenceDate = this.stopInformation.contraventions[0].occurrenceDate;
    this.occurrenceTime = this.stopInformation.contraventions[0].occurrenceTime;
    this.selectedLocationType = this.locationTypes.find(x => x.id == this.stopInformation.occurrenceLocation?.locationTypeId);

    //IRS Look back logic (If recipient is driver, identification is Driver's Licence and Province is Alberta)
    if ( this.stopInformation.recipient.recipientTypeId == RecipientTypes.Driver
      && this.stopInformation.recipient.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence 
      && this.stopInformation.recipient.recipientIdentification.issuingProvinceId == 1)
    {
      if (!this.stopInformation.operatorInformation)
      {
        this.connectivityService.getOnlineStatusAsync().then( isOnline => {
          if (isOnline) {
            this.movesService.getOperatorAsync(this.stopInformation.recipient.recipientIdentification.identificationNumber??this.stopInformation.recipient.recipientIdentification.motorVehicleIdentificationNumber)
              .subscribe(result => {
              this.stopInformation.operatorInformation = result;
            },
            error => {
              this.stopInformation.operatorInformation = null;
            }); 
          }
        });
      }
    }

    //Set default municipality location
    if (this.selectedLocationType == null)
    {
      this.selectedLocationType = this.locationTypes.find(x => x.id == this.user?.defaultMunicipalityLocationId);
    }

    // Get the current date and time, for occurrence date/time validation
    if (this.connectivityService.isUserOnline()) {
      this.intakeService.getCurrentMSTDateAsync().subscribe((receivedDateTime) => {
        this.currentDateTime = typeof receivedDateTime === 'string' ? new Date(receivedDateTime) : receivedDateTime;
      });
    }

    //scroll to top with tab change
    window.scroll(0,0);
  }

  validateLocations(locationNames: LocationType[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = locationNames.findIndex(x => {
        return (new RegExp('\^' + x.name + '\$')).test(control.value?.name);
      });
      return index < 0 ? { 'forbiddenLocations': { value: control.value } } : null;
    };
  }

  displayLocation(subject){
    return subject? subject.name : undefined;
  }

  onOccurrenceDateChange(occurrenceDate: Date)
  {
    this.validateOccurrenceDateTime(occurrenceDate, this.occurrenceTime);
  }

  onOccurrenceTimeChange()
  {
    this.validateOccurrenceDateTime(this.occurrenceDate, this.occurrenceTime);
  }

  validateOccurrenceDateTime(occurrenceDate: Date, occurrenceTime: string)
  {
    if (!occurrenceDate || !occurrenceTime || !this.currentDateTime) {
      return;
    }

    const inputTimeArray = occurrenceTime.split(':'); //occurrenceTime is the time in string format, "XX:XX"
    const inputHour = inputTimeArray[0];
    const inputMinute = inputTimeArray[1];
    const inputDateTime = new Date(new Date(occurrenceDate).getFullYear(), new Date(occurrenceDate).getMonth(), new Date(occurrenceDate).getDate(), parseInt(inputHour), parseInt(inputMinute));

    this.isFutureOccurrenceDateTime = inputDateTime > this.currentDateTime;
  }

  onRoadsideAppealConfirmation(isValid: boolean)
  {
    var irsContraventionTypeIds = this.contraventionTypes.filter(x => x.isIRS).map(s => s.id);
    var irsContravention: Contravention = this.contraventions.find(x => irsContraventionTypeIds.includes(+x.contraventionTypeId));

    // JTI-3170
    //
    var confirmationRequired = false;
    if (irsContravention!==null && irsContravention!==undefined) {

      var detailsSelected = irsContravention.contraventionDetails?.split(",");
      var totalDetailsSelected = detailsSelected?.length;

      if (irsContravention.contraventionTypeId == ContraventionTypes.IRSFail1st ||
          irsContravention.contraventionTypeId == ContraventionTypes.IRSFail2nd ||
          irsContravention.contraventionTypeId == ContraventionTypes.IRSFail3rd ) {
        if (totalDetailsSelected>1) {
          if (detailsSelected.includes(ContraventionDetailTypes.IRSFail1stRefusal.toString()) ||
              detailsSelected.includes(ContraventionDetailTypes.IRSFail2ndRefusal.toString()) ||
              detailsSelected.includes(ContraventionDetailTypes.IRSFail3rdRefusal.toString())) {
                confirmationRequired = true;
          }
        }
      }
    } 

    // if refusal is the only description selected .. then, we don't need the user to confirm
    if (!confirmationRequired) {
      this.onSubmitClick(isValid);
    } else {

      this.descriptionsSelectedText ='';
      let index = 0;
      let contraventionDetailTypes = this.localStorageService.getContraventionDetailTypes();
      contraventionDetailTypes.filter(x => x.contraventionTypeId == irsContravention.contraventionTypeId);
      detailsSelected.forEach( d => {
        this.descriptionsSelectedText += contraventionDetailTypes.filter(x => x.id == +d).map(n => n.name);
  
        // determine if we need a comma or an and
        index++;
        if (index < detailsSelected.length) {
          if (index >= detailsSelected.length-1)
            this.descriptionsSelectedText += ' and ';
          else
            this.descriptionsSelectedText += ', ';
        }
      })
  
      this.showHideConfirmModal(true);
    }
  }


  onSubmitClick(isValid: boolean)
  {
    this.isSubmitClicked = true;
    
    if (isValid
      && !this.isFutureOccurrenceDateTime
      && this.locationControl.valid
      && this.isValidChargeSelected()
    ) {
      
      this.stopInformation.occurrenceLocation.locationTypeId = this.selectedLocationType.id;
      this.stopInformation.ticketPayCentreTypeId = this.selectedLocationType.payCentreTypeId;

      // this will force connectivityService.isUserOnline() update
      this.connectivityService.getOnlineStatusAsync().then( isOnline => {
        this.intakeService.getCurrentMSTDateAsync()
        .subscribe(
          ((currentDate) => {
            //calculate and set fine fields.
            this.contraventions.forEach(contravention => {
              contravention.occurrenceDate = this.occurrenceDate;
              contravention.occurrenceTime = this.occurrenceTime;
            
              //Trim the time from occurrence date
              contravention.occurrenceDate.setHours(0, 0, 0, 0);
              if (!contravention.issueDate)
                contravention.issueDate = new Date(currentDate);
              contravention.issueDate.setHours(0, 0, 0, 0);  
           
              var dueDate: Date = new Date(contravention.issueDate);
              dueDate.setDate(dueDate.getDate() + 90);
              contravention.fine.dueDate = dueDate;
              contravention.fine.originalDueDate = dueDate;
              contravention.fine.latePenaltyAmount = contravention.fine.fineAmount * 0; // JTI-664: Set late penalty to 0
              contravention.fine.latePenaltyOverride = false;
            });
  
            // JTI-3353
            // Get IRS Contravention Object
            let isCommercialIRSZero = false;
            var irsContraventionTypeIds = this.contraventionTypes.filter(x => x.isIRS).map(s => s.id);
            var irsContravention: Contravention = this.contraventions.find(x => irsContraventionTypeIds.includes(+x.contraventionTypeId));
            if (irsContravention!=null) {
              isCommercialIRSZero = irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial1st ||
                                    irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial2nd ||
                                    irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial3rd;
              if (isCommercialIRSZero) {
                irsContravention.isNoVehicleSeizureMade = true;
                irsContravention.noVehicleSeizureDetails = 'Contravention type: IRS ZERO: Commercial contravention';
              }
            }
  
            if (this.stopInformation.recipient.recipientTypeId == RecipientTypes.NotInVehicle)
            {
              this.stopInformation.progressId = SubmissionProgrssStates.PrintAndIssue;
              this.intakeService.saveStopInformationContext();
              this.router.navigateByUrl('/contravention/submission/print-and-issue');
            }
            else
            {  
              this.stopInformation.progressId = SubmissionProgrssStates.VehicleDetails;
              this.intakeService.saveStopInformationContext();
              this.router.navigateByUrl('/contravention/submission/vehicle-information');     
            }
          })
        );
      });

    } 
    else 
      window.scroll(0,0); 
  }

  showHideConfirmModal(show?: boolean): void {
    if (show) {
      // Update the list of descriptions selected
      //
      var irsContraventionTypeIds = this.contraventionTypes.filter(x => x.isIRS).map(s => s.id);
      var irsContravention: Contravention = this.contraventions.find(x => irsContraventionTypeIds.includes(+x.contraventionTypeId));
      this.irsDetailsSelected = irsContravention.contraventionDetails?.split(",");
      this.confirmModalOverlay.addClass("modal-show");
    } 
    else 
    {
      this.confirmModalOverlay.removeClass("modal-show");
    }
  }

  isValidChargeSelected()
  {
    var intChargeCount = 0;
    //Check if any charge is not selected
    intChargeCount = this.contraventions.filter(x => (!x.contraventionTypeId || x.contraventionTypeId==0))?.length;
    if (intChargeCount > 0)
    {
      this.errorMessage = "Charge selection is required to proceed.";
      return false;
    }

    //Check if any IRS is selected
    var contraventionTypeIds = this.contraventionTypes.filter(x => x.isIRS).map(s => s.id);
    intChargeCount = this.contraventions.filter(x => contraventionTypeIds.includes(x.contraventionTypeId))?.length;
    this.stopInformation.isIRSSelected = intChargeCount > 0;

    if (intChargeCount > 1)
    {
      this.errorMessage = "More than one IRS charges are selected. There could be only one.";
      return false;
    }

    //Check if any SDP is selected which is issued under TSA
    contraventionTypeIds = this.contraventionTypes.filter(x => x.isTSASDP).map(s => s.id);
    intChargeCount = this.contraventions.filter(x => contraventionTypeIds.includes(x.contraventionTypeId))?.length;
    this.stopInformation.isTSASDPSelected = intChargeCount > 0;

    //Check if any SDP is selected
    contraventionTypeIds = this.contraventionTypes.filter(x => x.isSDP).map(s => s.id);
    intChargeCount = this.contraventions.filter(x => contraventionTypeIds.includes(x.contraventionTypeId))?.length;
    this.stopInformation.isSDPSelected = intChargeCount > 0;

    if (intChargeCount > 1)
    {
      this.errorMessage = "More than one SDP charges are selected. There could be only one.";
      return false;
    }

    //Check if any JOIN charge is selected
    var contraventionTypeIds = this.contraventionTypes.filter(x => x.isIRS || x.isNonTSASDP).map(s => s.id);
    intChargeCount = this.contraventions.filter(x => contraventionTypeIds.includes(x.contraventionTypeId))?.length;
    this.stopInformation.isTSASelected = this.contraventions.length > intChargeCount;

    //Check if any out of scope charge is selected
    var outOfScopeContraventionTypeIds = this.contraventionTypes.filter(x => !x.isAvailable).map(s => s.id);
    intChargeCount = this.contraventions.filter (x => outOfScopeContraventionTypeIds.includes(x.contraventionTypeId) || outOfScopeContraventionTypeIds.includes(x.secondaryContraventionTypeId) || outOfScopeContraventionTypeIds.includes(x.tertiaryContraventionTypeId))?.length;
    
    if (intChargeCount > 0)
    {
      this.errorMessage = "Out of Scope charge is selected. That contravention cannot be issued using APIS.";
      return false;
    }

    //Check if selected charges are applicable to selected recipient type
    var recipientTypeName = "";
    var chargeCode = "";
    var chargeList = [];
    var contraventionList = [];

    switch (+this.stopInformation.recipient.recipientTypeId) {
      case RecipientTypes.Driver:
        chargeList = this.contraventionTypes.filter(x => x.isApplicableToDriver).map(s => s.id);
        recipientTypeName = "Driver";
        break;
      case RecipientTypes.RegisteredOwner:
        chargeList = this.contraventionTypes.filter(x => x.isApplicableToRegisteredOwner).map(s => s.id);
        recipientTypeName = "Registered Owner";
        break;
      case RecipientTypes.Passenger:
        chargeList = this.contraventionTypes.filter(x => x.isApplicableToPassenger).map(s => s.id);
        recipientTypeName = "Passenger";
        break;
      case RecipientTypes.NotInVehicle:
        chargeList = this.contraventionTypes.filter(x => x.isApplicableToNotInVehicle).map(s => s.id);
        recipientTypeName = "not in vehicle";
        break;
    }

    contraventionList = this.contraventions.filter(x => !chargeList.includes(x.contraventionTypeId));

    if (contraventionList.length>0)
    {
      chargeCode = this.contraventionTypes.find(x => x.id == contraventionList[0].contraventionTypeId)?.formattedChargeCode;
      this.errorMessage = `The charge ${chargeCode} is not applicable if recipient is ${recipientTypeName}.`;
      return false;
    }

    this.errorMessage = ""; 
    return true;
  }

  showWarnings()
  {
    //Check if any charge is selected twice
    var warningMessage = "";
    var finallySelectedContraventionTypes = this.contraventions.map(s => (s.tertiaryContraventionTypeId??s.secondaryContraventionTypeId)??s.contraventionTypeId);
    let findDuplicateContraventions = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
    
    var duplicateContraventionTypeIds = [...new Set(findDuplicateContraventions(finallySelectedContraventionTypes))];

    if (duplicateContraventionTypeIds?.length > 0)
    {
      warningMessage = `Warning, Following duplicate offence(s) selected: ${Array.prototype.map.call(this.contraventionTypes.filter(x => duplicateContraventionTypeIds.includes(x.id)), function(item) { return item.formattedChargeCode; }).join(", ")}`;
    }

    return warningMessage;
  }

  trackContravention(index: number, obj: any): any {
    return index;
  }

  addAnotherContravention() {
    var contravention = new Contravention();
    contravention.contraventionDetails = "0";
    contravention.fine = new Fine();
    contravention.recipient = this.stopInformation.recipient;
    this.contraventions.push(contravention);
  }

  onCancelContravention(contravention: Contravention)
  {
    this.contraventions.splice(this.contraventions.findIndex(x => x==contravention), 1)
    this.errorMessage = "";
  }

  isPendingSelection()
  {
    return this.contraventions.filter(x => x.contraventionTypeId==null || x.contraventionTypeId==0).length > 0;
  }

  isPart2AlertRequired()
  {
    return this.contraventions.filter(x => x.offenceTypeId == OffenceTypes.Part2SummonsVoluntaryPaymentOption).length > 0;
  }

  anyTSAChargeSelected()
  {
      //Check if any JOIN charge is selected
      var contraventionTypeIds = this.contraventionTypes.filter(x => x.isIRS || x.isNonTSASDP).map(s => s.id);
      var tsaCharges = this.contraventions.filter(x => x.contraventionTypeId != null && x.contraventionTypeId > 0 && !contraventionTypeIds.includes(x.contraventionTypeId));
      return tsaCharges.length > 0;
  }

  private _filterLocation(value: string): string[] {
    const filterValue = String(value).toLowerCase();
    return this.locationTypes.filter(option => 
      option.name.toLowerCase().includes(filterValue));
  }

}
