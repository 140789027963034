import { Component, OnInit, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-offline-roadside-appeal-print',
  templateUrl: './offline-print-roadside-appeal.component.html',
  styleUrls: ['./offline-print-roadside-appeal.component.css']
})
export class OfflinePrintRoadsideAppealComponent implements OnInit {
  @Input() isMobile: any;
  @Input() pageBreakRequired: any;

  insertPageBreak: boolean;
  isMobilePrinter: boolean;


  constructor( private readonly spinner: NgxSpinnerService) { }

  ngOnInit(): void {        

    this.insertPageBreak = this.pageBreakRequired as boolean;
    this.isMobilePrinter = this.isMobile as boolean;
  }
  

}
