


export class UrineSampleTest {
    public urineSampleTestId: number = 0;
    public urineSampleTime: string;
    public labTypeId: number = 0;
    public urineSampleResult: string;
    public urineSampleDemandTime: string;

    constructor(init?: Partial<UrineSampleTest>) {
        
        Object.assign(this, init);
    }
}
