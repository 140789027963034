import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContraventionDetailTypes, ContraventionStatusTypes, ContraventionTypes, EventTypes, RoadsideAppealOutcomeTypes } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { RoadsideAppeal } from '@apis/shared/models/roadside-appeal.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { RoadsideAppealOutcomeType } from '@apis/shared/models/types/roadside-appeal-outcome-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-offline-print-confirmation',
  templateUrl: './offline-print-confirmation.component.html',
  styleUrls: ['./offline-print-confirmation.component.scss']
})
export class OfflinePrintConfirmationComponent implements OnInit {
  isMobilePrint: boolean = false;
  stopInformation: StopInformation;
  submissionTitle: string;
  irsContravention: Contravention;
  roadsideAppeal: RoadsideAppeal;
  roadsideAppealOutcomeTypes: RoadsideAppealOutcomeType[];
  screenName: string = "";
  contraventionsCount: number;
  seizuresCount: number;

  policeCopy = "police";
  driverCopy = "driver";
  ownerCopy = "owner";
  towlotCopy = "towlot";


  isProcessing: boolean = true;
  isSubmitClicked: boolean = false;
  ContraventionTypes = ContraventionTypes;
  TypeTable = TypeTable;
  RoadsideAppealOutcomeTypes = RoadsideAppealOutcomeTypes;
  isRefusal: boolean = false;

  currentOfflineId: string = null;

  constructor(private intakeService: IntakeService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    ) { }

  ngOnInit(): void {
    //Get Issuer and Submission Type object
    this.stopInformation = this.intakeService.getStopInformationModel();
    console.log(`this.stopInformation.isMobilePrint: ${this.stopInformation.isMobilePrint}`)

    this.isMobilePrint = this.stopInformation.isMobilePrint!==undefined ? this.stopInformation.isMobilePrint : false;
    console.log(`MobilePrinting: ${this.isMobilePrint}`)
    //Get Lookups
    this.roadsideAppealOutcomeTypes = this.localStorageService.getRoadsideAppealOutcomeTypes();

    this.spinner.show();
    this.screenName = "Processing.."
    
    this.initializeEntities();
  }

  isLastNap(contraventionNumber: string) : boolean {

    let totalContraventions = this.stopInformation.contraventions.length;
    let lastContravention = this.stopInformation.contraventions[totalContraventions-1];
    if (lastContravention && totalContraventions>0) {
      if (contraventionNumber?.trim() === lastContravention.contraventionNumber?.trim()) {
        return false;
      }
    }

    return true;
  }

  isLastSeizureNap(contraventionNumber: string) : boolean {

    let totalContraventions = this.stopInformation.contraventions.length;
    let lastContravention = this.stopInformation.contraventions[totalContraventions-1];
    let lastContraventionHasSeizure = lastContravention.vehicleSeizure != null;

    if (lastContravention && totalContraventions>0) {
      if (contraventionNumber?.trim() === lastContravention.contraventionNumber?.trim()) {
        if (!lastContraventionHasSeizure)
          return false;
      }
    }

    return true;
  }

  isLastSeizure(seizureNumber: string) : boolean {

    let totalSeizures = this.stopInformation.vehicleSeizures.length;
    let lastSeizure = this.stopInformation.vehicleSeizures[totalSeizures-1];
    if (lastSeizure && totalSeizures>0) {
      if (seizureNumber?.trim() === lastSeizure.seizureNumber?.trim()) {
        return false;
      }
    }

    return true;
  }



  ngAfterViewInit(): void {    

    setTimeout( () => {
      this.PrintSubmission();
    }, 2000);
    
  }


  initializeEntities()
  {
    this.screenName = this.stopInformation.isEmergencySubmission? "Submitted to APIS":"Notice(s) Printed";
          
    if (this.stopInformation.isIRSSelected)
    {  
      var irsContraventionTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isIRS).map(s => s.id);
      this.irsContravention = this.stopInformation.contraventions.find(x => irsContraventionTypeIds.includes(+x.contraventionTypeId));  
      
      // JTI-3170 - isRefusal is true ONLY is refusal is the ONLY detail selected
      //
      var detailsSelected = this.irsContravention?.contraventionDetails?.split(",");
      var totalDetailsSelected = detailsSelected?.length;
      
      this.isRefusal = (
          (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail1st && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail1stRefusal.toString()) && totalDetailsSelected===1)) ||
          (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail2nd && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail2ndRefusal.toString()) && totalDetailsSelected===1)) ||
          (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail3rd && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail3rdRefusal.toString()) && totalDetailsSelected===1))
      )
    }

    this.contraventionsCount = this.stopInformation.contraventions.length + this.stopInformation.warnings.length;
    this.seizuresCount = this.stopInformation.vehicleSeizures.length + this.stopInformation.contraventions.filter(x => x.vehicleSeizure).length;

    this.isProcessing = false;
    this.spinner.hide();
  }

  isIRSCancelled()
  {
    return this.irsContravention?.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled;
  }

  isIRSReIssued()
  {
    return this.irsContravention?.events?.filter(x=> x.eventTypeId == EventTypes.ContraventionReIssued).length > 0;
  }

  isReadOnly()
  {
    return this.isIRSCancelled() || this.isIRSReIssued();
  }

  isAddDetailsButtonRequired()
  {
     return !((this.irsContravention && this.isIRSCancelled() && this.contraventionsCount == 1 && this.stopInformation.vehicleSeizures.length == 0) ||
        (this.roadsideAppeal?.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LestThanAnyConcentration && !this.isIRSCancelled()) ||
        (this.roadsideAppeal?.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LessThanFirstTest && !this.isIRSReIssued()) ||
        (this.contraventionsCount == this.stopInformation.warnings.length && this.seizuresCount == 0));
  }

  PrintSubmission()
  {
    var documentTitle = document.title;
    document.title = "Offline Printout..."; //   this.submissionTitle;
    window.print();
    document.title = documentTitle;
  }

  contraventionHasSeizure(contravention: Contravention): boolean {
    var sdpSeizureTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isSDP).map(s => s.id);     
    var sTypeIdsContainCo = sdpSeizureTypeIds.find( i => i===contravention.contraventionTypeId);
    if (sTypeIdsContainCo===undefined && contravention.isWarning !== true) {
      if (contravention.vehicleSeizure!=null) {
        return true;
      }
    }

    return false;
  }

  isNapPrintingRequired(contravention: Contravention): boolean {
    var sdpSeizureTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isSDP).map(s => s.id);     
    var sTypeIdsContainCo = sdpSeizureTypeIds.find( i => i===contravention.contraventionTypeId);
    if (sTypeIdsContainCo!==undefined) {
        return false;
    }

    return true;
  }
  
  onReturnToDashboard() {
    this.isSubmitClicked = true;
    this.router.navigateByUrl(`/user-dashboard`);
  }

}



