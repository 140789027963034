import { AbstractControl, NG_ASYNC_VALIDATORS, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Directive } from '@angular/core';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service'
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Directive({
    selector: '[appSeizureNumberDuplicateValidator]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: SeizureNumberDuplicateValidatorDirective,
        multi: true
    }]
})
export class SeizureNumberDuplicateValidatorDirective implements AsyncValidator {
    constructor(private intakeService: IntakeService) {}

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
       const result = this.intakeService.checkVehicleSeizureNumberAsync(control.value)
       .pipe(
           map((isExist) => {
               return isExist? {'duplicateSeizureNumber': true} : null; 
           })
       );
       return result;
    }
}