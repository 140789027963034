import { User } from '../../../../../libs/shared/models/user.model';

export class IntakeUser extends User {
    public badgeNumber: string;
    public detachment: number;
    public service: number;
    public defaultMunicipalityLocationId: number;
    public defaultCourtLocationId: number;
    
    constructor(init?: Partial<IntakeUser>) {
        super();
        Object.assign(this, init);
    }
}