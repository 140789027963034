import { Component, OnInit, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContraventionTest } from '../../models/contravention-test.model';
import { ImpairmentScreeningModel } from '../../models/impairment-screening.model';
import { LocalStorageService } from '../../services/local-storage.service';
import { TestResultType } from '../../models/types/test-result-type.model';
import { TestModelType } from '../../models/types/test-model-type.model';
import { LabType } from '../../models/types/lab-type.model';
import { TestType } from '../../models/types/test-type.model';
import { StandardizedFieldSobrietyTest } from '../../models/standardized-field-sobriety-test.model';
import { ApprovedScreeningDeviceTest } from '../../models/approved-screening-device-test.model';
import { DrugScreeningEquipmentTest } from '../../models/drug-screening-equipment-test.model';
import { DrugRecognitionEvaluationTest } from '../../models/drug-recognition-evaluation-test.model';
import { ApprovedInstrumentTest } from '../../models/approved-instrument-test.model';
import { BloodSampleTest } from '../../models/blood-sample-test.model';
import { ControlContainer, NgForm } from '@angular/forms';
import { BloodSampleTypes, DriverDeclinedToTestTypes, RefusalOrFailedClassification, SampleClassification, TestTypes } from '@apis/shared/enums/app.enum';
import { UrineSampleTest } from '@apis/shared/models/urine-sample-test.model';
import { DriverDeclinedToTestType } from './../../models/types/driver-declined-to-test-type.model';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-impairment-screening',
  templateUrl: './impairment-screening.component.html',
  styleUrls: ['./impairment-screening.component.scss'],
  inputs: ['contraventionTest', 'impairmentScreeningModel', 'occurrenceTime', 'submissionDate', 'isRoadsideAppeal', 'impairmentScreeningTypeId'],
  outputs: ['onRemoveEvent', 'onTestTypeChangeEvent'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ImpairmentScreeningComponent implements OnInit, OnChanges {
  public contraventionTest: ContraventionTest;
  public impairmentScreeningModel: ImpairmentScreeningModel;
  public occurrenceTime: string;
  public submissionDate: Date;
  public isRoadsideAppeal: boolean;
  public impairmentScreeningTypeId: number;

  public isMASLabels: boolean;
  public isAIThirdTestVisible: boolean;
  public isNewDREProcess: boolean;

  onRemoveEvent = new EventEmitter<number>();
  onTestTypeChangeEvent = new EventEmitter();

  selectedADSEResultTypes: number[];
  testTypes: TestType[];
  testResultTypesSFST: TestResultType[];
  testResultTypesASD: TestResultType[];
  testResultTypesADSE: TestResultType[];
  testModelTypesASD: TestModelType[];
  testModelTypesADSE: TestModelType[];
  testModelTypesAI: TestModelType[];
  labTypes: LabType[];

  driverDeclinedToTestType: DriverDeclinedToTestType[];
  DriverDeclinedToTestTypes = DriverDeclinedToTestTypes;
  RefusalOrFailedClassification = RefusalOrFailedClassification;
  SampleClassification = SampleClassification;
  BloodSampleTypes = BloodSampleTypes;
  TestTypes = TestTypes;
  Constants = Constants;

  constructor(private localStorageService: LocalStorageService) { }

  ngOnChanges(): void {
    this.isMASLabels = !this.isRoadsideAppeal && this.impairmentScreeningTypeId == 2;
    this.isAIThirdTestVisible = new Date(this.submissionDate) >= new Date(+Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(8, 10))
    this.isNewDREProcess = new Date(this.submissionDate) >= new Date(+Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(8, 10))
  }

  ngOnInit(): void {
    //Get Types
    this.testTypes = this.impairmentScreeningModel.possibleTestTypes;
    this.testResultTypesSFST = this.localStorageService.getTestResultTypes().filter(x => x.testTypeId == 1);
    this.testResultTypesASD = this.localStorageService.getTestResultTypes().filter(x => x.testTypeId == 2);
    this.testResultTypesADSE = this.localStorageService.getTestResultTypes().filter(x => x.testTypeId == 3);
    this.testModelTypesASD = this.localStorageService.getTestModelTypes().filter(x => x.testTypeId == 2 && x.name != "Other");
    this.testModelTypesADSE = this.localStorageService.getTestModelTypes().filter(x => x.testTypeId == 3 && x.name != "Other");
    this.testModelTypesAI = this.localStorageService.getTestModelTypes().filter(x => x.testTypeId == 5 && x.name != "Other");
    this.labTypes = this.localStorageService.getLabTypes();
    this.driverDeclinedToTestType = this.localStorageService.getDriverDeclinedToTestTypes();

    //Initialize selected ADSE Result Types
    if (this.contraventionTest.testTypeId == TestTypes.ADSE && this.contraventionTest.drugScreeningEquipmentTest?.testResultTypeId)
    {
      this.selectedADSEResultTypes = this.contraventionTest.drugScreeningEquipmentTest.testResultTypeId?.split(',').map(x=> +x);
    }
  }

  onASDModelChange(modelId: number)
  {
    if(modelId != 2) // Not Other
      this.contraventionTest.approvedScreeningDeviceTest.testModelOther = "";
  }

  onADSEModelChange(modelId: number)
  {
    if(modelId != 5) // Not Other
      this.contraventionTest.drugScreeningEquipmentTest.testModelOther = "";
  }

  onADSEResultChange(resultId: number[])
  {
    this.contraventionTest.drugScreeningEquipmentTest.testResultTypeId = resultId.toString();
    if(!resultId.includes(10)) // Doesn't Include Other
      this.contraventionTest.drugScreeningEquipmentTest.testResultOther = "";
  }

  onAIModelChange(modelId: number)
  {
    if(modelId != 7) // Not Other
      this.contraventionTest.approvedInstrumentTest.testModelOther = ""
  }

  onImpairmentScreeningMethodChange(impairmentScreeningMethodId)
  {
    //Refresh impairmentScreeningModel and contraventionTest
    this.impairmentScreeningModel.ImpairmentScreeningMethodId = impairmentScreeningMethodId;
    this.contraventionTest.standardizedFieldSobrietyTest = null;
    this.contraventionTest.approvedScreeningDeviceTest = null;
    this.contraventionTest.drugScreeningEquipmentTest = null;
    this.contraventionTest.drugRecognitionEvaluationTest = null;
    this.contraventionTest.approvedInstrumentTest = null;
    this.contraventionTest.bloodSampleTest = null;
    this.contraventionTest.urineSampleTest = null;

    this.contraventionTest.testTypeId = impairmentScreeningMethodId;

    if (impairmentScreeningMethodId != "0" && impairmentScreeningMethodId != "7" ) //No Selection or No Method
    {
      this.contraventionTest.driverDeclinedToTestTypeId = null;

      switch(impairmentScreeningMethodId) {
        case "1": //SFST
          this.contraventionTest.standardizedFieldSobrietyTest = new StandardizedFieldSobrietyTest();
          break;
        case "2": //ASD
          this.contraventionTest.approvedScreeningDeviceTest = new ApprovedScreeningDeviceTest();
          break;
        case "3": //ADSE
          this.contraventionTest.drugScreeningEquipmentTest = new DrugScreeningEquipmentTest();
          break;
        case "4": //DRE
          this.contraventionTest.drugRecognitionEvaluationTest = new DrugRecognitionEvaluationTest();
          break;
        case "5": //AI
          this.contraventionTest.approvedInstrumentTest = new ApprovedInstrumentTest();
          break;
        case "6": //Blood Sample
          this.contraventionTest.bloodSampleTest = new BloodSampleTest();
          break;
        case "9": //Urine Sample
          this.contraventionTest.urineSampleTest = new UrineSampleTest();
          break;
      }
    }
    this.onTestTypeChangeEvent.emit(null);
  }

  onDRERefusalChange()
  {
    this.contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId = null;
    this.contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade = null;
    this.clearFields();
  }

  onDRERefusalTypeChange(refusalOrFailedClassificationId)
  {
    if (this.contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply && refusalOrFailedClassificationId == RefusalOrFailedClassification.BreathSample)
      this.contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade = true;
    else
      this.contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade = null;  

    this.clearFields();
  }

  onBreathDemandChange()
  {
    this.contraventionTest.drugRecognitionEvaluationTest.breathDemandTime = null;
    this.contraventionTest.drugRecognitionEvaluationTest.breathTestResult = null;
  }

  clearFields()
  {
    this.contraventionTest.drugRecognitionEvaluationTest.breathDemandTime = null;
    this.contraventionTest.drugRecognitionEvaluationTest.breathTestResult = null;
    this.contraventionTest.drugRecognitionEvaluationTest.testStartedTime = null;
    this.contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId = null;
    this.contraventionTest.drugRecognitionEvaluationTest.sampleDemandTime = null;
    this.contraventionTest.drugRecognitionEvaluationTest.sampleCollectionTime = null;
    this.contraventionTest.drugRecognitionEvaluationTest.labTypeId = null;
    this.contraventionTest.refusalTime = null;
  }

  clearBloodDemandFields(isClearAll: boolean)
  {
    if (isClearAll)
    {
      this.contraventionTest.driverDeclinedToTestTypeId = null;
      this.contraventionTest.bloodSampleTest.bloodSampleDemandTime = null;
    }

    this.contraventionTest.refusalTime = null;
    this.contraventionTest.bloodSampleTest.bloodSampleTime = null;
    this.contraventionTest.bloodSampleTest.labTypeId = null;
  }

  clearBloodWarrantFields()
  {
    this.contraventionTest.bloodSampleTest.bloodWarrantSampleTime = null;
    this.contraventionTest.bloodSampleTest.bloodWarrantLabTypeId = null;
  }

  onRemoveClick(){
    this.onRemoveEvent.emit(this.contraventionTest.testSequence);
  }
}
