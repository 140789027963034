import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Contravention } from '@apis/shared/models/contravention.model';
import { ContraventionSearch } from '@apis/shared/models/contravention-search.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { VehicleSeizureSearch } from '@apis/shared/models/vehicle-seizure-search.model';
import { NapPagedSearch } from '@apis/shared/models/nap-paged-search';
import { Guid } from 'guid-typescript';
import { ApiService } from './api.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { Recipient } from '@apis/shared/models/recipient.model';
import { RecipientIdentification } from '@apis/shared/models/recipient-identification.model';
import { RecipientContact } from '@apis/shared/models/recipient-contact.model';
import { IntakeAppProcess, IssueServiceTypes, RecipientTypes, SubmissionProgrssStates, SeizureTypes } from '@apis/shared/enums/app.enum';
import { Fine } from '@apis/shared/models/fine.model';
import { OccurrenceLocation } from '@apis/shared/models/occurrence-location.model';
import { Vehicle } from '@apis/shared/models/vehicle.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { NoticeCancellationRequest } from '@apis/shared/models/notice-cancellation-request.model';
import { ConnectivityService } from './connectivity.service';
import { SharedDocumentsUploadRequest } from '@apis/shared/models/shared-documents-upload-request.model';
import * as moment from 'moment';
import { TicketPagedSearch } from '@apis/shared/models/ticket-paged-search';
import { IssuanceResponse } from '@apis/shared/models/issuance-response.model';
import { CreatePoliceNotesRequest } from '@apis/shared/models/create-police-notes-request.model';

// https://github.com/dannyconnell/localbase
// IndexDB wrapper
//
import Localbase from 'localbase'
import { OfficerService } from './officer.service';
import { NapViewModel } from '@apis/shared/models/nap-view.model';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { Review } from '@apis/shared/models/review.model';
import { TicketViewModel } from '@apis/shared/models/ticket-view.model';
import { TicketStopInformation } from '@apis/shared/models/ticket-stop-information.model';
import { TicketService } from './ticket.service';
import { TicketWithdrawalRequest } from '@apis/shared/models/ticket-withdrawal-request.model';
let offlineDb = new Localbase('offlineDb')

// to diable the localBase console logging... uncomment the following line
offlineDb.config.debug = false


@Injectable({
    providedIn: 'root'
})
export class IntakeService extends ApiService {
    stopInformation: StopInformation;
    sharedDocumentsUploadRequest: SharedDocumentsUploadRequest;
    SeizureTypes = SeizureTypes;
    ticketStopInformation: TicketStopInformation;
    violationTicket: TicketViewModel;

    constructor(http: HttpClient, 
        private localStorage: LocalStorageService,
        private connectivityService: ConnectivityService,
        private officerService: OfficerService,
        private ticketService: TicketService){
        super(http, localStorage);
    }

    getReviewByNumber(reviewNumber: string): Observable<Review> {
        return this.get('reviews/{reviewNumber}', {reviewNumber});
    }

    public getSearchFilters() {
        return this.localStorage.getSearchFilters();
    }

    public saveSearchFilters(dashboardSearch: any){
        this.localStorage.setSearchFilters(dashboardSearch);
    }

    public clearSearchFilters(){
        this.localStorage.clearSearchFilters();
    }

    public getStopInformationModel(): StopInformation {
        this.stopInformation = this.localStorage.getStopInformationModel();

        if (this.stopInformation == null) {
            this.stopInformation = new StopInformation();

            //Initialize a temporary file storage location
            this.stopInformation.tempFileFolder = Guid.create().toString();
            
            this.stopInformation.issueServiceTypeId = IssueServiceTypes.InPerson;
            this.stopInformation.occurrenceLocation = new OccurrenceLocation();
            this.stopInformation.vehicle = new Vehicle();

            //Set Defaults
            this.stopInformation.vehicle.licensedCountryId = 40 //Canada
            this.stopInformation.vehicle.licensedProvinceId = 1 //Alberta
            this.stopInformation.vehicle.isAlbertaLicencePlate = true;

            //Initialize Recipient entities
            this.stopInformation.recipient = new Recipient();
            this.stopInformation.recipient.recipientIdentification = new RecipientIdentification();
            this.stopInformation.recipient.recipientContact = new RecipientContact();

            //Add one default contravention
            var contravention = new Contravention();
            contravention.contraventionDetails = "0";
            contravention.fine = new Fine();
            contravention.recipient = this.stopInformation.recipient;
            this.stopInformation.contraventions.push(contravention);

            //Set Defaults
            this.stopInformation.recipient.recipientTypeId = RecipientTypes.Driver;
            this.stopInformation.recipient.recipientIdentification.issuingCountryId = 40; //Canada
            this.stopInformation.recipient.recipientIdentification.issuingProvinceId = 1; //Alberta

            this.stopInformation.recipient.recipientContact.countryId = 40; //Canada
            this.stopInformation.recipient.recipientContact.provinceId = 1; //Alberta
            this.stopInformation.progressId = SubmissionProgrssStates.RecipientInformation;

            this.stopInformation.availableTSA = environment.availableTSA && this.ticketService.hasTrafficTicketPermissions();
        }

        return this.stopInformation;
    }

    public saveStopInformationContext(){
        this.localStorage.setStopInformationModel(this.stopInformation);
    }

    public createStopInformationContext(stopInformation:StopInformation): StopInformation{
        this.stopInformation = stopInformation

        //Initialize a temporary file storage location
        if (!this.stopInformation.tempFileFolder)
            this.stopInformation.tempFileFolder = Guid.create().toString();

        this.localStorage.setStopInformationModel(this.stopInformation);
        return this.stopInformation;
    }

    public resetStopInformationModel()
    {
        this.stopInformation = null;
        this.localStorage.resetStopInformationModel();
    }

    public createTicketStopInformationContext(ticketStopInformation: TicketStopInformation): TicketStopInformation{
        this.ticketStopInformation = ticketStopInformation

        this.localStorage.setTicketStopInformationModel(this.ticketStopInformation);
        return this.ticketStopInformation;
    }

    public getTicketStopInformationContext()
    {
        this.ticketStopInformation = this.localStorage.getTicketStopInformationModel();
        return this.ticketStopInformation;
    }

    public resetTicketStopInformationModel()
    {
        this.ticketStopInformation = null;
        this.localStorage.resetTicketStopInformationModel();
    }

    public getSharedDocumentUploadRequestContext()
    {
        this.sharedDocumentsUploadRequest = this.localStorage.getSharedDocumentUploadRequest();

        if (this.sharedDocumentsUploadRequest == null)
        {
            this.sharedDocumentsUploadRequest = new SharedDocumentsUploadRequest();

            //Initialize a temporary file storage location
            this.sharedDocumentsUploadRequest.tempFileFolder = Guid.create().toString();
        }    
        
        return this.sharedDocumentsUploadRequest;
    }

    public setSharedDocumentUploadRequestContext()
    {
        this.localStorage.setSharedDocumentUploadRequest(this.sharedDocumentsUploadRequest);
    }

    public resetSharedDocumentUploadRequestContext()
    {
        this.sharedDocumentsUploadRequest = null
        this.localStorage.resetSharedDocumentUploadRequest();
    }

    public createViolationTicketContext(violationTicket: TicketViewModel)
    {
        this.violationTicket = violationTicket;
        this.localStorage.setViolationTicketContext(this.violationTicket);
        return this.violationTicket;
    }

    public saveViolationTicketContext(){
        this.localStorage.setViolationTicketContext(this.violationTicket);
    }

    public getViolationTicketContext()
    {
        this.violationTicket = this.localStorage.getViolationTicketContext();
        return this.violationTicket;
    }

    public resetViolationTicketContext()
    {
        this.violationTicket = null
        this.localStorage.resetViolationTicketContext();
    }

    public findContraventionsAsync(contraventionSearch: ContraventionSearch): Observable<Contravention[]> {
        return this.get('contraventions', {contraventionSearch});
    }
    
    public createContraventonAsync(contravention: Contravention): Observable<void> {
        return this.post('contraventions', {contravention});
    }

    public createStopAsync(stopInformation: StopInformation): Observable<IssuanceResponse> {
        return this.post('stop', {stopInformation});
    }

    public getStopInformationByIdAsync(stopInformationId: string): Observable<StopInformation> {
        return this.get('stop/{stopInformationId}', {stopInformationId});
    }

    public getTicketStopInformationByIdAsync(ticketStopInformationId: string): Observable<TicketStopInformation> {
        return this.get('ticketstop/{ticketStopInformationId}', {ticketStopInformationId});
    }

    public getContraventionByContraventionNumberAsync(contraventionNumber: string): Observable<Contravention> {
        return this.get('contraventions/{contraventionNumber}', {contraventionNumber});
    }

    public checkContraventionNumberAsync(contraventionNumber: string): Observable<boolean> {
        return this.get('contraventions/exists/{contraventionNumber}', {contraventionNumber});
    }

    public updateContraventonReServeInfoAsync(contravention: Contravention): Observable<void> {
        return this.patch('contraventions/reserve', {contravention});
    }       
    
    public cancelContravention(contravention: Contravention): Observable<Contravention> {
        return this.put('contraventions/cancel', {contravention});
    }

    public updateContraventonRoadsideAppealInfoAsync(contravention: Contravention): Observable<Contravention> {
        return this.patch('contraventions/roadsideappeal', {contravention});
    }    

    public updateStopInformationAsync(stopInformation: StopInformation): Observable<StopInformation> {
        return this.patch('stop', {stopInformation});
    }    
    
    //Vehicle Seizure Methods
    public findVehicleSeizureAsync(vehicleSeizureSearch: VehicleSeizureSearch): Observable<VehicleSeizure> {
        return this.get('vehicleseizures/find', {vehicleSeizureSearch});
    }

    public createVehicleSeizureAsync(vehicleSeizure: VehicleSeizure): Observable<void> {
        return this.post('vehicleseizures', {vehicleSeizure});
    }
    
    public getVehicleSeizureByNumberAsync(seizureNumber: string): Observable<VehicleSeizure> {
        return this.get('vehicleseizures/{seizureNumber}', {seizureNumber});
    }

    public checkVehicleSeizureNumberAsync(seizureNumber: string): Observable<boolean> {
        return this.get('vehicleseizures/exists/{seizureNumber}', {seizureNumber});
    }

    public updateVehicleSeizureReServeInfoAsync(vehicleSeizure: VehicleSeizure): Observable<void> {
        return this.patch('vehicleseizures/reserve', {vehicleSeizure});
    }  

    public getCurrentMSTDateAsync(): Observable<Date> {

        if (this.connectivityService.isUserOnline()) {
            return this.get('current-mst-date', {}); // Returns a string
        }

        return of(DateUtil.getCurrentMSTDate()); // Returns a Date
    }

    // Nap Dashboard services
    public findContraventionsAndSeizuresAsync(napPagedSearch: NapPagedSearch): Observable<any> {

        let user = this.localStorage.getUser();
        let apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
      
        let params = new HttpParams();
        Object.entries(napPagedSearch).forEach((pair: [string, any]) => {
          params = params.append(pair[0], pair[1]);
        });
        
        return this.http.get(`${apiUrl}/naps/findall`, {
          headers: { "Authorization": `Bearer ${user.token}` },
          params: params      
        }).pipe(
          map(response => response)
        );
    }

     // Ticket Dashboard services
     public findTicketsAsync(ticketPagedSearch: TicketPagedSearch): Observable<any> {

        let user = this.localStorage.getUser();
        let apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
      
        let params = new HttpParams();
        Object.entries(ticketPagedSearch).forEach((pair: [string, any]) => {
          params = params.append(pair[0], pair[1]);
        });
        
        return this.http.get(`${apiUrl}/tickets/findall`, {
          headers: { "Authorization": `Bearer ${user.token}` },
          params: params      
        }).pipe(
          map(response => response)
        );
    }

    // Offline Management
    // this is autmatically called when the user comes online AND on dashboard OnInit
    // 

    async postOfflineStops(): Promise<any> {
        let stopsUpdated = 0;
        let isOnline = false;
        let stops = null;
        let currentProcess =  this.localStorage.getIntakeAppProcess();

        // perform a physical check for online status
        await this.connectivityService.getOnlineStatusAsync().then( result => {
            isOnline = result;
        });

        if (isOnline && currentProcess === IntakeAppProcess.ViewingDashboard) {
            await offlineDb.collection('stops').get({ keys: true }).then(result => {
                stops = result;
            });

            if(stops.length > 0) {

                // create stop and update offline number as inactive
                await this.createAndUpdateStops(stops).then( stopsUpdated => {

                    return stopsUpdated;
                })

            }
        } else {
            return 0;
        }
    }

    async createAndUpdateStops(stops): Promise<any> {

        let stopsUpdated = 0;
        stops.forEach( stopInformation => {
            let thisStop: StopInformation = stopInformation.data;
            if (this.connectivityService.isUserOnline()) {

                this.createStopAsync(thisStop).subscribe( result => {

                    // mark the Offline number as assigned
                    this.officerService.setOfficerOfflineNumberInactiveAsync(thisStop).subscribe( errorsFound => {            
                        if (errorsFound===0) {
                            let thisStopKey = stopInformation.key;
                            offlineDb.collection('stops').doc(thisStopKey).delete();
                            stopsUpdated++
                        }
                    },
                    (error: any) => {                
                        console.log('Error occured when setting offline number as assigned : ', thisStop);
                    })
                },
                (error: any) => {                
                    console.log('Error occured when posting stop to API', error);
                    console.log('Stop Details', thisStop);
                });
            }
        });

        return stopsUpdated;
    }

    async isOfflineStops(): Promise<boolean> {
        let isOfflineStops: boolean = false;
        await offlineDb.collection('stops').get({ keys: true }).then(stops => {
            if( stops.length>0) {
                isOfflineStops = true;
            }
        });

        return isOfflineStops;
    }

    // this is JUST for displaying the naps on the offline dashboard... therefore, some of the properties
    // are set to a hard-coded value for the purpose of creating the nap - but are not used when
    // pushing to the database...
    //
    async getOfflineStops(): Promise<NapViewModel[]> {

        let olResult: NapViewModel[] = [];

        await offlineDb.collection('stops').get({ keys: true }).then(stops => {

            if(stops.length > 0) {
                stops.forEach( offlineStop => {
                    
                    let stop: StopInformation = offlineStop.data;
                    stop.contraventions.forEach( c => {

                        if (!stop.isSDPSelected) {

                            let n: NapViewModel = this.createNapModelFromContravention(c, stop.issueDate);
                            olResult.push(n);

                            if (c.vehicleSeizure!=null) {
                                let n: NapViewModel = this.createNapModelFromSeizure(c.vehicleSeizure, stop.issueDate);
                                olResult.push(n);
                            }
                        }
                    })
                    stop.vehicleSeizures.forEach( s => {

                        let n: NapViewModel = this.createNapModelFromSeizure(s, stop.issueDate);
                        olResult.push(n);
                    })
                    
                });
            }
        });

        return olResult;
    }
    
    createNapModelFromContravention(c: Contravention, issueDate: Date): NapViewModel {

        //Get Types
        let contraventionTypes = this.localStorage.getContraventionTypes();
        let contraventionType: ContraventionType;

        //Get contravention type
        let contraventionTypeDescription = "";
        if (c.contraventionTypeId > 0)
        {
            contraventionType = contraventionTypes.find(x => x.id == c.contraventionTypeId);
            contraventionTypeDescription = contraventionType.formattedName;
        }

        let n: NapViewModel = new NapViewModel();
        n.stopInformationId = 0;
        n.includeCheckbox = false;
        n.isLessThan2Hours= false;
        n.occurrenceDateTime= (moment(c.occurrenceDate)).format('YYYY-MM-DD');
        n.issueDate = issueDate;
        n.contraventionNumber = c.contraventionNumber;
        n.contraventionTypeId = c.contraventionTypeId.toString();
        n.contraventionTypeName = c.contraventionTypeName;
        n.recipientName = c.recipient?.firstName;
        n.issuingOfficer = c.policeOfficerFullName;
        n.description = contraventionTypeDescription;
        n.policeFileNumber = c.policeFileNumber;
        n.apisStatus = 'Offline';
        n.contraventionStatusTypeId = c.contraventionStatusTypeId;
        n.contraventionStatusTypeName = this.localStorage.getContraventionStatusTypes().find(x => x.id == c.contraventionStatusTypeId)?.name;
        n.hasDocumentsWithPendingSubmissions = false;
        n.hasDocumentsRequiringRedaction = false;
        n.isReServeRequired = false;
        n.reServeDate = null;
        n.isSelected = false;
        n.vehicleSeizureId = null;
        n.contraventionId = c.contraventionId;
        n.isSeizure = false;
        n.isNoticeCancellationRequestSubmitted = false;
        n.noticeCancellationRequestDecisionTypeId = 1;

        return n;
    }

    createNapModelFromSeizure(c: VehicleSeizure, issueDate: Date): NapViewModel {

        // testing....
        let today = new Date();
        let todayDateString = (moment(today)).format('YYYY-MM-DD');
        

        let seizureDescription = c.seizureTypeId == this.SeizureTypes.IRSContraventionSelected ? "IRS Seizure" :
                                 (c.seizureTypeId == this.SeizureTypes.SuspendedDriversProgramFirstOccurrence ||
                                 c.seizureTypeId == this.SeizureTypes.SuspendedDriversProgramSecondOccurrence ) 
                                 ? "Suspended Driver Program Seizure" : '';

        let n: NapViewModel = new NapViewModel();
        n.stopInformationId = 0;
        n.includeCheckbox = false;
        n.isLessThan2Hours= false;
        n.occurrenceDateTime= (moment(c.seizureDate)).format('YYYY-MM-DD');
        n.issueDate = issueDate;
        n.contraventionNumber = c.seizureNumber;
        n.contraventionTypeId = c.seizureStatusTypeId.toString();
        n.contraventionTypeName = c.seizureStatusTypeName;
        n.recipientName = c.recipient?.firstName;
        n.issuingOfficer = c.policeOfficerFullName;
        n.description = seizureDescription;
        n.policeFileNumber = c.policeFileNumber;
        n.apisStatus = 'Offline';
        n.contraventionStatusTypeId = c.seizureStatusTypeId;
        n.contraventionStatusTypeName = c.seizureStatusTypeName;
        n.hasDocumentsWithPendingSubmissions = false;
        n.hasDocumentsRequiringRedaction = false;
        n.isReServeRequired = false;
        n.reServeDate = null;
        n.isSelected = false;
        n.vehicleSeizureId =c.vehicleSeizureId;
        n.contraventionId = c.contraventionId;
        n.isSeizure = true;
        n.isNoticeCancellationRequestSubmitted = false;
        n.noticeCancellationRequestDecisionTypeId = 1;

        return n;
    }

    async createStopOffline(stop: StopInformation, key: string) {
        return await offlineDb.collection('stops').add(stop, key)
    }

    // update will create the indexDb row if it doesn't exist
    // otherwise, overwrite the existing stop with the new one
    async updateStopOffline(stop: StopInformation, key: string) {
        let dbStop =  await offlineDb.collection('stops').doc(key).get();
        if (dbStop===null) {
            return await offlineDb.collection('stops').add(stop, key);
        }

        await offlineDb.collection('stops').doc(key).set(stop);

        return stop;
    }
    
    async getStopInformationByIdOffline(key: string) {
        let stop =  await offlineDb.collection('stops').doc(key).get();
        return stop;
    }

    // ensure we don't have any offline numbers already in use
    // this checks and ensures we don't reuse a contravention/Seizure number
    //
    // scenario: 
    //    1. the user is offline and creates contrvention
    //           the cached number is removed from local storage
    //    2. logout, session expires, close browser
    //    3. log back in.. the previously used cached number is re-added into local storage
    //       but the number is used and sitting in IndexDB
    //
    checkCacheIntegrity(filteredNaps: NapViewModel[],  checkOfflineNaps: boolean = false) {

        console.log('Checking integrity of offline numbers...');
        var cn = this.localStorage.getOfflineNumbers();
        var sn = this.localStorage.getOfflineSeizureNumbers();
        filteredNaps.forEach( n => {

            if (cn.includes(n.contraventionNumber)) {
                this.localStorage.removeOfflineNumber(n.contraventionNumber);
                console.log(`removed contravention number ${n.contraventionNumber}...`);
            }
            if (sn.includes(n.contraventionNumber)) {
                this.localStorage.removeOfflineSeizureNumber(n.contraventionNumber);
                console.log(`removed seizure number ${n.contraventionNumber}...`);
            }
        });

        // user is online but we want to check the offline naps
        if (checkOfflineNaps) {
            let olNaps: NapViewModel[] = [];
            this.getOfflineStops().then( naps=> {
                if (naps!==null && naps.length>0) {
                    olNaps = naps;
                } 
                olNaps.forEach( n => {

                    if (cn.includes(n.contraventionNumber)) {
                        this.localStorage.removeOfflineNumber(n.contraventionNumber);
                        console.log(`removed contravention number ${n.contraventionNumber}...`);
                    }
                    if (sn.includes(n.contraventionNumber)) {
                        this.localStorage.removeOfflineSeizureNumber(n.contraventionNumber);
                        console.log(`removed seizure number ${n.contraventionNumber}...`);
                    }
                }); 
            });             
        }
    }



    //Request
    public createNoticeCancellationRequestAsync(noticeCancellationRequest: NoticeCancellationRequest): Observable<NoticeCancellationRequest> {
        return this.post('notice-cancellation-requests', {noticeCancellationRequest});
    }

    public createTicketWithdrawalRequestAsync(ticketWithdrawalRequest: TicketWithdrawalRequest): Observable<void> {
        return this.post('ticket-withdrawal-requests', {ticketWithdrawalRequest});
    }

    //Shared Documents
    public addSharedDocumentsAsync(sharedDocumentsUploadRequest: SharedDocumentsUploadRequest): Observable<void> {
        return this.post('contraventions/shared-documents', {sharedDocumentsUploadRequest});
    }

    //Create police notes
    public createPoliceNotes(createPoliceNotesRequest: CreatePoliceNotesRequest): Observable<void> {
        return this.post('police-notes', {createPoliceNotesRequest});
    }
}
