
export class CreatePoliceNotesRequest {
    public ticketNumber: string;
    public policeNotes: string = '';
    public issuingOfficerName: string = '';
    public regimentalNumber: string = '';
    public issuingDetachmentId: number = 0;

    constructor(init?: Partial<CreatePoliceNotesRequest>) {
        Object.assign(this, init);
    }
}
