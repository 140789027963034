import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { CountryProvinceModel } from '../../models/country-province.model';
import { LocalStorageService } from '../../services/local-storage.service';
import { CountryType } from '../../models/types/country-type.model';
import { ProvinceType } from '../../models/types/province-type.model';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-country-province',
  templateUrl: './country-province.component.html',
  styleUrls: ['./country-province.component.scss'],
  inputs: ['countryProvince'],
  outputs: ['onChangeEvent'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CountryProvinceComponent implements OnInit {
  public countryProvince: CountryProvinceModel;
  @Input() useAdminIssuingAuthorityLabels: boolean = false;
  onChangeEvent = new EventEmitter<CountryProvinceModel>();

  countryTypes: CountryType[];
  provinceTypes: ProvinceType[];
  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.countryTypes = this.localStorageService.getCountryTypes();
    this.refreshProvinceDropdown();
  }

  onCountryChange(e){
    this.countryProvince.provinceId = null;
    this.countryProvince.province = '';
    this.refreshProvinceDropdown();
    this.onChangeEvent.emit(this.countryProvince);
  }

  refreshProvinceDropdown(): ProvinceType[] {
    if (this.countryProvince.countryId == 40)
    {
       this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function(provinceType) { return provinceType.countryId == 40; });
    }
    else if (this.countryProvince.countryId == 236)
    {
      this.provinceTypes = this.localStorageService.getProvinceTypes().filter(function(provinceType) { return provinceType.countryId == 236; });
    }
    else
    {
      this.countryProvince.provinceId = null;
    }
    
    return this.provinceTypes;
  }

  onProvinceChange(e){
    this.countryProvince.province = '';
    this.onChangeEvent.emit(this.countryProvince);
  }

  onProvinceTextChange(e){
    this.countryProvince.provinceId = null;
    this.onChangeEvent.emit(this.countryProvince);
  }


}
