import { Component, OnInit, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { NgModel, Validators } from '@angular/forms';
import { ContraventionStatusTypes, EventTypes, DocumentTypes } from '@apis/shared/enums/app.enum';
import { CancelContraventionRequest } from '@apis/shared/models/cancel-contravention-request.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
//import { AdjudicationService } from '../../../shared/services/adjudication.service';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';


import * as fileSaver from 'file-saver';
import { Guid } from 'guid-typescript';
import { Document } from '@apis/shared/models/document.model';
import { Event } from '@apis/shared/models/event.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';

@Component({
  selector: 'app-cancel-contravention-modal',
  templateUrl: './cancel-contravention-modal.component.html',
  styleUrls: ['./cancel-contravention-modal.component.scss']
})
export class CancelContraventionModalComponent implements OnInit, AfterViewInit {
  contravention: Contravention;
  contraventionNumber: string;
  isSubmitClicked: boolean = false;
  maxCharacters: number = 250;
  bodyElement: JQuery<HTMLElement>;
  cancellationReason: string = "";
  isContraventionCancelled: boolean = false;
  errorMessage: string;
  errorMessageHeader: string;
  requiredDocumentUploadError: string;
  additionalDocumentUploadError: string;
  additionalDocumentName: string;
  requiredDocumentName: string;  
  requiredDocument: Document;
  additionalDocument: Document;
  uploadingDocument: boolean = false;
  confirmButton: JQuery<HTMLElement>;

  requiredDocumentContainer: HTMLElement;
  requiredDocumentUploadContainer: HTMLElement;
  additionalDocumentUploadContainer: HTMLElement;  
  additionalDocumentButton: JQuery<HTMLElement>;

  cancellationReasonTextarea: JQuery<HTMLElement>;
  documents: Document[] = [];

  tempFileFolder: string;
  username: string;
  confirmModalOverlay: JQuery<HTMLElement>;

  close: EventEmitter<Contravention> = new EventEmitter<Contravention>();

  @ViewChild("cancellationReasonModel") cancellationReasonModel: NgModel;

  constructor( private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService,
    private readonly intakeService: IntakeService,
    private readonly documentService: DocumentService) { }

  ngAfterViewInit(): void {    
    this.cancellationReasonModel.control.setValidators(Validators.required);

    this.requiredDocumentContainer = document.getElementById("requiredDocumentContainer");
    this.requiredDocumentUploadContainer = document.getElementById("requiredDocumentUploadContainer");
    this.additionalDocumentUploadContainer = document.getElementById("additionalDocumentUploadContainer");
 
    this.additionalDocumentButton = $(".additional-document-button");
    this.cancellationReasonTextarea = $("#cancellationReasonTextarea");
    this.confirmButton = $(".confirm-button");

    if (this.contravention?.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled) {            
      this.cancellationReasonTextarea.attr("disabled", ""); 
      this.additionalDocumentButton.attr("disabled", "");            
    } 
  }

  ngOnInit(): void {        
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.confirmModalOverlay = $(".confirm-modal-overlay");

    this.contraventionNumber = this.contravention.contraventionNumber;
    

    this.tempFileFolder = Guid.create().toString();          
    this.username = this.localStorageService.getAdjudicationUser()?.userName;
    
    if (this.contravention.documents?.length > 0) {
      this.documents = this.contravention.documents.filter(d => 
        +d.documentTypeId == DocumentTypes.ContraventionCancellationAuthorization
        || +d.documentTypeId == DocumentTypes.ContraventionCancellationAdditional);
    }
       
    if (+this.contravention.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled) {
      this.cancellationReason = this.contravention?.events.find(x => +x.eventTypeId == EventTypes.ContraventionCancelled)?.eventDetails;
      this.maxCharacters = 250 - this.cancellationReason.length;
      this.isContraventionCancelled = true; 
      this.errorMessageHeader = "Contravention cancelled";
    }    
  }
  
  onCloseIconClick(): void {        
    this.bodyElement.removeClass("overflow-hidden");
    this.removeFilesBeforeClose();
    this.close.emit(null);
  }

  onCancelClick(): void {
    this.bodyElement.removeClass("overflow-hidden");
    this.removeFilesBeforeClose();
    this.close.emit(null);
  }

  private removeFilesBeforeClose(): void {
    if (this.requiredDocument != null) {
      this.onRemoveFileLinkClick(this.requiredDocument);
    } 

    if (this.additionalDocument != null) {
      this.onRemoveFileLinkClick(this.additionalDocument);
    }
  }
 
  onCancelTextareaInput(ev: any): void {
    this.maxCharacters = 250 - ev.target.value.length;    
  }

  onConfirmCancellationClick(isValid: boolean) {
    if (this.confirmButton.hasClass("disabled")) {
      return;
    }

    this.isSubmitClicked = true;
    
    if (isValid 
        && !this.isContraventionCancelled 
        && this.maxCharacters >= 0) {
          this.showHideConfirmModal(true);
    }
  } 

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  onUploadRequiredDocumentClick(fileElement: HTMLInputElement): void {
    fileElement.value = null;
    fileElement.click();
  }
  
  onRequiredDocumentChange(ev: any): void {    
    let files: FileList = ev.target.files;            
    let uploadBarProgress = document.getElementById("requiredDocumentUploadProgress");
    let removeFileLink = document.getElementById("requiredDocumentUploadRemoveFileLink");    
 
    this.requiredDocumentUploadError = "";
    for (let index = 0; index < files.length; index++) {
      let file = files.item(index) as File;
                  
      let lastIndex = file.name.lastIndexOf(".");
      let documentExtension = file.name.substring(lastIndex + 1);     

      if (!this.isValidRequiredDocumentMimeType(file)) {
        this.requiredDocumentUploadError = "Invalid file type. PDF only. ";
      }
           
      if (!this.isSizeLessThan5MB(file)) {
        this.requiredDocumentUploadError += "Maximum file size is 5 MB."
      }
      
      if (this.requiredDocumentUploadError.length > 0) {
        return;
      }
      
      this.requiredDocumentContainer.classList.add("hide");
      this.requiredDocumentUploadContainer.classList.add("show");
      this.requiredDocumentName = file.name;
      this.uploadingDocument = true;

      let storageFileName = Guid.create().toString();
      this.documentService.uploadDocumentAsync(file, this.tempFileFolder, storageFileName)
        .subscribe((result: any) => {
          if (+result.type == 1) {                               
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);    
            uploadBarProgress.innerHTML = `${percentage}%`;  
            uploadBarProgress.style.width =` ${percentage}%`;         
          } else if (+result.type == 4) {           
              this.requiredDocument = new Document({
                contentGuid: storageFileName,
                documentTypeId: DocumentTypes.ContraventionCancellationAuthorization,
                documentName: file.name,
                documentExtension: documentExtension,
                documentSize: CommonUtil.getDocumentSize(file),
                uploadedBy: this.username,
                isSubmitLater: false,
                isPublished: false                            
              });
            
              uploadBarProgress.style.width = '100%';
              uploadBarProgress.innerHTML = "Upload Complete";
              removeFileLink.classList.remove("disabled");    
              this.uploadingDocument = false;                                                  
          }            
        });
    }
  }

  onUploadAdditionalDocumentClick(fileElement: HTMLInputElement): void {
    fileElement.value = null;
    fileElement.click();
  }

  onAdditionalDocumentUploadChange(ev: any): void {
    let files: FileList = ev.target.files;

    let uploadBarProgress = document.getElementById("additionalDocumentUploadProgress");
    let removeFileLink = document.getElementById("additionalDocumentUploadRemoveFileLink");    

    this.additionalDocumentUploadError = "";
    for (let index = 0; index < files.length; index++) {
      let file = files.item(index);
      let lastIndex = file.name.lastIndexOf(".");
      let documentExtension = file.name.substring(lastIndex + 1);     

      if (!this.isSizeLessThan5MB(file)) {
        this.additionalDocumentUploadError = "Maximum file size is 5 MB.";
      }

      if (!this.isValidAdditionalDocumentMimeType(file)) {
        this.additionalDocumentUploadError += " Invalid file type.";
      }
      
      if (this.additionalDocumentUploadError.length > 0) {
        return;
      }
      
      this.additionalDocumentUploadContainer.classList.add("show");
      this.additionalDocumentName = file.name;
      this.uploadingDocument = true;

      let storageFileName = Guid.create().toString();
      this.documentService.uploadDocumentAsync(file, this.tempFileFolder, storageFileName)
        .subscribe((result: any) => {
          if (+result.type == 1) {                               
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);    
            uploadBarProgress.innerHTML = `${percentage}%`;  
            uploadBarProgress.style.width =` ${percentage}%`;
         
          } else if (+result.type == 4) {           
              this.additionalDocument = new Document({
                contentGuid: storageFileName,
                documentTypeId: DocumentTypes.ContraventionCancellationAdditional,
                documentName: file.name,
                documentExtension: documentExtension,
                documentSize: CommonUtil.getDocumentSize(file),
                uploadedBy: this.username,
                isSubmitLater: false,
                isPublished: false 
              });
            
              uploadBarProgress.style.width = '100%';
              uploadBarProgress.innerHTML = "Upload Complete";
              removeFileLink.classList.remove("disabled"); 
              this.additionalDocumentButton.attr("disabled", "");
              this.uploadingDocument = false;
          }            
        });
    }
  }

  onDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument("", "Contraventions", this.contravention.contraventionNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  onTempDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument(this.tempFileFolder, "", "", storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  onRemoveFileLinkClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`; 
    this.documentService.deleteDocument(storageFileName, this.tempFileFolder)
      .subscribe((result: any) => {
        if (+document.documentTypeId == DocumentTypes.ContraventionCancellationAuthorization) {
          this.requiredDocument = null;
          this.requiredDocumentUploadContainer.classList.remove("show");
          this.requiredDocumentContainer.classList.remove("hide");

        } else if (+document.documentTypeId == DocumentTypes.ContraventionCancellationAdditional) {
          this.additionalDocument = null;
          this.additionalDocumentUploadContainer.classList.remove("show");
          this.additionalDocumentButton.removeAttr("disabled");
        }
      },
      (error: any) => {});
  }

  isValidRequiredDocumentMimeType(file: File): boolean {
    return ["application/pdf"].includes(file.type.toLowerCase());
  }

  isValidAdditionalDocumentMimeType(file: File): boolean {
    return ["application/pdf", 
      "image/jpeg", 
      "image/png", 
      "image/gif", 
      "text/plain", 
      "application/msword", 
      "application/vnd.openxmlformats"].includes(file.type.toLowerCase());
  }

  isSizeLessThan2MB(file: File): boolean {
    return file.size / (Math.pow(1024, 2)) <= 2;
  }

  isSizeLessThan5MB(file: File): boolean {
    return file.size / (Math.pow(1024, 2)) <= 5;
  }

  getDocumentType(documentTypeId: number): string {
    if (documentTypeId == DocumentTypes.ContraventionCancellationAuthorization) return "Cancellation Authorization";

    if (documentTypeId == DocumentTypes.ContraventionCancellationAdditional) return "Additional Document";
  }

  onConfirmClick()
  {
    this.spinner.show();
     
    this.contravention.events.push(new Event({
      eventTypeId: EventTypes.ContraventionCancelled,
      eventDetails: this.cancellationReason,
      userName: this.username
    }));

    if (this.requiredDocument != null) {
      this.contravention.documents.push(this.requiredDocument);
    }

    if (this.additionalDocument != null) {
      this.contravention.documents.push(this.additionalDocument);
    }

    this.intakeService.cancelContravention(this.contravention)
      .subscribe((contravention: Contravention) => {
        this.errorMessage = ""; 
        
        if (this.requiredDocument != null || this.additionalDocument != null)
        {
          this.documentService.finalizeDocuments(this.tempFileFolder, "Contraventions", this.contravention.contraventionNumber)
            .subscribe((result: any) => {
              this.bodyElement.removeClass("overflow-hidden");
              this.spinner.hide();
              this.close.emit(contravention);     
            });     
        } 
        else
        {
          this.bodyElement.removeClass("overflow-hidden");
          this.spinner.hide();
          this.close.emit(contravention);     
        }        
      }, (error: any) => {
        if (error.status == 401 || error.status == 403) {
          this.errorMessage = "You are unauthorized to perform this operation";
        }          
        else  {
          this.showErrors(error);
        }                    
        this.spinner.hide();
      });
  }

  showHideConfirmModal(show?: boolean): void {
    if (show) {
      this.confirmModalOverlay.addClass("modal-show");
    } 
    else 
    {
      this.confirmModalOverlay.removeClass("modal-show");
    }
  }
}
