import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[isGreaterThanEndDate]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: EndDateValidatorDirective,
        multi: true
    }]
})
export class EndDateValidatorDirective implements Validator {
    @Input('isGreaterThanEndDate') endDate: string;
    
    validate(control: AbstractControl) {
      if (control.value == null || this.endDate == null || this.endDate.length == 0)
        return null;

      const endDate = new Date(+this.endDate.substring(0, 4), +this.endDate.substring(5, 7)-1, +this.endDate.substring(8, 10));
      const chkDate = new Date(control.value);

      return ((chkDate > endDate) ? {'LaterDate': true} : null);
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('endDate' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }

}