

import { Document } from './document.model';

export class Event {
    public eventId: number = 0;
    public contraventionId: number;
    public vehicleSeizureId: number;
    public reviewId: number;
    public lateReviewRequestId: number;
    public paymentAdditionalTimeRequestId: number;
    public eventTypeId: number = 0;
    public eventDate: Date = new Date();
    public eventDetails: string;
    public userName: string;
    public documents: Document[] = [];
    public judicialReviewId: number;
    public noticeCancellationRequestId:number;
    public isCreatedByCaseAdministrator: boolean;

    constructor(init?: Partial<Event>) {
        
        Object.assign(this, init);
    }
}
