import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DatePipe } from "@angular/common";
import { AvailabilityView } from '@apis/shared/models/availability-view';
import { eachDayOfInterval, endOfWeek, startOfWeek, parseJSON } from 'date-fns';
import { DateUtil } from '../../helpers/date-util';
import { AvailabilityInformation } from '../../models/availability-information';

@Component({
  selector: 'app-auto-scheduler',
  templateUrl: './auto-scheduler.component.html',
  styleUrls: ['./auto-scheduler.component.scss']
})
export class AutoSchedulerComponent implements OnInit, OnChanges {
  @Input() schedule: AvailabilityView;
  @Input() scheduleDate: Date; // scheduleDate is either a string date with time zone unspecified (which parseJSON() interprets as UTC), or a UTC Date object
  @Input() scheduleTime: string;
  @Output() onRefresh = new EventEmitter();
  @Output() scheduleDateSelected = new EventEmitter<Date>();

  datePipe: DatePipe = new DatePipe("en-US");

  scheduleDateUtc: Date;
  
  activeWeekStartDate: Date = startOfWeek(new Date(), { weekStartsOn: 1 });
  activeWeekEndDate: Date = endOfWeek(new Date(), { weekStartsOn: 1 });
  activeWeekDays: Date[] = new Array(7);
  activeWeekOffset: number = 0;

  activeWeekAvailabilityList: AvailabilityInformation[] = [];

  isFirstWeekOfSchedule: boolean;
  isLastWeekOfSchedule: boolean;
  hasAvailability: boolean;

  constructor() { }

  ngOnInit(): void {    
    if (this.scheduleDate) {
      this.scheduleDateUtc = parseJSON(this.scheduleDate);
      this.initializeCalendar(); // Required when cancelling a review method change in driver portal reschedule; ngOnChanges executes before ngOnInit
    }
  }

  ngOnChanges(changes: SimpleChanges): void {     
    this.initializeCalendar();
  }

  initializeCalendar() {
    if (this.schedule) {
      this.hasAvailability = this.schedule.availabilityInformationList?.length > 0;
      this.setActiveWeek(0);
    }
  }

  onPrevWeekClick() {
    this.setActiveWeek(-1);
  }

  onNextWeekClick() {
    this.setActiveWeek(1);
  }

  onRefreshClick() {
    this.activeWeekOffset = 0;
    this.onRefresh.emit();
  }

  setActiveWeek(weekInterval: number) {
    let initialWeekStartDate;
    if (this.scheduleDateUtc) {
      const localScheduleDate = new Date(this.scheduleDateUtc).setMinutes(this.scheduleDateUtc.getMinutes() + this.scheduleDateUtc.getTimezoneOffset());
      initialWeekStartDate = startOfWeek(localScheduleDate, { weekStartsOn: 1 });
    } else {
      initialWeekStartDate = startOfWeek(new Date(this.schedule.startDate), { weekStartsOn: 1 });
    }
    this.activeWeekOffset += weekInterval;

    this.activeWeekStartDate = DateUtil.addDays(initialWeekStartDate, this.activeWeekOffset * 7);
    this.activeWeekEndDate = DateUtil.addDays(this.activeWeekStartDate, 4);

    this.isFirstWeekOfSchedule = +this.activeWeekStartDate <= new Date(this.schedule.startDate).setHours(0, 0, 0, 0);
    this.isLastWeekOfSchedule = +this.activeWeekEndDate >= new Date(this.schedule.endDate).setHours(0, 0, 0, 0);

    this.setActiveWeekAvailability();
  }

  setActiveWeekAvailability() {
    this.activeWeekDays = eachDayOfInterval({
      start: this.activeWeekStartDate,
      end: this.activeWeekEndDate
    });

    this.activeWeekAvailabilityList = [];

    this.activeWeekDays.forEach(d => {
      
      var dayAvailabilityInfo = this.schedule.availabilityInformationList
        .find(a => new Date(a.date).setHours(0, 0, 0, 0) == +d);

      if (dayAvailabilityInfo == null) {
        dayAvailabilityInfo = new AvailabilityInformation({
          date: d
        });
      }

      this.activeWeekAvailabilityList.push(dayAvailabilityInfo);
    });
  }

  onTimeSlotSelected(date: Date, timeSlot: any) {
    var timeTokens = timeSlot.split(':');

    let selectedDateUtc = parseJSON(date);
    selectedDateUtc.setUTCHours(timeTokens[0], timeTokens[1], timeTokens[2], 0);

    this.scheduleTime = timeSlot;
    this.scheduleDateUtc = selectedDateUtc;
    this.activeWeekOffset = 0;

    this.scheduleDateSelected.emit(new Date(selectedDateUtc));
  }

  isSelectedTimeSlot(date: Date, timeSlot: any) {
    const utcDate = parseJSON(date);
    utcDate.setUTCHours(0, 0, 0, 0);

    return (this.scheduleDateUtc && this.datePipe.transform(new Date(utcDate),'dd MMM, yyyy', 'UTC') == this.datePipe.transform(new Date(this.scheduleDateUtc),'dd MMM, yyyy', 'UTC'))
      && (this.scheduleTime && timeSlot == this.scheduleTime);
  }

  toTime(timeString){
    var timeTokens = timeString.split(':');
    return new Date(1970,0,1, timeTokens[0], timeTokens[1], timeTokens[2]);
  }

}
