<app-registry-payer-information
    [registryAgentInformation]="registryAgentInformation"
    [isFormSubmitted]="isSubmitClicked"
    [sectionHeader]="sectionHeader"
    [disabled]="disabled"
    [skipInitialQuestion]="true">
    <div class="form-group row">
        <div class="col-lg-6">
            <label for="vehicleInterest" class="required">What is Your Interest in the Vehicle?</label>
            <select class="form-control" id="vehicleInterest" name="vehicleInterest" required
                [disabled]="disabled"
                [(ngModel)]="reviewApplicant.vehicleInterestTypeId" (change)="onVehicleInterestTypeChange()"
                #vehicleInterest="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && vehicleInterest.invalid }">
                <option [ngValue]="null">Select an Option</option>
                <option *ngFor="let vehicleInterestType of vehicleInterestTypes" 
                    [value]="vehicleInterestType.id">{{vehicleInterestType.name}}</option>
            </select>
            <div class="invalid-feedback">
                <div>Vehicle Interest is required</div>
            </div>
        </div>
        <div class="col-lg-6" *ngIf="reviewApplicant.vehicleInterestTypeId == VehicleInterestTypes.Other">
            <label for="otherInterestType" class="required">Specify ‘Other Interest’ Type</label>
            <input type="text" class="form-control" id="otherInterestType" name="otherInterestType"
                [disabled]="disabled"
                required #otherInterestType="ngModel" [(ngModel)]="reviewApplicant.otherInterestType"
                [ngClass]="{ 'is-invalid': isSubmitClicked && otherInterestType.invalid }">
            <div class="invalid-feedback">
                <div>Other Interest Type is required</div>
            </div>
        </div>
    </div>
    <div *ngIf="reviewApplicant.vehicleInterestTypeId != null && reviewApplicant.vehicleInterestTypeId != VehicleInterestTypes.RegisteredOwner && !disabled" class="goa-callout goa--important mt-1">
        <p>
            Please advise the client that the registered owner of the vehicle must complete and sign a Consent to Representation form and this form must be uploaded to the SafeRoads portal before the scheduled review.
        </p>
        <p>
            Download the <a href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" download="Consent to Representation" target="_blank">Consent to Representation Form</a>
            print it, and provide the hard copy to the client
        </p>
    </div>
</app-registry-payer-information>