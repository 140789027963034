import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appEmergencyContraventionNumberValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: EmergencyContraventionNumberValidatorDirective,
        multi: true
    }]
})
export class EmergencyContraventionNumberValidatorDirective implements Validator {
    @Input('appEmergencyContraventionNumberValidator') isRequired: boolean;
    
    validate(control: AbstractControl) {
        if (this.isRequired == null || this.isRequired){
            const contraventionNumberRegEx = new RegExp('^[F][0-9]{8}[A]$');
            
            if (contraventionNumberRegEx.test(control.value?.toUpperCase())) { //Basic Format Ok
                var contraventionNumberDigits = control.value.substring(1, 9);
                var checkDigit: number = +contraventionNumberDigits.substring(7);
                var ticketNumber: Number = +contraventionNumberDigits.substring(0, 7);
                
                //Range Check
                // if (contraventionNumberDigits < 14248415)
                //     return {'invalidContraventionNumber': true};

                //Check Digit Logic - MODULO 7
                return (checkDigit == (+ticketNumber % 7)) ? null : {'invalidContraventionNumber': true};
            }
            else
                return {'invalidContraventionNumber': true};
        }
        else
            return null;    
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('isRequired' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }

}