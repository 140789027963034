export class AvailabilityInformation {
    public date: Date;
    public isHoliday: boolean;
    public holidayName: string;
    public isWeekEnd: boolean;
    public timeSlots: string[] = [];

    constructor(init?: Partial<AvailabilityInformation>) {
        
        Object.assign(this, init);
    }
}