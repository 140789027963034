<div class="cancel-modal-overlay">
    <div class="cancel-modal">
        <div class="cancel-modal-header">
            <div class="cancel-modal-title">Cancel this IRS Notice</div>
            <div class="close-icon" (click)="onCancelClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="cancel-modal-body">
            <label for="cancellationReason" class="bold-text">Reason for cancelling</label>
            <input readonly type="text" class="form-control" id="cancellationReason" value="{{cancellationReason}}">

            <label for="additionalNotes" class="bold-text mt-4">Additional notes about Roadside Appeal <span class="label-hint">(Required)</span></label>
            <textarea required id="additionalNotes" name="additionalNotes" 
            class="form-control textarea-control" rows="4" [(ngModel)]="contravention.roadsideAppeal.additionalNotes"></textarea>   
        </div>
        <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row mt-4">  
            <span class="error">{{errorMessage}}</span>
        </div>
        <div class="button-container">
            <div id="btnCancelModalCancel" class="cancel-button" (click)="onCancelClick()">Close</div>
            <div id="btnCancelModalConfirm" class="confirm-button" 
                [ngClass]="{'disabled': isContraventionCancelled }" 
                (click)="onConfirmCancellationClick()">Cancel Notice</div>
        </div>
    </div>
</div>
<div class="confirm-modal-overlay">
    <div class="confirm-modal">
        <div class="cancel-modal-header">
            <div class="cancel-modal-title">Confirm Cancellation</div>
            <div class="close-icon" (click)="showHideConfirmModal(false)">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="cancel-modal-body">
            <div class="cancellation-alert">
                <div class="alert-icon">                
                    <i class="fas fa-exclamation-triangle fa-lg"></i>
                </div>
                <div class="cancellation-alert-text">
                    <span class="bold-text">Are you sure you want to cancel this Notice?</span>
                </div>                       
            </div>
            <div class="button-container">
                <button id="btnGoBack" class="btn btn-cancel" (click)="showHideConfirmModal(false)">Cancel</button>
                <button id="btnConfirm" class="btn btn-confirm" (click)="onConfirmClick()">Confirm</button>
            </div>
        </div>
    </div>
</div>

