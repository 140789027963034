

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '@apis/app-config';
import { Setting } from '../models/setting.model';

@Injectable({
    providedIn: 'root'
})
export class SettingService {
    private readonly apiUrl = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;

    constructor(private readonly httpClient: HttpClient,
        @Inject(APP_CONFIG) public appConfig: any){}

    getSettings(): Observable<any> {
        return this.httpClient.get(`${this.apiUrl}/settings`);
      }
}