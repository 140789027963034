
<div class="header d-flex align-items-center">  
    <div class="hamburger-menu-button-container">      
        <!-- <span class="hamburger-menu-button" (click)="showMenu()">
            <i class="fas fa-bars fa-2x"></i>
        </span> -->
    </div>
    <div class="logo-container"> 
        <div class="ab-logo"></div>
    </div>
             
    <div *ngIf="userName" class="log-out d-flex align-items-center">
        <button class="btn-link" (click)="onUserNameClick()">
            {{userName}}
         </button>, &nbsp;
        <button class="btn-link" (click)="onLogoutClick()">
           Log Out
        </button>
    </div>
</div>

<div class="hamburger-menu-overlay fade" (click)="hideMenu()">
    <div class="hamburger-menu">
        <div class="hamburger-menu-header">
            
        </div>    
        <div class="hamburger-menu-body">
            
        </div>
        <div class="hamburger-menu-footer">
        
        </div>
    </div>
</div>

