<div class="card mt-3 mt-lg-4">
    <div class="card-header">
        {{sectionHeader}}
    </div>
    <div class="card-body">
        <div *ngIf="!skipInitialQuestion">
            <div class="form-group row">
                <div class="col-lg-12">
                    <label for="payerType">{{sectionLabel}} <span class="label-hint">(Required)</span></label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-12 col-lg-4">
                    <div class="custom-control custom-radio custom-control-inline">
                        <input required [disabled]="disabled" type="radio" class="custom-control-input" id="recipient" name="payerType" #recipientRadioControl="ngModel" [value]="true" [(ngModel)]="registryAgentInformation.isPaidByRecipient" (change)="onRequesterTypeChange()" [ngClass]="{'is-invalid': registryAgentInformation.isPaidByRecipient == null && (recipientRadioControl.touched || someoneElseRadioControl.touched || isFormSubmitted)}">
                        <label class="custom-control-label section-label" for="recipient">{{requesterLabel}}</label>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="custom-control custom-radio custom-control-inline">
                        <input required [disabled]="disabled" type="radio" class="custom-control-input" id="someoneElse" name="payerType" #someoneElseRadioControl="ngModel" [value]="false" [(ngModel)]="registryAgentInformation.isPaidByRecipient" (change)="onRequesterTypeChange()" [ngClass]="{'is-invalid': registryAgentInformation.isPaidByRecipient == null && (recipientRadioControl.touched || someoneElseRadioControl.touched || isFormSubmitted)}">
                        <label class="custom-control-label section-label" for="someoneElse">Someone Else</label>
                    </div>
                </div>
                <input required type="radio" style="display:none;" id="payerTypeHidden" name="payerType" #payerTypeHiddenControl="ngModel" [value]="null" [(ngModel)]="registryAgentInformation.isPaidByRecipient" [ngClass]="{'is-invalid': registryAgentInformation.isPaidByRecipient == null && (recipientRadioControl.touched || someoneElseRadioControl.touched || isFormSubmitted)}">
                <div class="pl-4 invalid-feedback">
                    <div>{{sectionHeader}} Type is required</div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="registryAgentInformation.isPaidByRecipient==false || skipInitialQuestion">
            <div class="form-group row">
                <div class="col-lg-4">
                    <label for="payerFirstName">First Name <span class="label-hint">(Required)</span></label>
                    <input required [disabled]="disabled" #firstNameControl="ngModel" maxlength="50" type="text" class="form-control" id="payerFirstName" name="payerFirstName" placeholder="First Name" [(ngModel)]="registryAgentInformation.payerFirstName" (ngModelChange)="registryAgentInformation.payerFirstName=this.titleCasePipe.transform($event)" [ngClass]="{'is-invalid': (firstNameControl.touched || isFormSubmitted) && firstNameControl.invalid}">
                    <div class="invalid-feedback">
                        <div>First Name is required</div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <label for="payerLastName">Last Name <span class="label-hint">(Required)</span></label>
                    <input required [disabled]="disabled" #lastNameControl="ngModel" maxlength="50" type="text" class="form-control" id="payerLastName" name="payerLastName" placeholder="Last Name" [(ngModel)]="registryAgentInformation.payerLastName" (ngModelChange)="registryAgentInformation.payerLastName=this.titleCasePipe.transform($event)" [ngClass]="{'is-invalid': (lastNameControl.touched || isFormSubmitted) && lastNameControl.invalid}">
                    <div class="invalid-feedback">
                        <div>Last Name is required</div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <label for="mvid">MVID <span class="label-hint">(Required)</span></label>
                    <input [appMVIDNumber]="true" [appMVIDNumberValidator]="true" [disabled]="disabled" required #mvidControl="ngModel" maxlength="10" type="text" class="form-control" id="mvid" name="mvid" placeholder="MVID" [(ngModel)]="registryAgentInformation.payerMVID" [ngClass]="{'is-invalid': (mvidControl.touched || isFormSubmitted) && mvidControl.invalid}">
                    <div class="invalid-feedback">
                        <div *ngIf="mvidControl.errors?.required">MVID is required</div>
                        <div *ngIf="mvidControl.errors?.invalidMvidNumberLength && !mvidControl.errors?.required">M.V.I.D Number must be 10 digits long</div>
                        <div *ngIf="mvidControl.errors?.invalidMvidNumberFormat && !mvidControl.errors?.required">Only digits are allowed</div>
                        <div *ngIf="mvidControl.errors?.invalidCheckDigit && !mvidControl.errors?.required">Invalid M.V.I.D Number.</div>
                    </div>
                </div>
            </div>
            <ng-content></ng-content>
        </ng-container>
    </div>
</div>