import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DeclarationTypes } from '../../enums/app.enum';
import { Review } from '../../models/review.model';
import { DeclarationType } from '../../models/types/declaration-type.model';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'app-failed-attendance-declaration',
  templateUrl: './failed-attendance-declaration.component.html',
  styleUrls: ['./failed-attendance-declaration.component.scss']
})
export class FailedAttendanceDeclarationComponent implements OnInit {
  @Input() review: Review;
  @Input() isFormSubmitted: boolean;
  @Input() isDisabled: boolean = false;
  @Input() isRSGPortal: boolean;

  declarationTypes: DeclarationType[];
  firstDeclarationType: DeclarationType;
  firstDeclarationHTML: SafeHtml;
  secondDeclarationType: DeclarationType;

  DeclarationTypes = DeclarationTypes;

  constructor(
    private localStorageService: LocalStorageService,
    private sanitizer: DomSanitizer
    ) {
    this.declarationTypes = this.localStorageService.getDeclarationTypes();
  }

  ngOnInit(): void {
    this.firstDeclarationType = this.declarationTypes.find(x => x.id === this.DeclarationTypes.FailedAttendanceAbandonReview);
    this.firstDeclarationHTML = this.sanitizer.bypassSecurityTrustHtml(this.firstDeclarationType.formattedDescription);
    this.secondDeclarationType = this.declarationTypes.find(x => x.id === this.DeclarationTypes.FailedAttendanceContinueReview);
  }
}
