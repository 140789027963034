<div class="submissionMain">
    <div class="d-flex flex-column">
        <div class="row">
            <div class="col-12 col-sm-8">        
                <h1>
                    {{title}}
                </h1>
            </div>
            <!-- <div *ngIf="isSubmissionComplete" class="col-12 col-sm-4">
                <div class="float-right">
                    <ul>
                        <li class="mb-1">
                            <a [routerLink]="[]" (click)="PrintSubmission()">
                                <img src="../../../../assets/images/printer.svg" alt="" title="Printer" class="action-icon mr-1">Print Submission</a>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>

        <div class="card border-0">
            <h6 class="mt-0">
                {{message}}
            </h6>
            <p class="mb-0 mt-0 pl-4" *ngFor="let error of errors">• {{error}}</p>
        </div>

        <div class="card border-0 mt-2">
            <h6>
                If a correction or assistance is needed on this contravention or a previously submitted contravention, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.
            </h6>
        </div>

        <div class="form-group mt-4">
            <div class="row pt-3">
                <div class="col-12 col-sm-12">                
                    <a class="btn button-primary float-right" [routerLink]="['/']">Return to Dashboard</a>
                </div>
            </div>
        </div>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      
    </div>
</div>
