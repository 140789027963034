

import { Recipient } from './recipient.model';
import { Vehicle } from './vehicle.model';
import { StopInformation } from './stop-information.model';

export class Warning {
    public warningId: number = 0;
    public warningNumber: string;
    public contraventionTypeId: number = 0;
    public occurrenceDate: Date = new Date();
    public occurrenceTime: string;
    public issueDate: Date = new Date();
    public issueTime: string;
    public recipientId: number;
    public vehicleId: number;
    public recipient: Recipient;
    public vehicle: Vehicle;
    public stopInformationId: number = 0;
    public secondaryContraventionTypeId: number = 0;
    public tertiaryContraventionTypeId: number = 0;
    public fineAmount: number;
    public victimFineSurchargeAmount: number;
    public demerits: number;
    public speedLimit: number;
    public ticketedSpeed: number;
    public recipientVehicleSpeed: number;

    constructor(init?: Partial<Warning>) {
        
        Object.assign(this, init);
    }
}
