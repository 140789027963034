import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { VehicleInterestTypes } from '@apis/shared/enums/app.enum';
import { ReviewApplicant } from '@apis/shared/models/review-applicant.model';
import { VehicleInterestType } from '@apis/shared/models/types/vehicle-interest-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { RegistryAgentInformation } from '../../models/registry-agent-information.model';

@Component({
  selector: 'app-registry-applicant-information',
  templateUrl: './registry-applicant-information.component.html',
  styleUrls: ['./registry-applicant-information.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class RegistryApplicantInformationComponent implements OnInit {
  @Input() reviewApplicant: ReviewApplicant;
  @Input() registryAgentInformation: RegistryAgentInformation;
  @Input() isSubmitClicked: boolean;
  @Input() disabled: boolean;
  @Input() sectionHeader: string;
  @Output() vehicleInterestTypeChange = new EventEmitter();

  vehicleInterestTypes: VehicleInterestType[];
  VehicleInterestTypes = VehicleInterestTypes;

  constructor(
    private localStorageService: LocalStorageService
  ) {    
   }

  ngOnInit(): void {
    this.vehicleInterestTypes = this.localStorageService.getVehicleInterestTypes();
  }

  onVehicleInterestTypeChange() {
    this.registryAgentInformation.isPaidByRecipient = (+this.reviewApplicant.vehicleInterestTypeId === VehicleInterestTypes.RegisteredOwner);
    this.reviewApplicant.otherInterestType = '';
    this.vehicleInterestTypeChange.emit();
  }
}
