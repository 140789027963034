import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[isAfterMinimumAgeDate]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: AfterMinimumAgeDateValidatorDirective,
        multi: true
    }]
})
export class AfterMinimumAgeDateValidatorDirective implements Validator {
  @Input('isAfterMinimumAgeDate') birthDate: Date | string;

  validate(control: AbstractControl) {
    if (control.value == null || this.birthDate == null)
      return null;

    let inputDate: Date;
    if (typeof this.birthDate === 'string') {
      inputDate = new Date(this.birthDate);
    }
    else {
      inputDate = this.birthDate;
    }

    const minimumAgeDate = new Date(inputDate.getFullYear() + 12, inputDate.getMonth(), inputDate.getDate());
    const chkDate = new Date(new Date(control.value).getFullYear(), new Date(control.value).getMonth(), new Date(control.value).getDate());

    if (chkDate < minimumAgeDate) {
      return {'AfterMinimumAgeDate': true};
    }
    else {
      return null;
    }
  }
}