import { DisclosureInfo } from "./disclosure-info.model";

export class DisclosuresResult {
    public ticketNumber: string;
    public disclosures: DisclosureInfo[] = [];

    constructor(init?: Partial<DisclosuresResult>) {
        Object.assign(this, init);
    }
}
