import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContraventionDetailTypes, ContraventionStatusTypes, ContraventionTypes, EventTypes, RoadsideAppealOutcomeTypes, SpeedingDeviceTypes, TestTypes } from '@apis/shared/enums/app.enum';
import { ApprovedInstrumentTest } from '@apis/shared/models/approved-instrument-test.model';
import { ApprovedScreeningDeviceTest } from '@apis/shared/models/approved-screening-device-test.model';
import { BloodSampleTest } from '@apis/shared/models/blood-sample-test.model';
import { ContraventionTest } from '@apis/shared/models/contravention-test.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { DrugRecognitionEvaluationTest } from '@apis/shared/models/drug-recognition-evaluation-test.model';
import { DrugScreeningEquipmentTest } from '@apis/shared/models/drug-screening-equipment-test.model';
import { ImpairmentScreeningInformation } from '@apis/shared/models/impairment-screening-information.model';
import { ImpairmentScreeningModel } from '@apis/shared/models/impairment-screening.model';
import { RoadsideAppeal } from '@apis/shared/models/roadside-appeal.model';
import { StandardizedFieldSobrietyTest } from '@apis/shared/models/standardized-field-sobriety-test.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { CircumstanceType } from '@apis/shared/models/types/circumstance-type.model';
import { ContraventionGroundsType } from '@apis/shared/models/types/contravention-grounds-type.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { Event } from '@apis/shared/models/event.model';
import { ImpairmentScreeningType } from '@apis/shared/models/types/impairment-screening-type.model';
import { RoadsideAppealOutcomeType } from '@apis/shared/models/types/roadside-appeal-outcome-type.model';
import { SuspectedImpairmentType } from '@apis/shared/models/types/suspected-impairment-type.model';
import { SuspicionType } from '@apis/shared/models/types/suspicion-type.model';
import { TestType } from '@apis/shared/models/types/test-type.model';
import { UrineSampleTest } from '@apis/shared/models/urine-sample-test.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpeedingTicket } from '@apis/shared/models/speeding-ticket.model';
import { SpeedingDeviceMakeType } from '@apis/shared/models/types/speeding-device-make-type.model';
import { SpeedingDeviceModelType } from '@apis/shared/models/types/speeding-device-model-type.model';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-additional-details',
  templateUrl: './additional-details.component.html',
  styleUrls: ['./additional-details.component.scss']
})
export class AdditionalDetailsComponent implements OnInit {
  stopInformation: StopInformation;
  contraventionTypes: ContraventionType[];

  //IRS Contravention
  irsContravention: Contravention;
  impairmentScreeningInformation: ImpairmentScreeningInformation;
  contraventionTest: ContraventionTest;
  additionalTests: ContraventionTest[];
  roadsideAppeal: RoadsideAppeal;
  roadsideAppealTest: ContraventionTest;
  impairmentScreeningMethodId: number = 0;
  selectedSuspicionTypes: number[];
  circumstanceTypes: CircumstanceType[];
  contraventionGroundsTypes: ContraventionGroundsType[];
  impairmentScreeningTypes: ImpairmentScreeningType[];
  suspicionTypes: SuspicionType[];
  suspectedImpairmentTypes: SuspectedImpairmentType[];
  testTypes: TestType[];
  appealTestTypes: TestType[];
  commonTestTypes: number[] = [TestTypes.SFST, TestTypes.ASD, TestTypes.ADSE];
  complexTestTypes: number[] = [TestTypes.AI, TestTypes.DRE, TestTypes.Blood];
  impairmentScreeningModel: ImpairmentScreeningModel;
  additionalScreeningModels: ImpairmentScreeningModel[] = [];
  roadsideAppealModel: ImpairmentScreeningModel;
  selectedContraventionType: ContraventionType = null;
  roadsideAppealOutcomeType: RoadsideAppealOutcomeType;
  reIssueEvent: Event;
  
  //Speeding Contravention
  speedingContravention: Contravention;
  speedingTicket: SpeedingTicket;
  speedingDeviceMakeTypes: SpeedingDeviceMakeType[];
  speedingDeviceModelTypes: SpeedingDeviceModelType[];
  isLidar: boolean=false;
  isRadar: boolean=false;

  isSubmitClicked: boolean = false;
  isRoadsideAppealVisible: boolean = false;
  isCertificationChecked: boolean = false;
  ContraventionTypes = ContraventionTypes;
  ContraventionStatusTypes = ContraventionStatusTypes;
  RoadsideAppealOutcomeTypes = RoadsideAppealOutcomeTypes;
  Constants = Constants;

  testingDatePickerConfig: Partial<BsDatepickerConfig>;

  errorMessage: string = "";

  constructor(private intakeService: IntakeService, 
    private localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private router: Router) {
      this.testingDatePickerConfig = Object.assign({}, 
        {
          containerClass: 'theme-dark-blue', 
          showWeekNumbers: false,
          dateInputFormat: 'YYYY/MM/DD',
          minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
          isAnimated: true,
          customTodayClass: 'custom-today-class'
        })
     }

  ngOnInit(): void {
    //Get stop information object
    this.stopInformation = this.intakeService.getStopInformationModel();

    //Route to Supporting documents page if it is a legacy submission or details are already submitted
    if (this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1 || 
       (this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE2 && this.stopInformation.isDetailSubmitted))
      this.router.navigateByUrl('/contravention/add-details/supporting-documents');

    //Get Types
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    var irsTypeIds = this.contraventionTypes.filter(x=>x.isIRS).map(s=>s.id);
    var speedingTypeIds = this.contraventionTypes.filter(x=>x.isSpeedingOffence).map(s=>s.id);
    
    this.irsContravention = this.stopInformation.contraventions.find(x => irsTypeIds.includes(+x.contraventionTypeId));
    this.speedingContravention = this.stopInformation.contraventions.find(x => speedingTypeIds.includes(+((x.tertiaryContraventionTypeId??x.secondaryContraventionTypeId)??x.contraventionTypeId)));

    if (this.stopInformation.isIRSSelected)
    {
      //Get IRS Specific Types
      this.circumstanceTypes = this.localStorageService.getCircumstanceTypes();
      this.contraventionGroundsTypes = this.localStorageService.getContraventionGroundsTypes();
      this.impairmentScreeningTypes = this.localStorageService.getImpairmentScreeningTypes();
      this.suspicionTypes = this.localStorageService.getSuspicionTypes();
      this.appealTestTypes = this.localStorageService.getTestTypes().filter(x => x.isApplicableToRoadsideAppeal == true);

      //Roadside Appeal 
      if (this.irsContravention.roadsideAppeal == null) {
        this.irsContravention.roadsideAppeal = new RoadsideAppeal();  
  
        //Set Defaults
        this.irsContravention.roadsideAppeal.isRecipientPresentedWithInformation = null;
        this.irsContravention.roadsideAppeal.hasDriverRequestedRoadsideAppeal = null;
      }
      
      if (this.irsContravention.roadsideAppeal.roadsideTests == null || this.irsContravention.roadsideAppeal.roadsideTests.length == 0) {
        this.irsContravention.roadsideAppeal.roadsideTests = [];
        this.irsContravention.roadsideAppeal.roadsideTests.push(new ContraventionTest());
      }

      this.roadsideAppeal = this.irsContravention.roadsideAppeal;
      this.roadsideAppealTest = this.irsContravention.roadsideAppeal.roadsideTests[0];

      this.reIssueEvent = this.irsContravention.events.find(x=> x.eventTypeId == EventTypes.ContraventionReIssued);

      
      // JTI-3170 - isRefusal is true ONLY is refusal is the ONLY detail selected
      //
      var detailsSelected = this.irsContravention?.contraventionDetails?.split(",");
      var totalDetailsSelected = detailsSelected?.length;
      
      var isRefusal = (
          (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail1st && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail1stRefusal.toString()) && totalDetailsSelected===1)) ||
          (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail2nd && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail2ndRefusal.toString()) && totalDetailsSelected===1)) ||
          (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail3rd && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail3rdRefusal.toString()) && totalDetailsSelected===1))
      )
      
      this.isRoadsideAppealVisible = ((this.irsContravention.contraventionTypeId != ContraventionTypes.IRS24 || (this.irsContravention.contraventionTypeId == ContraventionTypes.IRS24 && !!this.reIssueEvent)) && !isRefusal);
      this.roadsideAppealOutcomeType = this.localStorageService.getRoadsideAppealOutcomeTypes().find(x=> x.id == this.roadsideAppeal.roadsideAppealOutcomeTypeId);

      if (this.irsContravention.contraventionTypeId == ContraventionTypes.IRS24) {
        var suspectedImpairmentTypeIds = [];

        var contraventionDetailsIds = [];

        contraventionDetailsIds = this.irsContravention.contraventionDetails.split(",");

        contraventionDetailsIds.forEach(detail => {
          switch (+detail) {
            case ContraventionDetailTypes.IRS24Fatigue:
              suspectedImpairmentTypeIds.push(2);
              break;
            case ContraventionDetailTypes.IRS24MedicalCondition:
              suspectedImpairmentTypeIds.push(3);
              break;
            case ContraventionDetailTypes.IRS24DrugOrAlcohol:
              suspectedImpairmentTypeIds.push(1);
              suspectedImpairmentTypeIds.push(4);
              suspectedImpairmentTypeIds.push(5);
              break;
          }
        });
        
        this.suspectedImpairmentTypes = this.localStorageService.getSuspectedImpairmentTypes().filter(x => suspectedImpairmentTypeIds.includes(x.id));
      }
      else if(this.irsContravention.contraventionTypeId == ContraventionTypes.IRSWarn1st || this.irsContravention.contraventionTypeId == ContraventionTypes.IRSWarn2nd || this.irsContravention.contraventionTypeId == ContraventionTypes.IRSWarn3rd)
      {
        this.suspectedImpairmentTypes = this.localStorageService.getSuspectedImpairmentTypes().filter(x => x.id==1);
      }
      else
        this.suspectedImpairmentTypes = this.localStorageService.getSuspectedImpairmentTypes().filter(x => [1,4,5].includes(x.id));

      //Initialize Impairment Screening Information
      if (this.irsContravention.impairmentScreeningInformation == null) {
        this.irsContravention.impairmentScreeningInformation = new ImpairmentScreeningInformation();
        this.irsContravention.impairmentScreeningInformation.suspectedImpairmentTypeId = 0;
      }

      this.impairmentScreeningInformation = this.irsContravention.impairmentScreeningInformation;
      
      if(this.irsContravention.impairmentScreeningInformation.impairmentTests != null && this.irsContravention.impairmentScreeningInformation.impairmentTests.length > 0) 
      {
        this.contraventionTest = this.irsContravention.impairmentScreeningInformation.impairmentTests[0];
        this.impairmentScreeningMethodId = this.irsContravention.impairmentScreeningInformation.impairmentTests[0].testTypeId;
      }
      
      //Initialize selected suspicion types
      this.selectedSuspicionTypes = this.impairmentScreeningInformation.suspicionTypes?.split(',').map(x=> +x);
      
      //Populate dependant types
      switch(this.irsContravention.impairmentScreeningInformation.impairmentScreeningTypeId?.toString()) {
        case "1": //RS
          this.testTypes = this.localStorageService.getTestTypes().filter(x => x.isApplicableToReasonableSuspicion == true);
          break;
        case "2": //MAS
          this.testTypes = this.localStorageService.getTestTypes().filter(x => x.isApplicableToMAS == true);  
          break;
        default:
          this.testTypes = null;
          break;
      }

      this.additionalTests = this.irsContravention.impairmentScreeningInformation.impairmentTests;
      
      //Initialize Common Control
      for (var index in this.additionalTests) {
        var isSimpleTestType = this.commonTestTypes.includes(+this.additionalTests[index].testTypeId);
        var additionalScreeningModel = new ImpairmentScreeningModel();
        additionalScreeningModel.ImpairmentScreeningMethodId = this.additionalTests[index].testTypeId;
        additionalScreeningModel.isImpairmentScreeningMethodSelectionRequired = true;
        additionalScreeningModel.selectionMessageLabel = "Select an Impairment Screening Method to Add Details";
        additionalScreeningModel.noSelectionMessageLabel = "No Method Used";
        additionalScreeningModel.controlSuffixText = "Additional";
        additionalScreeningModel.possibleTestTypes = isSimpleTestType?this.localStorageService.getTestTypes().filter(x => this.commonTestTypes.includes(x.id)):this.localStorageService.getTestTypes().filter(x => this.complexTestTypes.includes(x.id));
        additionalScreeningModel.impairmentScreeningCardLabel = isSimpleTestType?"Additional ASD/SFST/ADSE Test":"Additional Intox/DRE/Blood Test";
        additionalScreeningModel.impairmentScreeningResultCardLabel = "Additional Test Method";
        this.additionalScreeningModels.push(additionalScreeningModel);
      }
    
      //Get contravention type
      if (this.irsContravention.contraventionTypeId > 0)
      {
        this.selectedContraventionType = this.contraventionTypes.find(x => x.id == this.irsContravention.contraventionTypeId);
      }
  
      //Initialize Common Controls
      this.impairmentScreeningModel = new ImpairmentScreeningModel();
      this.impairmentScreeningModel.ImpairmentScreeningMethodId = this.impairmentScreeningMethodId;
      this.impairmentScreeningModel.isImpairmentScreeningMethodSelectionRequired = false;
      this.impairmentScreeningModel.impairmentScreeningResultCardLabel = "Impairment Screening Method Details";
      this.impairmentScreeningModel.selectionMessageLabel = "Select an Impairment Screening Method to Add Details";
      this.impairmentScreeningModel.noSelectionMessageLabel = "No Method Used";
      this.impairmentScreeningModel.controlSuffixText = "Impairment";
  
      this.roadsideAppealModel = new ImpairmentScreeningModel();
      this.roadsideAppealModel.ImpairmentScreeningMethodId = this.roadsideAppealTest?.testTypeId;
      this.roadsideAppealModel.isImpairmentScreeningMethodSelectionRequired = false;
      this.roadsideAppealModel.impairmentScreeningResultCardLabel = "Roadside Appeal Method Details";
      this.roadsideAppealModel.selectionMessageLabel = "Select a Roadside Appeal Method to Add Details";
      this.roadsideAppealModel.noSelectionMessageLabel = "No Roadside Appeal Provided";
      this.roadsideAppealModel.controlSuffixText = "RoadsideAppeal"
    }

    if (this.stopInformation.isSpeedingSelected)
    {
      this.speedingTicket = this.speedingContravention.speedingTicket;

      //Get Speeding Specific Types
      this.speedingDeviceMakeTypes = this.localStorageService.getSpeedingDeviceMakeTypes().filter(x=> x.speedingDeviceTypeId == this.speedingTicket.speedingDeviceTypeId);  
      this.speedingDeviceModelTypes = this.localStorageService.getSpeedingDeviceModelTypes().filter(x => x.speedingDeviceMakeTypeId == this.speedingTicket.speedingDeviceMakeTypeId);
    
      var otherType = new  SpeedingDeviceModelType()
      otherType.id = 99;
      otherType.name = "Other";
      otherType.description = "Other";

      if (this.speedingDeviceModelTypes.length > 0)
        this.speedingDeviceModelTypes.push(otherType);

      //Initialize speeding device
      this.isLidar = this.speedingTicket.speedingDeviceTypeId == SpeedingDeviceTypes.Lidar;
      this.isRadar = this.speedingTicket.speedingDeviceTypeId == SpeedingDeviceTypes.Radar;

      if (this.speedingTicket.timeOfTest?.length>5)
        this.speedingTicket.timeOfTest = this.speedingTicket.timeOfTest.substring(0, 5);
    }

    //scroll to top with tab change
    window.scroll(0,0);
  }

  onContraventionGroundsChange(contraventionGroundsId: number)
  {
    //Reset impairment screening information fields
    this.irsContravention.impairmentScreeningInformation.impairmentTests = [];
    this.irsContravention.impairmentScreeningInformation.impairmentScreeningTypeId = 0;
    this.irsContravention.impairmentScreeningInformation.suspicionTypes = "";
    this.irsContravention.impairmentScreeningInformation.suspicionOther = "";
    this.selectedSuspicionTypes = [];
    this.additionalScreeningModels = [];
    
    if(contraventionGroundsId == 1) // Yes
    {
      this.irsContravention.impairmentScreeningInformation.impairmentTests.push(new ContraventionTest())
      this.contraventionTest = this.irsContravention.impairmentScreeningInformation.impairmentTests[0];
      this.contraventionTest.testSequence = 0; // Always 0 for contravention test
      this.irsContravention.reasonableGroundsDetails = "";
      this.irsContravention.consumptionEvidence = "";  
      this.onImpairmentScreeningTypeChange(0);
    }

    this.additionalTests = this.irsContravention.impairmentScreeningInformation.impairmentTests;
  }

  onImpairmentScreeningTypeChange(impairmentScreeningTypeId)
  {
    switch(impairmentScreeningTypeId) {
      case "1": //RS
        this.testTypes = this.localStorageService.getTestTypes().filter(x => x.isApplicableToReasonableSuspicion == true);
        break;
      case "2": //MAS
        this.testTypes = this.localStorageService.getTestTypes().filter(x => x.isApplicableToMAS == true);  
        break;
      default:
        this.testTypes = null;
        break;
    }
    this.impairmentScreeningMethodId = 0; //Reset Impairment Screening Method
    this.onImpairmentScreeningMethodChange(this.impairmentScreeningMethodId)
  }

  onImpairmentScreeningMethodChange(impairmentScreeningMethodId)
  {
    //Refresh impairmentScreeningModel and contraventionTest
    this.impairmentScreeningModel.ImpairmentScreeningMethodId = impairmentScreeningMethodId;
    this.contraventionTest.standardizedFieldSobrietyTest = null;
    this.contraventionTest.approvedScreeningDeviceTest = null;
    this.contraventionTest.drugScreeningEquipmentTest = null;
    this.contraventionTest.drugRecognitionEvaluationTest = null;
    this.contraventionTest.approvedInstrumentTest = null;
    this.contraventionTest.bloodSampleTest = null;
    this.contraventionTest.urineSampleTest = null;

    this.contraventionTest.testTypeId = impairmentScreeningMethodId;
    
    if (impairmentScreeningMethodId == "0" ||impairmentScreeningMethodId == "7" ) //No Selection or No Method
    {
      this.impairmentScreeningModel.impairmentScreeningResultCardLabel = "Impairment Screening Method Details";
    }
    else
    {
      this.contraventionTest.testSequence = 0; // Always 0 for impairment test
      this.contraventionTest.hasDriverRefusedToTest = null;
      this.impairmentScreeningModel.impairmentScreeningResultCardLabel = this.testTypes.find(x => x.id == impairmentScreeningMethodId).name;
      switch(impairmentScreeningMethodId) {
        case "1": //SFST
          this.contraventionTest.standardizedFieldSobrietyTest = new StandardizedFieldSobrietyTest();
          break;
        case "2": //ASD
          this.contraventionTest.approvedScreeningDeviceTest = new ApprovedScreeningDeviceTest();
          break;  
        case "3": //ADSE
          this.contraventionTest.drugScreeningEquipmentTest = new DrugScreeningEquipmentTest();
          break;  
        case "4": //DRE
          this.contraventionTest.drugRecognitionEvaluationTest = new DrugRecognitionEvaluationTest();
          break;  
        case "5": //AI
          this.contraventionTest.approvedInstrumentTest = new ApprovedInstrumentTest();
          break;  
        case "6": //Blood Sample
          this.contraventionTest.bloodSampleTest = new BloodSampleTest();
          break;    
        case "9": //Urine Sample
          this.contraventionTest.urineSampleTest = new UrineSampleTest();
          break;    
      }
    }
    this.refreshRoadsideAppealTestTypes();
  }

  onSuspicionBasedOnChange(selectedSuspicionTypesValue: number[])
  {
    this.impairmentScreeningInformation.suspicionTypes = selectedSuspicionTypesValue.toString();
  }

  refreshRoadsideAppealTestTypes()
  {
    if(this.irsContravention.impairmentScreeningInformation?.impairmentTests?.filter(x=>x.testTypeId == TestTypes.DRE).length > 0)
      this.appealTestTypes = this.localStorageService.getTestTypes().filter(x => [TestTypes.Blood, TestTypes.Urine, TestTypes.NoAppeal].includes(x.id));
    else
      this.appealTestTypes = this.localStorageService.getTestTypes().filter(x => x.isApplicableToRoadsideAppeal == true);
    
    this.roadsideAppealTest.testTypeId = 0;
    this.onRoadsideAppealMethodChange(0);
  }

  onAddAdditionalTestClick(isSimpleTestType: boolean)
  {
    if (this.additionalScreeningModels.length == 0 && this.irsContravention.contraventionGroundsTypeId == 1)
      this.additionalScreeningModels.push(new ImpairmentScreeningModel());

    var additionalScreeningModel = new ImpairmentScreeningModel();

    additionalScreeningModel.ImpairmentScreeningMethodId = 0; //Will be selected witin the component
    additionalScreeningModel.isImpairmentScreeningMethodSelectionRequired = true;
    additionalScreeningModel.selectionMessageLabel = "Select an Impairment Screening Method to Add Details";
    additionalScreeningModel.noSelectionMessageLabel = "No Method Used";
    additionalScreeningModel.controlSuffixText = "Additional";
    additionalScreeningModel.isSubmitClicked = this.isSubmitClicked;
    additionalScreeningModel.possibleTestTypes = isSimpleTestType?this.localStorageService.getTestTypes().filter(x => this.commonTestTypes.includes(x.id)):this.localStorageService.getTestTypes().filter(x => this.complexTestTypes.includes(x.id));
    additionalScreeningModel.impairmentScreeningCardLabel = isSimpleTestType?"Additional ASD/SFST/ADSE Test":"Additional Intox/DRE/Blood Test";
    additionalScreeningModel.impairmentScreeningResultCardLabel = "Additional Test Method";

    this.additionalScreeningModels.push(additionalScreeningModel);

    var maxSequence = this.additionalTests.length == 0? 0 : Math.max.apply(Math, this.additionalTests.map(function(x) { return x.testSequence; }));

    var contraventionTest = new ContraventionTest();
    contraventionTest.testSequence = maxSequence + 1;

    this.additionalTests.push(contraventionTest);
  }

  onRemoveAdditionalScreening(sequenceNumber)
  {
    var testToRemove =  this.additionalTests.find(x=> x.testSequence == sequenceNumber);
    if (testToRemove)
    {
      var index = this.additionalTests.indexOf(testToRemove);
      this.additionalTests.splice(index, 1);
      this.additionalScreeningModels.splice(index, 1);
    }

    //Reorder additional test sequence numbers
    this.additionalTests.filter(x => x.testSequence != 0).forEach((element, index) => {
      element.testSequence = index + 1;
    });

    this.refreshRoadsideAppealTestTypes();
  }

  onRoadsideAppealMethodChange(roadsideAppealMethodId)
  {
    //Refresh roadsideAppealModel and contraventionTest
    this.roadsideAppealModel.ImpairmentScreeningMethodId = roadsideAppealMethodId;
    this.roadsideAppealTest.standardizedFieldSobrietyTest = null;
    this.roadsideAppealTest.approvedScreeningDeviceTest = null;
    this.roadsideAppealTest.drugScreeningEquipmentTest = null;
    this.roadsideAppealTest.drugRecognitionEvaluationTest = null;
    this.roadsideAppealTest.approvedInstrumentTest = null;
    this.roadsideAppealTest.bloodSampleTest = null;
    this.roadsideAppealTest.urineSampleTest = null;

    if (roadsideAppealMethodId == "0" || roadsideAppealMethodId == "8" ) //No Selection or No Method
    {
      this.roadsideAppealModel.impairmentScreeningResultCardLabel = "Roadside Appeal Method Details";
    }
    else
    {
      this.roadsideAppealTest.testSequence = 0; // Always 0 for roadside appeal test
      this.roadsideAppealTest.hasDriverRefusedToTest = null;
      this.roadsideAppealTest.testTypeId = roadsideAppealMethodId;
      this.roadsideAppealModel.impairmentScreeningResultCardLabel = this.appealTestTypes.find(x => x.id == roadsideAppealMethodId).name;
      switch(roadsideAppealMethodId) {
        case "1": //SFST
          this.roadsideAppealTest.standardizedFieldSobrietyTest = new StandardizedFieldSobrietyTest();
          break;
        case "2": //ASD
          this.roadsideAppealTest.approvedScreeningDeviceTest = new ApprovedScreeningDeviceTest();
          break;  
        case "3": //ADSE
          this.roadsideAppealTest.drugScreeningEquipmentTest = new DrugScreeningEquipmentTest();
          break;  
        case "4": //DRE
          this.roadsideAppealTest.drugRecognitionEvaluationTest = new DrugRecognitionEvaluationTest();
          break;  
        case "5": //AI
          this.roadsideAppealTest.approvedInstrumentTest = new ApprovedInstrumentTest();
          break;  
        case "6": //Blood Sample
          this.roadsideAppealTest.bloodSampleTest = new BloodSampleTest();
          break;  
        case "9": //Urine Sample
          this.roadsideAppealTest.urineSampleTest = new UrineSampleTest();
          break;       
      }
    }
  }

  onSubmitClick(isValid: boolean)
  {
    this.isSubmitClicked = true;
   
    if (isValid && this.isValidSpeedingInfo() && this.isValidAdditionalTests()) 
    {
      if(this.stopInformation.isIRSSelected)
      {
        this.impairmentScreeningModel.isSubmitClicked = true;
        this.roadsideAppealModel.isSubmitClicked = true;
        this.additionalScreeningModels.forEach(additionalScreeningModel => {
          additionalScreeningModel.isSubmitClicked = true;
        });
      
        //Check if roadside appeal impairment test is required
        if (!this.irsContravention.roadsideAppeal.isRecipientPresentedWithInformation)
        {
          this.irsContravention.roadsideAppeal.hasDriverRequestedRoadsideAppeal = null;
          this.irsContravention.roadsideAppeal.roadsideTests = null;
        }

        //Check is driver declined the roadside appeal test
        if (!this.irsContravention.roadsideAppeal.hasDriverRequestedRoadsideAppeal)
          this.irsContravention.roadsideAppeal.roadsideTests = null;

        //Check if roadside appeal is required or not
        if (!this.isRoadsideAppealVisible)
          this.irsContravention.roadsideAppeal = null;
      }

      if(this.stopInformation.isSpeedingSelected)
      {
        if(this.isRadar || this.isLidar)
          this.speedingTicket.speedingDeviceTypeId = this.isRadar? SpeedingDeviceTypes.Radar: SpeedingDeviceTypes.Lidar;
        else
        this.speedingTicket.speedingDeviceTypeId = null;
      }

      //Refresh the stop documents list
      this.stopInformation.documents = this.stopInformation.documents?.filter(x => x.documentId > 0 || x.documentName?.length > 0);

      this.intakeService.saveStopInformationContext();
      this.router.navigateByUrl('/contravention/add-details/supporting-documents');     
    } 
    else
      window.scroll(0,0); 
  }
  
  isValidAdditionalTests() {
    // Check if any duplicate Intox/DRE/Blood test added as part of the IRS contravention additional screening 
    if(this.stopInformation.isIRSSelected && this.irsContravention?.impairmentScreeningInformation?.impairmentTests?.filter(x => x.testSequence != 0)?.length > 1)
    {
      var additionalTests = this.irsContravention?.impairmentScreeningInformation?.impairmentTests?.filter(x => x.testSequence != 0);

      if (additionalTests.filter(x => x.testTypeId == TestTypes.DRE).length > 1)
      {
        this.errorMessage = "Drug Recognition Evaluation test is added multiple times. There could be only one";
        return false;
      }

      if (additionalTests.filter(x => x.testTypeId == TestTypes.AI).length > 1)
      {
        this.errorMessage = "Approved Instrument test is added multiple times. There could be only one";
        return false;
      }

      if (additionalTests.filter(x => x.testTypeId == TestTypes.Blood).length > 1)
      {
        this.errorMessage = "Blood Sample test is added multiple times. There could be only one";
        return false;
      }
    }

    this.errorMessage = "";
    return true;
  }

  isValidSpeedingInfo() {
   return  (
       !this.speedingTicket ||  
      ((this.isLidar || this.isRadar || this.speedingTicket?.isPacing || this.speedingTicket?.isVisualEstimate || this.speedingTicket?.isClocked || this.speedingTicket?.isAircraft || this.speedingTicket?.isOtherMethod) &&
      !(this.isLidar && this.isRadar) &&
      !(this.isLidar && this.speedingTicket.isPacing) &&
      !((this.isLidar || this.isRadar || this.speedingTicket.isPacing || this.speedingTicket.isVisualEstimate) && this.speedingTicket.isAircraft))
     );
  }

  onOtherDeviceChange() {
    this.speedingTicket.otherMethod = null;
  }

  onSpeedingDeviceChange(){
    this.speedingDeviceMakeTypes = this.localStorageService.getSpeedingDeviceMakeTypes().filter(x=> x.speedingDeviceTypeId == (this.isLidar?SpeedingDeviceTypes.Lidar:SpeedingDeviceTypes.Radar));  
    this.speedingDeviceModelTypes = [];
    this.speedingTicket.isNotSpeedingDeviceOperator = null;
    this.onSpeedingDeviceOperatorChange();
  }

  onSpeedingDeviceOperatorChange()
  {
    this.speedingTicket.policeVehicleDirection = null;
    this.speedingTicket.policeVehicleSpeed = null;
    this.speedingTicket.recipientVehicleDirection = null;
    this.speedingTicket.speedingDeviceDistanceToVehicle = null;
    this.speedingTicket.isUploadOperatorReport = null;
    this.onUploadReportChange();
  }

  onUploadReportChange()
  {
    this.speedingTicket.speedingDeviceMakeTypeId = null;
    this.speedingTicket.speedingDeviceModelTypeId = null;
    this.speedingTicket.speedingDeviceModelOther = null;
    this.speedingTicket.isOperatorCertified = null;
    this.speedingTicket.isManufacturerTestCompleted = null;
    this.speedingTicket.reasonManufacturerTestNotCompleted = null;
    this.speedingTicket.isManufacturerTestPassed = null;
    this.speedingTicket.reasonManufacturerTestFailed = null;
    this.speedingTicket.dateOfTest = null;
    this.speedingTicket.timeOfTest = null;
    this.isCertificationChecked = false;
  }

  onSpeedingDeviceMakeChange()
  {
    this.speedingTicket.speedingDeviceModelTypeId = null;
    this.speedingDeviceModelTypes = this.localStorageService.getSpeedingDeviceModelTypes().filter(x => x.speedingDeviceMakeTypeId == this.speedingTicket.speedingDeviceMakeTypeId);
    
    var otherType = new  SpeedingDeviceModelType()
    otherType.id = 99;
    otherType.name = "Other";
    otherType.description = "Other";

    if (this.speedingDeviceModelTypes.length > 0)
      this.speedingDeviceModelTypes.push(otherType);
  }
}
