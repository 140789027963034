import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Event } from '@apis/shared/models/event.model';
import { EventService } from "@apis/shared/services/event.service";
import { DocumentService } from "@apis/shared/services/document.service";
import { KeycloakService } from 'keycloak-angular';

import { NgxSpinnerService } from 'ngx-spinner';
import { Document } from '@apis/shared/models/document.model';

@Component({
  selector: 'app-delete-note-modal',
  templateUrl: './delete-note-modal.component.html',
  styleUrls: ['./delete-note-modal.component.scss']
})
export class DeleteNoteModalComponent implements OnInit {
  eventId: number;  
  documentRefTypeName: string = "Events";
  documentRefTypeNumber: string;

  @Output() close: EventEmitter<boolean> = new EventEmitter();

  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  event: Event = new Event();
  
  documents: Document[] = [];  
          
  constructor(private readonly eventService: EventService,
    private readonly documentService: DocumentService,
    private readonly spinner: NgxSpinnerService) { }

  ngOnInit(): void {      
      this.spinner.show();
      this.eventService.getEventByIdAsync(this.eventId)
        .subscribe((event: Event) => {
          this.event = event;
          this.documents = this.event.documents;
          this.spinner.hide();
        }, (error: any) => this.spinner.hide());   
    
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");         
  }
  
  onCancelClick(): void {
    this.bodyElement.removeClass("overflow-hidden");   
    this.close.emit();
  }

  onConfirmClick(): void {
    //Remove all documents associated with the event
    this.spinner.show();
    if (this.documents && this.documents.length>0)
    {
      this.documents.forEach((document, index) => {
          let storageFileName = `${document.contentGuid}.${document.documentExtension}`; 
          this.documentService.deleteFinalizedDocument(storageFileName, this.documentRefTypeName, this.documentRefTypeNumber)
          .subscribe((result: any) => { 
            this.documentService.deletePermanentDocument(document.documentId, document.contentGuid)
            .subscribe((result: any) => { 
              if (index == this.documents.length-1) //If all documents deleted then delete the event
              {
                this.deleteEvent();
              }
            }, (error: any) => this.spinner.hide()); 
          }, (error: any) => this.spinner.hide()); 
      });
    }
    else
    {
      this.deleteEvent();
    }
  }

  deleteEvent()
  {
    this.eventService.deleteEventAsync(this.eventId)
    .subscribe((result: any) => {
      this.spinner.hide();
      this.bodyElement.removeClass("overflow-hidden");
      this.close.emit(true);
    }, (error: any) => this.spinner.hide()); 
  }
}