
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { ContraventionTypes } from "../enums/app.enum";

import { Contravention } from "../models/contravention.model";
import { ContraventionType } from "../models/types/contravention-type.model";

@Injectable()
export class ContraventionUtilityService {

  constructor(private readonly localStorageService: LocalStorageService) { }

  getContraventionDescriptionText(contravention: Contravention): string {

    let descriptionText: string = '';
    let contraventionType: ContraventionType = 
      this.localStorageService.getContraventionTypes().find(x => x.id == (+(contravention.tertiaryContraventionTypeId??(contravention.secondaryContraventionTypeId??contravention.contraventionTypeId))));
    
    if (contraventionType?.isIRS)
    {
      if (contraventionType.id == ContraventionTypes.IRS24 || contraventionType.id == ContraventionTypes.IRSFail1st || contraventionType.id == ContraventionTypes.IRSFail2nd || contraventionType.id == ContraventionTypes.IRSFail3rd )
      {
        descriptionText = Array.prototype.map.call(this.localStorageService.getContraventionDetailTypes().filter(x => contravention.contraventionDetails.split(',').includes(x.id.toString())), function(item) { return item.description; }).join("\r\n") 
      }
      else
        descriptionText = contraventionType.description;
    }

    return descriptionText;
  }
}