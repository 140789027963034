import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'apps/intake/src/environments/environment';
import { User } from '@apis/shared/models/user.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SharedDisclosureDocumentsUploadRequest } from '@apis/shared/models/shared-disclosure-documents-upload-request.model';
import { Constants } from '@apis/shared/helpers/constants';

@Injectable()
export class TicketService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
  private user: User;

  sharedDisclosureDocumentsUploadRequest: SharedDisclosureDocumentsUploadRequest;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService
  ) {}

  public getTicketServiceHealthCheck(): Observable<any> {
    return this.httpClient
      .get(`${this.apiUrl}/tickets/healthcheck`, {responseType: 'text'})
      .pipe(map((response) => response));
  }

  public getTicket(ticketNumber: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient
      .get(`${this.apiUrl}/tickets/${ticketNumber}`, {
        headers: { Authorization: `Bearer ${this.user.token}` },
      })
      .pipe(map((response) => response));
  }

  public getDisclosureDocuments(ticketNumber: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient
      .get(`${this.apiUrl}/tickets/${ticketNumber}/disclosure-documents`, {
        headers: { Authorization: `Bearer ${this.user.token}` },
      })
      .pipe(map((response) => response));
  }

  public addSharedDisclosureDocumentsAsync(sharedDisclosureDocumentsUploadRequest: SharedDisclosureDocumentsUploadRequest): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient
      .post(`${this.apiUrl}/tickets/shared-documents`, sharedDisclosureDocumentsUploadRequest, {
        headers: { Authorization: `Bearer ${this.user.token}` },
      })
      .pipe(map((response) => response));
  }

  public getSharedDisclosureDocumentsUploadRequestContext() {
    this.sharedDisclosureDocumentsUploadRequest = this.localStorageService.getSharedDisclosureDocumentsUploadRequest();

    if (this.sharedDisclosureDocumentsUploadRequest == null) {
      this.sharedDisclosureDocumentsUploadRequest = new SharedDisclosureDocumentsUploadRequest();
    }

    return this.sharedDisclosureDocumentsUploadRequest;
  }

  public setSharedDisclosureDocumentsUploadRequestContext() {
    this.localStorageService.setSharedDisclosureDocumentsUploadRequest(
      this.sharedDisclosureDocumentsUploadRequest
    );
  }

  public resetSharedDisclosureDocumentsUploadRequestContext() {
    this.sharedDisclosureDocumentsUploadRequest = null;
    this.localStorageService.resetSharedDisclosureDocumentsUploadRequest();
  }

  public hasTrafficTicketPermissions() {
    return this.localStorageService.hasPermission(Constants.Resource.POLICE_INTAKE_SUBMISSION,Constants.Permission.SUBMIT_TRAFFIC_TICKET);
  }
}
