


export class DrugRecognitionEvaluationTest {
    public drugRecognitionEvaluationTestId: number = 0;
    public testDemandTime: string;
    public testStartedTime: string;
    public refusalOrFailedClassificationId: number;
    public isBreathDemandMade: boolean;
    public breathDemandTime: string;
    public breathTestResult: string;
    public sampleClassificationId: number;
    public sampleDemandTime: string;
    public sampleCollectionTime: string;
    public labTypeId: number;

    constructor(init?: Partial<DrugRecognitionEvaluationTest>) {
        
        Object.assign(this, init);
    }
}
