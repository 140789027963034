


export class SpeedingTicket {
    public speedingTicketId: number = 0;
    public speedLimit: number;
    public ticketedSpeed: number;
    public speedingDeviceTypeId: number = null;
    public speedingDeviceMakeTypeId: number = null;
    public speedingDeviceModelTypeId: number = null;
    public isStaticSiteOperation: boolean;
    public policeVehicleDirection: string;
    public policeVehicleSpeed: number;
    public recipientVehicleDirection: string;
    public recipientVehicleSpeed: number;
    public isNotSpeedingDeviceOperator: boolean; 
    public isInterceptorOfVehicle: boolean;
    public continuityInformation: string;
    public isSpeedLimitSignVisible: boolean;
    public signsAndLocationInformation: string;
    public speedingDeviceModelOther: string;
    public speedingDeviceModelSerialNumber: string;
    public isManufacturerTestCompleted: boolean;
    public dateOfTest: Date;
    public timeOfTest: string;
    public testDetails: string;
    public isRadarWorkingProperly: boolean;
    public isLaserWorkingProperly: boolean;
    public speedLimitPostedClassificationId: number;
    public additionalSpeedLimitNotes: string;
    public issueMethod: string;
    public isPacing: boolean;
    public isVisualEstimate: boolean;
    public isClocked: boolean;
    public isAircraft: boolean;
    public isOtherMethod: boolean;
    public otherMethod: string;
    public speedingDeviceDistanceToVehicle: string;
    public isUploadOperatorReport: boolean;
    public isOperatorCertified: boolean;
    public reasonManufacturerTestNotCompleted: string;
    public isManufacturerTestPassed: boolean;
    public reasonManufacturerTestFailed: string;
    public additionalDetails: string;
    public ticketMethodCode: string = "";

    public isPart3Offence: boolean;

    constructor(init?: Partial<SpeedingTicket>) {
        
        Object.assign(this, init);
    }
}
