<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">Delete Note?</div>
            <div class="close-icon" (click)="onCancelClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            <div class="delete-note-message">Are you sure you want to delete this note?</div>
        </div>
        <div class="button-container">
            <div id="cancelButton" class="cancel-button" (click)="onCancelClick()">Cancel</div>
            <div id="confirmButton" class="confirm-button" (click)="onConfirmClick()">
                Confirm
                <i class="fas fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</div>
