import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
@Directive({
  selector: '[appAlbertaLicence]'
})
export class AlbertaLicenceDirective {
  @Input('appAlbertaLicence') isApplicable: boolean;
  
  get control() {
    return this.ngControl.control;
  }
  
  constructor(
    public ngControl: NgControl,
    public titleCase: TitleCasePipe
  ) { }

  // add "-" at the right spot of alberta licence number
  @HostListener('focusout') onBlur() {
        var value = this.control.value;
        if (this.isApplicable && value && value.length > 3)
        {   
            value = value.replace(/-/g, "");
            const controlValue = value.slice(0, value.length-3) + "-" + value.slice(value.length-3);
            this.control.setValue(controlValue);
        }
    }
}