import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { environment } from "../../environments/environment"
import { User } from '@apis/shared/models/user.model';

@Injectable()
export class LookupService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
  private user: User;
  constructor(private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService
    ) { }

  getLookups(fields?: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/lookups`,
      {
        params: {
          "fields": fields
        },
        headers: {'Authorization': `Bearer ${this.user.token}`}
      });
  }

  setLookups(fields?: string): Observable<any> {
    let params = null;
    this.user = this.localStorageService.getUser();
    
    if (fields != null && fields.length > 0) {
      params = { "fields": fields }
    }
    
    return this.httpClient.get(`${this.apiUrl}/lookups`, { params: params, headers: {'Authorization': `Bearer ${this.user.token}`} })
  }
}

