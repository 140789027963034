import { TitleCasePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { RegistryAgentInformation } from '../../models/registry-agent-information.model';

@Component({
  selector: 'app-registry-payer-information',
  templateUrl: './registry-payer-information.component.html',
  styleUrls: ['./registry-payer-information.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class RegistryPayerInformationComponent implements OnInit, AfterViewInit  {
  @Input() registryAgentInformation: RegistryAgentInformation;
  @Input() isFormSubmitted: boolean;
  @Input() sectionHeader: string;
  @Input() sectionLabel: string;
  @Input() requesterLabel: string;
  @Input() disabled: boolean;
  @Input() skipInitialQuestion: boolean;
  @Output() requesterTypeChange = new EventEmitter();

  // originalDisabledValue is a workaround for disabling when "registryAgentInformation.isPaidByRecipient = true"
  // Directly setting "this.disabled = true" doesn't work. Need to set to false, then true after init
  originalDisabledValue: boolean;

  constructor(public titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
    if (!this.requesterLabel || this.requesterLabel.length <= 0)
      this.requesterLabel = "Recipient (Driver)";

    this.originalDisabledValue = this.disabled;
    this.disabled = false;
  }

  ngAfterViewInit() {
    if (this.originalDisabledValue) {
      this.disabled = true;
    }
  }

  onRequesterTypeChange() {
    if (this.registryAgentInformation.isPaidByRecipient) {
      this.registryAgentInformation.payerFirstName = null;
      this.registryAgentInformation.payerLastName = null;
      this.registryAgentInformation.payerMVID = null;
    }
    this.requesterTypeChange.emit();
  }
}


