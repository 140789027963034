import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import * as $ from "jquery";
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedIn: boolean;
  hamburgerMenuOverlay: JQuery<HTMLElement>;
  bodyElement: HTMLBodyElement;
  userName: string;
  
  constructor(private readonly router: Router,
    private keycloakService: KeycloakService,
              private readonly localStorageService: LocalStorageService) { 
                this.localStorageService.getLoggedInUserName.subscribe(name => {this.userName = name});
              }

  ngOnInit(): void {
    this.hamburgerMenuOverlay = $(".hamburger-menu-overlay");
    this.bodyElement = document.getElementsByTagName("body")[0];   
  }

  showMenu(): void {
    this.bodyElement.style.overflow = "hidden";

    setTimeout(() => {
      this.hamburgerMenuOverlay.addClass("show");
      
    }, 100);
    
    this.hamburgerMenuOverlay.css("display", "flex");
  }

  hideMenu(): void {
    this.bodyElement.style.overflow = "auto";
    
    setTimeout(() => {
      this.hamburgerMenuOverlay.css("display", "none");
    }, 100);
    
    this.hamburgerMenuOverlay.removeClass("show");
  }
  onUserNameClick()
  {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  onLogoutClick()
  {
    this.localStorageService.logout();
    this.keycloakService.logout();    
  }

}
