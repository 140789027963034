import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Document } from '@apis/shared/models/document.model';
import { DocumentState } from '@apis/shared/enums/document-state.enum';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Guid } from 'guid-typescript';
import { DocumentService } from '../../services/document.service';
import * as fileSaver from 'file-saver';
import { DocumentTypes } from '@apis/shared/enums/app.enum';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { Constants } from '@apis/shared/helpers/constants';
import * as tus from "tus-js-client";
import { environment } from 'apps/intake/src/environments/environment';
import { StorageService } from '@apis/shared/services/storage.service';
import { DisclosureUploadOptions } from '@apis/shared/models/disclosure-upload-options.model';
import { AbortController } from '@azure/abort-controller'
import { concatMap, reduce, tap } from 'rxjs/operators';
import { IntakeUser } from '../../models/intake-user.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'intake-file-upload',
  templateUrl: './intake-file-upload.component.html',
  styleUrls: ['./intake-file-upload.component.scss'],
  outputs: ['onCancelEvent', 'onUploadEvent', 'onDeleteEvent']
})
export class IntakeFileUploadComponent implements OnInit {
  @Input() document: Document;
  @Input() additionalDocumentTypes: DocumentType[];
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  onCancelEvent = new EventEmitter<Document>();
  onUploadEvent = new EventEmitter<Document>();
  onDeleteEvent = new EventEmitter();

  DocumentState = DocumentState;
  documentState = DocumentState.NotUploaded;
  documentType: DocumentType = null;
  isAdditionalDocument: boolean = false;
  uploadProgress: number = 0;
  uploadProgressStyle: string;
  errorMessage: string = "";
  validExtensions: string[];
  isActionAllowed: boolean = true;
  maxFileSize: string;
  DocumentTypes = DocumentTypes;
  requiresWitnessCallout: boolean = false;
  abortController: AbortController = null;
  user: IntakeUser;
  upload: tus.Upload;
  uploadSubscription: Subscription;

  constructor(private localStorageService: LocalStorageService,
              private documentService: DocumentService,
              private storageService: StorageService) { }

  ngOnInit(): void {
    //Get logged in user's name
    this.user = this.localStorageService.getUser();

    this.initializeDocumentState();
  }

  initializeDocumentState(): void {
    if (this.document.isUploadPeriodExpired) {
      this.isActionAllowed = false;
    } else {
      this.isActionAllowed = this.document.uploadedDate == null;
    }

    if (this.documentState === DocumentState.Uploaded && !this.document.documentName) {
      this.documentState = DocumentState.NotUploaded;
    } else if (this.documentState === DocumentState.NotUploaded && this.document.documentName) {
      this.documentState = DocumentState.Uploaded;
    } else if(this.document.isUploadPeriodExpired) {
      this.documentState = DocumentState.UploadPeriodExpired;
    }

    //Check if it is a supplemental document
    if (this.document.documentTypeId == null)
      this.isAdditionalDocument = true;
    else {
      this.documentType = this.localStorageService.getDocumentTypes().find(x => x.id == this.document.documentTypeId);
      this.validExtensions = this.documentType.acceptedFileTypes.split(',');
      this.getMaxFileSizeText();

      // PoliceNarrative and RoadsideAppeal can be additional or non-additional documents, depending on the situation
      if (this.document.documentTypeId == DocumentTypes.PoliceNarrative || this.document.documentTypeId == DocumentTypes.RoadsideAppeal) {
        if (this.document.forcePrimaryDocument) {
          this.isAdditionalDocument = false;
        }
        else {
          this.isAdditionalDocument = true;
        }
      }
      else {
        this.isAdditionalDocument = this.documentType.isSupplemental;
      }
    }

    this.requiresWitnessCallout = false;
    if (this.document.documentTypeId == DocumentTypes.WitnessStatements) {
      let minDate = new Date(+Constants.Intake.WITNESS_UPLOAD_DECLARATION_EFFECTIVE_DATE.substring(0, 4),
                            +Constants.Intake.WITNESS_UPLOAD_DECLARATION_EFFECTIVE_DATE.substring(5, 7)-1,
                            +Constants.Intake.WITNESS_UPLOAD_DECLARATION_EFFECTIVE_DATE.substring(8, 10));
      if (!this.document.isSubmitLater) {
        let uploadedDate = this.document.uploadedDate ? this.document.uploadedDate : new Date();

        if (uploadedDate>=minDate) {
          this.requiresWitnessCallout = true;
        }
      }
    }
  }

  getMaxFileSizeText() {
    if (this.documentType?.maximumFileSize)
    {
      this.maxFileSize = this.documentType.maximumFileSize >= 1024? `${(this.documentType.maximumFileSize / 1024).toFixed(0)} GB` : `${this.documentType.maximumFileSize} MB`;
    }
  }

  triggerFileUpload(){
    this.fileDropEl.nativeElement.click();
  }

  /**
   * on file drop handler
   */
  onFileDropped(files) {
    if (this.documentState == DocumentState.NotUploaded && files.length > 0) {
      this.UploadDocument(files[0]);
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    if (files.length > 0) {
      this.UploadDocument(files[0]);
    }
  }

  UploadDocument(file) {
    if (!this.isActionAllowed)
      return;

    var fileExtension = file?.name.split('.').pop();
    this.errorMessage = "";

    switch (this.documentState)
    {
      case DocumentState.Uploaded:
        this.errorMessage = "Remove previous uploaded file to upload a new file.";
        break;

      case DocumentState.Uploading: case DocumentState.Error:
        this.errorMessage = "An upload is already in progress. Please cancel that first to upload a new file.";
        break;

      case DocumentState.NotUploaded:
      {
        if (!this.document.documentTypeId)
        {
          this.errorMessage = "Please select a file category before uploading the file."
          break;
        }

        if (!this.validExtensions.includes(fileExtension.toLowerCase()))
        {
          this.errorMessage = "This type of file is not allowed. Allowed file types are: " + this.documentType.acceptedFileTypes;
          break;
        }

        if (this.documentType.maximumFileSize != 0 && file.size > (this.documentType.maximumFileSize * 1024 * 1024))
        {
          this.errorMessage = "Invalid file size. The maximum allowed file size is " + this.documentType.maximumFileSize.toString() + "MB";
          break;
        }
        if(file.size === 0){
          this.errorMessage = "File appears to be corrupt or not converted properly. Please check file and reupload.";
          break;
        }

        var newFileName = Guid.create().toString();
        var newFileNameWithExtension = `${newFileName}.${fileExtension}`;
        this.document.contentGuid = newFileName;

        this.documentState = DocumentState.Uploading;

        // Fetch auth token
        var authToken = this.localStorageService.getUser()?.token;

        // File exceeding 100MB in size are classified as large files and will be uploaded to the configured storage medium
        if (file.size > 104857600 && (tus.isSupported || environment.availableBlobUpload) && authToken) {
          if (environment.availableBlobUpload) { // <<<**** Use blob upload ****>>>
            // Abortcontroller to cancel the upload request
            this.abortController = new AbortController();

            const uploadOptions = new DisclosureUploadOptions({
              name: newFileNameWithExtension,
              fullName: `${this.document.tempFileFolder}/${newFileNameWithExtension}`,
              abortController: this.abortController,
              metadata: {
                originalname: file?.name,
                type: this.documentType.name,
                uploadedBy: this.user.userName
              }
            });

            this.documentState = DocumentState.Uploading;

            this.documentService.getWipContainerSasUri()
            .pipe(
              concatMap((res) => this.storageService.uploadFile(res.containerSasUri, uploadOptions, file)),
              tap(
                (loadedBytes) => { // Next
                  let percentage = Math.round((loadedBytes / file.size) * 100);
                  this.uploadProgress = percentage;
                },
                (error: any) => { // Error
                  if (error?.name != 'AbortError') {
                    this.abortController = null;
                    this.uploadProgress = 0;
                    this.errorMessage = "Something went wrong. Please try uploading the document again."
                    this.documentState = DocumentState.Error;
                  }
                },
                () => { // Complete
                  // Do nothing
                }
              ),
              // accumulates all chunk uploads and emits a single value when the upload is completed
              // this avoids multiple get calls when each chunk is uploaded
              reduce((acc, value) => value),
              concatMap(() => this.documentService.getDocumentSasUri(this.document.tempFileFolder, "", "", newFileNameWithExtension, file?.name)),
              tap((result) => {
                this.document.documentName = file?.name;
                this.document.documentExtension = fileExtension;
                this.document.documentSize = CommonUtil.getDocumentSize(file);
                this.document.isSubmitLater = false;
                this.document.isPublished = (this.document.documentTypeId == DocumentTypes.WitnessStatements? null : true);
                this.documentState = DocumentState.Uploaded;                  
                this.document.isWitnessStatmentRedacted == false;
                this.document.version = Constants.DocumentVersion.VERSION3;
                this.document.downloadUrl = result.blobSasUri;

                this.abortController = null;
                this.uploadProgress = 0;
                this.errorMessage = "";
                this.onUploadEvent.emit(this.document);
              }, (error: any) => {
                if (error?.name != 'AbortError') {
                  this.abortController = null;
                  this.uploadProgress = 0;
                  this.errorMessage = "Something went wrong. Please try uploading the document again."
                  this.documentState = DocumentState.Error;
                }
              })
            )
            .subscribe();
          } else if (tus.isSupported) { // <<<**** Use Tus upload ****>>>
            this.upload = new tus.Upload(file, {
              //Set Auth header, since HttpInterceptor wont work for requests orignating from 3rd party components
              headers: {
                Authorization: 'Bearer ' + authToken
              },
              //File server upload endpoint address settings
              endpoint: `${this.documentService.apiBaseUrl}/files`,
              //Retrying delay settings
              retryDelays: [0, 3000, 5000, 10000, 20000],
              //ChunkSize 40MB
              chunkSize: 41943040,
              //Metadata required by attachment server
              metadata: {
                  name: newFileName,
                  extension: fileExtension,
                  folder: this.document.tempFileFolder,
                  contentType: file.type || 'application/octet-stream'
              },
              //Callback errors that cannot be resolved by retrying
              onError:  (error) => {
                  this.errorMessage = "Something went wrong. Please try uploading the document again."
                  this.documentState = DocumentState.Error;
                  this.upload = null;
                  console.log("Failed because: " + error)
              },
              onProgress:  (bytesUploaded, bytesTotal) => {
                let percentage = Math.round((bytesUploaded / bytesTotal * 100));
                this.uploadProgress = percentage;
              },
              onSuccess:  () => {
                this.document.documentName = file?.name;
                this.document.documentExtension = fileExtension;
                this.document.documentSize = CommonUtil.getDocumentSize(file);
                this.document.isSubmitLater = false;
                this.document.isPublished = (this.document.documentTypeId == DocumentTypes.WitnessStatements? null : true);
                this.documentState = DocumentState.Uploaded;
                this.upload = null;
                this.uploadProgress = 0;
                this.errorMessage = "";
                this.document.isWitnessStatmentRedacted == false;
                this.onUploadEvent.emit(this.document);
              }
            });

            this.upload.start();
          }
        } else {
          this.uploadSubscription = this.documentService.uploadDocumentAsync(file, this.document.tempFileFolder, newFileName)
            .subscribe((result: any) => {
              if (+result.type === 1) {
                  let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);
                  this.uploadProgress = percentage
              } else if (+result.type === 4) {
                this.document.documentName = file?.name;
                this.document.documentExtension = fileExtension;
                this.document.documentSize = CommonUtil.getDocumentSize(file);
                this.document.isSubmitLater = false;
                this.document.isPublished = (this.document.documentTypeId == DocumentTypes.WitnessStatements? null : true);
                this.documentState = DocumentState.Uploaded;
                this.uploadSubscription = null;
                this.uploadProgress = 0;
                this.errorMessage = "";
                this.document.isWitnessStatmentRedacted == false;
                this.onUploadEvent.emit(this.document);
              }
          }, (error: any) => {
            this.errorMessage = "Something went wrong. Please try uploading the document again."
            this.documentState = DocumentState.Error;
            this.uploadSubscription = null;
          });
        }
        break;
      }
    }
  }

  RetryUpload() {
    this.upload?.findPreviousUploads().then((previousUploads) => {
      if (previousUploads.length) {
        var previousUpload = previousUploads.find(p => p["uploadUrl"] == this.upload.url);

        if (previousUpload) {
          this.documentState = DocumentState.Uploading;
          this.errorMessage = '';

          this.upload.resumeFromPreviousUpload(previousUpload);
          this.upload.start();
        }
      }
    });
  }

  DownloadDocument(){
    var tempFileFolder: string;
    var documentRefTypeName: string;
    var documentRefTypeNumber: string;

    var storageFileName = `${this.document.contentGuid}.${this.document.documentExtension}`;

    if (this.isActionAllowed) //Dcoument is at temporary location
    {
      tempFileFolder = this.document.tempFileFolder;
      documentRefTypeName = "";
      documentRefTypeNumber = "";
    }
    else //Document is at permanent location
    {
      tempFileFolder = "";
      documentRefTypeName = this.document.documentRefTypeName;
      documentRefTypeNumber = this.document.documentRefTypeNumber;
    }

    if (this.document.version == Constants.DocumentVersion.VERSION3)
    {
      this.documentService.getDocumentSasUri(tempFileFolder, documentRefTypeName, documentRefTypeNumber, storageFileName , this.document.documentName)
        .subscribe((result: any) => {
          window.open(result.documentSasUri, "_blank");
        });
      
      return;
    }

    this.documentService.downloadDocument(tempFileFolder, documentRefTypeName, documentRefTypeNumber, storageFileName , this.document.documentName)
      .subscribe((result: any) => {
        if (result)
        {
          fileSaver.saveAs(new Blob([result]), this.document.documentName);
          this.errorMessage = "";
        }
      },
      (error: any) => {
        if (error.status == 400)
        {
          this.errorMessage = error.error.message;
        }
        else
          this.errorMessage = "Unable to download the file. Please try again.";
      });
  }

  CancelUpload(){
    if (this.documentState == DocumentState.Uploading) {
      if (this.abortController) {
        this.abortController.abort();
        this.abortController = null;
      }
      if (this.upload) {
        this.upload.abort(true);
        this.upload = null;
      }
      if (this.uploadSubscription) {
        this.uploadSubscription.unsubscribe();
        this.uploadSubscription = null;
      }
    }

    this.uploadProgress = 0;
    this.documentState = DocumentState.NotUploaded;    
  }

  RemoveDocument(){
    var fileName = `${this.document.contentGuid}.${this.document.documentExtension}`;

    if (this.document.version == Constants.DocumentVersion.VERSION3) {
      this.documentService.deleteWipDocument(fileName, this.document.tempFileFolder)
      .subscribe((result: any) => {
        this.resetDocument();
        this.errorMessage = "";
        this.onDeleteEvent.emit();
      },
      (error: any) => {
        this.errorMessage = "Unable to remove the file. Please try again.";
      })
    } else {
      this.documentService.deleteDocument(fileName, this.document.tempFileFolder)
      .subscribe((result: any) => {
        this.resetDocument();
        this.errorMessage = "";
        this.onDeleteEvent.emit();
      },
      (error: any) => {
        if (error.status == 400)
        {
          this.errorMessage = error.error.message;
          this.resetDocument();
          this.onDeleteEvent.emit();
        }
        else
          this.errorMessage = "Unable to remove the file. Please try again.";
      });
    }
  }

  resetDocument() {
    this.document.uploadedDate = null;
    this.document.contentGuid = null;
    this.document.documentName = null;
    this.document.documentExtension = null;
    this.document.documentSize = null;
    this.document.isPublished = null;
    this.document.version = null;
    this.documentState = DocumentState.NotUploaded;
  }

  getTestName(testTypeId)
  {
    // This logic is applicable only to Impairment Screening Tests
    var testType = this.localStorageService.getTestTypes().find(x => x.id == testTypeId);
    return testType && this.document.documentDescription.indexOf('-') == -1? ` - ${testType.code?testType.code:testType.name}` : '';
  }

  CancelDocument()
  {
    this.onCancelEvent.emit(this.document);
  }

  onFileCtegoryChange(fileCategory: DocumentType)
  {
    if (fileCategory) {
      this.document.documentTypeId = fileCategory?.id;
      if (this.document.documentTypeId == DocumentTypes.AffidavitOfService)
      {
        this.document.isOptional = true;
      }
      if (this.document.documentTypeId == DocumentTypes.WitnessStatements)
      {
        this.requiresWitnessCallout = true;
      }
      this.validExtensions = this.documentType.acceptedFileTypes.split(',');
      this.getMaxFileSizeText();
    }
  }

  getControlClass()
  {
    if (this.isActionAllowed)
      return "card mb-4";
    else
      return "card mb-4 disabled-control";
  }
}
