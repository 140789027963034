import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[isMatching]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: MustMatchValidatorDirective,
        multi: true
    }]
})

export class MustMatchValidatorDirective implements Validator {
    @Input('isMatching') matchControlValue: string;
    
    validate(control: AbstractControl) {
      if (control.value == null || this.matchControlValue == null || this.matchControlValue.length == 0)
        return null;

      return ((control.value != this.matchControlValue) ? {'mustMatch': true} : null);
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('matchControlValue' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }
}