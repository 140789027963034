import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ResolveEnd, Router, RoutesRecognized } from "@angular/router";
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'stepper2',
  templateUrl: './stepper2.component.html',
  styleUrls: ['./stepper2.component.scss']
})
export class Stepper2Component implements OnInit {
  @Input() activatedRouteSnapshot: Observable<ActivatedRouteSnapshot>;
  stepLabels: string[] = [];
  activeStepLabel: string = "";
  activeStepIndex: number = 0;
  activeStepData: any;

  constructor(private readonly route: ActivatedRoute,
              private readonly router: Router) { }

  ngOnInit() {
    this.activatedRouteSnapshot.subscribe(firstChild => {
      if (firstChild){
        this.stepLabels = [];
        this.activeStepData = {};

        let parentRoute: ActivatedRouteSnapshot = this.getParentRoute(firstChild);
        
        if (!parentRoute.routeConfig) return;

        parentRoute.routeConfig.children.forEach(route => {
          if(route.data) {
            this.stepLabels.push(route.data.label);
          }
        });

        this.findStateData(firstChild);

        this.activeStepLabel = this.activeStepData.label;
        this.activeStepIndex = this.stepLabels.findIndex(l => l === this.activeStepLabel);
      }
    });
  }

  getParentRoute(firstChild: any) {
    if (firstChild.firstChild) {
      return this.getParentRoute(firstChild.firstChild);
    }

    return firstChild.parent;
  }

  findStateData(firstChild: any){
    if(!firstChild){
      return;
    }
    if(firstChild.data){
      for(let key in firstChild.data){
        if (firstChild.data.hasOwnProperty(key)) {
          this.activeStepData[key] = firstChild.data[key];
        }
      }
    }
    this.findStateData(firstChild.firstChild);
  }

  getStepperLineClass(stepLabel: string, index: number): string {
    if (stepLabel === this.activeStepLabel || index < this.activeStepIndex) {
      return "stepper-line-active";
    }

    return "stepper-line-inactive";
  }

  getStepperButtonClass(stepLabel: string, index: number): string {
    if (stepLabel === this.activeStepLabel && index < this.stepLabels.length - 1) {
      return "stepper-state-active";
    }

    if (index > 0 && index > this.activeStepIndex && index < this.stepLabels.length - 1) {
      return "stepper-state-inactive";
    }
    
    if (index < this.activeStepIndex) {
      return "stepper-state-complete";
    }

    if (index === this.stepLabels.length - 1 && this.activeStepIndex < this.stepLabels.length - 1) {
      return "stepper-complete-inactive";
    }

    if (this.activeStepIndex === this.stepLabels.length - 1) {
      return "stepper-complete-active";
    }
    
  }

}
