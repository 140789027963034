import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { LocationType } from '@apis/shared/models/types/location-type.model';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { ObservedByType } from '@apis/shared/models/types/observed-by-type.model';
import { CircumstanceType } from '@apis/shared/models/types/circumstance-type.model';
import { Witness } from '@apis/shared/models/witness.model';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Fine } from '@apis/shared/models/fine.model';
import { ContraventionTypes, SpeedingDeviceTypes, ObservedByTypes, OfficerTransportationTypes, OfficerUniformTypes, RecipientTypes, PassengerSeatingTypes, WeatherConditionTypes, RoadConditionTypes, RoadSurfaceTypes, VisibilityConditionTypes, ContraventionDetailTypes, HighwayCharacteristicsTypes } from '@apis/shared/enums/app.enum';
import { OfficerVehicleType } from '@apis/shared/models/types/officer-vehicle-type.model';
import { OfficerTransportationType } from '@apis/shared/models/types/officer-transportation-type.model';
import { OfficerUniformType } from '@apis/shared/models/types/officer-uniform-type.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { OccurrenceLocation } from '@apis/shared/models/occurrence-location.model';
import { DirectionType } from '@apis/shared/models/types/direction-type.model';
import { Constants } from '@apis/shared/helpers/constants';
import { WeatherConditionType } from '@apis/shared/models/types/weather-condition-type.model';
import { RoadConditionType } from '@apis/shared/models/types/road-condition-type.model';
import { RoadSurfaceType } from '@apis/shared/models/types/road-surface-type.model';
import { VisibilityConditionType } from '@apis/shared/models/types/visibility-condition-type.model';
import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { PayCentreType } from '@apis/shared/models/types/pay-centre-type.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HighwayCharacteristicsType } from '@apis/shared/models/types/highway-characteristics-type.model';

@Component({
  selector: 'app-tsa-contravention-details',
  templateUrl: './tsa-contravention-details.component.html',
  styleUrls: ['./tsa-contravention-details.component.scss']
})
export class TsaContraventionDetailsComponent implements OnInit {
  contravention: Contravention;
  contraventionNumbers: string;
  fine: Fine;
  stopInformation: StopInformation;
  occurrenceLocation: OccurrenceLocation;
  witnesses: Witness[];
  locationTypes: any;
  contraventionTypes: ContraventionType[];
  selectedContraventionType: ContraventionType = null;
  observedByTypes: ObservedByType[];
  circumstanceTypes: CircumstanceType[];
  selectedLocationType: LocationType;
  ContraventionTypes = ContraventionTypes;
  OfficerTransportationTypes = OfficerTransportationTypes;
  OfficerUniformTypes = OfficerUniformTypes;
  officerVehicleTypes: OfficerVehicleType[];
  officerTransportationTypes: OfficerTransportationType[];
  officerUniformTypes: OfficerUniformType[];
  directionTypes: DirectionType[];
  SpeedingDeviceTypes = SpeedingDeviceTypes;
  datePickerConfig: Partial<BsDatepickerConfig>;
  occurrenceDatePickerConfig: Partial<BsDatepickerConfig>;
  isSubmitClicked: boolean = false;

  ObservedByTypes = ObservedByTypes;
  RecipientTypes = RecipientTypes;
  PassengerSeatingTypes = PassengerSeatingTypes;

  policeFileNumber: string;
  additionalNotes: string;
  policeOfficerName: string;
  seatingTypeId: number = 1;
  isBodycamVideoActive: boolean = false;

  selectedWeatherConditionTypes: number[];
  selectedRoadConditionTypes: number[];
  selectedRoadSurfaceTypes: number[];
  selectedVisibilityConditionTypes: number[];
  selectedHighwayCharacteristicsTypes: number[];
  weatherConditionTypes: WeatherConditionType[];
  roadConditionTypes: RoadConditionType[];
  roadSurfaceTypes: RoadSurfaceType[];
  visibilityConditionTypes: VisibilityConditionType[];
  highwayCharacteristicsTypes: HighwayCharacteristicsType[];

  Constants = Constants;
  WeatherConditionTypes = WeatherConditionTypes;
  RoadConditionTypes = RoadConditionTypes;
  RoadSurfaceTypes = RoadSurfaceTypes;
  VisibilityConditionTypes = VisibilityConditionTypes;
  HighwayCharacteristicsTypes = HighwayCharacteristicsTypes;
  payCentreControl = new UntypedFormControl();
  payCentreTypes: any;
  selectedPayCentreType: PayCentreType;
  filteredPayCentres: Observable<string[]>;
 
  constructor(private intakeService: IntakeService, 
              private localStorageService: LocalStorageService,
              private readonly spinner: NgxSpinnerService,
              private router: Router) { 
                this.datePickerConfig = Object.assign({}, 
                  {
                    containerClass: 'theme-dark-blue', 
                    showWeekNumbers: false,
                    dateInputFormat: 'YYYY/MM/DD',
                    minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
                    maxDate: new Date(2099,11,31), // 2099/12/31 - Month is 0 based index
                    isAnimated: true,
                    customTodayClass: 'custom-today-class'
                  })
                
                //Get Pay Centre Types
                this.payCentreTypes = this.localStorageService.getPayCentreTypes();
                this.payCentreControl.setValidators(this.validatePayCentres(this.payCentreTypes));
              }

  ngOnInit(): void {
    //Get Types
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    this.payCentreTypes = this.localStorageService.getPayCentreTypes();
    this.filteredPayCentres = this.payCentreControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterPayCentres(value))
    );

    //Get stop Information object
    this.stopInformation = this.intakeService.getStopInformationModel();

    //Route to Supporting documents page if it is a legacy submission or details are already submitted
    if (this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1 || 
       (this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE2 && this.stopInformation.isDetailSubmitted))
      this.router.navigateByUrl('/contravention/add-details/supporting-documents');

    //Check if Roadside Appeal information is provided for any IRS
    var irsTypeIds = this.contraventionTypes.filter(x=>x.isIRS).map(s=>s.id);
    if (this.stopInformation.isIRSSelected && this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE2 && !this.stopInformation.isOnlySDPActive)
    {

      // JTI-3170 - isRefusal is true ONLY is refusal is the ONLY detail selected
      //
      var irsContravention = this.stopInformation.contraventions.find(x=>irsTypeIds.includes(+x.contraventionTypeId));
      var detailsSelected = irsContravention?.contraventionDetails?.split(",");
      var totalDetailsSelected = detailsSelected?.length;
      
      var isRefusal = (
          (irsContravention.contraventionTypeId == ContraventionTypes.IRSFail1st && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail1stRefusal.toString()) && totalDetailsSelected===1)) ||
          (irsContravention.contraventionTypeId == ContraventionTypes.IRSFail2nd && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail2ndRefusal.toString()) && totalDetailsSelected===1)) ||
          (irsContravention.contraventionTypeId == ContraventionTypes.IRSFail3rd && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail3rdRefusal.toString()) && totalDetailsSelected===1))
      )

      if (irsContravention && irsContravention.contraventionTypeId != ContraventionTypes.IRS24 && !isRefusal && irsContravention.roadsideAppeal?.isRecipientPresentedWithInformation == null)
        this.router.navigateByUrl(`/contravention/submission/print-confirmation`);
    }   

    //Get Types
    this.spinner.show();
    this.locationTypes = this.localStorageService.getLocationTypes();
    this.observedByTypes = this.localStorageService.getObservedByTypes().filter(x=>x.id != ObservedByTypes.CareAndControl);
    this.circumstanceTypes = this.localStorageService.getCircumstanceTypes();
    this.officerVehicleTypes = this.localStorageService.getOfficerVehicleTypes();
    this.officerTransportationTypes = this.localStorageService.getOfficerTransportationTypes();
    this.officerUniformTypes = this.localStorageService.getOfficerUniformTypes();
    this.directionTypes = this.localStorageService.getDirectionTypes();
    this.weatherConditionTypes = this.localStorageService.getWeatherConditionTypes();
    this.roadConditionTypes = this.localStorageService.getRoadConditionTypes();
    this.roadSurfaceTypes = this.localStorageService.getRoadSurfaceTypes();
    this.visibilityConditionTypes = this.localStorageService.getVisibilityConditionTypes();
    this.highwayCharacteristicsTypes = this.localStorageService.getHighwayCharacteristicsTypes();

    this.spinner.hide();

    this.occurrenceLocation = this.stopInformation.occurrenceLocation;
    this.contraventionNumbers = this.stopInformation.isOnlySDPActive? this.stopInformation.vehicleSeizures[0].seizureNumber : Array.prototype.map.call(this.stopInformation.contraventions, function(item) { return item.contraventionNumber; }).join(", ");
    this.witnesses = this.stopInformation.witnesses;  
        
    //Initialize the selected location type
    if(this.stopInformation.occurrenceLocation.locationTypeId != null && this.stopInformation.occurrenceLocation.locationTypeId > 0) {
      this.selectedLocationType = this.locationTypes.find(x => x.id == this.stopInformation.occurrenceLocation.locationTypeId);
    }

    // Initialize pay centre
    if (!this.stopInformation.isOnlySDPActive) {
      if (this.stopInformation.isIRSSelected) {
        this.selectedPayCentreType = this.payCentreTypes.find(x => x.code == "POA");
      } else if (this.stopInformation.contraventions.length > 0) { // If we have contraventions, but none are IRS. (SDP-only submissions do not have a paycentre)
        if (this.occurrenceLocation?.payCentreTypeId) {
          this.selectedPayCentreType = this.payCentreTypes.find(x => x.id == this.occurrenceLocation.payCentreTypeId);
        }
        else {
          this.selectedPayCentreType = this.payCentreTypes.find(x => x.id == this.selectedLocationType.payCentreTypeId);
        }
      }
    }

    if (this.stopInformation.isOnlySDPActive)
    {
      this.policeOfficerName = this.stopInformation.vehicleSeizures[0].policeOfficerFullName;
      this.policeFileNumber = this.stopInformation.vehicleSeizures[0].policeFileNumber;
      this.additionalNotes = this.stopInformation.vehicleSeizures[0].additionalNotes;
    }
    else
    {
      this.policeOfficerName = this.stopInformation.contraventions[0].policeOfficerFullName;
      this.policeFileNumber = this.stopInformation.contraventions[0].policeFileNumber;
      this.additionalNotes = this.stopInformation.contraventions[0].additionalNotes;
    }

    //If ticket is for passenger then it is sure that there are passengers in vehicle
    if (this.stopInformation.recipient.recipientTypeId == RecipientTypes.Passenger) 
      this.stopInformation.isPassengerInVehicle = true;

    if (this.stopInformation.firstObservedTime?.length>5)
      this.stopInformation.firstObservedTime = this.stopInformation.firstObservedTime.substring(0,5);

    if (this.stopInformation.stopTime?.length>5)
      this.stopInformation.stopTime = this.stopInformation.stopTime.substring(0,5);  

    //Initialize selected location condition types
    this.selectedWeatherConditionTypes = this.stopInformation.weatherConditionTypes?.split(',').map(x=> +x);
    this.selectedRoadConditionTypes = this.stopInformation.roadConditionTypes?.split(',').map(x=> +x);
    this.selectedRoadSurfaceTypes = this.stopInformation.roadSurfaceTypes?.split(',').map(x=> +x);
    this.selectedVisibilityConditionTypes = this.stopInformation.visibilityConditionTypes?.split(',').map(x=> +x);
    this.selectedHighwayCharacteristicsTypes = this.stopInformation.highwayCharacteristicsTypes?.split(',').map(x=> +x);

    //Determine whether bodycam video question is active
    if (this.stopInformation.isIRSSelected && !this.stopInformation.isOnlySDPActive) {
      const issuedAfterBodycamVideoEffective = new Date(this.stopInformation.contraventions[0].issueDate) > new Date(+Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Intake.BODYCAM_VIDEO_EFFECTIVE_DATE.substring(8, 10));
      const issuedBeforeBodycamVideoRemoved = new Date(this.stopInformation.contraventions[0].issueDate) < new Date(+Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(0, 4), +Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(5, 7)-1, +Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(8, 10));
      if ((issuedAfterBodycamVideoEffective && issuedBeforeBodycamVideoRemoved) || this.stopInformation?.hasBodycamVideo != null) { //If the officer already answered the bodycam video question (meaning hasBodycamVideo is not null), the question should remain active
        this.isBodycamVideoActive = true;
      }
      else {
        this.isBodycamVideoActive = new Date() < new Date(+Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(0, 4), +Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(5, 7)-1, +Constants.Intake.BODYCAM_VIDEO_REMOVED_DATE.substring(8, 10));
      }
    }

    //scroll to top with tab change
    window.scroll(0,0);
  }

  //#region Control Events

  onWeatherConditionChange(selectedWeatherConditionTypesValue: number[])
  {
    this.stopInformation.weatherConditionTypes = selectedWeatherConditionTypesValue.toString();
    if (!selectedWeatherConditionTypesValue.includes(WeatherConditionTypes.Other))
      this.stopInformation.weatherConditionOther = null;
  }

  onRoadConditionChange(selectedRoadConditionTypesValue: number[])
  {
    this.stopInformation.roadConditionTypes = selectedRoadConditionTypesValue.toString();
    if (!selectedRoadConditionTypesValue.includes(RoadConditionTypes.Other))
      this.stopInformation.roadConditionOther = null;
  }

  onRoadSurfaceConditionChange(selectedRoadSurfaceTypesValue: number[])
  {
    this.stopInformation.roadSurfaceTypes = selectedRoadSurfaceTypesValue.toString();
    if (!selectedRoadSurfaceTypesValue.includes(RoadSurfaceTypes.Other))
      this.stopInformation.roadSurfaceOther = null;
  }

  onVisibilityConditionChange(selectedVisibilityConditionTypesValue: number[])
  {
    this.stopInformation.visibilityConditionTypes = selectedVisibilityConditionTypesValue.toString();
    if (!selectedVisibilityConditionTypesValue.includes(VisibilityConditionTypes.Other))
      this.stopInformation.visibilityConditionOther = null;
  }

  onHighwayCharacteristicsChange(selectedHighwayCharacteristicsTypesValue: number[])
  {
    this.stopInformation.highwayCharacteristicsTypes = selectedHighwayCharacteristicsTypesValue.toString();
    if (!selectedHighwayCharacteristicsTypesValue.includes(HighwayCharacteristicsTypes.Other))
      this.stopInformation.highwayCharacteristicsOther = null;
  }

  onCircumstanceChange(circumstanceTypeId: number)
  {
    if (circumstanceTypeId != 8) //Not Other Circumstance
      this.stopInformation.circumstanceOther = "";
  }

  onAddWitnessClick()
  {
    var witness = new Witness();
    witness.dateOfBirth = null;
    this.witnesses.push(witness);
  }

  onRemoveWitnessClick(witnessIndex: number)
  {
    this.witnesses.splice(witnessIndex, 1);
  }

  onBackClick()
  {
    this.router.navigateByUrl(`/contravention/submission/print-confirmation`);
  }

  onSubmitClick(isValid: boolean)
  {
    this.isSubmitClicked = true;
    if (!this.occurrenceLocation.addressOrHighwayNumber || this.occurrenceLocation.addressOrHighwayNumber.trim() == "") {
      this.occurrenceLocation.addressOrHighwayNumber = "";
      return;
    }

    if (!/\d/.test(this.occurrenceLocation.addressOrHighwayNumber)) {
      if (!/[a-zA-Z]/.test(this.occurrenceLocation.addressOrHighwayNumber)) {
        this.occurrenceLocation.addressOrHighwayNumber = "";
        return;
      }
    }
    
    if (isValid && this.payCentreControl.valid) {
      if (this.selectedPayCentreType) {
        this.stopInformation.occurrenceLocation.payCentreTypeId = this.selectedPayCentreType.id;
      }
      else {
        this.stopInformation.occurrenceLocation.payCentreTypeId = null;
      }

      if (this.stopInformation.observedByTypeId == ObservedByTypes.WitnessObservedSubject)
      {
        //reorder witness sequence
        this.witnesses.forEach((witness, index) => {
          witness.witnessSequence = index + 1;
        });
      } else {
        this.stopInformation.witnesses = [];
      }

      this.stopInformation.contraventions.forEach(contravention => {
        contravention.policeFileNumber = this.policeFileNumber;
        contravention.additionalNotes = this.additionalNotes;
        if (contravention.vehicleSeizure)
        {
          contravention.vehicleSeizure.policeFileNumber = this.policeFileNumber;
          contravention.additionalNotes = this.additionalNotes;
        }
      });

      this.stopInformation.vehicleSeizures.forEach(seizure => {
        seizure.policeFileNumber = this.policeFileNumber;
        seizure.additionalNotes = this.additionalNotes;
      })
      
      //this.contravention.isNoVehicleSeizureMade = true;
      this.intakeService.saveStopInformationContext();
      if (!this.stopInformation.isOnlySDPActive && (this.stopInformation.isIRSSelected || this.stopInformation.isSpeedingSelected))
        this.router.navigateByUrl('/contravention/add-details/additional-details');
      else  
        this.router.navigateByUrl('/contravention/add-details/supporting-documents');     
    }
    else
      window.scroll(0,0); 
  }

  onNoTrafficStopChange() {
    this.stopInformation.stopTime = null;
    this.stopInformation.circumstanceTypeId = null;
    this.stopInformation.circumstanceOther = null;
    this.stopInformation.isPassengerInVehicle = null;
    this.stopInformation.seatingTypeId = null
    this.stopInformation.vehicleOccupants = null;
    this.stopInformation.vehicleOccupantsNotes = null;
  }

  onPassengerInVehicleChange() {
    this.stopInformation.seatingTypeId = null
    this.stopInformation.vehicleOccupants = null;
    this.stopInformation.vehicleOccupantsNotes = null;
  }

  onSeatingTypeChange() {
    this.stopInformation.vehicleOccupants = this.stopInformation.seatingTypeId == PassengerSeatingTypes.Other? null:"10000000";
  }

  onIntercepterSameAsIssuerChange()
  {
    this.stopInformation.multipleOfficerOperationInterceptor = this.stopInformation.isInterceptorSameAsIssuer?this.policeOfficerName:null;
  }

  onObservedByChange()
  {
    this.stopInformation.multipleOfficerOperationIssuer = this.stopInformation.observedByTypeId==ObservedByTypes.MultipleOfficerOperation?this.policeOfficerName:null;
  }

  validatePayCentres(payCentreNames: PayCentreType[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = payCentreNames.findIndex(x => {
        return (new RegExp('\^' + x.name + '\$')).test(control.value?.name);
      });
      return index < 0 ? { 'forbiddenPayCentres': { value: control.value } } : null;
    };
  }

  displayPayCentre(subject){
    return subject? subject.code : undefined;
  }

  private _filterPayCentres(value: string): string[] {
    const filterValue = String(value).toLowerCase();
    return this.payCentreTypes.filter(option => 
      option.name.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue));
  }

  //#endregion
}


