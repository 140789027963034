

import { GenderType } from './types/gender-type.model';
import { RegisteredOwnerContact } from './registered-owner-contact.model';

export class RegisteredOwner {
    public registeredOwnerId: number = 0;
    public driverLicenceNumber: string;
    public motorVehicleIdentificationNumber: string;
    public firstName: string;
    public lastName: string;
    public otherName: string;
    public genderId: number = null;
    public dateOfBirth: Date = null;
    public isSameAsDriver: boolean;
    public gender: GenderType;
    public registeredOwnerContact: RegisteredOwnerContact;

    constructor(init?: Partial<RegisteredOwner>) {
        
        Object.assign(this, init);
    }
}
