


export class Witness {
    public witnessId: number = 0;
    public witnessSequence: number = 0;
    public firstName: string;
    public lastName: string;
    public dateOfBirth: Date;
    public stopInformationId: number = 0;

    constructor(init?: Partial<Witness>) {
        
        Object.assign(this, init);
    }
}
