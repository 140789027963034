import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from "apps/intake/src/environments/environment";
import { User } from '@apis/models/user.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export abstract class ApiService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiV1}/`;
  private user: User;

  protected constructor(public http: HttpClient,
    private readonly localStorageService: LocalStorageService) {
      
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  get(action: string, params: any): Observable<any> {
    let route = action;
    let httpParams = new HttpParams();
    this.user = this.localStorageService.getUser();

    if (params !== null) {
        Object.keys(params).forEach(function(key) {
            const val = params[key];

            // Resolve route parameters if exists
            if (route.includes('{' + key + '}')) {
              route = route.replace('{' + key + '}', val);
            }
            else if (val instanceof Array) {
                val.forEach((c, i) => {
                    const keyIndex = key + '[' + i + ']';
                    httpParams = httpParams.append(keyIndex, c);
                });
            } else {
                httpParams = httpParams.append(key, val);
            }
        });
    }

    return this.http
      .get(this.apiUrl + route, {
        params: httpParams,
        headers: {'Authorization': `Bearer ${this.user.token}`}
      })
      .pipe(catchError(this.formatErrors));
  }

  getBlob(action: string, params: any): Observable<any> {
    let route = action;
    let httpParams = new HttpParams();
    this.user = this.localStorageService.getUser();

    if (params !== null) {
        Object.keys(params).forEach(function(key) {
            const val = params[key];

            // Resolve route parameters if exists
            if (route.includes('{' + key + '}')) {
              route = route.replace('{' + key + '}', val);
            }
            else if (val instanceof Array) {
                val.forEach((c, i) => {
                    const keyIndex = key + '[' + i + ']';
                    httpParams = httpParams.append(keyIndex, c);
                });
            } else {
                httpParams = httpParams.append(key, val);
            }
        });
    }

    return this.http
      .get(this.apiUrl + route, {
        params: httpParams,
        headers: {'Authorization': `Bearer ${this.user.token}`},
        responseType:'blob'
      })
      .pipe(catchError(this.formatErrors));
  }

  put(action: string, body: Object = {}): Observable<any> {
    this.user = this.localStorageService.getUser();
    const httpOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.user.token}`
          }
        )
      }

    for (var key in body) {
        if (!Object.prototype.hasOwnProperty.call(body, key)) continue;
        body = body[key];
        break;
    }
    var newJson = JSON.stringify(body);

    return this.http
      .put(this.apiUrl + action, newJson, httpOptions)
      .pipe(catchError(this.formatErrors));
  }

  post(action: string, body: Object = {}): Observable<any> {
    this.user = this.localStorageService.getUser();
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.user.token}`
        }
      )
    }

    for (var key in body) {
        if (!Object.prototype.hasOwnProperty.call(body, key)) continue;
        body = body[key];
        break;
    }
    var newJson = JSON.stringify(body);

    return this.http
      .post(this.apiUrl + action, newJson, httpOptions)
      .pipe(catchError(this.formatErrors));
  }

  patch(action: string, body: Object = {}): Observable<any> {
    this.user = this.localStorageService.getUser();
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.user.token}`
        }
      )
    }

    for (var key in body) {
        if (!Object.prototype.hasOwnProperty.call(body, key)) continue;
        body = body[key];
        break;
    }
    var newJson = JSON.stringify(body);

    return this.http
      .patch(this.apiUrl + action, newJson, httpOptions)
      .pipe(catchError(this.formatErrors));
  }

  delete(action: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Authorization': `Bearer ${this.user.token}`
        }
      )
    }
    return this.http
      .delete(this.apiUrl + action, httpOptions)
      .pipe(catchError(this.formatErrors));
  }
}
