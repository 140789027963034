import { Component, OnInit, EventEmitter } from '@angular/core';
import { ContraventionTypes, EventTypes, IdentificationTypes, RecipientTypes } from '@apis/shared/enums/app.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Event } from '@apis/shared/models/event.model';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { ContraventionDetailType } from '@apis/shared/models/types/contravention-detail-type.model';
import { MovesService } from 'apps/intake/src/shared/services/moves.service';
import { OperatorInformation } from '@apis/shared/models/operator-information.model';

@Component({
  selector: 'app-reissue-contravention-modal',
  templateUrl: './reissue-contravention-modal.component.html',
  styleUrls: ['./reissue-contravention-modal.component.scss']
})
export class ReissueContraventionModalComponent implements OnInit {
  contravention: Contravention;
  contraventionNumber: string;
  isSubmitClicked: boolean = false;
  bodyElement: JQuery<HTMLElement>;
  
  contraventionTypes: ContraventionType[];
  contraventionDetailTypes: ContraventionDetailType[];
  originalContraventionType: ContraventionType = null;
  selectedContraventionType: ContraventionType = null;  
  operatorInformation: OperatorInformation;

  errorMessage: string;
  username: string;
  confirmModalOverlay: JQuery<HTMLElement>;
  isNoticeReissued: boolean = false;
  
  close: EventEmitter<Contravention> = new EventEmitter<Contravention>();
  
  constructor( private readonly spinner: NgxSpinnerService,
    private movesService: MovesService,
    private readonly localStorageService: LocalStorageService,
    private readonly intakeService: IntakeService) { }

  ngOnInit(): void {        
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.confirmModalOverlay = $(".confirm-modal-overlay");
    this.contraventionTypes = this.localStorageService.getContraventionTypes().filter(x => x.id<9); //Only IRS (except IRS Fail)
    this.contraventionDetailTypes = this.localStorageService.getContraventionDetailTypes();
    
    this.contraventionNumber = this.contravention.contraventionNumber;
    this.originalContraventionType = this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId);
    
    //Disable all charges which are more severe than the existing charge
    this.contraventionTypes.filter(x => x.id >= this.contravention.contraventionTypeId).forEach(charge => {
      charge.isDisabled = true;      
    });

    var user = this.localStorageService.getUser();
    this.username = `${user?.firstName} ${user?.lastName}`;

    //IRS Look back logic (If recipient is driver, identification is Driver's Licence and Province is Alberta)
    if ( this.contravention.recipient.recipientTypeId == RecipientTypes.Driver
      && this.contravention.recipient.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence 
      && this.contravention.recipient.recipientIdentification.issuingProvinceId == 1)
    {
      this.spinner.show();
      this.movesService.getOperatorAsync(this.contravention.recipient.recipientIdentification.identificationNumber??this.contravention.recipient.recipientIdentification.motorVehicleIdentificationNumber)
        .subscribe(result => {
        this.operatorInformation = result;
        this.applyIRSLookBackRestrictions();
        this.spinner.hide();
      },
      error => {
        this.operatorInformation = null;
        this.spinner.hide();
      }); 
    }

    //IRS Zero Novice
    if(this.contravention?.recipient?.recipientIdentification?.isGDLDriver == false)
    {
      this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroNovice).isDisabled = true; //IRS Zero Novice
    }
  }
  
  onContraventionTypeChange(contraventionType: ContraventionType)
  {
    if (contraventionType == null) {
      this.contravention.contraventionTypeId = 0;
      this.contravention.suspensionDuration = 0;
      this.contravention.suspensionDurationType = "";
      this.contravention.isLicenceSeized = null;
      this.contravention.isLicenceDestroyed = null;
      this.contravention.additionalSuspension = null;
      this.contravention.remedialEducation = null;
    }
    else
    {
      this.contravention.contraventionTypeId = contraventionType.id;
      this.contravention.suspensionDuration = contraventionType.licenceSuspensionDuration;
      this.contravention.suspensionDurationType = contraventionType.licenceSuspensionDurationType;
      this.contravention.isLicenceSeized = contraventionType.isLicenseSeized;
      this.contravention.isLicenceDestroyed = contraventionType.isLicenseDestroyed;
      this.contravention.additionalSuspension = contraventionType.additionalSuspension;
      this.contravention.remedialEducation = contraventionType.remedialEducation;
    }

    this.contravention.contraventionDetails = null;
  }

  filterContraventionDetailTypes(contraventionTypeId)
  {
    return this.contraventionDetailTypes.filter(x => x.contraventionTypeId == contraventionTypeId);
  }

  onCancelClick(): void {
    this.bodyElement.removeClass("overflow-hidden");
    this.close.emit(null);
  }

  onConfirmChangeClick(isValid: boolean) {
    this.isSubmitClicked = true;

    if (isValid) {
      this.showHideConfirmModal(true);
    }
  } 

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  onConfirmClick()
  {
    this.spinner.show();
     
    this.contravention.events.push(new Event({
      eventTypeId: EventTypes.ContraventionReIssued,
      eventDetails: `Change in contravention type: from ${this.originalContraventionType.formattedName} to ${this.selectedContraventionType.formattedName}`,
      userName: this.username
    }));

    this.intakeService.updateContraventonRoadsideAppealInfoAsync(this.contravention)
      .subscribe((contravention: Contravention) => {
        this.errorMessage = "This notice has been re-issued";
        this.isNoticeReissued = true;
        this.contravention = contravention;
        this.spinner.hide();
      }, (error: any) => {
        if (error.status == 401 || error.status == 403) {
          this.errorMessage = "You are unauthorized to perform this operation";
        }          
        else {
          this.showErrors(error);
        }
        this.showHideConfirmModal();
        this.spinner.hide();
      });
  }

  onPrintClick()
  {
    this.showHideConfirmModal();
    this.bodyElement.removeClass("overflow-hidden");
    this.close.emit(this.contravention);
  }

  showHideConfirmModal(show?: boolean): void {
    if (show) {
      this.confirmModalOverlay.addClass("modal-show");
    } 
    else 
    {
      this.confirmModalOverlay.removeClass("modal-show");
    }
  }

  applyIRSLookBackRestrictions()
  {
    //IRS Zero Commercial
    switch (this.operatorInformation.irsCommercial.toString())
    {
      case "0":
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial2nd).isDisabled = true; 
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial3rd).isDisabled = true; 
        break;
      case "1":
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial1st).isDisabled = true; 
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial3rd).isDisabled = true; 
        break;
      default:  
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial1st).isDisabled = true; 
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial2nd).isDisabled = true; 
        break;
    }

    //IRS Warn
    switch (this.operatorInformation.irsWarn.toString())
    {
      case "0":
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn2nd).isDisabled = true; 
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn3rd).isDisabled = true; 
        break;
      case "1":
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn1st).isDisabled = true; 
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn3rd).isDisabled = true; 
        break;
      default:  
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn1st).isDisabled = true; 
        this.contraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn2nd).isDisabled = true; 
        break;
    }
  }
}
