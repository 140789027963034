import { Component, OnInit, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { ContraventionStatusTypes, EventTypes, SeizureUserType } from '@apis/shared/enums/app.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Event } from '@apis/shared/models/event.model';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { SpeedingOffence } from '@apis/shared/models/speeding-offence.model';
import { Fine } from '@apis/shared/models/fine.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { Recipient } from '@apis/shared/models/recipient.model';
import { DatePipe } from '@angular/common';
import { PoliceServiceInformation } from '@apis/shared/models/police-service-information.model';
import { Vehicle } from '@apis/shared/models/vehicle.model';
import { RegisteredOwner } from '@apis/shared/models/registered-owner.model';
import { RegisteredOwnerContact } from '@apis/shared/models/registered-owner-contact.model';
import { RecipientIdentification } from '@apis/shared/models/recipient-identification.model';
import { RecipientContact } from '@apis/shared/models/recipient-contact.model';

@Component({
  selector: 'app-offline-seizure-print',
  templateUrl: './offline-print-seizure.component.html',
  styleUrls: ['./offline-print-seizure.component.css']
})
export class OfflinePrintSeizureComponent implements OnInit {
  stop: StopInformation;

  @Input() vehicleSeizure: VehicleSeizure;
  @Input() printType: string;
  @Input() pageBreakRequired: any;

  seizureNumber: string;
  recipient: Recipient;
  recipientIdentification: RecipientIdentification;


  isSubmitClicked: boolean = false;
  bodyElement: JQuery<HTMLElement>;
  cancellationReason: string = "";
  isContraventionCancelled: boolean = false;
  errorMessage: string;
  confirmButton: JQuery<HTMLElement>;
  username: string;
  confirmModalOverlay: JQuery<HTMLElement>;
  additionalNotesTextArea: JQuery<HTMLElement>;

  // local vars for template 
  
  policeServiceInfo: PoliceServiceInformation
  policeFileNumber = '';
  policeOfficerName = '';
  regimentalNumber = '';
  detachmentName = '';
  agencyName = '';
  
  insertPageBreak: boolean;

  seizureTypeName = "";
  mvid = "";
  seizureUserType: string;
  SeizureUserType = SeizureUserType;

  locationName="";
  speedingOffences: SpeedingOffence[];
  fine: Fine;
  vehicle: Vehicle = null;
  registeredOwner: RegisteredOwner = null;
  vehicleProv = '';
  RORecipientName = '';
  ROAddress = '';
  ROPhoneNumber = '';
  ROMvid = '';
  ROLicenceNumber = '';
  RODateOfBirth;
  
  // printing block flags to simplify the UI logic
  //
  copyTypeText: string = '';
  isDriver: boolean = false;
  isRo: boolean = false;
  printBlock1: boolean = false;   // seizure details
  printBlock2: boolean = false;   // driver information
  printBlock3: boolean = false;   // driver address information
  printBlock4: boolean = false;   // registered owner details
  printBlock5: boolean = false;   // vehicle information
  printBlock6: boolean = false;   // Tow lot information
  printBlock7: boolean = false;   // tow lot 'received by' signiture
  printBlock8: boolean = false;   // your options section
  printBlock9: boolean = false;   // issuing officer details


  close: EventEmitter<VehicleSeizure> = new EventEmitter<VehicleSeizure>();

  constructor( private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService,
    private readonly intakeService: IntakeService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {        

    // setup print flags
    this.setupPrintFlags();

    //Get Issuer and Submission Type object
    this.stop = this.intakeService.getStopInformationModel();

    this.seizureNumber = this.vehicleSeizure.seizureNumber;  
    this.recipient = this.stop.recipient;   
    this.recipientIdentification = this.recipient.recipientIdentification;

    var user = this.localStorageService.getUser();
    this.username = `${user?.firstName} ${user?.lastName}`;
  
    if (this.vehicleSeizure.policeServiceInformation.length>0) {
      this.policeServiceInfo = this.vehicleSeizure.policeServiceInformation[0];
      this.policeFileNumber = this.vehicleSeizure.policeFileNumber;
      this.policeOfficerName = this.policeServiceInfo.policeOfficerName;
      var detachment = this.localStorageService.getDetachments().find(x => x.detachmentId == this.policeServiceInfo.detachmentId);
  
      if (detachment!==null && detachment!==undefined ) {
        this.policeOfficerName = this.policeServiceInfo.policeOfficerName;
        this.regimentalNumber = this.policeServiceInfo.regimentalNumber;
        this.detachmentName = detachment.detachmentName
        this.agencyName = detachment?.agency?.agencyName;
      } 
    }

    if (this.vehicleSeizure.vehicle !== null && this.vehicleSeizure.vehicle !== undefined) {
      this.vehicle = this.vehicleSeizure.vehicle;
      this.vehicleProv = (this.vehicle.licensedCountryId == 40 || this.vehicle.licensedCountryId == 236)
        ? (this.vehicle.licensedProvinceId == null? "" 
        : this.localStorageService.getProvinceTypes().find(x=> x.id == this.vehicle.licensedProvinceId)
          ?.name) 
          : this.vehicle.licensedProvince;

      if (this.vehicleSeizure.vehicle?.registeredOwner !== null) {
        this.registeredOwner = this.vehicleSeizure.vehicle?.registeredOwner;
        this.RORecipientName = `${this.registeredOwner.firstName} ${this.registeredOwner.lastName}`;
        this.ROAddress = this.formatAddress(this.registeredOwner.registeredOwnerContact);
        this.ROPhoneNumber = this.formatROPhoneNumber(this.registeredOwner.registeredOwnerContact);
        this.ROMvid = this.getROMvidNumber();
        this.ROLicenceNumber = this.registeredOwner.driverLicenceNumber;
        this.RODateOfBirth = this.datePipe.transform(this.registeredOwner.dateOfBirth, 'dd MMM, yyyy');
      }
    }

 
  }



  setupPrintFlags() {

    this.insertPageBreak = this.pageBreakRequired as boolean;

    // common to all print outs
    this.printBlock1 = true;    // seizure details
    this.printBlock5 = true;    // vehicle information
    this.printBlock6 = true;    // Tow lot information
    this.printBlock9 = true;    // issuing officer details

    this.isDriver = false;
    this.isRo = false;

    switch(this.printType) { 
      case "police": { 
          this.printBlock2 = true;    // driver information
          this.printBlock3 = true;    // driver address information
          this.printBlock4 = true;    // registered owner details
          this.printBlock7 = true;    // tow lot 'received by' signiture
          this.printBlock8 = false;   // your options section
          this.copyTypeText = 'POLICE COPY';
          break; 
      } 
      case "driver": { 
        this.printBlock2 = true;    
        this.printBlock3 = false;    
        this.printBlock4 = false;    
        this.printBlock7 = false;    
        this.printBlock8 = true;   
        this.copyTypeText = 'DRIVER COPY'; 
        this.isDriver = true;
        break; 
      } 
      case "owner": { 
        this.printBlock2 = false;    
        this.printBlock3 = false;    
        this.printBlock4 = true;    
        this.printBlock7 = false;    
        this.printBlock8 = true;    
        this.copyTypeText = 'REGISTERED OWNER COPY';
        this.isRo = true;
        break; 
      } 
      case "towlot": { 
        this.printBlock2 = false;    
        this.printBlock3 = false;    
        this.printBlock4 = true;    
        this.printBlock7 = false;    
        this.printBlock8 = false;    
        this.copyTypeText = 'TOW COMPANY COPY';
        break; 
      } 
    }
  }


  formatDriverPhoneNumber(recipientContact: RecipientContact) {
    if(!recipientContact)
      return '';
    if (recipientContact.phoneNumber1 && recipientContact.phoneNumber1.trim().length>0)
      return recipientContact.phoneNumber1;
    if (recipientContact.phoneNumber2 && recipientContact.phoneNumber2.trim().length>0)
      return recipientContact.phoneNumber2;
    if (recipientContact.phoneNumber3 && recipientContact.phoneNumber3.trim().length>0)
      return recipientContact.phoneNumber3;
    return 'N/A';
  }
  
  formatROPhoneNumber(recipientContact: RegisteredOwnerContact) {
    if(!recipientContact)
      return '';
    if (recipientContact.phoneNumber1 && recipientContact.phoneNumber1.trim().length>0)
      return recipientContact.phoneNumber1;
    if (recipientContact.phoneNumber2 && recipientContact.phoneNumber2.trim().length>0)
      return recipientContact.phoneNumber2;
    return 'N/A';
  }
  
  formatAddress(recipientContact: RecipientContact | RegisteredOwnerContact) {
    if(!recipientContact)
      return '';

    let address = '';
    if (recipientContact.addressLine1 && recipientContact.addressLine1.trim().length>0)
      address += recipientContact.addressLine1.trim();

    if (recipientContact.addressLine2 && recipientContact.addressLine2.trim().length>0) {
      if (recipientContact.addressLine1 && recipientContact.addressLine1.trim().length>0)
        address += ', ';
      address += recipientContact.addressLine2.trim();
    }

    if (recipientContact.addressLine3 && recipientContact.addressLine3.trim().length>0) {
      if ((recipientContact.addressLine1 && recipientContact.addressLine1.trim().length>0) || (recipientContact.addressLine2 && recipientContact.addressLine2.trim().length>0 ))
        address += ', ';
      address += recipientContact.addressLine3.trim();
    }

    if (recipientContact.city && recipientContact.city.trim().length>0) {
      address += ', ' + recipientContact.city.trim();
    }

    if (recipientContact.province && recipientContact.province.trim().length>0) {
      address += ' ' + recipientContact.province.trim();
    }

    if (recipientContact.postalCode && recipientContact.postalCode.trim().length>0) {
      address += ', ' + recipientContact.postalCode.trim();
    }

    return address;
  }

  officerNameText(): string {
    return this.policeOfficerName;
  }

  regimentalNumberText(): string {
    return this.regimentalNumber;
  }

  policeFileNumberText(): string {
    if (this.policeFileNumber && (this.policeFileNumber.trim().length>0))
    {
      return this.policeFileNumber;
    }
    return "N/A";
  }

  agencyText(): string {
    return this.agencyName;
  }

  detachmentText(): string {
    return this.detachmentName;
  }

  getSeizureType(): string {
    let seizureType = this.localStorageService.getSeizureTypes().find(x => x.id == this.vehicleSeizure.seizureTypeId);
    this.seizureTypeName = seizureType?.name;

    return this.seizureTypeName;
  }

  getMvidNumber(): string {
    if (this.recipient) {
      let mvidNumber = this.recipient.recipientIdentification?.motorVehicleIdentificationNumber;
      if (mvidNumber && mvidNumber.trim().length>0)
        return mvidNumber;
    }
    
    return "N/A";
  }

  getROMvidNumber(): string {
    if (this.vehicleSeizure.vehicle?.registeredOwner) {
      let mvidNumber = this.vehicleSeizure.vehicle?.registeredOwner.motorVehicleIdentificationNumber;
      if (mvidNumber && mvidNumber.trim().length>0)
        return mvidNumber;
    }
    
    return "N/A";
  }
  
  getDriversLicence(): string {
    if (this.recipient) {
      return this.recipient.recipientIdentification?.identificationNumber;
    }
    
    return "";
  }
  
  getDateOfBirth(): string {
    if (this.recipient) {
      return this.datePipe.transform(this.recipient.recipientIdentification?.dateOfBirth, 'dd MMM, yyyy');
    }
    
    return "";
  }

  getIsRegisteredOwner() : string {
    return this.vehicleSeizure.vehicle?.registeredOwner !== null ? "True" : "False";
  }

  vehicleSeizureDurationText(): string  {
    return `${this.vehicleSeizure.seizureDuration}  ${this.vehicleSeizure.seizureDurationType}`;
  }

}
