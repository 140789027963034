export class OperatorSearch {
    public operatorNumber: string;
    public mvid: string;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public genderId: number = 0;
    public dob: Date;
    
    constructor(init?: Partial<OperatorSearch>) {
        
        Object.assign(this, init);
    }
}