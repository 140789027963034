import { EventEmitter, Injectable, Output } from '@angular/core';
import { Role } from '../models/role.model';
import { CountryType } from '../models/types/country-type.model';
import { CircumstanceType } from '../models/types/circumstance-type.model';
import { ProvinceType } from '../models/types/province-type.model';
import { ContraventionType } from '../models/types/contravention-type.model';
import { ClientType } from '../models/types/client-type.model';
import { ContraventionDetailType } from '../models/types/contravention-detail-type.model';
import { ContraventionGroundsType } from '../models/types/contravention-grounds-type.model';
import { DocumentType } from '../models/types/document-type.model';
import { DriversLicenceClassType } from '../models/types/drivers-licence-class-type.model';
import { DriversLicenceStatusType } from '../models/types/drivers-licence-status-type.model';
import { LocationType } from '../models/types/location-type.model';
import { GenderType } from '../models/types/gender-type.model';
import { ImpairmentScreeningType } from '../models/types/impairment-screening-type.model';
import { LabType } from '../models/types/lab-type.model';
import { ObservedByType } from '../models/types/observed-by-type.model';
import { SeizureType } from '../models/types/seizure-type.model';
import { SuspectedImpairmentType } from '../models/types/suspected-impairment-type.model';
import { SuspicionType } from '../models/types/suspicion-type.model';
import { TestModelType } from '../models/types/test-model-type.model';
import { TestResultType } from '../models/types/test-result-type.model';
import { TestType } from '../models/types/test-type.model';
import { VehicleRegistrationStatusType } from '../models/types/vehicle-registration-status-type.model';
import { ContraventionStatusType } from '../models/types/contravention-status-type.model';
import { ContactMethodType } from '../models/types/contact-method-type.model';
import { TypeTable } from '../enums/type-table.enum';
import { IncomeLevelType } from '../models/types/income-level-type.model';
import { RequestStatusType } from '../models/types/request-status-type.model';
import { ReviewType } from "../models/types/review-type.model";
import { ReviewStatusType } from "../models/types/review-status-type.model";
import { RepresentationType } from "../models/types/representation-type.model";
import { ReviewMethodType } from '../models/types/review-method-type.model';
import { SeizureStatusType } from '../models/types/seizure-status-type.model';
import { VehicleInterestType } from '../models/types/vehicle-interest-type.model';
import { RequestType } from '../models/types/request-type.model';
import { EventType } from "../models/types/event-type.model";
import { SeizureGroupType } from "../models/types/seizure-group-type.model";
import { DecisionType } from "../models/types/decision-type.model";
import { IntakeUser } from 'apps/intake/src/shared/models/intake-user.model';
import { AdjudicationUser } from 'apps/adjudication/src/app/shared/models/adjudication-user.model';
import { ReviewOutcomeType } from "../models/types/review-outcome-type.model";
import { AvailabilityType } from "../models/types/availability-type.model";
import { MeetingType } from "../models/types/meeting-type.model";

import { IdentificationType } from "../models/types/identification-type.model";
import { Agency } from '../models/agency.model';
import { Detachment } from '../models/detachment.model';
import { RequestDecisionType } from "../models/types/request-decision-type.model";
import { Holiday } from "../models/holiday.model";
import { TermCondition } from "../models/term-condition.model";
import { NotificationType } from "../models/types/notification-type.model";
import { User } from "../models/user.model";
import { ReServeMethodType } from '../models/types/re-serve-method-type.model';
import { RsgAgentProfile } from '../models/rsg-agent-profile.model';
import { RolePermission } from '../models/role-permission.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IssueServiceType } from '../models/types/issue-service-type.model';
import { SpeedingOffence } from '../models/speeding-offence.model';
import { OfficerTransportationType } from '../models/types/officer-transportation-type.model';
import { OfficerUniformType } from '../models/types/officer-uniform-type.model';
import { OfficerVehicleType } from '../models/types/officer-vehicle-type.model';
import { SpeedingDeviceType } from '../models/types/speeding-device-type.model';
import { SpeedingDeviceMakeType } from '../models/types/speeding-device-make-type.model';
import { SpeedingDeviceModelType } from '../models/types/speeding-device-model-type.model';
import { ContraventionTypeMapping } from '../models/types/contravention-type-mapping.model';
import { RoadsideAppealOutcomeType } from '../models/types/roadside-appeal-outcome-type.model';
import { DirectionType } from '../models/types/direction-type.model';
import { DriverDeclinedToTestType } from './../models/types/driver-declined-to-test-type.model';
import { NoticeCancellationReasonType } from './../models/types/notice-cancellation-reason-type.model';
import { RoadConditionType } from '../models/types/road-condition-type.model';
import { RoadSurfaceType } from '../models/types/road-surface-type.model';
import { WeatherConditionType } from '../models/types/weather-condition-type.model';
import { VisibilityConditionType } from '../models/types/visibility-condition-type.model';
import { PayCentreType } from '../models/types/pay-centre-type.model';
import { RequestDenyReasonType } from '../models/types/request-deny-reason-type.model';
import { IntakeAppProcess } from '../enums/app.enum';
import { JudicalReviewDecisionType } from '../models/types/judicial-review-decision-type.model';
import { StayOrderStatusType } from '../models/types/stay-order-status-type.model';
import { ConditionCodeType } from '../models/types/condition-code-type.model';
import { VehicleStatusType } from '../models/types/vehicle-status-type.model';
import { DeclarationType } from '../models/types/declaration-type.model';
import { ReviewCancellationReasonType } from '../models/types/review-cancellation-reason-type.model';
import { ReviewCancellationSecondaryReasonType } from '../models/types/review-cancellation-secondary-reason-type.model';
import { ReviewCancellationReasonCategoryType } from '../models/types/review-cancellation-reason-category-type.model';
import { HighwayCharacteristicsType } from '../models/types/highway-characteristics-type.model';
import { CourtType } from '../models/types/court-type.model';
import { OffenceType } from '../models/types/offence-type.model';
import { TicketMethodType } from '../models/types/ticket-method-type.model';
import { TicketWithdrawalReasonType } from '../models/types/ticket-withdrawal-reason-type.model';

@Injectable()
export class LocalStorageService {
  @Output() getLoggedInUserName: EventEmitter<any> = new EventEmitter();

  private readonly circumstanceTypesKey = "circumstanceTypesKey";
  private readonly clientTypesKey = "clientTypesKey";
  private readonly contraventionDetailTypesKey = "contraventionDetailTypesKey";
  private readonly contraventionGroundsTypesKey = "contraventionGroundsTypesKey";
  private readonly contraventionStatusTypesKey = "contraventionStatusTypesKey";
  private readonly contraventionTypesKey = "contraventionTypesKey";
  private readonly countryTypesKey = "countryTypesKey";
  private readonly documentTypesKey = "documentTypesKey";
  private readonly driversLicenceClassTypesKey = "driversLicenceClassTypesKey";
  private readonly driversLicenceStatusTypesKey = "driversLicenceStatusTypesKey";
  private readonly genderTypesKey = "genderTypesKey";
  private readonly impairmentScreeningTypesKey = "impairmentScreeningTypesKey";
  private readonly labTypesKey = "labTypesKey";
  private readonly locationTypesKey = "locationTypesKey";
  private readonly observedByTypesKey = "observedByTypesKey";
  private readonly provinceTypesKey = "provinceTypesKey";
  private readonly seizureTypesKey = "seizureTypesKey";
  private readonly suspectedImpairmentTypesKey = "suspectedImpairmentTypesKey";
  private readonly suspicionTypesKey = "suspicionTypesKey";
  private readonly testModelTypesKey = "testModelTypesKey";
  private readonly testResultTypesKey = "testResultTypesKey";
  private readonly testTypesKey = "testTypesKey";
  private readonly vehicleRegistrationStatusTypesKey = "vehicleRegistrationStatusTypesKey";
  private readonly rolesKey = "rolesKey";
  private readonly userKey = "userKey";
  private readonly contraventionPaymentRequestKey = "conPayReq";
  private readonly contactMethodTypesKey = "contactMethodTypesKey";
  private readonly incomeLevelTypesKey = "incomeLevelTypesKey";
  private readonly requestStatusTypesKey = "requestStatusTypesKey";
  private readonly reviewTypesKey = "reviewTypesKey";
  private readonly reviewStatusTypesKey = "reviewStatusTypesKey";
  private readonly representationTypesKey = "representationTypesKey";
  private readonly reviewMethodTypesKey = "reviewMethodTypesKey";
  private readonly reviewRequestKey = "reviewRequestKey";
  private readonly createdReviewKey = "createdReviewKey"
  private readonly tempFolderNameKey = "tempFolderNameKey";
  private readonly stopInformationModelKey = "stopInformationModelKey";
  private readonly intakeDashboardSearchFiltersKey = "intakeDashboardSearchFiltersKey";
  private readonly sharedDocumentUploadRequestKey = "sharedDocumentUploadRequestKey";
  private readonly sharedDisclosureDocumentsUploadRequestKey = "sharedDisclosureDocumentsUploadRequestKey";
  private readonly lateReviewRequestKey = "lateReviewRequestKey";
  private readonly seizureStatusTypesKey = "seizureStatusTypesKey";
  private readonly vehicleInterestTypesKey = "vehicleInterestTypesKey";
  private readonly requestTypesKey = "requestTypesKey";
  private readonly eventTypesKey = "eventTypesKey";
  private readonly seizureGroupTypesKey = "seizureGroupTypesKey";
  private readonly decisionTypesKey = "decisionTypesKey";
  private readonly agenciesKey = "agenciesKey";
  private readonly detachmentsKey = "detachmentsKey";
  private readonly requestDecisionTypesKey = "requestDecisionTypesKey";
  private readonly holidaysKey = "holidaysKey";
  private readonly tsaHolidaysKey = "tsaHolidaysKey";
  private readonly termsConditionsKey = "termsConditionsKey";
  private readonly notificationTypesKey = "notificationTypesKey";
  private readonly adjudicatorsKey = "adjudicatorsKey";
  private readonly reviewOutcomeTypesKey = "reviewOutcomeTypesKey";
  private readonly reServeMethodTypesKey = "reServeMethodTypesKey";
  private readonly rsgAgentProfileKey = "rsgAgentProfileKey";
  private readonly rolePermissionKey = "rolePermissionKey";
  private readonly availabilityTypesKey = "availabilityTypesKey";
  private readonly meetingTypesKey = "meetingTypesKey";
  private readonly identificationTypesKey = "identificationTypesKey";
  private readonly issueServiceTypesKey = "issueServiceTypesKey";
  private readonly speedingOffencesKey = "speedingOffencesKey";
  private readonly officerTransportationTypesKey = "officerTransportationTypesKey";
  private readonly officerUniformTypesKey = "officerUniformTypesKey";
  private readonly officerVehicleTypesKey = "officerVehicleTypesKey";
  private readonly speedingDeviceTypeKey = "speedingDeviceTypeKey";
  private readonly speedingDeviceMakeTypeKey = "speedingDeviceMakeTypeKey";
  private readonly speedingDeviceModelTypeKey = "speedingDeviceModelTypeKey";
  private readonly contraventionTypeMappingsKey = "contraventionTypeMappingsKey";
  private readonly roadsideAppealOutcomeTypesKey = "roadsideAppealOutcomeTypesKey";
  private readonly directionTypesKey = "directionTypesKey";
  private readonly driverDeclinedToTestTypesKey = "driverDeclinedToTestTypesKey";
  private readonly noticeCancellationReasonTypesKey = "noticeCancellationReasonTypesKey";
  private readonly offlineNumbersKey = "offlineNumbersKey";
  private readonly offlineSeizureNumbersKey = "offlineSeizureNumbersKey";
  private readonly intakeAppProcessKey = "intakeAppProcessKey";
  private readonly currentOfflineIdKey = "currentOfflineIdKey";
  private readonly roadConditionTypesKey = "roadConditionTypesKey";
  private readonly roadSurfaceTypesKey = "roadSurfaceTypesKey";
  private readonly weatherConditionTypesKey = "weatherConditionTypesKey";
  private readonly visibilityConditionTypesKey = "visibilityConditionTypesKey";
  private readonly payCentreTypesKey = "payCentreTypesKey";
  private readonly requestDenyReasonTypesKey = "requestDenyReasonTypesKey";
  private readonly judicialReviewDecisionTypesKey = "judicialReviewDecisionTypesKey";
  private readonly stayOrderStatusTypesKey = "stayOrderStatusTypesKey";
  private readonly conditionCodeTypesKey = "conditionCodeTypesKey";
  private readonly vehicleStatusTypesKey = "vehicleStatusTypesKey";
  private readonly declarationTypesKey = "declarationTypesKey";
  private readonly reviewCancellationReasonTypesKey = "reviewCancellationReasonTypesKey";
  private readonly reviewCancellationSecondaryReasonTypesKey = "reviewCancellationSecondaryReasonTypesKey";
  private readonly reviewCancellationReasonCategoryTypesKey = "reviewCancellationReasonCategoryTypesKey";
  private readonly enableLicencePickupModalKey = "enableLicencePickupModalKey";
  private readonly highwayCharacteristicsTypesKey = "highwayCharacteristicsTypesKey";
  private readonly courtTypesKey = "courtTypesKey";
  private readonly offenceTypesKey = "offenceTypesKey";
  private readonly violationTicketKey = "violationTicketKey";
  private readonly ticketStopInformationModelKey = "ticketStopInformationModelKey";
  private readonly ticketMethodTypesKey = "TicketMethodTypesKey";
  private readonly ticketWithdrawalReasonTypesKey = "ticketWithdrawalReasonTypesKey";
  public readonly hasLocalStorageReady$ = new BehaviorSubject(false);

  constructor() { }

  getTypeItemDescriptionById(id: number, typeTable: TypeTable): string {
    let typeItemList: any[];
    const getTypesFuncMap: {key: TypeTable, func: any}[] = [
      { key: TypeTable.CircumstanceType, func: this.getCircumstanceTypes },
      { key: TypeTable.ClientType, func: this.getClientTypes },
      { key: TypeTable.ContraventionDetailType, func: this.getContraventionDetailTypes },
      { key: TypeTable.ContraventionGroundsType, func: this.getContraventionGroundsTypes },
      { key: TypeTable.ContraventionStatusType, func: this.getContraventionStatusTypes },
      { key: TypeTable.ContraventionType, func: this.getContraventionTypes },
      { key: TypeTable.CountryType, func: this.getCountryTypes },
      { key: TypeTable.DocumentType, func: this.getDocumentTypes },
      { key: TypeTable.DriversLicenceClassType, func: this.getDriversLicenceClassTypes },
      { key: TypeTable.DriversLicenceStatusType, func: this.getDriversLicenceStatusTypes },
      { key: TypeTable.GenderType, func: this.getGenderTypes },
      { key: TypeTable.ImpairmentScreeningType, func: this.getImpairmentScreeningTypes },
      { key: TypeTable.LabType, func: this.getLabTypes },
      { key: TypeTable.LocationType, func: this.getLocationTypes },
      { key: TypeTable.ObservedByType, func: this.getObservedByTypes },
      { key: TypeTable.ProvinceType, func: this.getProvinceTypes },
      { key: TypeTable.SeizureType, func: this.getSeizureTypes },
      { key: TypeTable.SeizureStatusType, func: this.getSeizureStatusTypes },
      { key: TypeTable.SuspectedImpairmentType, func: this.getSuspectedImpairmentTypes },
      { key: TypeTable.SuspicionType, func: this.getSuspicionTypes },
      { key: TypeTable.TestModelType, func: this.getTestModelTypes },
      { key: TypeTable.TestResultType, func: this.getTestResultTypes },
      { key: TypeTable.TestType, func: this.getTestTypes },
      { key: TypeTable.VehicleRegistrationStatusType, func: this.getVehicleRegistrationStatusTypes },
      { key: TypeTable.ReviewType, func: this.getReviewTypes },
      { key: TypeTable.ReviewStatusType, func: this.getReviewStatusTypes },
      { key: TypeTable.VehicleInterestType, func: this.getVehicleInterestTypes },
      { key: TypeTable.RequestType, func: this.getRequestTypes },
      { key: TypeTable.RequestStatusType, func: this.getRequestStatusTypes },
      { key: TypeTable.EventType, func: this.getEventTypes },
      { key: TypeTable.SeizureGroupType, func: this.getSeizureGroupTypes },
      { key: TypeTable.ContactMethodType, func: this.getContactMethodTypes },
      { key: TypeTable.DecisionType, func: this.getDecisionTypes },
      { key: TypeTable.RequestDecisionType, func: this.getRequestDecisionTypes },
      { key: TypeTable.NotificationType, func: this.getNotificationTypes },
      { key: TypeTable.ReviewMethodType, func: this.getReviewMethodTypes },
      { key: TypeTable.ReviewOutcomeType, func: this.getReviewOutcomeTypes },
      { key: TypeTable.ReServeMethodType, func: this.getReServeMethodTypes },
      { key: TypeTable.RoadsideAppealOutcomeType, func: this.getRoadsideAppealOutcomeTypes },
      { key: TypeTable.DirectionType, func: this.getDirectionTypes },
      { key: TypeTable.IssueServiceType, func: this.getIssueServiceTypes },
      { key: TypeTable.DriverDeclinedToTestType, func: this.getDriverDeclinedToTestTypes},
      { key: TypeTable.RoadConditionType, func: this.getRoadConditionTypes },
      { key: TypeTable.RoadSurfaceType, func: this.getRoadSurfaceTypes },
      { key: TypeTable.WeatherConditionType, func: this.getWeatherConditionTypes },
      { key: TypeTable.VisibilityConditionType, func: this.getVisibilityConditionTypes },      
      { key: TypeTable.PayCentreType, func: this.getPayCentreTypes },
      { key: TypeTable.NoticeCancellationReasonType, func: this.getNoticeCancellationReasonTypes },
      { key: TypeTable.RequestDenyReasonType, func: this.getRequestDenyReasonTypes },
      { key: TypeTable.JudicialReviewDecisionType, func: this.getJudicialReviewDecisionTypes },
      { key: TypeTable.StayOrderStatusType, func: this.getStayOrderStatusTypes },
      { key: TypeTable.ConditionCodeType, func: this.getConditionCodeTypes },
      { key: TypeTable.VehicleStatusType, func: this.getVehicleStatusTypes },
      { key: TypeTable.DeclarationType, func: this.getDeclarationTypes },
      { key: TypeTable.RepresentationType, func: this.getRepresentationTypes },
      { key: TypeTable.HighwayCharacteristicsType, func: this.getHighwayCharacteristicsTypes },
      { key: TypeTable.CourtType, func: this.getCourtTypes },
      { key: TypeTable.OffenceType, func: this.getOffenceTypes },
      { key: TypeTable.TicketMethodType, func: this.getTicketMethodTypes },
      { key: TypeTable.TicketWithdrawalReasonType, func: this.getTicketWithdrawalReasonTypes },
    ];

    if (id == undefined) { return ''; }

    typeItemList = getTypesFuncMap.find(map => map.key === typeTable).func.call(this);

    if (typeItemList != null && typeItemList.length > 0) {
      return typeItemList.find(i => i.id == id)?.name;
    } else {
      return '';
    }
  }

  setAvailabilityTypes(availabilityTypes: AvailabilityType[]): void {
    localStorage.setItem(this.availabilityTypesKey, JSON.stringify(availabilityTypes));
  }

  getAvailabilityTypes(): AvailabilityType[] {
    return JSON.parse(localStorage.getItem(this.availabilityTypesKey));
  }

  setMeetingTypes(meetingTypes: MeetingType[]): void {
    localStorage.setItem(this.meetingTypesKey, JSON.stringify(meetingTypes));
  }

  getMeetingTypes(): MeetingType[] {
    return JSON.parse(localStorage.getItem(this.meetingTypesKey));
  }

  setTermsAndConditionsList(termsConditions: TermCondition[]): void {
    localStorage.setItem(this.termsConditionsKey, JSON.stringify(termsConditions));
  }

  getTermsAndConditionsList(): TermCondition[] {
    return JSON.parse(localStorage.getItem(this.termsConditionsKey));
  }

  setHolidayList(holidays: Holiday[]): void {
    localStorage.setItem(this.holidaysKey, JSON.stringify(holidays));
  }

  getHolidayList(): Holiday[] {
    return JSON.parse(localStorage.getItem(this.holidaysKey));
  }

  setTsaHolidayList(holidays: Holiday[]): void {
    localStorage.setItem(this.tsaHolidaysKey, JSON.stringify(holidays));
  }

  getTsaHolidayList(): Holiday[] {
    return JSON.parse(localStorage.getItem(this.tsaHolidaysKey));
  }

  setDecisionTypes(decisionTypes: DecisionType[]): void {
    localStorage.setItem(this.decisionTypesKey, JSON.stringify(decisionTypes));
  }

  getDecisionTypes(): DecisionType[] {
    return JSON.parse(localStorage.getItem(this.decisionTypesKey));
  }

  setRequestDecisionTypes(requestDecisionTypes: RequestDecisionType[]): void {
    localStorage.setItem(this.requestDecisionTypesKey, JSON.stringify(requestDecisionTypes));
  }

  getRequestDecisionTypes(): RequestDecisionType[] {
    return JSON.parse(localStorage.getItem(this.requestDecisionTypesKey));
  }

  setContactMethodTypes(contactMethodTypes: ContactMethodType[]): void {
    localStorage.setItem(this.contactMethodTypesKey, JSON.stringify(contactMethodTypes));
  }

  getContactMethodTypes(): ContactMethodType[] {
    return JSON.parse(localStorage.getItem(this.contactMethodTypesKey));
  }

  setCircumstanceTypes(circumstanceTypes: CircumstanceType[]): void {
    localStorage.setItem(this.circumstanceTypesKey, JSON.stringify(circumstanceTypes));
  }

  getCircumstanceTypes(): CircumstanceType[] {
    return JSON.parse(localStorage.getItem(this.circumstanceTypesKey));
  }

  setClientTypes(clientTypes: ClientType[]): void {
    localStorage.setItem(this.clientTypesKey, JSON.stringify(clientTypes));
  }

  getClientTypes(): ClientType[] {
    return JSON.parse(localStorage.getItem(this.clientTypesKey));
  }

  setContraventionDetailTypes(contraventionDetailTypes: ContraventionDetailType[]): void {
    localStorage.setItem(this.contraventionDetailTypesKey, JSON.stringify(contraventionDetailTypes));
  }

  getContraventionDetailTypes(): ContraventionDetailType[] {
    return JSON.parse(localStorage.getItem(this.contraventionDetailTypesKey));
  }

  setContraventionGroundsTypes(contraventionGroundsTypes: ContraventionGroundsType[]): void {
    localStorage.setItem(this.contraventionGroundsTypesKey, JSON.stringify(contraventionGroundsTypes));
  }

  getContraventionGroundsTypes(): ContraventionGroundsType[] {
    return JSON.parse(localStorage.getItem(this.contraventionGroundsTypesKey));
  }

  setContraventionStatusTypes(contraventionStatusTypes: ContraventionStatusType[]): void {
    localStorage.setItem(this.contraventionStatusTypesKey, JSON.stringify(contraventionStatusTypes));
  }

  getContraventionStatusTypes(): ContraventionStatusType[] {
    return JSON.parse(localStorage.getItem(this.contraventionStatusTypesKey)) || [];
  }

  setContraventionTypes(contraventionTypes: ContraventionType[]): void {
    localStorage.setItem(this.contraventionTypesKey, JSON.stringify(contraventionTypes));
  }

  getContraventionTypes(): ContraventionType[] {
    return JSON.parse(localStorage.getItem(this.contraventionTypesKey));
  }

  setContraventionTypeMappings(contraventionTypeMappings: ContraventionTypeMapping[]): void {
    localStorage.setItem(this.contraventionTypeMappingsKey, JSON.stringify(contraventionTypeMappings));
  }

  getContraventionTypeMappings(): ContraventionTypeMapping[] {
    return JSON.parse(localStorage.getItem(this.contraventionTypeMappingsKey));
  }

  setCountryTypes(countryTypes: CountryType[]): void {
    localStorage.setItem(this.countryTypesKey, JSON.stringify(countryTypes));
  }

  getCountryTypes(): CountryType[] {
    return JSON.parse(localStorage.getItem(this.countryTypesKey)) || [];
  }

  setDocumentTypes(documentTypes: DocumentType[]): void {
    localStorage.setItem(this.documentTypesKey, JSON.stringify(documentTypes));
  }

  getDocumentTypes(): DocumentType[] {
    return JSON.parse(localStorage.getItem(this.documentTypesKey)) || [];
  }

  setDriverDeclinedToTestTypes(driverDeclinedToTestTypes: DriverDeclinedToTestType[]): void {
    localStorage.setItem(this.driverDeclinedToTestTypesKey, JSON.stringify(driverDeclinedToTestTypes));
  }

  getDriverDeclinedToTestTypes(): DriverDeclinedToTestType[] {
    return JSON.parse(localStorage.getItem(this.driverDeclinedToTestTypesKey)) || [];
  }

  setDriversLicenceClassTypes(driversLicenceClassTypes: DriversLicenceClassType[]): void {
    localStorage.setItem(this.driversLicenceClassTypesKey, JSON.stringify(driversLicenceClassTypes));
  }

  getDriversLicenceClassTypes(): DriversLicenceClassType[] {
    return JSON.parse(localStorage.getItem(this.driversLicenceClassTypesKey)) || [];
  }

  setDriversLicenceStatusTypes(driversLicenceStatusTypes: DriversLicenceStatusType[]): void {
    localStorage.setItem(this.driversLicenceStatusTypesKey, JSON.stringify(driversLicenceStatusTypes));
  }

  getDriversLicenceStatusTypes(): DriversLicenceStatusType[] {
    return JSON.parse(localStorage.getItem(this.driversLicenceStatusTypesKey)) || [];
  }

  setGenderTypes(genderTypes: GenderType[]): void {
    localStorage.setItem(this.genderTypesKey, JSON.stringify(genderTypes));
  }

  getGenderTypes(): GenderType[] {
    return JSON.parse(localStorage.getItem(this.genderTypesKey));
  }

  setImpairmentScreeningTypes(impairmentScreeningTypes: ImpairmentScreeningType[]): void {
    localStorage.setItem(this.impairmentScreeningTypesKey, JSON.stringify(impairmentScreeningTypes));
  }

  getImpairmentScreeningTypes(): ImpairmentScreeningType[] {
    return JSON.parse(localStorage.getItem(this.impairmentScreeningTypesKey));
  }

  setLabTypes(labTypes: LabType[]): void {
    localStorage.setItem(this.labTypesKey, JSON.stringify(labTypes));
  }

  getLabTypes(): LabType[] {
    return JSON.parse(localStorage.getItem(this.labTypesKey));
  }

  setLocationTypes(locationTypes: LocationType[]): void {
    localStorage.setItem(this.locationTypesKey, JSON.stringify(locationTypes));
  }

  getLocationTypes(): LocationType[] {
    return JSON.parse(localStorage.getItem(this.locationTypesKey));
  }

  setObservedByTypes(observedByTypes: ObservedByType[]): void {
    localStorage.setItem(this.observedByTypesKey, JSON.stringify(observedByTypes));
  }

  getObservedByTypes(): ObservedByType[] {
    return JSON.parse(localStorage.getItem(this.observedByTypesKey));
  }

  setProvinceTypes(provinceTypes: ProvinceType[]): void {
    localStorage.setItem(this.provinceTypesKey, JSON.stringify(provinceTypes));
  }

  getProvinceTypes(): ProvinceType[] {
    return JSON.parse(localStorage.getItem(this.provinceTypesKey));
  }

  setSeizureTypes(seizureTypes: SeizureType[]): void {
    localStorage.setItem(this.seizureTypesKey, JSON.stringify(seizureTypes));
  }

  getSeizureTypes(): SeizureType[] {
    return JSON.parse(localStorage.getItem(this.seizureTypesKey));
  }

  setSeizureGroupTypes(seizureGroupTypes: SeizureGroupType[]): void {
    localStorage.setItem(this.seizureGroupTypesKey, JSON.stringify(seizureGroupTypes));
  }

  getSeizureGroupTypes(): SeizureGroupType[] {
    return JSON.parse(localStorage.getItem(this.seizureGroupTypesKey));
  }

  setSuspectedImpairmentTypes(suspectedImpairmentTypes: SuspectedImpairmentType[]): void {
    localStorage.setItem(this.suspectedImpairmentTypesKey, JSON.stringify(suspectedImpairmentTypes));
  }

  getSuspectedImpairmentTypes(): SuspectedImpairmentType[] {
    return JSON.parse(localStorage.getItem(this.suspectedImpairmentTypesKey));
  }

  setSuspicionTypes(suspicionTypes: SuspicionType[]): void {
    localStorage.setItem(this.suspicionTypesKey, JSON.stringify(suspicionTypes));
  }

  getSuspicionTypes(): SuspicionType[] {
    return JSON.parse(localStorage.getItem(this.suspicionTypesKey));
  }

  setTestModelTypes(testModelTypes: TestModelType[]): void {
    localStorage.setItem(this.testModelTypesKey, JSON.stringify(testModelTypes));
  }

  getTestModelTypes(): TestModelType[] {
    return JSON.parse(localStorage.getItem(this.testModelTypesKey));
  }

  setTestResultTypes(testResultTypes: TestResultType[]): void {
    localStorage.setItem(this.testResultTypesKey, JSON.stringify(testResultTypes));
  }

  getTestResultTypes(): TestResultType[] {
    return JSON.parse(localStorage.getItem(this.testResultTypesKey));
  }

  setTestTypes(testTypes: TestType[]): void {
    localStorage.setItem(this.testTypesKey, JSON.stringify(testTypes));
  }

  getTestTypes(): TestType[] {
    return JSON.parse(localStorage.getItem(this.testTypesKey));
  }

  setVehicleRegistrationStatusTypes(vehicleRegistrationStatusTypes: VehicleRegistrationStatusType[]): void {
    localStorage.setItem(this.vehicleRegistrationStatusTypesKey, JSON.stringify(vehicleRegistrationStatusTypes));
  }

  getVehicleRegistrationStatusTypes(): VehicleRegistrationStatusType[] {
    return JSON.parse(localStorage.getItem(this.vehicleRegistrationStatusTypesKey));
  }

  setIncomeLevelTypes(incomeLevelTypes: IncomeLevelType[]): void {
    localStorage.setItem(this.incomeLevelTypesKey, JSON.stringify(incomeLevelTypes));
  }

  getIncomeLevelTypes(): IncomeLevelType[] {
    return JSON.parse(localStorage.getItem(this.incomeLevelTypesKey));
  }

  setRequestTypes(requestTypes: RequestType[]): void {
    localStorage.setItem(this.requestTypesKey, JSON.stringify(requestTypes));
  }

  getRequestTypes(): RequestType[] {
    return JSON.parse(localStorage.getItem(this.requestTypesKey));
  }

  setRequestStatusTypes(requestStatusTypes: RequestStatusType[]): void {
    localStorage.setItem(this.requestStatusTypesKey, JSON.stringify(requestStatusTypes));
  }

  getRequestStatusTypes(): RequestStatusType[] {
    return JSON.parse(localStorage.getItem(this.requestStatusTypesKey));
  }

  setRepresentationTypes(representationTypes: RepresentationType[]): void {
    localStorage.setItem(this.representationTypesKey, JSON.stringify(representationTypes));
  }

  getRepresentationTypes(): RepresentationType[] {
    return JSON.parse(localStorage.getItem(this.representationTypesKey)) || [];
  }

  setRoles(roles: Role[]): void {
    localStorage.setItem(this.rolesKey, JSON.stringify(roles));
  }

  getRoles(): Role[]{
    const roles = localStorage.getItem(this.rolesKey);
    if (roles) {
      const parsed = JSON.parse(roles);
      return   parsed;
    }
    return [];
  }

  setUser(userModel: IntakeUser): void {
    localStorage.setItem(this.userKey, JSON.stringify(userModel));
    this.getLoggedInUserName.emit(userModel.displayName);
  }

  getUser(): IntakeUser {
    const userModel = localStorage.getItem(this.userKey);
    if (userModel) {
      const parsed = JSON.parse(userModel);
      this.getLoggedInUserName.emit(parsed.displayName);
      return new IntakeUser({
        userId: parsed.userId,
        userName: parsed.userName,
        displayName: parsed.displayName,
        token: parsed.token,
        userRoles: parsed.roles,
        firstName: parsed.firstName,
        lastName: parsed.lastName,
        detachment: parsed.detachment,
        service: parsed.service,
        badgeNumber: parsed.badgeNumber,
        defaultMunicipalityLocationId: parsed.defaultMunicipalityLocationId,
        defaultCourtLocationId: parsed.defaultCourtLocationId
      });
    }
    return null;
  }

  // Observable wrapper around getUser()
  //
  public getUserObservable(): Observable<IntakeUser> {
    return of(this.getUser());
  }

  setRegistryAgentProfile(rsgAgentProfileModel: RsgAgentProfile): void {
    localStorage.setItem(this.rsgAgentProfileKey, JSON.stringify(rsgAgentProfileModel));
    this.getLoggedInUserName.emit(rsgAgentProfileModel.userName);
  }

  getRegistryAgentProfile(): RsgAgentProfile {
    const rsgAgentProfileModel = localStorage.getItem(this.rsgAgentProfileKey);
    if (rsgAgentProfileModel) {
      const parsed = JSON.parse(rsgAgentProfileModel);
      this.getLoggedInUserName.emit(parsed.userName);
      return new RsgAgentProfile({
        userName: parsed.userName,
        accessToken: parsed.accessToken,
        organization: parsed.organization,
        transactionId: parsed.transactionId
      });
    }
    return null;
  }

  setAdjudicationUser(userModel: AdjudicationUser): void {
    localStorage.setItem(this.userKey, JSON.stringify(userModel));
    this.getLoggedInUserName.emit(userModel.displayName);
  }

  getAdjudicationUser(): AdjudicationUser {
    const userModel = localStorage.getItem(this.userKey);
    if (userModel) {
      const parsed = JSON.parse(userModel);
      this.getLoggedInUserName.emit(parsed.displayName);
      return new AdjudicationUser({
        userId: parsed.userId,
        userName: parsed.userName,
        displayName: parsed.displayName,
        token: parsed.token,
        userRoles: parsed.roles,
        firstName: parsed.firstName,
        lastName: parsed.lastName,
        emailAddress: parsed.emailAddress
      });
    }
    return null;
  }

  setContraventionPaymentRequest(paymentRequest: any) {
    localStorage.setItem(this.contraventionPaymentRequestKey, JSON.stringify(paymentRequest));
  }

  getContraventionPaymentRequest(): any {
    return JSON.parse(localStorage.getItem(this.contraventionPaymentRequestKey));
  }

  removeContraventionPaymentRequest() {
    localStorage.removeItem(this.contraventionPaymentRequestKey);
  }

  setItem<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key));
  }

  setReviewTypes(reviewTypes: ReviewType[]): void {
    localStorage.setItem(this.reviewTypesKey, JSON.stringify(reviewTypes));
  }

  getReviewTypes(): ReviewType[] {
    return JSON.parse(localStorage.getItem(this.reviewTypesKey)) || [];
  }

  setReviewStatusTypes(reviewStatusTypes: ReviewStatusType[]): void {
    localStorage.setItem(this.reviewStatusTypesKey, JSON.stringify(reviewStatusTypes));
  }

  getReviewStatusTypes(): ReviewStatusType[] {
    return JSON.parse(localStorage.getItem(this.reviewStatusTypesKey)) || [];
  }

  setReviewMethodTypes(reviewMethodTypes: ReviewMethodType[]): void {
    localStorage.setItem(this.reviewMethodTypesKey, JSON.stringify(reviewMethodTypes));
  }

  getReviewMethodTypes(): ReviewMethodType[] {
    return JSON.parse(localStorage.getItem(this.reviewMethodTypesKey)) || [];
  }

  setReviewOutcomeTypes(reviewOutcomeTypes: ReviewOutcomeType[]): void {
    localStorage.setItem(this.reviewOutcomeTypesKey, JSON.stringify(reviewOutcomeTypes));
  }

  getReviewOutcomeTypes(): ReviewOutcomeType[] {
    return JSON.parse(localStorage.getItem(this.reviewOutcomeTypesKey)) || [];
  }

  setReviewRequest(review: any) {
    localStorage.setItem(this.reviewRequestKey, JSON.stringify(review));
  }

  getReviewRequest(): any {
    return JSON.parse(localStorage.getItem(this.reviewRequestKey));
  }

  setCreatedReview(review: any) {
    localStorage.setItem(this.createdReviewKey, JSON.stringify(review));
  }

  getCreatedReview(): any {
    return JSON.parse(localStorage.getItem(this.createdReviewKey));
  }

  setTempFolderName(folderName: string) {
    localStorage.setItem(this.tempFolderNameKey, folderName);
  }

  getTempFolderName(): any {
    return localStorage.getItem(this.tempFolderNameKey);
  }

  setStopInformationModel(stopInformation: any) {
    localStorage.setItem(`${this.stopInformationModelKey}-${this.getUser()?.userName}`, JSON.stringify(stopInformation));
  }

  getStopInformationModel(): any {
    return JSON.parse(localStorage.getItem(`${this.stopInformationModelKey}-${this.getUser()?.userName}`), this.dateReviver);
  }

  resetStopInformationModel(){
    localStorage.removeItem(`${this.stopInformationModelKey}-${this.getUser()?.userName}`);
  }

  getSearchFilters() {
    return JSON.parse(localStorage.getItem(`${this.intakeDashboardSearchFiltersKey}`), this.dateReviver)
  }

  setSearchFilters(dashboardSearch: any) {
    localStorage.setItem(`${this.intakeDashboardSearchFiltersKey}`, JSON.stringify(dashboardSearch));
  }

  clearSearchFilters(){
    localStorage.removeItem(`${this.intakeDashboardSearchFiltersKey}`);
  }

  setTicketStopInformationModel(ticketStopInformation: any) {
    localStorage.setItem(`${this.ticketStopInformationModelKey}-${this.getUser()?.userName}`, JSON.stringify(ticketStopInformation));
  }

  getTicketStopInformationModel(): any {
    return JSON.parse(localStorage.getItem(`${this.ticketStopInformationModelKey}-${this.getUser()?.userName}`), this.dateReviver);
  }

  resetTicketStopInformationModel(){
    localStorage.removeItem(`${this.ticketStopInformationModelKey}-${this.getUser()?.userName}`);
  }

  setSharedDocumentUploadRequest(sharedDocumentsUploadRequest: any) {
    localStorage.setItem(`${this.sharedDocumentUploadRequestKey}-${this.getUser()?.userName}`, JSON.stringify(sharedDocumentsUploadRequest));
  }

  getSharedDocumentUploadRequest(): any {
    return JSON.parse(localStorage.getItem(`${this.sharedDocumentUploadRequestKey}-${this.getUser()?.userName}`), this.dateReviver);
  }

  resetSharedDocumentUploadRequest(){
    localStorage.removeItem(`${this.sharedDocumentUploadRequestKey}-${this.getUser()?.userName}`);
  }

  setSharedDisclosureDocumentsUploadRequest(sharedDisclosureDocumentsUploadRequest: any) {
    localStorage.setItem(`${this.sharedDisclosureDocumentsUploadRequestKey}-${this.getUser()?.userName}`, JSON.stringify(sharedDisclosureDocumentsUploadRequest));
  }

  getSharedDisclosureDocumentsUploadRequest(): any {
    return JSON.parse(localStorage.getItem(`${this.sharedDisclosureDocumentsUploadRequestKey}-${this.getUser()?.userName}`), this.dateReviver);
  }

  resetSharedDisclosureDocumentsUploadRequest(){
    localStorage.removeItem(`${this.sharedDisclosureDocumentsUploadRequestKey}-${this.getUser()?.userName}`);
  }

  setViolationTicketContext(violationTicket: any) {
    localStorage.setItem(`${this.violationTicketKey}-${this.getUser()?.userName}`, JSON.stringify(violationTicket));
  }

  getViolationTicketContext(): any {
    return JSON.parse(localStorage.getItem(`${this.violationTicketKey}-${this.getUser()?.userName}`), this.dateReviver);
  }

  resetViolationTicketContext(){
    localStorage.removeItem(`${this.violationTicketKey}-${this.getUser()?.userName}`);
  }
  
  setLateReviewRequest(lateReviewRequest: any) {
    localStorage.setItem(this.lateReviewRequestKey, JSON.stringify(lateReviewRequest));
  }

  getLateReviewRequest(): any {
    return JSON.parse(localStorage.getItem(this.lateReviewRequestKey));
  }

  setSeizureStatusTypes(seizureStatusTypes: SeizureStatusType[]): void {
    localStorage.setItem(this.seizureStatusTypesKey, JSON.stringify(seizureStatusTypes));
  }

  getSeizureStatusTypes(): SeizureStatusType[] {
    return JSON.parse(localStorage.getItem(this.seizureStatusTypesKey));
  }

  setVehicleInterestTypes(vehicleInterestTypes: VehicleInterestType[]): void {
    localStorage.setItem(this.vehicleInterestTypesKey, JSON.stringify(vehicleInterestTypes));
  }

  getVehicleInterestTypes(): VehicleInterestType[] {
    return JSON.parse(localStorage.getItem(this.vehicleInterestTypesKey));
  }

  setEventTypes(eventTypes: EventType[]): void {
    localStorage.setItem(this.eventTypesKey, JSON.stringify(eventTypes));
  }

  getEventTypes(): EventType[] {
    return JSON.parse(localStorage.getItem(this.eventTypesKey)) || [];
  }

  setAgencies(agenciess: Agency[]): void {
    localStorage.setItem(this.agenciesKey, JSON.stringify(agenciess));
  }

  getAgencies(): Agency[] {
    return JSON.parse(localStorage.getItem(this.agenciesKey)) || [];
  }

  setDetachments(detachments: Detachment[]): void {
    localStorage.setItem(this.detachmentsKey, JSON.stringify(detachments));
  }

  getDetachments(): Detachment[] {
    return JSON.parse(localStorage.getItem(this.detachmentsKey)) || [];
  }

  setNotificationTypes(notificationTypes: NotificationType[]): void {
    localStorage.setItem(this.notificationTypesKey, JSON.stringify(notificationTypes));
  }

  getNotificationTypes(): NotificationType[] {
    return JSON.parse(localStorage.getItem(this.notificationTypesKey)) || [];
  }

  setReServeMethodTypes(reServeMethodTypes: ReServeMethodType[]): void {
    localStorage.setItem(this.reServeMethodTypesKey, JSON.stringify(reServeMethodTypes));
  }

  getReServeMethodTypes(): ReServeMethodType[] {
    return JSON.parse(localStorage.getItem(this.reServeMethodTypesKey)) || [];
  }

  setAdjudicators(adjudicators: User[]): void {
    localStorage.setItem(this.adjudicatorsKey, JSON.stringify(adjudicators));
  }

  getAdjudicators(): User[] {
    return JSON.parse(localStorage.getItem(this.adjudicatorsKey)) || [];
  }

  setRolePermissions(rolePermissions: RolePermission[]): void {
    localStorage.setItem(this.rolePermissionKey, JSON.stringify(rolePermissions));
  }

  setLocalStorageReady() {
    this.hasLocalStorageReady$.next(true);
  }

  getRolePermissions(): RolePermission[] {
    return JSON.parse(localStorage.getItem(this.rolePermissionKey)) || [];
  }

  hasPermission(resourceName:string, permissionName: string): boolean {
   return this.getRolePermissions()?.some( x=> x.resourceName===resourceName && x.permissionName===permissionName)
  }

  setIdentificationTypes(identificationTypes: IdentificationType[]): void {
    localStorage.setItem(this.identificationTypesKey, JSON.stringify(identificationTypes));
  }

  getIdentificationTypes(): IdentificationType[] {
    return JSON.parse(localStorage.getItem(this.identificationTypesKey));
  }

  setIssueServiceTypes(issueServiceTypes: IssueServiceType[]): void {
    localStorage.setItem(this.issueServiceTypesKey, JSON.stringify(issueServiceTypes));
  }

  getIssueServiceTypes(): IssueServiceType[] {
    return JSON.parse(localStorage.getItem(this.issueServiceTypesKey));
  }

  setSpeedingOffences(speedingOffences: SpeedingOffence[]): void {
    localStorage.setItem(this.speedingOffencesKey, JSON.stringify(speedingOffences));
  }

  getSpeedingOffences(): SpeedingOffence[] {
    return JSON.parse(localStorage.getItem(this.speedingOffencesKey));
  }

  setOfficerTransportationTypes(officerTransportationTypes: OfficerTransportationType[]): void {
    localStorage.setItem(this.officerTransportationTypesKey, JSON.stringify(officerTransportationTypes));
  }

  getOfficerTransportationTypes(): OfficerTransportationType[] {
    return JSON.parse(localStorage.getItem(this.officerTransportationTypesKey));
  }

  setOfficerUniformTypes(officerUniformTypes: OfficerUniformType[]): void {
    localStorage.setItem(this.officerUniformTypesKey, JSON.stringify(officerUniformTypes));
  }

  getOfficerUniformTypes(): OfficerUniformType[] {
    return JSON.parse(localStorage.getItem(this.officerUniformTypesKey));
  }

  setOfficerVehicleTypes(officerVehicleTypes: OfficerVehicleType[]): void {
    localStorage.setItem(this.officerVehicleTypesKey, JSON.stringify(officerVehicleTypes));
  }

  getOfficerVehicleTypes(): OfficerVehicleType[] {
    return JSON.parse(localStorage.getItem(this.officerVehicleTypesKey));
  }

  setSpeedingDeviceTypes(speedingDeviceTypes: SpeedingDeviceType[]): void {
    localStorage.setItem(this.speedingDeviceTypeKey, JSON.stringify(speedingDeviceTypes));
  }

  getSpeedingDeviceTypes(): SpeedingDeviceType[] {
    return JSON.parse(localStorage.getItem(this.speedingDeviceTypeKey));
  }

  setSpeedingDeviceMakeTypes(speedingDeviceMakeTypes: SpeedingDeviceMakeType[]): void {
    localStorage.setItem(this.speedingDeviceMakeTypeKey, JSON.stringify(speedingDeviceMakeTypes));
  }

  getSpeedingDeviceMakeTypes(): SpeedingDeviceMakeType[] {
    return JSON.parse(localStorage.getItem(this.speedingDeviceMakeTypeKey));
  }

  setSpeedingDeviceModelTypes(speedingDeviceModelTypes: SpeedingDeviceModelType[]): void {
    localStorage.setItem(this.speedingDeviceModelTypeKey, JSON.stringify(speedingDeviceModelTypes));
  }

  getSpeedingDeviceModelTypes(): SpeedingDeviceModelType[] {
    return JSON.parse(localStorage.getItem(this.speedingDeviceModelTypeKey));
  }

  setRoadsideAppealOutcomeTypes(roadsideAppealOutcomeTypes: RoadsideAppealOutcomeType[]): void {
    localStorage.setItem(this.roadsideAppealOutcomeTypesKey, JSON.stringify(roadsideAppealOutcomeTypes));    
  }

  getRoadsideAppealOutcomeTypes(): RoadsideAppealOutcomeType[] {
    return JSON.parse(localStorage.getItem(this.roadsideAppealOutcomeTypesKey)) || [];
  }

  setDirectionTypes(directionTypes: DirectionType[]): void {
    localStorage.setItem(this.directionTypesKey, JSON.stringify(directionTypes));    
  }

  getDirectionTypes(): DirectionType[] {
    return JSON.parse(localStorage.getItem(this.directionTypesKey)) || [];
  }

  setNoticeCancellationReasonTypes(noticeCancellationReasonTypes: NoticeCancellationReasonType[]): void {
    localStorage.setItem(this.noticeCancellationReasonTypesKey, JSON.stringify(noticeCancellationReasonTypes));    
  }

  getNoticeCancellationReasonTypes(): NoticeCancellationReasonType[] {
    return JSON.parse(localStorage.getItem(this.noticeCancellationReasonTypesKey)) || [];
  }

  setRequestDenyReasonTypes(requestDenyReasonTypes: RequestDenyReasonType[]): void {
    localStorage.setItem(this.requestDenyReasonTypesKey, JSON.stringify(requestDenyReasonTypes));    
  }

  getRequestDenyReasonTypes(): RequestDenyReasonType[] {
    return JSON.parse(localStorage.getItem(this.requestDenyReasonTypesKey)) || [];
  }


  setRoadConditionTypes(roadConditionTypes: RoadConditionType[]): void {
    localStorage.setItem(this.roadConditionTypesKey, JSON.stringify(roadConditionTypes));    
  }

  getRoadConditionTypes(): RoadConditionType[] {
    return JSON.parse(localStorage.getItem(this.roadConditionTypesKey)) || [];
  }

  setRoadSurfaceTypes(roadSurfaceTypes: RoadSurfaceType[]): void {
    localStorage.setItem(this.roadSurfaceTypesKey, JSON.stringify(roadSurfaceTypes));    
  }

  getRoadSurfaceTypes(): RoadSurfaceType[] {
    return JSON.parse(localStorage.getItem(this.roadSurfaceTypesKey)) || [];
  }

  setWeatherConditionTypes(weatherConditionTypes: WeatherConditionType[]): void {
    localStorage.setItem(this.weatherConditionTypesKey, JSON.stringify(weatherConditionTypes));    
  }

  getWeatherConditionTypes(): WeatherConditionType[] {
    return JSON.parse(localStorage.getItem(this.weatherConditionTypesKey)) || [];
  }

  setVisibilityConditionTypes(visibilityConditionTypes: VisibilityConditionType[]): void {
    localStorage.setItem(this.visibilityConditionTypesKey, JSON.stringify(visibilityConditionTypes));    
  }

  getVisibilityConditionTypes(): VisibilityConditionType[] {
    return JSON.parse(localStorage.getItem(this.visibilityConditionTypesKey)) || [];
  }

  setJudicialReviewDecisionTypes(judicalReviewDecisionTypes: JudicalReviewDecisionType[]): void {
    localStorage.setItem(this.judicialReviewDecisionTypesKey, JSON.stringify(judicalReviewDecisionTypes));    
  }

  getJudicialReviewDecisionTypes(): JudicalReviewDecisionType[] {
    return JSON.parse(localStorage.getItem(this.judicialReviewDecisionTypesKey)) || [];
  }

  setStayOrderStatusTypes(stayOrderStatusTypes: StayOrderStatusType[]): void {
    localStorage.setItem(this.stayOrderStatusTypesKey, JSON.stringify(stayOrderStatusTypes));    
  }

  getStayOrderStatusTypes(): StayOrderStatusType[] {
    return JSON.parse(localStorage.getItem(this.stayOrderStatusTypesKey)) || [];
  }

  setConditionCodeTypes(conditionCodeTypes: ConditionCodeType[]): void {
    localStorage.setItem(this.conditionCodeTypesKey, JSON.stringify(conditionCodeTypes));    
  }

  getConditionCodeTypes(): ConditionCodeType[] {
    return JSON.parse(localStorage.getItem(this.conditionCodeTypesKey)) || [];
  }

  setVehicleStatusTypes(vehicleStatusTypes: VehicleStatusType[]): void {
    localStorage.setItem(this.vehicleStatusTypesKey, JSON.stringify(vehicleStatusTypes));
  }

  getVehicleStatusTypes(): VehicleStatusType[] {
    return JSON.parse(localStorage.getItem(this.vehicleStatusTypesKey));
  }

  setDeclarationTypes(declarationTypes: DeclarationType[]): void {
    localStorage.setItem(this.declarationTypesKey, JSON.stringify(declarationTypes));
  }

  getDeclarationTypes(): DeclarationType[] {
    return JSON.parse(localStorage.getItem(this.declarationTypesKey));
  }

  setReviewCancellationReasonTypes(reviewCancellationReasonTypes: ReviewCancellationReasonType[]): void {
    localStorage.setItem(this.reviewCancellationReasonTypesKey, JSON.stringify(reviewCancellationReasonTypes));
  }

  getReviewCancellationReasonTypes(): ReviewCancellationReasonType[] {
    return JSON.parse(localStorage.getItem(this.reviewCancellationReasonTypesKey));
  }

  setReviewCancellationSecondaryReasonTypes(reviewCancellationSecondaryReasonTypes: ReviewCancellationSecondaryReasonType[]): void {
    localStorage.setItem(this.reviewCancellationSecondaryReasonTypesKey, JSON.stringify(reviewCancellationSecondaryReasonTypes));
  }

  getReviewCancellationSecondaryReasonTypes(): ReviewCancellationSecondaryReasonType[] {
    return JSON.parse(localStorage.getItem(this.reviewCancellationSecondaryReasonTypesKey));
  }

  setReviewCancellationReasonCategoryTypes(reviewCancellationReasonCategoryTypes: ReviewCancellationReasonCategoryType[]): void {
    localStorage.setItem(this.reviewCancellationReasonCategoryTypesKey, JSON.stringify(reviewCancellationReasonCategoryTypes));
  }

  getReviewCancellationReasonCategoryTypes(): ReviewCancellationReasonCategoryType[] {
    return JSON.parse(localStorage.getItem(this.reviewCancellationReasonCategoryTypesKey));
  }

  setEnableLicencePickupModal(enableLicencePickupModal: boolean): void {
    localStorage.setItem(this.enableLicencePickupModalKey, enableLicencePickupModal.toString());
  }

  getEnableLicencePickupModal(): boolean {
    return (localStorage.getItem(this.enableLicencePickupModalKey) === 'true');
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  //
  // Offline contravention/seizure related functions
  //
  setOfflineNumbers(offlineNumbers: string[]): void {
    localStorage.setItem(this.offlineNumbersKey, JSON.stringify(offlineNumbers));    
  }

  setOfflineSeizureNumbers(offlineSeizureNumbers: string[]): void {
    localStorage.setItem(this.offlineSeizureNumbersKey, JSON.stringify(offlineSeizureNumbers));    
  }

  getOfflineNumbers(): string[] {
    return JSON.parse(localStorage.getItem(this.offlineNumbersKey)) || [];
  }

  getOfflineSeizureNumbers(): string[] {
    return JSON.parse(localStorage.getItem(this.offlineSeizureNumbersKey)) || [];
  }

  getNextOfflineNumber(): string {
    let numbers = this.getOfflineNumbers();
    if (numbers.length>0) {
      return numbers[0];
    }

    return "";
  }

  getNextOfflineSeizureNumber(): string {
    let numbers = this.getOfflineSeizureNumbers();
    if (numbers.length>0) {
      return numbers[0];
    }

    return "";
  }

  removeOfflineNumber(offlineNumber: string) {
    let numbers = this.getOfflineNumbers();
    if (numbers.length>0) {
      let newArray = numbers.filter(function(n) { 
        return n != offlineNumber; 
      });
      this.setOfflineNumbers(newArray);
    }
  }

  removeOfflineSeizureNumber(offlineSeizureNumber: string) {
    let numbers = this.getOfflineSeizureNumbers();
    if (numbers.length>0) {
      let newArray = numbers.filter(function(n) { 
        return n != offlineSeizureNumber; 
      });
      this.setOfflineSeizureNumbers(newArray);
    }
  }

  setCurrentOfflineId(key: string) {
    localStorage.setItem(this.currentOfflineIdKey, key);
  }

  getCurrentOfflineId(): string {
    return localStorage.getItem(this.currentOfflineIdKey);
  }

  clearCurrentOfflineId() {
    localStorage.removeItem(this.currentOfflineIdKey);
  }

  setIntakeAppProcess(process: any) {
    localStorage.setItem(this.intakeAppProcessKey, process.toString());
  }

  getIntakeAppProcess(): IntakeAppProcess {
    let p = localStorage.getItem(this.intakeAppProcessKey);
    if (p===null || p===undefined)
      return 1;
    
    return +p;
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////

  setPayCentreTypes(payCentreTypes: PayCentreType[]): void {
    localStorage.setItem(this.payCentreTypesKey, JSON.stringify(payCentreTypes));    
  }

  getPayCentreTypes(): PayCentreType[] {
    return JSON.parse(localStorage.getItem(this.payCentreTypesKey)) || [];
  }

  setHighwayCharacteristicsTypes(highwayCharacteristicsTypes: HighwayCharacteristicsType[]): void {
    localStorage.setItem(this.highwayCharacteristicsTypesKey, JSON.stringify(highwayCharacteristicsTypes));
  }

  getHighwayCharacteristicsTypes(): HighwayCharacteristicsType[] {
    return JSON.parse(localStorage.getItem(this.highwayCharacteristicsTypesKey));
  }

  setCourtTypes(courtTypes: CourtType[]): void {
    localStorage.setItem(this.courtTypesKey, JSON.stringify(courtTypes));
  }

  getCourtTypes(): CourtType[] {
    return JSON.parse(localStorage.getItem(this.courtTypesKey));
  }

  setOffenceTypes(offenceTypes: OffenceType[]): void {
    localStorage.setItem(this.offenceTypesKey, JSON.stringify(offenceTypes));
  }

  getOffenceTypes(): OffenceType[] {
    return JSON.parse(localStorage.getItem(this.offenceTypesKey));
  }

  setTicketMethodTypes(ticketMethodTypes: TicketMethodType[]): void {
    localStorage.setItem(this.ticketMethodTypesKey, JSON.stringify(ticketMethodTypes));
  }

  getTicketMethodTypes(): TicketMethodType[] {
    return JSON.parse(localStorage.getItem(this.ticketMethodTypesKey));
  }

  setTicketWithdrawalReasonTypes(ticketWithdrawalReasonTypes: TicketWithdrawalReasonType[]): void {
    localStorage.setItem(this.ticketWithdrawalReasonTypesKey, JSON.stringify(ticketWithdrawalReasonTypes));
  }

  getTicketWithdrawalReasonTypes(): TicketWithdrawalReasonType[] {
    return JSON.parse(localStorage.getItem(this.ticketWithdrawalReasonTypesKey)) || [];
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  get(key: string) {
      return localStorage.getItem(key);
  }

  remove(key: string) {
      localStorage.removeItem(key);
  }

  logout()
  {
    localStorage.clear();
    this.getLoggedInUserName.emit(null);
  }

  clearAll(): void {
    localStorage.clear();
  }

  private dateReviver(key, value) {
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (typeof value === "string" && dateFormat.test(value)) {
      return new Date(+value.substring(0, 4), +value.substring(5, 7)-1, +value.substring(8, 10));
    }
    return value;
  }
}
