

import { NoticeCancellationRequestMapping } from './notice-cancellation-request-mapping.model';
import { Event } from './event.model';
import { Document } from './document.model';
import { Officer } from './officer.model';

export class NoticeCancellationRequest {
    public noticeCancellationRequestId: number = 0;
    public noticeCancellationRequestNumber: string;
    public noticeCancellationReasonTypeId: number = null;
    public contraventionTypeId: number;
    public vehicleSeizureTypeId: number;
    public noticeCancellationReasonNotes: string;
    public requestStatusTypeId: number = 0;
    public requestDate: Date = new Date();
    public requestDecisionTypeId: number;
    public decisionDate: Date;
    public requestDenyReasonNotes: string;
    public requestingOfficerId: number;
    public requestingOfficer: Officer;
    public events: Event[] = [];
    public documents: Document[] = [];
    public noticeCancellationRequestMappings: NoticeCancellationRequestMapping[] = [];
    public supervisingOfficerLastName: string;
    public supervisingOfficerFirstName:string;
    public requestDenyReasonTypeId:number;
    public recipientName: string;
    public issueDate: Date;
    public issuingAgencyCode: string;
    public issuingDetachmentName: string;
    public issuingOfficerName: string;
    public issuingOfficerRegimentalNumber: string;


    //Non DB fields
    public tempFileFolder: string;

    constructor(init?: Partial<NoticeCancellationRequest>) {
        
        Object.assign(this, init);
    }
}
