import { BrowserModule } from '@angular/platform-browser';
import { Inject, Injectable, NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { APP_CONFIG } from '@apis/app-config';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { SharedModule } from '@apis/shared/shared.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContraventionModule } from './contravention/contravention.module';
import { IntakeService } from '../shared/services/intake.service';
import { ConnectivityService } from '../shared/services/connectivity.service';
import { MovesService } from '../shared/services/moves.service';
import { DriversLicenceScanningService } from '../shared/services/driverslicencescanning.service';
import { SettingService } from '../shared/services/setting.service';
import { LookupService } from '../shared/services/lookup.service';
import { DocumentService } from '../shared/services/document.service';
import { OfficerService } from '../shared/services/officer.service';
import { SystemNameHeaderComponent } from '../shared/components/system-name-header/system-name-header.component';
import { IntakeHeaderComponent } from '../shared/components/header/intake-header.component';
import { KeycloakAngularModule } from 'keycloak-angular';
import { KeycloakService } from 'keycloak-angular';
import {initializeKeycloak} from '../shared/security/keycloak.init';
import { APP_INITIALIZER } from '@angular/core';
import { CustomHttpInterceptor } from '../shared/interceptors/custom-http-interceptor';
import { SpinnerHttpInterceptor } from 'libs/shared/interceptors/spinner-http-interceptor';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { ScheduledMaintenanceComponent } from  './scheduled-maintenance/scheduled-maintenance.component';
import { AccountActivationPendingComponent } from './account-activation-pending/account-activation-pending.component';
import { ReportsComponent } from './reports/reports.component';
import { ContraventionAnalyticsComponent } from './reports/contravention-analytics/contravention-analytics.component';
import { SeizureNoticesReportComponent } from './reports/seizure-notices/seizure-notices-report.component';
import { ReportService } from '../shared/services/report.service';
import { UserDashboardComponent } from './dashboard/user-dashboard.component';
import { CancelContraventionModalComponent } from './dashboard/modals/cancel-contravention-modal/cancel-contravention-modal.component';
import { CancelIrsSeizureConfirmationModalComponent  } from './dashboard/modals/cancel-irs-seizure-confirmation-modal/cancel-irs-seizure-confirmation-modal.component';
import { CancelMultipleContraventionsModalComponent } from './dashboard/modals/cancel-multiple-contraventions-modal/cancel-multiple-contraventions-modal.component';
import { IntakeAuthGuard } from './../shared/security/auth.guard';
import { IntakeFooterComponent } from '../shared/components/footer/intake-footer.component';
import { HelpAndResourcesComponent } from './help-and-resources/help-and-resources.component';
import { CancelledContraventionsReportComponent } from './reports/cancelled-contraventions/cancelled-contraventions-report.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DisclosureService } from '../shared/services/disclosure.service';
import { DisclosureModule } from './disclosure/disclosure.module';
import { TicketService } from '../shared/services/ticket.service';
import { WithdrawTrafficTicketsModalComponent } from './dashboard/modals/withdraw-traffic-tickets-modal/withdraw-traffic-tickets-modal.component';
import { IntakeDisclosureUploadComponent } from '../shared/components/disclosure-upload/intake-disclosure-upload.component';
import { TrafficDisclosureUploadComponent } from './disclosure/traffic-disclosure-upload/traffic-disclosure-upload.component';
import { DirectiveModule } from '../shared/directives/directive.module';
import { TrafficTicketsReportComponent } from './reports/traffic-tickets/traffic-tickets-report.component';
import { CancelledTicketsReportComponent } from './reports/cancelled-tickets/cancelled-tickets-report.component';

@NgModule({
  declarations: [
    AppComponent,    
    PageNotFoundComponent,
    SystemNameHeaderComponent,
    IntakeHeaderComponent,
    UserDashboardComponent,
    CancelContraventionModalComponent,
    CancelIrsSeizureConfirmationModalComponent,
    InternalServerErrorComponent,
    ScheduledMaintenanceComponent,
    AccountActivationPendingComponent,
    ReportsComponent,
    ContraventionAnalyticsComponent,
    SeizureNoticesReportComponent,
    CancelMultipleContraventionsModalComponent,
    IntakeFooterComponent,
    HelpAndResourcesComponent,
    CancelledContraventionsReportComponent,
    WithdrawTrafficTicketsModalComponent,
    IntakeDisclosureUploadComponent,
    TrafficDisclosureUploadComponent,
    TrafficTicketsReportComponent,
    CancelledTicketsReportComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,    
    SharedModule,
    HttpClientModule,    
    FlexLayoutModule, 
    RecaptchaV3Module,      
    ContraventionModule,
    KeycloakAngularModule,
    DisclosureModule,
    DirectiveModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: APP_CONFIG, useValue: environment},
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '_siteKey' }, 
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerHttpInterceptor,
      multi: true
    },
    ConnectivityService,
    IntakeService,
    MovesService,
    DriversLicenceScanningService,
    LookupService,
    DocumentService,
    OfficerService,
    SettingService,
    ReportService,
    IntakeAuthGuard,
    DisclosureService,
    TicketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
