<div class="d-flex flex-column h-100" *ngIf="!isProcessing">
  <intake-header></intake-header>
  <app-system-name-header></app-system-name-header>
  <div class="submission-report-header">
    <div class="report-header">Advanced Projects Information System</div>
  </div>  
  <div class="flex-fill d-flex justify-content-center">       
    <router-outlet></router-outlet>    
  </div>
  <intake-footer class="intake-footer"></intake-footer>
</div>
<ngx-spinner *ngIf="isProcessing" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white"> Loading System. Please wait... </p></ngx-spinner>
<ngx-spinner name="downloadDocumentSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white"> Loading... </p></ngx-spinner>