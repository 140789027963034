import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';
import { CommonUtil } from '../helpers/common-util';

@Directive({
    selector: '[appEmergencySeizureNumberValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: EmergencySeizureNumberValidatorDirective,
        multi: true
    }]
})
export class EmergencySeizureNumberValidatorDirective implements Validator {
    @Input('appEmergencySeizureNumberValidator') isRequired: boolean;
    
    validate(control: AbstractControl) {
        if (this.isRequired == null || this.isRequired){
            const seizureNumberRegEx = new RegExp('^[1][0]{4}[0-9]{12}$');
            
            if (seizureNumberRegEx.test(control.value)) { //Basic Format Ok
                var lastTenDigits = +control.value.slice(control.value.length - 10);
                                
                //Range Check
                if (lastTenDigits < 1001888011 || lastTenDigits > 1002000000 || !CommonUtil.isValidSeizureNumber(control.value))
                    return {'invalidSeizureNumber': true};

                return null; 
            }
            else
                return {'invalidSeizureNumber': true};
        }
        else
            return null;    
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('isRequired' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }

}