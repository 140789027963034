<div class="cancel-modal-overlay">
    <div class="cancel-modal">
        <div class="cancel-modal-header">
            <div class="cancel-modal-title">Cancel IRS Seizure</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="cancellation-alert">
            <div class="alert-icon">                
                <i class="fas fa-exclamation-triangle fa-lg"></i>
            </div>
            <div class="cancellation-alert-text">
                <span class="bold-text">Are you sure you want to cancel only the IRS Seizure?</span>
                <br />
                <span>{{ notice.recordNumber }}  {{ notice.vehicleSeizure?.contraventionTypeName }}   {{ notice.vehicleSeizure?.recipientName }}</span>
            </div>    
        </div>                 
        <div class="button-container">
            <div id="btnCancelModalCancel" class="cancel-button" (click)="onCancelClick()" >No, I want to cancel the Contravention too</div>
            <div id="btnCancelModalConfirm" class="confirm-button" (click)="onConfirmClick()">Yes, cancel just the Seizure</div>
        </div>
    </div>
</div>