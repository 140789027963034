


export class SpeedingDeviceModelType {
    public speedingDeviceMakeTypeId: number = 0;
    public id: number = 0;
    public code: string;
    public name: string;
    public description: string;
    public displayOrder: number = 0;
    public effectiveDate: Date = new Date();
    public expiryDate: Date;

    constructor(init?: Partial<SpeedingDeviceModelType>) {
        
        Object.assign(this, init);
    }
}
