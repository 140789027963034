import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Document } from '@apis/models/document.model';
import { environment } from "apps/intake/src/environments/environment";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { User } from '@apis/shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    public apiBaseUrl: string = `${environment.apiUrl}`;
    private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
    private user: User;
    
    constructor(public http: HttpClient,
        public localStorageService: LocalStorageService){}


    public getWipContainerSasUri(): Observable<any> {
        this.user = this.localStorageService.getUser();
        return this.http.get(`${this.apiUrl}/document/wip/sasuri`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
        .pipe(
            map(response => response)
        );
    }

    public getDocumentSasUri(tempFileFolder: string, documentRefTypeName: string, documentRefTypeNumber: string, storageFileName: string, applicationFileName: string): Observable<any> {
        this.user = this.localStorageService.getUser();
        return this.http.get(`${this.apiUrl}/document/sasuri`, {
            params: {
                "tempFileFolder": tempFileFolder,
                "documentRefTypeName": documentRefTypeName,
                "documentRefTypeNumber": documentRefTypeNumber,
                "storageFileName": storageFileName,
                "applicationFileName": applicationFileName
            },
            headers: {'Authorization': `Bearer ${this.user.token}`}
        })
        .pipe(
            map(response => response)
        );
    }

    //File Upload Methods
    public uploadDocumentAsync(file: File, tempFileFolder: string, newFileName: string): Observable<any> {
        let formData = new FormData();
        formData.append("file", file, file.name);
        this.user = this.localStorageService.getUser();

        return this.http.post(`${this.apiUrl}/document`, formData, 
            {        
                observe: "events", 
                reportProgress: true,
                params:{
                    "newFileName": newFileName,
                    "tempFileFolder": tempFileFolder
                },
                headers: {'Authorization': `Bearer ${this.user.token}`} 
            }
        );
    }

    public downloadDocument(tempFileFolder: string, documentRefTypeName: string, documentRefTypeNumber: string, storageFileName: string, applicationFileName: string): Observable<any> {
        this.user = this.localStorageService.getUser();
        return this.http.get(`${this.apiUrl}/document`, {
            params: {
                "tempFileFolder": tempFileFolder,
                "documentRefTypeName": documentRefTypeName,
                "documentRefTypeNumber": documentRefTypeNumber,
                "storageFileName": storageFileName,
                "applicationFileName": applicationFileName
            },
            headers: {'Authorization': `Bearer ${this.user.token}`},
            responseType: 'blob'
          });
    }

    public downloadOneDocument(stopId: number, isOnlyIRS: boolean): Observable<any> {
        this.user = this.localStorageService.getUser();
        return this.http.get(`${this.apiUrl}/document/one`, {
            params: {
                "stopid": stopId.toString(),
                "isOnlyIRS": isOnlyIRS
            },
            headers: {'Authorization': `Bearer ${this.user.token}`},
            responseType: 'blob'
          });
    }

    public deleteWipDocument(fileName: string, tempFileFolder: string): Observable<any> {
        this.user = this.localStorageService.getUser();
        return this.http.delete(`${this.apiUrl}/document/wip`, {
            params: {
                "fileName": fileName,
                "tempFileFolder": tempFileFolder
            },
            headers: {'Authorization': `Bearer ${this.user.token}`} 
          });
    }

    public deleteDocument(fileName: string, tempFileFolder: string): Observable<any> {
        this.user = this.localStorageService.getUser();
        return this.http.delete(`${this.apiUrl}/document`, {
            params: {
                "fileName": fileName,
                "tempFileFolder": tempFileFolder
            },
            headers: {'Authorization': `Bearer ${this.user.token}`} 
          });
    }

    public finalizeDocuments(tempFileFolder: string, documentRefTypeName: string, documentRefTypeNumber: string): Observable<any> {
        this.user = this.localStorageService.getUser();
        return this.http.get(`${this.apiUrl}/document/finalizefiles`, {        
                params:{
                    "tempFileFolder": tempFileFolder,
                    "documentRefTypeName": documentRefTypeName,
                    "documentRefTypeNumber": documentRefTypeNumber
                },
                headers: {'Authorization': `Bearer ${this.user.token}`}   
            },
        );
    }

    public updateDocumentAsync(document: Document): Observable<any> {
        return this.put('document', {document});
    }

    put(action: string, body: Object = {}): Observable<any> {
        this.user = this.localStorageService.getUser();
        const httpOptions = {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.user.token}`
                })
          }

        for (var key in body) {
            if (!Object.prototype.hasOwnProperty.call(body, key)) continue;
            body = body[key];
            break;
        }
        var newJson = JSON.stringify(body);
        
        return this.http
          .put(this.apiUrl + '/' + action, newJson, httpOptions)
          .pipe(catchError(this.formatErrors));
    }

    private formatErrors(error: any) {
        return throwError(error);
    }  
}
