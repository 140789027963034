import { Injectable } from "@angular/core";
import { TransferProgressEvent } from "@azure/core-http";
import { BlobClient, BlobServiceClient, BlobUploadCommonResponse, BlockBlobClient, ContainerClient } from "@azure/storage-blob";
import { Observable, Subscriber } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { DisclosureUploadOptions } from "../models/disclosure-upload-options.model";


@Injectable({
  providedIn: 'root'
})
export class StorageService {

    uploadFile(sasUrl: string, uploadOptions: DisclosureUploadOptions, file: File) {
        const containerClient = new ContainerClient(sasUrl);
        const blockBlobClient = containerClient.getBlockBlobClient (uploadOptions.fullName);

        return new Observable<number>(observer => {
          blockBlobClient
            .uploadData(file, {
              abortSignal: uploadOptions.abortController.signal,
              metadata: uploadOptions.metadata,
              blockSize: 4 * 1024 * 1024, // 4 MiB max block size
              concurrency: 2, // maximum number of parallel transfer workers
              maxSingleShotSize: 8 * 1024 * 1024, // 8 MiB initial transfer size
              onProgress: this.onProgress(observer),
              blobHTTPHeaders: {
                blobContentType: file.type,
                blobContentDisposition: `filename="${uploadOptions.metadata.originalname}"`
              }
            })
            .then(
              this.onUploadComplete(observer, file),
              this.onUploadError(observer)
            );
        }).pipe(distinctUntilChanged());
      }

      private onUploadError(observer: Subscriber<number>) {
        return (error: any) => observer.error(error);
      }
    
      private onUploadComplete(observer: Subscriber<number>, file: File) {
        return () => {
          observer.next(file.size);
          observer.complete();
        };
      }
    
      private onProgress(observer: Subscriber<number>) {
        return (progress: TransferProgressEvent) =>
          observer.next(progress.loadedBytes);
      }

}