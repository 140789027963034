<div class="d-flex flex-column">
    <div class="form-group row">
        <div class="col-12 col-sm-12">
           <h1 class="pl-2 pl-md-0">Upload Traffic Disclosure</h1>
        </div>
    </div>

    <div class="card my-2" *ngIf="isApisIssuedTicket">
        <div class="container d-flex justify-content-left">
            <div class="row callout">
                <div class="col-md-1 callout-logo d-flex align-items-center">
                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2"
                        fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                        <path
                            d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                    </svg>
                </div>
                <div class="col-md-11 mt-4 mb-2">
                    <h4>
                        Any disclosure uploaded to this violation ticket will be added to other violation tickets issued to the same accused from the same traffic stop.
                    </h4>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-2 mt-lg-4">
        <div class="card-header">
            Violation Ticket
        </div>
        <div class="card-body form-group">
            <ng-template [ngIf]="sharedDisclosureDocumentsUploadRequest?.ticketView != null" [ngIfElse]="multiTickets">
                <div class="row">
                    <div class="col">
                        <div>Ticket Number</div>
                        <div class="value">{{sharedDisclosureDocumentsUploadRequest?.ticketView.ticketNumber}}</div>
                    </div>
                    <div class="col">
                        <div>Charge</div>
                        <div class="value">{{sharedDisclosureDocumentsUploadRequest?.ticketView.ticketDescription}}</div>
                    </div>
                    <div class="col">
                        <div>Accused Name</div>
                        <div class="value">{{sharedDisclosureDocumentsUploadRequest?.ticketView.accusedName}}</div>
                    </div>
                    <div class="col">
                        <div>Offence Date</div>
                        <div class="value">{{sharedDisclosureDocumentsUploadRequest?.ticketView.offenceDate | date:'mediumDate'}}</div>
                    </div>
                </div>
            </ng-template>
            <ng-template #multiTickets>
                <label>
                    {{sharedDisclosureDocumentsUploadRequest.ticketNumbers.join(", ") }}
                </label>         
            </ng-template>
        </div>
    </div>

    <form #uploadForm="ngForm">
        <div class="card mt-2 mt-lg-4">
            <div class="card-header">
                Add Disclosures
            </div>            
            <div class="card-body">
                <div class="form-check mb-3">
                    <input #chkDisclosurePreviouslyProvided="ngModel" [(ngModel)]="sharedDisclosureDocumentsUploadRequest.isDisclosurePreviouslyProvided"
                        type="checkbox" id="chkDisclosurePreviouslyProvided" name="chkDisclosurePreviouslyProvided"
                        class="form-check-input mb-0">
                    <label class="form-check-label mb-0" for="chkDisclosurePreviouslyProvided">
                        Check if disclosure was previously provided manually
                    </label>
                </div>
                <div *ngFor="let doc of sharedDisclosureDocumentsUploadRequest.disclosureDocuments; let i = index; trackBy: trackDocument">
                    <intake-disclosure-upload [document]="doc" [additionalDocumentTypes]="additionalDocumentTypes"
                        (onCancelEvent)="onCancelDcoument($event)"></intake-disclosure-upload>
                </div>
                <div class="form-group row">
                    <div class="col-lg-12">
                        <button class="btn button-secondary-outline float-right" (click)="AddAdditionalDocument()">Add
                            Additional Disclosure</button>
                    </div>
                </div>
                <div *ngIf="errorMessage.length > 0">
                    <span class="error">{{errorMessage}}</span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="row mt-4">
                <div class="col-12">
                    <label class="w-100">Is this complete disclosure?<span class="label-hint">(Required)</span></label>
                    <div class="custom-control custom-radio">
                        <input required type="radio" class="custom-control-input" id="disclosurePendingNo" name="disclosurePendingNo" #disclosurePendingNoControl="ngModel" [value]="0" 
                        [(ngModel)]="sharedDisclosureDocumentsUploadRequest.isDisclosurePending" [ngClass]="{'is-invalid':(disclosurePendingYesControl.touched || disclosurePendingNoControl.touched || courtDocsPendingYesControl.touched || isSubmitClicked) && disclosurePendingNoControl.invalid}">
                        <label class="custom-control-label" for="disclosurePendingNo">Yes, all disclosure has been provided</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input required type="radio" class="custom-control-input" id="courtDocsPendingYes" name="courtDocsPendingYes" #courtDocsPendingYesControl="ngModel" [value]="2" 
                        [(ngModel)]="sharedDisclosureDocumentsUploadRequest.isDisclosurePending" [ngClass]="{'is-invalid':(disclosurePendingYesControl.touched || disclosurePendingNoControl.touched || courtDocsPendingYesControl.touched || isSubmitClicked) && courtDocsPendingYesControl.invalid}">
                        <label class="custom-control-label" for="courtDocsPendingYes">Yes, except for required court documents (for trial)</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input required type="radio" class="custom-control-input" id="disclosurePendingYes" name="disclosurePendingYes" #disclosurePendingYesControl="ngModel" [value]="1" 
                        [(ngModel)]="sharedDisclosureDocumentsUploadRequest.isDisclosurePending" [ngClass]="{'is-invalid':(disclosurePendingYesControl.touched || disclosurePendingNoControl.touched || courtDocsPendingYesControl.touched || isSubmitClicked) && disclosurePendingYesControl.invalid}">
                        <label class="custom-control-label" for="disclosurePendingYes">No, there are additional disclosure not yet uploaded</label>
                    </div>
                    <div *ngIf="(disclosurePendingYesControl.touched || disclosurePendingNoControl.touched || courtDocsPendingYesControl.touched || isSubmitClicked) && disclosurePendingNoControl.invalid">
                        <div class="error">Please select one</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="!isApisIssuedTicket">
            <div class="row mt-4">
                <div class="col-12">
                    <label class="w-100">Are you the issuing officer <span class="label-hint">(Required)</span></label>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input required type="radio" class="custom-control-input" id="issuingOfficerYes" name="issuingOfficerYes" #issuingOfficerYesControl="ngModel" [value]="true" [(ngModel)]="isIssuingOfficer" [ngClass]="{'is-invalid':(issuingOfficerYesControl.touched || issuingOfficerNoControl.touched || isSubmitClicked) && issuingOfficerYesControl.invalid}">
                        <label class="custom-control-label" for="issuingOfficerYes">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input required type="radio" class="custom-control-input" id="issuingOfficerNo" name="issuingOfficerNo" #issuingOfficerNoControl="ngModel" [value]="false" [(ngModel)]="isIssuingOfficer" [ngClass]="{'is-invalid':(issuingOfficerYesControl.touched || issuingOfficerNoControl.touched || isSubmitClicked) && issuingOfficerNoControl.invalid}">
                        <label class="custom-control-label" for="issuingOfficerNo">No</label>
                    </div>
                    <div *ngIf="(issuingOfficerYesControl.touched || issuingOfficerNoControl.touched || isSubmitClicked) && issuingOfficerNoControl.invalid">
                        <div class="error">Please select one</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-4 mb-4">
            <div class="container d-flex justify-content-left">
                <div class="row callout">
                    <div class="col-md-1 callout-logo">
                        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2"
                            fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                            <path
                                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                        </svg>
                    </div>
                    <div class="col-md-11 mt-4 mb-4">
                        <h5 class="heading">Disclosure Declaration <span class="label-hint">(Required)</span></h5>
                        <div class="form-check mt-2">
                            <div class="custom-control custom-checkbox pl-1">
                                <input required #chkDisclosureDeclaration="ngModel" [(ngModel)]="isTrueInformation"
                                    type="checkbox" id="chkDisclosureDeclaration" name="chkDisclosureDeclaration"
                                    class="custom-control-input"
                                    [ngClass]="{'is-invalid': (chkDisclosureDeclaration.touched || isSubmitClicked) && chkDisclosureDeclaration.invalid}">
                                <label class="custom-control-label section-label" for="chkDisclosureDeclaration" *ngIf="isIssuingOfficer">
                                    I confirm that the contents of the report(s), notes or other documents are true, and any photograph(s), audio recording(s), and video(s) attached have not been altered in any material way. This confirmation is based on my personal knowledge except in the case of the notes, reports, photos or videos taken or produced by other officers or other persons acting in their professional capacity in which case the confirmation is based on information and belief from that officer or person that the contents of their documents or records are true and any photograph(s) and video(s) attached have not been altered in any material way
                                </label>
                                <label class="custom-control-label section-label" for="chkDisclosureDeclaration" *ngIf="!isIssuingOfficer">
                                    I confirm these uploaded report(s), notes, any photograph(s), video(s) or other documents attached are a true copy of what was provided by the officer and were uploaded without altering.
                                </label>
                                <div class="invalid-feedback">
                                    <div>Disclosure Declaration must be checked</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="row pt-3">
                <div class="col-12 col-sm-6">                
                    <a class="btn button-secondary btn-block" [routerLink]="['/']">Back to Dashboard</a>
                </div>
                <div class="col-12 col-sm-6 text-right">                
                    <button class="btn button-primary btn-block" (click)="onSubmitClick(uploadForm.valid)">Review & Confirm Disclosure</button>   
                </div>
            </div>
        </div>
    </form>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>