import { Injectable } from '@angular/core';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { Observable, Operator, of } from 'rxjs';
import { OperatorInformation } from '@apis/shared/models/operator-information.model';


@Injectable({ providedIn: 'root' })
export class DriversLicenceScanningService {
    scanneddata: string = 'reading...';
    reader: ReadableStreamDefaultReader;
    flushStreamReader: ReadableStreamDefaultReader;
    decoder = new TextDecoder();
    incoming = new Uint8Array();
    endOfDataStream: boolean = false;
    serialport: any = null;
    linesRead: number = 0;

    constructor() {
        this.initilizePorts();
    }
    
    async initilizePorts() {
        (navigator as any).serial.addEventListener("connect", async (event) => {
            await this.deviceConnected();
          });
      
          (navigator as any).serial.addEventListener("disconnect", async (event) => {
            await this.deviceDisConnected();
          });
    }
    
    async deviceConnected()
    {
      await this.onPortSelect();
    }

    async deviceDisConnected()
    {
      this.reader.releaseLock();
      this.serialport = null;
    }

    async onCancelScanning()
    {
      if(this.flushStreamReader)
          await this.flushStreamReader.cancel();

        if(this.reader)
          await this.reader.cancel();
        
        this.endOfDataStream = true;
    }

    async onPortSelect(): Promise<string> {
        if('serial' in navigator)
        {
          
          try{
            if(!this.serialport || this.serialport === undefined){
                // Filter compatible devices, ex. M260 eSeek USB Vendor/Product IDs.
                const filters = [
                    { usbVendorId: 0x0403, usbProductId: 0x6001 }
                  ];
                this.serialport = await (navigator as any).serial.requestPort();
                await this.serialport.open({ baudRate: 9600, bufferSize: 51000 });
                return "";
            }         
          } catch(err) {
              this.serialport = null;
              return err;            
          }
        }
        else{
          return "Web serial doesn\'t seem to be available in your browser.";          
        }
    }

    appendBuffer(arraybuffer:Uint8Array) {
        var tmp = new Uint8Array(this.incoming.length + arraybuffer.length);
        tmp.set(this.incoming, 0);
        tmp.set(new Uint8Array(arraybuffer), this.incoming.length);
        this.incoming = tmp;
    }
    
    setEndReadStreamTrigger()
    {
      if(this.linesRead == 0) {
      setTimeout(() => {
        this.reader.cancel();
      }, 2000);
     }
    }

    async flushPort()
    {
      if(this.serialport)
      {
        if(this.serialport.readable.locked)
        {
          await this.serialport.readable.cancel();
        }
        this.flushStreamReader = this.serialport.readable.getReader();
       
        // This is allowed, even if one or more read() promises are still pending.
        // this will read the reader input queue and make it clear to hold the data for the last scan
        //otherwise the queue will hold the data for all previous scans
        const promise1 = await this.flushStreamReader.read();
        this.flushStreamReader.releaseLock();
      }
    }   

    async onDataRead(): Promise<OperatorInformation>{
        if(!this.serialport || this.serialport === undefined)
        {
            var error = await this.onPortSelect();
            if(error && error.length > 0)
            {
              var driverLicencedetails = new OperatorInformation();
              driverLicencedetails.errorMessage = error;
              return driverLicencedetails;
            }
        }
        this.incoming = new Uint8Array();
        await this.flushPort();

        this.reader = this.serialport.readable.getReader();        
        if(this.serialport)
        {
          this.endOfDataStream = false;
          this.linesRead = 0;
          while (!this.endOfDataStream) {
            const { value, done } = await this.reader.read();
            this.setEndReadStreamTrigger();
            if(done) {
              this.scanneddata = this.decoder.decode(this.incoming);
              this.incoming = new Uint8Array();
              var driverLicencedetails = new OperatorInformation();
              this.endOfDataStream = true;
              driverLicencedetails.InitilizebyAAMVABarcode(this.scanneddata);
              return driverLicencedetails;
            }
            ++ this.linesRead;
            this.appendBuffer(value);
          }           
        }
        else{
          var driverLicencedetails = new OperatorInformation();
          driverLicencedetails.errorMessage = "Cannot connect to the reader device";
          return driverLicencedetails;
        }

  }
}