import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { ReportService } from 'apps/intake/src/shared/services/report.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import * as fileSaver from "file-saver";
import * as moment from 'moment';
import { DateUtil } from '@apis/shared/helpers/date-util';

@Component({
  selector: 'app-contravention-analytics',
  templateUrl: './contravention-analytics.component.html',
  styleUrls: ['./contravention-analytics.component.scss']
})
export class ContraventionAnalyticsComponent implements OnInit {
  isDateFilter: boolean;
  startDate: Date;
  endDate: Date;
  fromDate: string;
  toDate: string;
  selectedYear: number=0;
  selectedMonth: number=0;
  datePickerConfig: Partial<BsDatepickerConfig>;
  monthList: any;
  yearList: any;
  errorMessage: string="";
  
  constructor(
    private readonly reportService: ReportService, 
    private readonly spinner: NgxSpinnerService
    ) 
  {
    this.datePickerConfig = Object.assign({}, 
    {
      containerClass: 'theme-dark-blue', 
      showWeekNumbers: false,
      dateInputFormat: 'YYYY/MM/DD',
      minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
      maxDate: new Date(2099,11,31), // 2099/12/31 - Month is 0 based index
      isAnimated: true,
      customTodayClass: 'custom-today-class'
    }); 
  }

  ngOnInit(): void {
    this.monthList = CommonUtil.getMonthList();
    this.yearList = CommonUtil.getYearList();
  }

  onDownloadClick(isValid: boolean)
  {
    if(isValid && this.isValidDateRange(isValid))
    {
      if (this.isDateFilter)
      {
        this.fromDate = (moment(this.startDate)).format('YYYY-MM-DD');
        this.toDate = (moment(this.endDate)).format('YYYY-MM-DD');
      }
      else
      {
        // Check if month value is selected or not
        if (this.selectedMonth == 0) //Month value not selected so consider only year
        {
          this.fromDate = `${this.selectedYear}-01-01`;
          this.toDate = `${this.selectedYear}-12-31`;
        }
        else 
        {
          this.fromDate = `${this.selectedYear}-${this.selectedMonth < 10? '0'+ this.selectedMonth: this.selectedMonth }-01`;
          this.toDate = `${this.selectedYear}-${this.selectedMonth < 10? '0' + this.selectedMonth: this.selectedMonth}-${new Date(this.selectedYear, this.selectedMonth,0).getDate()}`;
        }
      }

      this.spinner.show();
      this.reportService.downloadContraventionAnalyticsReport(this.fromDate, this.toDate)
        .subscribe((result: HttpEvent<Blob>) => {       
          if (result.type == HttpEventType.Response) {
            this.spinner.hide();
            fileSaver.saveAs(result.body, "impaired-notices.csv");          
          }        
        }, (error: any) => this.spinner.hide());            
      }
  }

  isValidDateRange(isValid: boolean) : boolean
  {
    if (!isValid || !this.isDateFilter || DateUtil.addDays(this.startDate, 365) >= this.endDate)
    {
      this.errorMessage = "";
      return true;
    }
    else
    {
      this.errorMessage = "Date range must be 1 year or less";
      return false
    }
  }

  onFilterTypeChange()
  {
    this.errorMessage = "";
    
    if(this.isDateFilter)
    {
      this.selectedYear = 0;
      this.selectedMonth = 0;
    }
    else
    {
      this.startDate = null;
      this.endDate = null;
    }
  }
}
