import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@apis/shared/shared.module"
import { TsaContraventionDetailsComponent } from './tsa-contravention-details/tsa-contravention-details.component';
import { AdditionalDetailsComponent } from './additional-details/additional-details.component';
import { SupportingDocumentsComponent } from '../add-details/supporting-documents/supporting-documents.component';
import { ReviewConfirmComponent } from '../add-details/review-confirm/review-confirm.component';
import { SubmissionCompleteComponent } from '../add-details/submission-complete/submission-complete.component';
import { IntakeFileUploadComponent } from '../../../shared/components/file-upload/intake-file-upload.component';
import { NgxMaskDirective } from 'ngx-mask';
import { AgGridModule } from 'ag-grid-angular';
import { LegacySubmissionPrintComponent } from './submission-print/legacy-submission-print.component';
import { SubmissionPrintComponent } from './submission-print/submission-print.component';
import { DirectiveModule } from '../../../shared/directives/directive.module';

@NgModule({
  declarations: [
    TsaContraventionDetailsComponent,
    AdditionalDetailsComponent,
    SupportingDocumentsComponent,
    ReviewConfirmComponent,
    SubmissionCompleteComponent,
    LegacySubmissionPrintComponent,
    SubmissionPrintComponent,
    IntakeFileUploadComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    NgxMaskDirective,
    AgGridModule,
    DirectiveModule
  ]
})
export class ContraventionAddDetailsModule { }
