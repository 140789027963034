import { Guid } from "guid-typescript";

export class DisclosureDocument {
    public name: string;
    public fullName: string;
    public type: string;
    public contentLength: number;
    public size: string;
    public uploadedBy: string;
    public uploadedDate: string;
    public url: string;
    public tempUrl: string;
    public documentRefTypeNumber: string;
    public originalName: string;
    public documentNumber: string = Guid.create().toString(); // front-end only field, used for Angular change tracking

    constructor(init?: Partial<DisclosureDocument>) {
        Object.assign(this, init);
    }
}
