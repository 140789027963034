import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appTransactionIdValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: TransactionIdValidatorDirective,
        multi: true
    }]
})
export class TransactionIdValidatorDirective implements Validator {
    @Input('appTransactionIdValidator') isRequired: boolean;
    
    validate(control: AbstractControl) {
        if (!this.isRequired || !control.value)  
            return null;
       
        var transactionId = control.value.replace(/-/g, "");    

        if (transactionId.length!=17 || !/^\d+$/.test(transactionId) || transactionId.charAt(14) != '0') 
            return {'invalidTransactionId': true};  

        return null;
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('isRequired' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }

}