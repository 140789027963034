import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appContraventionNumberValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ContraventionNumberValidatorDirective,
        multi: true
    }]
})
export class ContraventionNumberValidatorDirective implements Validator {
    @Input('appContraventionNumberValidator') isRequired: boolean;
    
    validate(control: AbstractControl) {
        const contraventionNumber = control.value?.toUpperCase();
        if (contraventionNumber?.length != 10) {
            return {'invalidLength': true};
        }

        if (this.isRequired == null || this.isRequired){
            const contraventionNumberRegEx = new RegExp('^[AC][0-9]{8}[A-Z]$');
            
            if (contraventionNumberRegEx.test(contraventionNumber)) { //Basic Format Ok
                const prefix = contraventionNumber.charAt(0);
                const contraventionNumberDigits = contraventionNumber.substring(1, 9);
                const checkDigit: number = +contraventionNumberDigits.substring(7);
                const ticketNumber: number = +contraventionNumberDigits.substring(0, 7);
                
                //Range Check
                if (prefix === 'A') {
                    if (contraventionNumberDigits < 14248415)
                        return {'invalidContraventionNumber': true};
                }

                //Check Digit Logic - MODULO 7
                return (checkDigit == (+ticketNumber % 7)) ? null : {'invalidContraventionNumber': true};
            }
            else
                return {'invalidContraventionNumber': true};
        }
        else
            return null;    
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('isRequired' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }

}