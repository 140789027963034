<ng-container *ngIf="sectionDetails.length > 0">
    <div class="heading">{{sectionTitle}}</div>
    <div *ngIf="sectionTitle!=''" class="heading-underline"></div>
    <div class="row">
        <div *ngFor="let sectionDetail of sectionDetails" [ngClass]="sectionDetail.isFullLineText? 'col-12 col-sm-12': 'col-12 col-sm-4'">
            <div *ngIf="sectionDetail.labelText.length>0" class="label">{{sectionDetail.labelText}}</div>
            <div class="value" *ngIf="sectionDetail.isInnerHTML; else plainText" [innerHTML]="(sectionDetail.valueText == null)? '-': sectionDetail.valueText"></div>
            <ng-template #plainText>
                <div class="value">{{(sectionDetail.valueText == null || sectionDetail.valueText?.trim() == "")? "-": sectionDetail.valueText?.trim()}}</div>
            </ng-template>
        </div>
    </div>
</ng-container>

