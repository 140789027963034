import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Contravention } from '@apis/shared/models/contravention.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContraventionTest } from '@apis/shared/models/contravention-test.model';
import { TestTypes } from '@apis/shared/enums/app.enum';
import { StopInformation } from '@apis/shared/models/stop-information.model';

@Component({
  selector: 'app-submission-complete',
  templateUrl: './submission-complete.component.html',
  styleUrls: ['./submission-complete.component.scss']
})
export class SubmissionCompleteComponent implements OnInit {
  stopInformation: StopInformation;
  contravention: Contravention;
  vehicleSeizure: VehicleSeizure;
  title: string;
  message: string ="";
  errors: string[] = [];
  isSubmissionComplete: boolean = false;
  documentCount: number = 0;
  submissionTitle: string;
    
  constructor(private intakeService: IntakeService,
              private readonly spinner: NgxSpinnerService,
              private router: Router) { }

  ngOnInit(): void {
    this.spinner.show(); 
    this.title = "Processing..";
    
    //Get stop information object
    this.stopInformation = this.intakeService.getStopInformationModel();
    
    if (this.stopInformation.stopInformationId > 0)
    {
      this.cleanupReferences();
      this.intakeService.updateStopInformationAsync(this.stopInformation).subscribe(
        (response: any) => {
          this.title = "Submission Complete";
          this.message = "Your submission has been submitted successfully."; 
          this.errors = null;
          this.isSubmissionComplete = true;
          this.intakeService.resetStopInformationModel();
          this.spinner.hide(); 
        },
        (error: any) => {
          this.title = "Submission Failed";
          this.message = "Your submission contains fatal errors. It cannot be enforced due to the following critical errors."; 
          this.showErrors(error);
          this.spinner.hide(); 
        }
      );
    }
    else
      this.router.navigateByUrl('/');  
  }

  cleanupReferences() {
    var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.stopInformation.contraventions.forEach(contravention => {
      //Cleanup references before submit
      if (contravention.recipient?.recipientIdentification?.driversLicenceClassTypeId == 0)
        contravention.recipient.recipientIdentification.driversLicenceClassTypeId = null;
      if (contravention.recipient?.recipientIdentification?.driversLicenceStatusTypeId == 0)
        contravention.recipient.recipientIdentification.driversLicenceStatusTypeId = null;  
      if (contravention.recipient?.recipientIdentification?.issuingCountryId == 0)  
        contravention.recipient.recipientIdentification.issuingCountryId = null;
      if (contravention.recipient?.recipientIdentification?.issuingProvinceId == 0)  
        contravention.recipient.recipientIdentification.issuingProvinceId = null;
      if (contravention.recipient?.recipientIdentification?.genderId == 0)  
        contravention.recipient.recipientIdentification.genderId = null;
      if (contravention.impairmentScreeningInformation?.suspectedImpairmentTypeId == 0)
        contravention.impairmentScreeningInformation.suspectedImpairmentTypeId = null;  
      if (contravention.vehicleSeizure?.vehicle?.registeredOwner?.genderId == 0)
        contravention.vehicleSeizure.vehicle.registeredOwner.genderId = null;
      if (contravention.vehicleSeizure?.vehicle?.registeredOwner?.registeredOwnerContact?.countryId == 0)
        contravention.vehicleSeizure.vehicle.registeredOwner.registeredOwnerContact.countryId = null;  
      if (contravention.vehicleSeizure?.vehicle?.vehicleRegistrationStatusTypeId == 0)  
        contravention.vehicleSeizure.vehicle.vehicleRegistrationStatusTypeId = null;
      if (contravention.vehicleSeizure?.vehicle?.licensedCountryId == 0)  
        contravention.vehicleSeizure.vehicle.licensedCountryId = null;
      if (contravention.vehicleSeizure?.vehicle?.licensedProvinceId == 0)  
        contravention.vehicleSeizure.vehicle.licensedProvinceId = null;
      if (contravention.vehicleSeizure?.vehicle?.clientTypeId == 0)  
        contravention.vehicleSeizure.vehicle.clientTypeId = null;  
      if (contravention.recipient?.recipientContact?.countryId == 0)  
        contravention.recipient.recipientContact.countryId = null;
      if (contravention.recipient?.recipientContact?.provinceId == 0)  
        contravention.recipient.recipientContact.provinceId = null;
      if(contravention.impairmentScreeningInformation?.impairmentScreeningTypeId == 0)
        contravention.impairmentScreeningInformation.impairmentScreeningTypeId = null;  

      //Cleanup references for all impaired tests
      if (contravention.impairmentScreeningInformation?.impairmentTests != null && contravention.impairmentScreeningInformation?.impairmentTests.length > 0)
      {
        contravention.impairmentScreeningInformation?.impairmentTests.forEach(test => {
          this.cleanupContraventionTestReferences(test);
        });
      }  

      //Cleanup references for roadside appeal test
      if(contravention.roadsideAppeal?.roadsideTests != null && contravention.roadsideAppeal.roadsideTests.length > 0)
      {
        this.cleanupContraventionTestReferences(contravention.roadsideAppeal.roadsideTests[0]);
      }

      //Update Upload dates for all documents
      contravention.documents.filter(x=>(x.documentName != null && x.documentName.trim().length > 0 && x.documentId <= 0)).forEach(document => {
        document.uploadedDate = today;
      });
    });

    //Update Upload dates for all documents
    this.stopInformation.documents.filter(x=>(x.documentName != null && x.documentName.trim().length > 0 && x.documentId <= 0)).forEach(document => {
      document.uploadedDate = today;
    });
  }
  
  cleanupContraventionTestReferences(contraventionTest : ContraventionTest)
  {
    switch (+contraventionTest.testTypeId)
    {
      case TestTypes.SFST: 
        if(contraventionTest.standardizedFieldSobrietyTest?.testResultTypeId == 0)
          contraventionTest.standardizedFieldSobrietyTest.testResultTypeId = null;
        break;
      case TestTypes.ASD: 
        if(contraventionTest.approvedScreeningDeviceTest?.testModelTypeId == 0)
          contraventionTest.approvedScreeningDeviceTest.testModelTypeId = null;
        if(contraventionTest.approvedScreeningDeviceTest?.testResultTypeId == 0)
          contraventionTest.approvedScreeningDeviceTest.testResultTypeId = null;
        break;  
      case TestTypes.ADSE: 
        if(contraventionTest.drugScreeningEquipmentTest?.testModelTypeId == 0)
          contraventionTest.drugScreeningEquipmentTest.testModelTypeId = null;
        break;  
      case TestTypes.AI: 
        if(contraventionTest.approvedInstrumentTest?.testModelTypeId == 0)
          contraventionTest.approvedInstrumentTest.testModelTypeId = null;
        break;  
      case TestTypes.Blood: 
        if(contraventionTest.bloodSampleTest?.labTypeId == 0)
          contraventionTest.bloodSampleTest.labTypeId = null;
        break;    
      case TestTypes.Urine: 
        if(contraventionTest.urineSampleTest?.labTypeId == 0)
          contraventionTest.urineSampleTest.labTypeId = null;
        break;    
    }    
  }

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errors = error.error;
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errors.push(error.error);
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errors.push(error.error.error);
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errors.push(error.error.errors);  
    else    
      this.errors.push("Something went wrong with the submission. Please try again later.");
  }
}
