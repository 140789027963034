import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { TicketService } from 'apps/intake/src/shared/services/ticket.service';
import { SharedDisclosureDocumentsUploadRequest } from '@apis/shared/models/shared-disclosure-documents-upload-request.model';
import { DisclosureDocument } from '@apis/shared/models/disclosure-document.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-traffic-disclosure-review-and-confirm',
  templateUrl: './traffic-disclosure-review-and-confirm.component.html',
  styleUrls: ['./traffic-disclosure-review-and-confirm.component.scss']
})
export class TrafficDisclosureReviewAndConfirmComponent implements OnInit {
  sharedDisclosureDocumentsUploadRequest: SharedDisclosureDocumentsUploadRequest;
  errorMessage: string = "";
  isSubmitClicked: boolean = false;
  policeServiceInformation: SectionDetailsModel[] = [];
  policeOfficerFullName: string;
  submissionDate: Date;
  sortedDisclosureDocuments: DisclosureDocument[] = [];

  constructor(
    private readonly ticketService: TicketService,
    private readonly spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private router: Router
    ) { }

  ngOnInit(): void {
    //Get shared document upload request context
    this.sharedDisclosureDocumentsUploadRequest = this.ticketService.getSharedDisclosureDocumentsUploadRequestContext();

    if (!this.sharedDisclosureDocumentsUploadRequest || this.sharedDisclosureDocumentsUploadRequest.ticketNumbers.length == 0) {
      this.router.navigateByUrl('/');
    }

    // Sort documents in chronological order, newest to oldest
    this.sortedDisclosureDocuments = this.sharedDisclosureDocumentsUploadRequest.disclosureDocuments.sort((a: any, b: any) => {
      if (a.uploadedDate == null || b.uploadedDate == null) return -1;
      if (a.uploadedDate > b.uploadedDate) return -1;      
      if (b.uploadedDate > a.uploadedDate) return 1;
      return 0;
    });    

    //Get logged in user's name
    var user = this.localStorageService.getUser();
    this.policeOfficerFullName = `${user?.firstName} ${user?.lastName}`;
    
    // Police Service Section
    var detachment = this.localStorageService.getDetachments().find(x => x.detachmentId == user.detachment);

    this.policeServiceInformation.push(new SectionDetailsModel('Name of Submitter', this.policeOfficerFullName));
    this.policeServiceInformation.push(new SectionDetailsModel('Regimental Number', user.badgeNumber));
    this.policeServiceInformation.push(new SectionDetailsModel('Police Service', detachment?.agency?.agencyName));
    this.policeServiceInformation.push(new SectionDetailsModel('Detachment', detachment.detachmentName, true));
    
    //Set Submission Date and Time
    this.submissionDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  }

  onSubmitClick()
  {
    this.isSubmitClicked = true;
    this.sharedDisclosureDocumentsUploadRequest.disclosureDocuments.forEach(document => {
      if (document.tempUrl) {
        document.uploadedBy = this.policeOfficerFullName;
      }
    });
    
    this.router.navigateByUrl('/ticket/disclosure/submission-complete');
  }
}
