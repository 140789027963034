import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentTypes } from '@apis/shared/enums/app.enum';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { Router } from '@angular/router';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { TicketService } from 'apps/intake/src/shared/services/ticket.service';
import { Ticket } from '@apis/shared/models/ticket.model';
import { DisclosureDocument } from '@apis/shared/models/disclosure-document.model';
import { SharedDisclosureDocumentsUploadRequest } from '@apis/shared/models/shared-disclosure-documents-upload-request.model';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-traffic-disclosure-upload',
  templateUrl: './traffic-disclosure-upload.component.html',
  styleUrls: ['./traffic-disclosure-upload.component.scss']
})
export class TrafficDisclosureUploadComponent implements OnInit {
  sharedDisclosureDocumentsUploadRequest: SharedDisclosureDocumentsUploadRequest;
  additionalDocumentTypes: DocumentType[];
  errorMessage: string = "";
  isIssuingOfficer: boolean;
  isSubmitClicked: boolean = false;
  isTrueInformation: boolean = false;
  isApisIssuedTicket: boolean = false;
  userName: string;

  constructor(
    private readonly intakeService: IntakeService,
    private readonly ticketService: TicketService,
    private readonly spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.additionalDocumentTypes = this.localStorageService.getDocumentTypes().filter(x => [DocumentTypes.Photo, DocumentTypes.AudioVideo, DocumentTypes.WitnessStatements, DocumentTypes.PoliceNotes, DocumentTypes.OtherOfficerAccount, DocumentTypes.TechnicalMaterial, DocumentTypes.ViolationTicket, DocumentTypes.Other, DocumentTypes.PoliceNarrative, DocumentTypes.DisclosurePackage,
        DocumentTypes.DriverAbstract, DocumentTypes.CertificateOfSuspension, DocumentTypes.RegisteredOwnerCertificate, DocumentTypes.CertificateOfRegistration, DocumentTypes.MotorVehicleCertificate, DocumentTypes.CollisionReport].includes(+x.id));
    this.spinner.hide();

    //Get logged in user's name
    var user = this.localStorageService.getUser();
    this.userName = `${user?.firstName} ${user?.lastName}`;

    // Get shared disclosure document upload request context
    this.sharedDisclosureDocumentsUploadRequest = this.ticketService.getSharedDisclosureDocumentsUploadRequestContext();

    if (!this.sharedDisclosureDocumentsUploadRequest || this.sharedDisclosureDocumentsUploadRequest.ticketNumbers.length == 0) {
      this.router.navigateByUrl('/');
    }

    if (this.sharedDisclosureDocumentsUploadRequest.ticketNumbers?.length == 1) {
      this.isApisIssuedTicket = this.sharedDisclosureDocumentsUploadRequest.ticketView.isApisIssuedTicket;
    }
    this.sharedDisclosureDocumentsUploadRequest.isDisclosurePreviouslyProvided = this.sharedDisclosureDocumentsUploadRequest.ticketView.isDisclosurePreviouslyProvided;

    // Assign a documentNumber to each disclosure document, for Angular change tracking
    this.sharedDisclosureDocumentsUploadRequest.disclosureDocuments.forEach(disclosureDocument => {
      disclosureDocument.documentNumber = Guid.create().toString();
    })
  }

  trackDocument(index: number, document: DisclosureDocument): any {
    return document.documentNumber;
  }

  AddAdditionalDocument() {
    this.sharedDisclosureDocumentsUploadRequest.disclosureDocuments.push(new DisclosureDocument({
      uploadedBy: this.userName,
      documentRefTypeNumber: Guid.create().toString()
    }));
  }

  onCancelDcoument(document: DisclosureDocument)
  {
    this.sharedDisclosureDocumentsUploadRequest.disclosureDocuments.splice(this.sharedDisclosureDocumentsUploadRequest.disclosureDocuments.findIndex(x => x==document), 1)
  }

  onSubmitClick(isValid: boolean)
  {
    this.isSubmitClicked = true;

    if (isValid) {
      this.ticketService.setSharedDisclosureDocumentsUploadRequestContext();
      this.router.navigateByUrl('/ticket/disclosure/review-confirm');    
    }
  }
}
