import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[appTransactionIdFormatter]'
})
export class TransactionIdFormatterDirective {
  @Input('appTransactionIdFormatter') isApplicable: boolean;
  
  get control() {
    return this.ngControl.control;
  }
  
  constructor(
    public ngControl: NgControl
  ) { }

  // add "-" at the right spot of Transaction Id
  @HostListener('focusout') onBlur() {
        var value = this.control.value;
        if (this.isApplicable && value && value.length >= 17)
        {   
            value = value.replace(/-/g, "");
            const controlValue = value.slice(0, value.length-10) + "-" + value.slice(value.length-10, value.length-3) + "-" + value.slice(value.length-3);
            this.control.setValue(controlValue);
        }
    }
}