import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable()
export class ReportService {
    private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
    
    constructor(private readonly httpClient: HttpClient){}

    downloadContraventionAnalyticsReport(startdate: string, enddate: string): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/contravention-analytics`, 
        { 
          params: {
              startdate: startdate,
              enddate: enddate
          },
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }

    downloadSeizureNoticesReport(startdate: string, enddate: string): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/seizure-notices`, 
        { 
          params: {
              startdate: startdate,
              enddate: enddate
          },
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }

    downloadUserListReport(): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/user-list`, 
        { 
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });      
    }

    downloadCancelledContraventionsReport(startdate: string, enddate: string): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/cancelled-contraventions`, 
        { 
          params: {
              startdate: startdate,
              enddate: enddate
          },
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }

    downloadTrafficTicketsReport(startdate: string, enddate: string): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/traffic-tickets`, 
        { 
          params: {
              startdate: startdate,
              enddate: enddate
          },
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }

    downloadCancelledTicketsReport(startdate: string, enddate: string): Observable<HttpEvent<Blob>> {
      return this.httpClient.get(`${this.apiUrl}/reports/cancelled-tickets`, 
        { 
          params: {
              startdate: startdate,
              enddate: enddate
          },
          responseType: "blob",
          reportProgress: true,
          observe: "events"
        });
    }
}
