import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentRequest } from '@apis/shared/models/payment-request.model';
import { ControlContainer, NgForm } from '@angular/forms';

declare function customcheckout(): any;

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class PaymentDetailsComponent implements OnInit {
  @Input() paymentRequest: PaymentRequest;
  @Input() isSubmitClicked: boolean;

  customCheckout: any;

  tokenErrorMessage = '';

  isCardNumberComplete = false;
  isCVVComplete = false;
  isExpiryComplete = false;

  constructor() {
    this.customCheckout = customcheckout();
  }

  ngOnInit(): void {
    this.createInputs();
    this.addListeners();
  }

  createInputs() {
    var options = {};

    // Create and mount the inputs
    options["placeholder"] = 'Card number';
    this.customCheckout.create('card-number', options).mount('#card-number');

    options["placeholder"] = 'CVV';
    this.customCheckout.create('cvv', options).mount('#card-cvv');

    options["placeholder"] = 'MM / YY';
    this.customCheckout.create('expiry', options).mount('#card-expiry');
  }

  addListeners() {
    var self = this;

    this.customCheckout.on('brand', function(event) {
      console.log('brand: ' + JSON.stringify(event));

      var cardLogo = 'none';
      if (event.brand && event.brand !== 'unknown') {
        var filePath =
          'https://cdn.na.bambora.com/downloads/images/cards/' +
          event.brand +
          '.svg';
        cardLogo = 'url(' + filePath + ')';
      }
      document.getElementById('card-number').style.backgroundImage = cardLogo;
      self.paymentRequest['cardLogo'] = cardLogo;
    });
  }

  createToken() : Observable<any> {
    const trSubject = new BehaviorSubject<any>(null);

    this.customCheckout.createToken(result => {
      if(result.error){
        this.tokenErrorMessage = result.error.message;
        trSubject.next(null);
        trSubject.complete();
      }
      else {
        trSubject.next(result);
        trSubject.complete(); // It seems like multiple instances of customCheckout can be created when the payment page is visited multiple times. Use complete() to prevent receiving responses from old subjects.
      }
    });

    return trSubject.asObservable();
  }

}
