import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "apps/intake/src/environments/environment";
import { User } from '@apis/shared/models/user.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DisclosuresResult } from '@apis/shared/models/disclosures-result.model';
import { DisclosureInfo } from '@apis/shared/models/disclosure-info.model';

@Injectable()
export class DisclosureService {
  private apiUrl: string = `${environment.apiUrl}${environment.apiV1}`;
  private user: User;

  constructor(private readonly httpClient: HttpClient, private readonly localStorageService: LocalStorageService) {     
  }

  getTicketWipContainerSasUri(): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/disclosures/ticket-wip/sasuri`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map(response => response)
    );
  }

  getTicketWipDisclosureSasUri(ticketNumber: string, disclosureName: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/disclosures/ticket-wip/${ticketNumber}/${disclosureName}/sasuri`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map(response => response)
    );
  }

  getDisclosureSasUri(ticketNumber: string, disclosureName: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/disclosures/${ticketNumber}/${disclosureName}/sasuri`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map(response => response)
    );
  }

  getDisclosures(ticketNumber: string): Observable<DisclosuresResult> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.get(`${this.apiUrl}/disclosures/${ticketNumber}`, { headers: {'Authorization': `Bearer ${this.user.token}`} } )
    .pipe(
      map((response) => new DisclosuresResult(response))
    );
  }

  downloadDisclosures(ticketNumber: string, disclosures: DisclosureInfo[]): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.post(`${this.apiUrl}/disclosures/${ticketNumber}/download`, disclosures, 
    { headers: {'Authorization': `Bearer ${this.user.token}`},  responseType: 'blob' });
  }

  deleteWipDisclosure(tempFolder: string, disclosureName: string): Observable<any> {
    this.user = this.localStorageService.getUser();
    return this.httpClient.delete(`${this.apiUrl}/disclosures/ticket-wip/${tempFolder}/${disclosureName}`,  
    { headers: {'Authorization': `Bearer ${this.user.token}`},  responseType: 'blob' });
  }
}
