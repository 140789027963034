import { AvailabilityInformation } from './availability-information';

export class AvailabilityView {
    public startDate: Date;
    public endDate: Date;
    public availabilityInformationList: AvailabilityInformation[] = [];

    constructor(init?: Partial<AvailabilityView>) {
        
        Object.assign(this, init);
    }
}