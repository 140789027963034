import { Component, OnInit, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { ContraventionStatusTypes, EventTypes } from '@apis/shared/enums/app.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Event } from '@apis/shared/models/event.model';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { SpeedingOffence } from '@apis/shared/models/speeding-offence.model';
import { Fine } from '@apis/shared/models/fine.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { PoliceServiceInformation } from '@apis/shared/models/police-service-information.model';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-offline-nap-print',
  templateUrl: './offline-print-nap.component.html',
  styleUrls: ['./offline-print-nap.component.css']
})
export class OfflinePrintNapComponent implements OnInit {
  stop: StopInformation;
  @Input() contravention: Contravention;
  @Input() pageBreakRequired: any;

  contraventionNumber: string;
  isSubmitClicked: boolean = false;

  bodyElement: JQuery<HTMLElement>;
  cancellationReason: string = "";
  isContraventionCancelled: boolean = false;
  errorMessage: string;
  confirmButton: JQuery<HTMLElement>;
  username: string;
  confirmModalOverlay: JQuery<HTMLElement>;
  additionalNotesTextArea: JQuery<HTMLElement>;

  // local vars for template
  locationName="";
  contraventionTypes: any;
  secondaryContraventionTypes: any;
  tertiaryContraventionTypes: any;
  speedingOffences: SpeedingOffence[];
  fine: Fine;
  contraventionDetailsText: string;
  selectedContraventionDetails: number[];
  selectedContraventionType: ContraventionType = null;
  selectedSecondaryContraventionType: ContraventionType = null;
  selectedTertiaryContraventionType: ContraventionType = null;

  policeServiceInfo: PoliceServiceInformation
  policeFileNumber = '';
  policeOfficerName = '';
  regimentalNumber = '';
  detachmentName = '';
  agencyName = '';

  insertPageBreak: boolean;

  fineAmount: number;
  surchargePercentage: number;
  
  close: EventEmitter<Contravention> = new EventEmitter<Contravention>();

  constructor( private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService,
    private readonly intakeService: IntakeService,
    private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {        

    this.insertPageBreak = this.pageBreakRequired as boolean;

    //Get Issuer and Submission Type object
    this.stop = this.intakeService.getStopInformationModel();

    this.contraventionNumber = this.contravention.contraventionNumber;     

    if (this.contravention.policeServiceInformation.length>0) {
      this.policeServiceInfo = this.contravention.policeServiceInformation[0];
      this.policeFileNumber = this.contravention.policeFileNumber;
      this.policeOfficerName = this.policeServiceInfo.policeOfficerName;
      var detachment = this.localStorageService.getDetachments().find(x => x.detachmentId == this.policeServiceInfo.detachmentId);
  
      if (detachment!==null && detachment!==undefined ) {
        this.policeOfficerName = this.policeServiceInfo.policeOfficerName;
        this.regimentalNumber = this.policeServiceInfo.regimentalNumber;
        this.detachmentName = detachment.detachmentName
        this.agencyName = detachment?.agency?.agencyName;
      } 
    }

    // local vars for template 
    this.locationName = this.localStorageService.getLocationTypes().find(x => x.id == this.stop?.occurrenceLocation?.locationTypeId)?.name; 
    
    //Get Types
    this.contraventionTypes = this.localStorageService.getContraventionTypes();
    this.speedingOffences = this.localStorageService.getSpeedingOffences();
    
    //Get contravention type
    if (this.contravention.contraventionTypeId > 0)
    {
      this.selectedContraventionType = this.contraventionTypes.find(x => x.id == this.contravention.contraventionTypeId);
      this.fineAmount = this.contravention.fine?.fineAmount + 
        (this.contravention.fine?.fineAmount * this.selectedContraventionType.victimFineSurchargePercentage) / 100;
      this.surchargePercentage = this.selectedContraventionType.victimFineSurchargePercentage;
    }
    //Get secondary contravention type
    if (this.contravention.secondaryContraventionTypeId > 0)
    {
      var secondaryContraventionTypeIds = this.localStorageService.getContraventionTypeMappings().filter(x => x.parentContraventionTypeId == this.contravention.contraventionTypeId).map(s => s.childContraventionTypeId);
      if (secondaryContraventionTypeIds?.length > 0)
      {
        this.secondaryContraventionTypes = this.contraventionTypes.filter(x => secondaryContraventionTypeIds.includes(x.id));
      }
      else {
        this.secondaryContraventionTypes = null;  
      }
     
      this.selectedSecondaryContraventionType = this.contraventionTypes.find(x => x.id == this.contravention.secondaryContraventionTypeId);
    }

    //Get tertiary contravention type

    if (this.contravention.tertiaryContraventionTypeId > 0)
    {
      var tertiaryContraventionTypeIds = this.localStorageService.getContraventionTypeMappings().filter(x => x.parentContraventionTypeId == this.contravention.secondaryContraventionTypeId).map(s => s.childContraventionTypeId);
      if (tertiaryContraventionTypeIds?.length > 0)
      {
        this.tertiaryContraventionTypes = this.contraventionTypes.filter(x => tertiaryContraventionTypeIds.includes(x.id));
      }
      else {
        this.tertiaryContraventionTypes = null;  
      }
      
      this.selectedTertiaryContraventionType = this.contraventionTypes.find(x => x.id == this.contravention.tertiaryContraventionTypeId);
    }

    var user = this.localStorageService.getUser();
    this.username = `${user?.firstName} ${user?.lastName}`;
  }
  

  vehicleSeizureDurationText(): string  {
    let returnText = "";
    if (this.contravention.vehicleSeizure!=null) {
      returnText = `${this.contravention.vehicleSeizure.seizureDuration}  ${this.contravention.vehicleSeizure.seizureDurationType}`;
    }

    return returnText;
  }

  getFineAmount(): string {
    return this.currencyPipe.transform(this.fineAmount);
  }

  officerNameText(): string {
    return this.policeOfficerName;
  }

  regimentalNumberText(): string {
    return this.regimentalNumber;
  }

  policeFileNumberText(): string {
    if (this.policeFileNumber && (this.policeFileNumber.trim().length>0))
    {
      return this.policeFileNumber;
    }
    return "N/A";
  }

  agencyText(): string {
    return this.agencyName;
  }

  detachmentText(): string {
    return this.detachmentName;
  }

  regulationText(): string {
    let regText = '';
    if (this.selectedContraventionType.regulation!==null && this.selectedContraventionType.regulation!==undefined && this.selectedContraventionType.regulation!=='') {
      switch (this.selectedContraventionType.regulation) {
        case "OHVR":
        {
          regText = `OHVR = Off-Highway Vehicle Regulation`;
          break;
        }
        case "OLVCR":
        {
          regText = `OLVCR = Operator Licensing and Vehicle Control Regulation`;
          break;
        }
        case "UHRRR":
        {
          regText = `UHRRR = Use of Highway and Rules of the Road Regulation`;
          break;
        }
        case "VER":
        {
          regText = `VER = Vehicle Equipment Regulation`;
          break;
        }
  
      }
    }
    return regText;
  }

  hasVehicleSeizure(): boolean {
    var sdpSeizureTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isSDP).map(s => s.id);     
    var sTypeIdsContainCo = sdpSeizureTypeIds.find( i => i===this.contravention.contraventionTypeId);
    if (sTypeIdsContainCo===undefined && this.contravention.isWarning !== true) {
      if (this.contravention.vehicleSeizure!=null) {
        return true;
      }
    }

    return false;
  }


  licenceSuspensionDurationText(): string  {
    return `${this.contravention.suspensionDuration} ${this.contravention.suspensionDurationType}${this.contravention.additionalSuspension?" + " + this.contravention.additionalSuspension:""}`;
  }

  licenceDestroyedText(): string  {
    if (this.contravention.isLicenceSeized) {
      return 'seized';
    }
    if (this.contravention.isLicenceDestroyed) {
      return 'destroyed';
    }
    return '';
  }

}
