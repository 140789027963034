import { Component, OnInit, EventEmitter, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgModel, Validators } from '@angular/forms';
import { EventTypes, DocumentTypes, RequestDecisionTypes, NoticeCancellationReasonTypes } from '@apis/shared/enums/app.enum';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import * as fileSaver from 'file-saver';
import { Guid } from 'guid-typescript';
import { Document } from '@apis/shared/models/document.model';
import { Event } from '@apis/shared/models/event.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { NoticeCancellationReasonType } from '@apis/shared/models/types/notice-cancellation-reason-type.model';
import { NoticeCancellationRequest } from '@apis/shared/models/notice-cancellation-request.model';
import { NoticeCancellationRequestMapping } from '@apis/shared/models/notice-cancellation-request-mapping.model';

@Component({
  selector: 'app-cancel-multiple-contraventions-modal',
  templateUrl: './cancel-multiple-contraventions-modal.component.html',
  styleUrls: ['./cancel-multiple-contraventions-modal.component.scss']
})
export class CancelMultipleContraventionsModalComponent implements OnInit, AfterViewInit {
  noticeCancellationRequest: NoticeCancellationRequest;
  isSubmitClicked: boolean = false;
  maxCharactersAllowed: number = 250;
  remainingCharacters: number = this.maxCharactersAllowed;
  bodyElement: JQuery<HTMLElement>;
  cancellationReason: string = "";
  isContraventionCancelled: boolean = false;
  errorMessage: string;
  errorMessageHeader: string;
  requiredDocumentUploadError: string;
  additionalDocumentUploadError: string;
  additionalDocumentName: string;
  requiredDocumentName: string;  
  requiredDocument: Document;
  additionalDocument: Document;
  uploadingDocument: boolean = false;
  confirmButton: JQuery<HTMLElement>;

  requiredDocumentContainer: HTMLElement;
  requiredDocumentUploadContainer: HTMLElement;
  additionalDocumentUploadContainer: HTMLElement;  
  additionalDocumentButton: JQuery<HTMLElement>;

  cancellationReasonTextarea: JQuery<HTMLElement>;
  documents: Document[] = [];
  noticeCancellationReasonTypes: NoticeCancellationReasonType[];
  expiredNoticeCancellationReasonTypes = 
  [
    NoticeCancellationReasonTypes.OfficerEmergencyServiceMemberInExecutionOfDuty
  ];

  username: string;
  confirmModalOverlay: JQuery<HTMLElement>;
  
  cancellationReasonTypeId: number;
  isRequestSubmitted: boolean = false;
  isSingleNotice: boolean = false;
  requestDecisionTypeId: number; 
  RequestDecisionTypes = RequestDecisionTypes;

  close: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("cancellationReasonModel") cancellationReasonModel: NgModel;

  constructor( private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService,
    private readonly intakeService: IntakeService,
    private readonly documentService: DocumentService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {    
    this.cancellationReasonModel.control.setValidators(Validators.required);

    this.requiredDocumentContainer = document.getElementById("requiredDocumentContainer");
    this.requiredDocumentUploadContainer = document.getElementById("requiredDocumentUploadContainer");
    this.additionalDocumentUploadContainer = document.getElementById("additionalDocumentUploadContainer");
 
    this.additionalDocumentButton = $(".additional-document-button");
    this.cancellationReasonTextarea = $("#cancellationReasonTextarea");
    this.confirmButton = $(".confirm-button");
  }

  ngOnInit(): void {        
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.confirmModalOverlay = $(".confirm-modal-overlay");

    this.noticeCancellationReasonTypes = this.localStorageService.getNoticeCancellationReasonTypes().filter(x => !this.expiredNoticeCancellationReasonTypes.includes(x.id));
    this.noticeCancellationRequest.tempFileFolder = Guid.create().toString();          
    this.username = this.localStorageService.getUser().displayName;

    this.isSingleNotice = this.noticeCancellationRequest.noticeCancellationRequestMappings?.length == 1;
    if (this.isSuperUserRole()) {
      this.maxCharactersAllowed = 1000;
      this.remainingCharacters = this.maxCharactersAllowed;

    }
  }

  isSuperUserRole(): boolean {
    let isSuperUser: boolean = false;
    let rolePermissionKey = this.localStorageService.getRolePermissions();
    if (rolePermissionKey!==null && rolePermissionKey!==undefined) {
      rolePermissionKey.forEach( p => {
          if (p.roleName==='PoliceSuperUser') {
            isSuperUser = true;
          }
      });
    }

    return isSuperUser;
  }

  onCloseClick(): void {
    this.bodyElement.removeClass("overflow-hidden");

    if(!this.isRequestSubmitted)
      this.removeFilesBeforeClose();

    this.close.emit(this.isRequestSubmitted);
  }

  private removeFilesBeforeClose(): void {
    if (this.requiredDocument != null) {
      this.onRemoveFileLinkClick(this.requiredDocument);
    } 

    if (this.additionalDocument != null) {
      this.onRemoveFileLinkClick(this.additionalDocument);
    }
  }
 
  onCancelTextareaInput(ev: any): void {
    this.remainingCharacters = this.maxCharactersAllowed - ev.target.value.length;    
  }

  onConfirmCancellationClick(isValid: boolean) {
    if (this.confirmButton.hasClass("disabled")) {
      return;
    }

    this.isSubmitClicked = true;
    
    if (isValid 
        && !this.isContraventionCancelled 
        && this.remainingCharacters >= 0) {
          this.showHideConfirmModal(true);
    }
  } 

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  onUploadRequiredDocumentClick(fileElement: HTMLInputElement): void {
    fileElement.value = null;
    fileElement.click();
  }
  
  onRequiredDocumentChange(ev: any): void {    
    let files: FileList = ev.target.files;            
    let uploadBarProgress = document.getElementById("requiredDocumentUploadProgress");
    let removeFileLink = document.getElementById("requiredDocumentUploadRemoveFileLink");    
 
    this.requiredDocumentUploadError = "";
    for (let index = 0; index < files.length; index++) {
      let file = files.item(index) as File;
                  
      let lastIndex = file.name.lastIndexOf(".");
      let documentExtension = file.name.substring(lastIndex + 1);     

      if (!this.isValidRequiredDocumentMimeType(file)) {
        this.requiredDocumentUploadError = "Invalid file type. PDF only. ";
      }
           
      if (!this.isSizeLessThan5MB(file)) {
        this.requiredDocumentUploadError += "Maximum file size is 5 MB."
      }
      
      if (this.requiredDocumentUploadError.length > 0) {
        return;
      }
      
      this.requiredDocumentContainer.classList.add("hide");
      this.requiredDocumentUploadContainer.classList.add("show");
      this.requiredDocumentName = file.name;
      this.uploadingDocument = true;

      let storageFileName = Guid.create().toString();
      this.documentService.uploadDocumentAsync(file, this.noticeCancellationRequest.tempFileFolder, storageFileName)
        .subscribe((result: any) => {
          if (+result.type == 1) {                               
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);    
            uploadBarProgress.innerHTML = `${percentage}%`;  
            uploadBarProgress.style.width =` ${percentage}%`;         
          } else if (+result.type == 4) {           
              this.requiredDocument = new Document({
                contentGuid: storageFileName,
                documentTypeId: DocumentTypes.ContraventionCancellationAuthorization,
                documentName: file.name,
                documentExtension: documentExtension,
                documentSize: CommonUtil.getDocumentSize(file),
                uploadedBy: this.username,
                isSubmitLater: false,
                isPublished: false                            
              });

              uploadBarProgress.style.width = '100%';
              uploadBarProgress.innerHTML = "Upload Complete";
              removeFileLink.classList.remove("disabled");    
              this.uploadingDocument = false;                                                  
              this.changeDetectorRef.detectChanges();
          }            
        });
    }
  }

  onUploadAdditionalDocumentClick(fileElement: HTMLInputElement): void {
    fileElement.value = null;
    fileElement.click();
  }

  onAdditionalDocumentUploadChange(ev: any): void {
    let files: FileList = ev.target.files;

    let uploadBarProgress = document.getElementById("additionalDocumentUploadProgress");
    let removeFileLink = document.getElementById("additionalDocumentUploadRemoveFileLink");    

    this.additionalDocumentUploadError = "";
    for (let index = 0; index < files.length; index++) {
      let file = files.item(index);
      let lastIndex = file.name.lastIndexOf(".");
      let documentExtension = file.name.substring(lastIndex + 1);     

      if (!this.isSizeLessThan5MB(file)) {
        this.additionalDocumentUploadError = "Maximum file size is 5 MB.";
      }

      if (!this.isValidAdditionalDocumentMimeType(file)) {
        this.additionalDocumentUploadError += " Invalid file type.";
      }
      
      if (this.additionalDocumentUploadError.length > 0) {
        return;
      }
      
      this.additionalDocumentUploadContainer.classList.add("show");
      this.additionalDocumentName = file.name;
      this.uploadingDocument = true;

      let storageFileName = Guid.create().toString();
      this.documentService.uploadDocumentAsync(file, this.noticeCancellationRequest.tempFileFolder, storageFileName)
        .subscribe((result: any) => {
          if (+result.type == 1) {                               
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);    
            uploadBarProgress.innerHTML = `${percentage}%`;  
            uploadBarProgress.style.width =` ${percentage}%`;
         
          } else if (+result.type == 4) {           
              this.additionalDocument = new Document({
                contentGuid: storageFileName,
                documentTypeId: DocumentTypes.ContraventionCancellationAdditional,
                documentName: file.name,
                documentExtension: documentExtension,
                documentSize: CommonUtil.getDocumentSize(file),
                uploadedBy: this.username,
                isSubmitLater: false,
                isPublished: false 
              });
            
              uploadBarProgress.style.width = '100%';
              uploadBarProgress.innerHTML = "Upload Complete";
              removeFileLink.classList.remove("disabled"); 
              this.additionalDocumentButton.attr("disabled", "");
              this.uploadingDocument = false;
              this.changeDetectorRef.detectChanges();
          }            
        });
    }
  }

  onTempDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;    
    this.documentService.downloadDocument(this.noticeCancellationRequest.tempFileFolder, "", "", storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
  }

  onRemoveFileLinkClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`; 
    this.documentService.deleteDocument(storageFileName, this.noticeCancellationRequest.tempFileFolder)
      .subscribe((result: any) => {
        if (+document.documentTypeId == DocumentTypes.ContraventionCancellationAuthorization) {
          this.requiredDocument = null;
          this.requiredDocumentUploadContainer.classList.remove("show");
          this.requiredDocumentContainer.classList.remove("hide");

        } else if (+document.documentTypeId == DocumentTypes.ContraventionCancellationAdditional) {
          this.additionalDocument = null;
          this.additionalDocumentUploadContainer.classList.remove("show");
          this.additionalDocumentButton.removeAttr("disabled");
        }
      },
      (error: any) => {});
  }

  isValidRequiredDocumentMimeType(file: File): boolean {
    return ["application/pdf"].includes(file.type.toLowerCase());
  }

  isValidAdditionalDocumentMimeType(file: File): boolean {
    return ["application/pdf", 
      "image/jpeg", 
      "image/png", 
      "image/gif", 
      "text/plain", 
      "application/msword", 
      "application/vnd.openxmlformats"].includes(file.type.toLowerCase());
  }

  isSizeLessThan2MB(file: File): boolean {
    return file.size / (Math.pow(1024, 2)) <= 2;
  }

  isSizeLessThan5MB(file: File): boolean {
    return file.size / (Math.pow(1024, 2)) <= 5;
  }

  getDocumentType(documentTypeId: number): string {
    if (documentTypeId == DocumentTypes.ContraventionCancellationAuthorization) return "Cancellation Authorization";

    if (documentTypeId == DocumentTypes.ContraventionCancellationAdditional) return "Additional Document";
  }

  onConfirmClick()
  {
    this.spinner.show();
     
    this.noticeCancellationRequest.events = [];
    this.noticeCancellationRequest.events.push(new Event({
      eventTypeId: EventTypes.RequestReceived,
      userName: this.username
    }));

    this.noticeCancellationRequest.documents = [];
    if (this.requiredDocument != null) {
      this.noticeCancellationRequest.documents.push(this.requiredDocument);
    }

    if (this.additionalDocument != null) {
      this.noticeCancellationRequest.documents.push(this.additionalDocument);
    }
   
    if (this.noticeCancellationRequest.noticeCancellationRequestMappings != null) {
      this.noticeCancellationRequest.noticeCancellationRequestMappings.forEach(childObj=> {
        if(childObj.contraventionId != null){
        childObj.vehicleSeizureId = childObj?.contravention?.vehicleSeizureId;
      }
      });
    }

    this.intakeService.createNoticeCancellationRequestAsync(this.noticeCancellationRequest)
      .subscribe((result: NoticeCancellationRequest) => {
        this.requestDecisionTypeId = result.requestDecisionTypeId;
        this.isRequestSubmitted = true;
        this.errorMessage = ""; 
        this.showHideConfirmModal(false);
        this.spinner.hide();
      }, (error: any) => {
        if (error.status == 401 || error.status == 403) {
          this.errorMessage = "You are unauthorized to perform this operation";
        }          
        else  {
          this.showErrors(error);
        }                    
        this.showHideConfirmModal(false);
        this.spinner.hide();
      });
  }

  showHideConfirmModal(show?: boolean): void {
    if (show) {
      this.confirmModalOverlay.addClass("modal-show");
      this.changeDetectorRef.detectChanges();
    } 
    else 
    {
      this.confirmModalOverlay.removeClass("modal-show");
      this.changeDetectorRef.detectChanges();
    }
  }

  getChargeName(notice: NoticeCancellationRequestMapping){
    if (notice.contravention)
    {
      if (notice.contravention.description?.indexOf('-')>0)
        return notice.contravention.description.substring(0, notice.contravention.description.indexOf('-'));
      else
        return notice.contravention.description;
    }
    else
    {
      if (notice.vehicleSeizure.description?.indexOf('-')>0)
        return notice.vehicleSeizure.description.substring(0, notice.vehicleSeizure.description.indexOf('-'));
      else
        return notice.vehicleSeizure.description;
    }
  }
}
