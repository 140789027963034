<div class="card mt-3 mt-lg-4">
    <div class="card-header">
        Registry Agent
    </div>
    <div class="card-body">
        <div class="form-group row">
            <div class="col-lg-6">
                <label for="userName">ROADS User Name</label>
                <input type="text" class="form-control" id="userName" name="userName" value="{{registryAgentInformation.userName}}" placeholder="User Name" readonly>
            </div>
            <div class="col-lg-6">
                <label for="organizationCode">Organization Code</label>
                <input type="text" class="form-control" id="organizationCode" name="organizationCode" value="{{registryAgentInformation.organizationCode}}" placeholder="Organization Code" readonly>
            </div>
        </div>
    </div>
</div>