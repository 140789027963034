import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl, NgControl } from '@angular/forms';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: SchedulerComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SchedulerComponent,
      multi: true
    }
  ]
})
export class SchedulerComponent implements OnInit, ControlValueAccessor {
  @Input() periodStartDate: Date;
  @Input() periodEndDate: Date;
  @Input() preferredTimes: string;
  @Output() preferredTimesChange = new EventEmitter<string>();

  onChange = (v) => { };
  onTouched = () => { };

  activeWeekStartDate: Date = new Date();
  activeWeekEndDate: Date = new Date();
  activeWeekDays: Date[] = new Array(7);
  weekIndex = 0;

  selectedTimeSlots: number[][] = new Array(14)
                                            .fill(0)
                                            .map(() => new Array(3)
                                            .fill(0));

  constructor(public localStorageService: LocalStorageService) {  }

  writeValue(value) { }

  validate({ value }: UntypedFormControl) {
    const hasSelectionsMade = false;
    return hasSelectionsMade && {
      invalid: true
    }
  }

  registerOnChange(fn) { this.onChange = fn; }
  registerOnTouched(fn) { this.onTouched = fn; }

  ngOnInit(): void {
    if(this.preferredTimes) {
      this.selectedTimeSlots = JSON.parse(this.preferredTimes);
    }    

    this.activeWeekStartDate = new Date(this.periodStartDate);

    this.setActiveWeek(0);
  }

  onPrevWeekClick() {
    this.weekIndex-=1;
    this.setActiveWeek(-1);
  }

  onNextWeekClick() {
    this.weekIndex+=1;
    this.setActiveWeek(1);
  }

  updateAvailability(dayIndex, timePart, isAvaillable) {
    this.selectedTimeSlots[dayIndex][timePart] = isAvaillable? 1 : 0;

    this.preferredTimes = JSON.stringify(this.selectedTimeSlots);
    this.preferredTimesChange.emit(this.preferredTimes);

    this.onChange(this.preferredTimes);
    this.onTouched();
  }

  isStatHoliday(date: Date): boolean {
    let dateToCompare = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return this.localStorageService.getHolidayList()?.find(h => +new Date(h.holidayDate) === +dateToCompare) != null;    
  }

  setActiveWeek(weekInterval: number) {
    this.activeWeekStartDate = this.addDays(this.activeWeekStartDate, weekInterval * 7);
    this.activeWeekEndDate = this.addDays(this.activeWeekStartDate, 6);

    this.setActiveWeekDays();
  }

  setActiveWeekDays() {
    for (let i = 0; i < 7; i++) {
      this.activeWeekDays[i] = this.addDays(this.activeWeekStartDate, i);
    }
  }

  isAvailableForSchedule(date: Date): boolean {
    if (date > this.periodEndDate) {
      return false;
    }
    
    return (date.getDay()  !== 0 && date.getDay() !== 6) && !this.isStatHoliday(date);
  }

  addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }

}
