import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'intake-footer',
  templateUrl: './intake-footer.component.html',
  styleUrls: ['./intake-footer.component.scss']
})
export class IntakeFooterComponent implements OnInit {
  year: number;

  constructor() { }

  ngOnInit(): void {
    this.year = (new Date()).getFullYear();
  }

}
