import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EventTypes } from '@apis/shared/enums/app.enum';
import { Event } from '@apis/shared/models/event.model';
import { EventService } from "@apis/shared/services/event.service";
import { DocumentService } from "@apis/shared/services/document.service";
import { KeycloakService } from 'keycloak-angular';

import * as fileSaver from 'file-saver';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Document } from '@apis/shared/models/document.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { DocumentTypes } from '../../../enums/app.enum';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss']
})
export class NoteModalComponent implements OnInit {
  reviewId: number;
  contraventionId: number;
  vehicleSeizureId: number;  
  lateReviewRequestId: number;
  paymentAdditionalTimeRequestId: number;
  judicialReviewId: number;
  stayOrderId: number;
  documentRefTypeName: string = "Events";
  documentRefTypeNumber: string;
  
  @Output() close: EventEmitter<Event> = new EventEmitter();

  bodyElement: JQuery<HTMLElement>;   
  modalOverlay: JQuery<HTMLElement>;
  maxCharacters: number = 500;
  modalTextarea: JQuery<HTMLElement>;
  addButton: JQuery<HTMLElement>;
  event: Event = new Event();
  username: string;
  eventId: number;
   
  tempFileFolder: string;
  documents: Document[] = [];  
  invalidDocuments: Document[] = [];  

  disableFileUpload: boolean = false;
  validFileExtensions = [".pdf"];
  fileErrorMessage: string;
  isUpdateMode: boolean = false;

  noticeCancellationRequestId:number;
          
  constructor(private readonly eventService: EventService,
    private readonly documentService: DocumentService,
    private readonly keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService) { }

  ngOnInit(): void {      
    this.tempFileFolder = Guid.create().toString();      

    if (!this.eventId)
    {
      this.event = new Event({ 
        reviewId: this.reviewId, 
        contraventionId: this.contraventionId,
        vehicleSeizureId: this.vehicleSeizureId,
        lateReviewRequestId: this.lateReviewRequestId,
        paymentAdditionalTimeRequestId: this.paymentAdditionalTimeRequestId,
        judicialReviewId:this.judicialReviewId,
        noticeCancellationRequestId:this.noticeCancellationRequestId,
        userName: this.keycloakService.getUsername(),
        eventTypeId: EventTypes.NoteAdded,
        eventDetails: "",
        isCreatedByCaseAdministrator: this.keycloakService.isUserInRole(Constants.Role.CASE_ADMINISTRATOR)
      });
    }
    else
    {
      this.spinner.show();
      this.isUpdateMode = true;
      this.eventService.getEventByIdAsync(this.eventId)
        .subscribe((event: Event) => {
          this.event = event;
          this.documents = this.event.documents;
          this.maxCharacters -= this.event.eventDetails.length;
          this.spinner.hide();
        }, (error: any) => this.spinner.hide());   
    }

    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.modalOverlay = $(".modal-overlay");         
    this.modalTextarea = $(".modal-textarea");  
    this.addButton = $(".add-button"); 

    // If the user adds multiple notes, the modal's previous "disabled" status should not be preserved
    if (this.addButton.hasClass("disabled")) {
      this.addButton.removeClass("disabled");
    }
  }

  onUploadFileChanged(ev: any): void {
    var fileList = (ev.target.files as FileList);
    let existingDocumentCount = this.documents.length;
    
    let validFiles: File[] = [];
    for (let index = 0; index < fileList.length; index++) {
      let file = fileList[index];    

      let document = new Document({
        reviewId: this.reviewId,
        contraventionId: this.contraventionId,
        vehicleSeizureId: this.vehicleSeizureId,
        lateReviewRequestId: this.lateReviewRequestId,
        paymentAdditionalTimeRequestId: this.paymentAdditionalTimeRequestId,
        judicialReviewId:this.judicialReviewId,   
        stayOrderId:this. stayOrderId,    
        contentGuid: Guid.create().toString(),
        documentTypeId: DocumentTypes.Note,
        documentName: file.name,            
        documentExtension: file.name.substring(file.name.lastIndexOf(".") + 1),
        documentSize: this.getDocumentSize(file),
        uploadedBy: this.keycloakService.getUsername(),
        uploadedDate: DateUtil.today(),
        isSubmitLater: false,
        isPublished: false,
        isUploading: true
      });
      
      if (this.isValidFile(file)) {
        if (this.documents.findIndex(d => d.documentName.toLowerCase() == file.name.toLowerCase()) == -1) {
          file["contentGuid"] = document.contentGuid;
          validFiles.push(file);
          this.documents.push(document);
        }
      } else {
        if (this.invalidDocuments.findIndex(d => d.documentName.toLowerCase() == file.name.toLowerCase()) == -1) {
          document["error"] = file["error"];
          this.invalidDocuments.push(document);
        }
      }                                                 
    } 

    setTimeout(() => {
      validFiles.forEach((file: File, index: number) => {
        this.uploadFile(file, index + existingDocumentCount)
      });
    }, 1000);
            
  } 

  uploadFile(file: File, index: number): void {   
    let uploadBarProgress = document.getElementById(`uploadBarProgress_${index}`);
    let newDocument = this.documents[index];
    this.documentService.uploadDocumentAsync(file, this.tempFileFolder, file["contentGuid"])
      .subscribe((result: any) => {                  
        if (+result.type == 1) {                               
            let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);    
            uploadBarProgress.innerHTML = `${percentage}%`;  
            uploadBarProgress.style.width =` ${percentage}%`;
         
        } else if (+result.type == 4) {                              
          uploadBarProgress.innerHTML = "Upload Complete";
          newDocument.isUploading = false;
        }
      }, (error: any) => {
        newDocument["error"] = "Something went wrong. Please try uploading the document again.";
        this.invalidDocuments.push(newDocument);
        let index = this.documents.findIndex(d => d.contentGuid == newDocument.contentGuid);
        if (index != -1) {
          // Minor bug: If a user starts a new document upload while another document's upload encounters an error, this splice will target the wrong document.
          // The user will be unable to submit, and will need to close and re-open the modal to fix the issue.
          this.documents.splice(index, 1);
        }
      });
  }

  // Returns true if any document upload is currently in progress.
  isUploadInProgress(): boolean {
    return this.documents.some(document => document.isUploading);
  }

  onDocumentNameClick(document: Document): void {
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`;   

    if (document.documentId > 0) //Document at permanent location
    {
      this.documentService.downloadDocument("", this.documentRefTypeName , this.documentRefTypeNumber, storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
    }
    else //Document at temporary location
    {
      this.documentService.downloadDocument(this.tempFileFolder, "", "", storageFileName, document.documentName)
      .subscribe((result: Blob) => {          
          fileSaver.saveAs(result, document.documentName);          
        },
        (error: any) => {});
    }
  }

  onRemoveFileLinkClick(document: Document): void {    
    if (this.disableFileUpload || this.isUploadInProgress()) { // The document upload code uses the document's index. Deleting documents would change the index and cause errors.
      return;
    }
    let storageFileName = `${document.contentGuid}.${document.documentExtension}`; 
    if (document.documentId > 0) //Document at permanent location
    {
      //Mark document for deletion
      document.isDeleted =  !document.isDeleted;
    }
    else //Document at temporary location
    {
      this.documentService.deleteDocument(storageFileName, this.tempFileFolder)
        .subscribe((result: any) => { 
          let index = this.documents.findIndex(d => d.contentGuid == document.contentGuid);
          if (index != -1) {
            this.documents.splice(index, 1);
          }
        },
        (error: any) => { });
    }
  }

  onTextareaInput(ev: any): void {
    this.maxCharacters = 500 - ev.target.value.length;    
  }
 
  onCloseIconClick(): void {   
    this.removeDocumentsCloseModal();
  }

  onCancelClick(): void {
    this.removeDocumentsCloseModal();
  }

  private removeDocumentsCloseModal(): void {
    this.documents.forEach((d: Document)=> {
      if (d.documentId <=0 )
      {
        let storageFileName = `${d.contentGuid}.${d.documentExtension}`
        this.documentService.deleteDocument(storageFileName, this.tempFileFolder)
          .subscribe((result: any) => {});
      }
      else if (d.isDeleted)
        d.isDeleted = false;
    });
  
    this.bodyElement.removeClass("overflow-hidden");   
    this.close.emit();
  }

  onAddClick(ev: any): void {
    if (this.maxCharacters < 0) {
      return;
    }

    if (this.addButton.hasClass("disabled")) {
      return;
    }
    
    this.addButton.addClass("disabled");
    this.addButton.addClass("saving");
    this.disableFileUpload = true;

    this.event.documents = this.documents;

    if (this.isUpdateMode)
    {
      //Remove delete marked documents
      this.documents.forEach(document => {
        if (document.isDeleted)
        {
          let storageFileName = `${document.contentGuid}.${document.documentExtension}`; 
          this.documentService.deleteFinalizedDocument(storageFileName, this.documentRefTypeName, this.documentRefTypeNumber)
          .subscribe((result: any) => { 
            this.documentService.deletePermanentDocument(document.documentId, document.contentGuid)
            .subscribe((result: any) => { });
          },
          (error: any) => { });
        }
      });

      this.event.documents = this.documents.filter(x=> !x.isDeleted);

      this.eventService.putEventAsync(this.event)
        .subscribe((event: Event) => {

          if (this.event.documents?.filter(x=>x.documentId <= 0).length > 0) {
            this.documentService.finalizeDocuments(this.tempFileFolder, "Events", this.documentRefTypeNumber)
            .subscribe((result: any) => {});
          };
          
          this.bodyElement.removeClass("overflow-hidden");
          this.close.emit(event);
        });
    }
    else
    {
      this.eventService.postEventAsync(this.event)
        .subscribe((event: Event) => {

          if (this.event.documents?.length > 0) {
            this.documentService.finalizeDocuments(this.tempFileFolder, "Events", this.documentRefTypeNumber)
            .subscribe((result: any) => {});
          };
          
          this.bodyElement.removeClass("overflow-hidden");
          this.close.emit(event);
        });
    }
  }

  isValidFile(file: File): boolean {
    let size = file.size;
    let error = ""
    if ((size/1024)/1024 > 5) {
      error = "Maximum upload size is 5 MB. "
    } 
        
    let fileExtension = file.name.substring(file.name.lastIndexOf("."));     
             
    if (!this.validFileExtensions.includes(fileExtension.toLowerCase())) {
      error += "Invalid file extension, PDF only.";           
    }

    if (error.length > 0) {
      file["error"] = error;
      
    }

    return file["error"] == null;
  }
     
  private getDocumentSize(file: File): string {
    if (file == null) {
      return null;
    }

    let size = file.size; 

    if (size < 1024) {      
      return `${size} bytes`;
    }
      
    if (size >= 1024 && size < 1048576) {
      return `${(size/1024).toFixed(2)}kb`;
    }
      
    if (size >= 1048576 && size < 1073741824) {
      return `${((size/1024)/1024).toFixed(2)}mb`;
    }
          
    if (size >= 1073741824) {     
        return `${(((size/1024)/1024)/1024).toFixed(2)}gb`;  
    }      
  }
}