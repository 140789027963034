import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardtype',
})
export class CardTypePipe implements PipeTransform {
  constructor() {}

  transform(cardType: string): string {
    // AM = American Express, DI = Diners, JB = JCB, MC = MasterCard, NN = Discover, VI = Visa.

    switch (cardType.toUpperCase()) {
      case 'AM':
        return 'American Express';
      case 'DI':
        return 'Diners';
      case 'JB':
        return 'JCB';
      case 'MC':
        return 'MasterCard';
      case 'NN':
        return 'Discover';
      case 'VI':
        return 'Visa';
      default:
        return cardType;
    }
  }
}
