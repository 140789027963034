import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from "@angular/router";

import * as $ from "jquery";
import { filter } from 'rxjs/operators';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { RecipientTypes, ContraventionTypes } from '@apis/shared/enums/app.enum';
import { Constants } from '../../helpers/constants';
import { ConnectivityService } from 'apps/intake/src/shared/services/connectivity.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Contravention } from '@apis/shared/models/contravention.model';
import { environment } from 'apps/intake/src/environments/environment';
import { TicketService } from 'apps/intake/src/shared/services/ticket.service';


const ONLINE_CONFIRMPATH = 'print-confirmation';
const OFFLINE_CONFIRMPATH = 'offline-print-confirmation';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  stopInformation: StopInformation;
  mappedRoutes: any[] = [];
  routePath: string = "";
  routeIndex: number = 0;
  mySubscription: any;
  availableTSA: boolean = false;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router,
              private readonly intakeService: IntakeService,
              private connectivityService: ConnectivityService,
              private localStorageService: LocalStorageService,
              private ticketService: TicketService) {
                //Check if TSA feature is allowed or not
                this.availableTSA = environment.availableTSA && this.ticketService.hasTrafficTicketPermissions();

                this.mySubscription = this.router.events.subscribe((event) => {
                  if (event instanceof NavigationEnd) {
                    this.stopInformation = this.intakeService.getStopInformationModel();
                  }
                });
              }

  ngOnInit(): void {  
    this.stopInformation = this.intakeService.getStopInformationModel();
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
  
  get isOnline() {
    return this.connectivityService.isUserOnline;
    //return !!window.navigator.onLine;
  }
  
  getMappedRoutes(): any[] {
    this.mappedRoutes = [];
    let pathname = window.location.pathname;

    let routes = this.router.config.filter(config => config.children != null && pathname.indexOf(config.path) > -1);
    if (routes.length > 0) {

      routes[0].children.forEach(child => {
        if (child.data != null) {
          if  ((child.path.split("/")[1] == 'vehicle-seizure' && ( this.stopInformation?.isIRSSelected != true && this.stopInformation?.isSDPSelected != true))
              ||(child.path.split("/")[1] == 'vehicle-seizure' && this.isCommercialContravention() && this.stopInformation.isSDPSelected == false)
              ||(child.path.split("/")[1] == 'vehicle-information' && this.stopInformation?.recipient?.recipientTypeId == RecipientTypes.NotInVehicle)
              ||(['contravention-details','additional-details'].includes(child.path.split("/")[1]) && (this.stopInformation?.contraventions?.length==0 || this.stopInformation.isOnlySDPActive || this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1 || !this.stopInformation.userIsAddingDetails))
              ||(child.path.split("/")[1] == 'sdp-notice-details' && (!this.stopInformation?.isOnlySDPActive || this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1 || !this.stopInformation.userIsAddingDetails))
              ||(child.path.split("/")[1] == 'additional-details' && this.stopInformation?.isIRSSelected == false && this.stopInformation?.isSpeedingSelected == false)
              )
            return;

          // supress the print confirmation step not associated to our online/offline status
          // ie. when online, don't show the Offline Print Confirmation Step
          let path = child.path.split("/")[1];
          if (pathname.indexOf(child.path.split("/")[0] + "/") > -1) {
            if (this.isOnline && path!==OFFLINE_CONFIRMPATH) {
              this.mappedRoutes.push({path: child.path.split("/")[1], label: child.data.label, section: child.path.split("/")[0], url: child.path});
            }
            if (!this.isOnline && path!==ONLINE_CONFIRMPATH) {
              this.mappedRoutes.push({path: child.path.split("/")[1], label: child.data.label, section: child.path.split("/")[0], url: child.path});
            }
          }
        } 
      });          
    }    
    return this.mappedRoutes;
  }

  // JTI-3353
  isCommercialContravention(): boolean {
    var irsContraventionTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isIRS).map(s => s.id);
    var irsContravention: Contravention = this.stopInformation.contraventions.find(x => irsContraventionTypeIds.includes(+x.contraventionTypeId));
    if (irsContravention!=null) {
      if( irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial1st ||
          irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial2nd ||
          irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial3rd) {
            return true;
      }
    }

    return false;
  }

  hasSection(route: any): boolean {
    return window.location.pathname.indexOf(route.section) > -1;
  }

  getStepperLineClass(route: any, index: number): string {
    var lastIndex = window.location.pathname.lastIndexOf("/") + 1;    
    this.routePath = window.location.pathname.substring(lastIndex);
    this.routeIndex = this.mappedRoutes.findIndex(m => m.path === this.routePath);
           
    if (route.path === this.routePath || index < this.routeIndex) {
      return "stepper-line-active";
    }

    return "stepper-line-inactive";
  }

  getStepperButtonClass(route: any, index: number): string {
    var lastIndex = window.location.pathname.lastIndexOf("/") + 1;    
    this.routePath = window.location.pathname.substring(lastIndex);   
    
    this.routeIndex = this.mappedRoutes.findIndex(m => m.path === this.routePath);
    
    /*
    stepper-state-active
    stepper-state-inactive
    stepper-state-complete
    stepper-complete-inactive
    stepper-complete-active
    */
  

    if (route.path === this.routePath && this.routeIndex < this.mappedRoutes.length - 1) {
      return "stepper-state-active";
    }

    if (index > 0 && index > this.routeIndex && index < this.mappedRoutes.length - 1) {
      return "stepper-state-inactive";
    }
    
    if (index < this.routeIndex) {
      return "stepper-state-complete";
    }

    if (index === this.mappedRoutes.length - 1 && this.routeIndex < this.mappedRoutes.length - 1) {
      return "stepper-complete-inactive";
    }

    if (this.routeIndex === this.mappedRoutes.length - 1) {
      return "stepper-complete-active";
    }
    
  }

}
