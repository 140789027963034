import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, TitleCasePipe } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { LocalStorageService } from './services/local-storage.service';
import { AuthGuard } from './security/auth.guard';
import { RsgAuthGuard } from './security/rsg-auth.guard';
import { MatSliderModule } from '@angular/material/slider';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StepperComponent } from './components/stepper/stepper.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DragDropDirective } from './directives/dragdrop.directive';
import { LookupService } from './services/lookup.service';
import { ContraventionUtilityService } from './services/contravention-utility.service';
import { SectionDetailsComponent } from './components/section-details/section-details.component';
import { MatSelectModule } from '@angular/material/select';
import { CountryProvinceComponent } from './components/country-province/country-province.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SchedulerComponent } from './components/scheduler/scheduler.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { ImpairmentScreeningComponent } from './components/impairment-screening/impairment-screening.component';
import { ImpairmentScreeningAlertComponent } from './components/impairment-screening-alert/impairment-screening-alert.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SelectRequiredValidatorDirective } from './directives/select-required-validator.directive';
import { FocusInvalidInputDirective } from './directives/focus-invalid-input.directive';
import { ContraventionNumberValidatorDirective } from './directives/contravention-number-validator.directive';
import { ContraventionNumberDuplicateValidatorDirective } from './directives/contravention-number-duplicate-validator.directive';
import { SeizureNumberValidatorDirective } from './directives/seizure-number-validator.directive';
import { SeizureNumberDuplicateValidatorDirective } from './directives/seizure-number-duplicate-validator.directive';
import { StartDateValidatorDirective } from './directives/start-date-validator.directive';
import { EndDateValidatorDirective } from './directives/end-date-validator.directive';
import { Stepper2Component } from './components/stepper2/stepper2.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { NoteModalComponent } from "./components/modals/note-modal/note-modal.component";
import { DeleteNoteModalComponent } from "./components/modals/delete-note-modal/delete-note-modal.component";
import { TransactionIdPipe } from "./pipes/transactionid.pipe";
import { CardTypePipe } from "./pipes/cardtype.pipe";
import { FutureDateValidatorDirective } from './directives/future-date-validator.directive';
import { BirthDateValidatorDirective } from './directives/birth-date-validator.directive';
import { BasicDateValidatorDirective } from './directives/basic-date-validator.directive';
import { SettingService } from './services/setting.service';
import { TitleCaseDirective } from './directives/title-case.directive';
import { TimePipe } from "./pipes/time.pipe";
import { AlbertaLicenceDirective } from './directives/alberta-licence.directive';
import { AlbertaLicenceValidatorDirective } from './directives/alberta-licence-validator.directive';
import { MVIDNumberDirective } from './directives/mvid-number.directive';
import { MVIDNumberValidatorDirective } from './directives/mvid-number-validator.directive';
import { AccessControlComponent } from './components/access-control/access-control.component';
import { RegistryAgentInformationComponent } from './components/registry-agent-information/registry-agent-information.component';
import { RegistryPayerInformationComponent } from './components/registry-payer-information/registry-payer-information.component';
import { TransactionIdValidatorDirective } from './directives/transaction-id-validator.directive';
import { TransactionIdFormatterDirective } from './directives/transaction-id-formatter.directive';
import { ContactSaferoadsModalComponent } from './components/modals/contact-saferoads-modal/contact-saferoads-modal.component';
import { RegistryApplicantInformationComponent } from './components/registry-applicant-information/registry-applicant-information.component';
import { AutoSchedulerComponent } from './components/auto-scheduler/auto-scheduler.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { CountdownModule } from 'ngx-countdown';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PassengerPositionComponent } from './components/passenger-position/passenger-position.component';
import { MaxNumberValidatorDirective } from './directives/max-number-validator.directive';
import { EmergencyContraventionNumberValidatorDirective } from './directives/emergency-contravention-number-validator.directive';
import { EmergencySeizureNumberValidatorDirective }  from './directives/emergency-seizure-number-validator.directive';
import { MinNumberValidatorDirective } from './directives/min-number-validator.directive';
import { DetachmentsFilterPipe } from "./pipes/detachments-filter.pipe";
import { ReportSectionDetailsComponent } from 'apps/intake/src/shared/components/report-section-details/report-section-details.component';
import { MustMatchValidatorDirective } from './directives/must-match-validator.directive';
import { PaperContraventionNumberValidatorDirective } from './directives/paper-contravention-number-validator.directive';
import { FailedAttendanceDeclarationComponent } from './components/failed-attendance-declaration/failed-attendance-declaration.component';
import { TowCompanyService } from './services/tow-company.service';
import { AppUpdateService } from './services/app-update.service';
import { TicketNumberValidatorDirective } from './directives/ticket-number-validator.directive';
import { IssuanceDateValidatorDirective } from './directives/issuance-date-validator.directive';
import { OrderByPipe } from "./pipes/order-by.pipe";
import { WorkingdayValidatorDirective } from "./directives/workingday-validator.directive";
import { StorageService } from './services/storage.service';
import { AfterMinimumAgeDateValidatorDirective } from './directives/after-minimum-age-date-validator.directive';
import { provideNgxMask } from 'ngx-mask';
import { NgxMaskDirective } from 'ngx-mask';
import { ModdedTitleCasePipe } from '@apis/shared/pipes/moddedTitleCase.pipe';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    HeaderComponent,
    FileUploadComponent,
    DragDropDirective,
    SectionDetailsComponent,
    CountryProvinceComponent,
    SchedulerComponent,
    PaymentDetailsComponent,
    ImpairmentScreeningComponent,
    ImpairmentScreeningAlertComponent,
    SelectRequiredValidatorDirective,
    FocusInvalidInputDirective,
    ContraventionNumberValidatorDirective,
    TicketNumberValidatorDirective,
    ContraventionNumberDuplicateValidatorDirective,
    SeizureNumberValidatorDirective,
    SeizureNumberDuplicateValidatorDirective,
    StartDateValidatorDirective,
    EndDateValidatorDirective,
    FutureDateValidatorDirective,
    BirthDateValidatorDirective,
    BasicDateValidatorDirective,
    StepperComponent,
    Stepper2Component,
    NoteModalComponent,
    DeleteNoteModalComponent,
    TransactionIdPipe,
    CardTypePipe,
    TitleCaseDirective,
    TimePipe,
    AlbertaLicenceDirective,
    AlbertaLicenceValidatorDirective,
    MVIDNumberDirective,
    MVIDNumberValidatorDirective,
    AccessControlComponent,
    RegistryAgentInformationComponent,
    RegistryPayerInformationComponent,
    TransactionIdValidatorDirective,
    TransactionIdFormatterDirective,
    ContactSaferoadsModalComponent,    
    AutoSchedulerComponent,
    CountdownTimerComponent,
    RegistryApplicantInformationComponent,
    UnauthorizedComponent,
    PassengerPositionComponent,
    MaxNumberValidatorDirective,
    EmergencyContraventionNumberValidatorDirective,
    EmergencySeizureNumberValidatorDirective,
    MinNumberValidatorDirective,
    DetachmentsFilterPipe,
    ReportSectionDetailsComponent,
    MustMatchValidatorDirective,
    PaperContraventionNumberValidatorDirective,
    FailedAttendanceDeclarationComponent,
    IssuanceDateValidatorDirective,
    OrderByPipe,
    WorkingdayValidatorDirective,
    AfterMinimumAgeDateValidatorDirective,
    ModdedTitleCasePipe
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    NgxSpinnerModule,
    MatSelectModule,
    MatAutocompleteModule,
    BsDatepickerModule.forRoot(),
    AgGridModule,
    CountdownModule,
    NgxMaskDirective,
    ToastrModule.forRoot()
  ],
  exports: [
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    NgxSpinnerModule,
    HeaderComponent,
    StepperComponent,
    Stepper2Component,
    FileUploadComponent,
    SectionDetailsComponent,
    PaymentDetailsComponent,
    MatSelectModule,
    CountryProvinceComponent,
    MatAutocompleteModule,
    SchedulerComponent,
    ImpairmentScreeningComponent,
    ImpairmentScreeningAlertComponent,
    BsDatepickerModule,
    SelectRequiredValidatorDirective,
    FocusInvalidInputDirective,
    ContraventionNumberValidatorDirective,
    TicketNumberValidatorDirective,
    ContraventionNumberDuplicateValidatorDirective,
    SeizureNumberValidatorDirective,
    SeizureNumberDuplicateValidatorDirective,
    StartDateValidatorDirective,
    EndDateValidatorDirective,
    FutureDateValidatorDirective,
    BirthDateValidatorDirective,
    BasicDateValidatorDirective,
    TransactionIdPipe,
    CardTypePipe,
    TitleCaseDirective,
    TimePipe,
    AlbertaLicenceDirective,
    AlbertaLicenceValidatorDirective,
    MVIDNumberDirective,
    MVIDNumberValidatorDirective,
    AccessControlComponent,
    RegistryAgentInformationComponent,
    RegistryPayerInformationComponent,
    TransactionIdValidatorDirective,
    TransactionIdFormatterDirective,
    ContactSaferoadsModalComponent,
    AutoSchedulerComponent,
    CountdownTimerComponent,
    RegistryApplicantInformationComponent,
    UnauthorizedComponent,
    PassengerPositionComponent,
    MaxNumberValidatorDirective,
    EmergencyContraventionNumberValidatorDirective,
    EmergencySeizureNumberValidatorDirective,
    MinNumberValidatorDirective,
    DetachmentsFilterPipe,
    ReportSectionDetailsComponent,
    MustMatchValidatorDirective,
    PaperContraventionNumberValidatorDirective,
    FailedAttendanceDeclarationComponent,
    IssuanceDateValidatorDirective,
    OrderByPipe,
    WorkingdayValidatorDirective,
    AfterMinimumAgeDateValidatorDirective,
    ModdedTitleCasePipe
  ],
  providers: [    
    LocalStorageService,
    AuthGuard,
    RsgAuthGuard,
    LookupService,
    ContraventionUtilityService,
    TowCompanyService,
    DatePipe,
    CurrencyPipe,
    SettingService,
    TitleCasePipe,
    AppUpdateService,
    StorageService,
    ModdedTitleCasePipe,
    provideNgxMask()
  ]
})
export class SharedModule { }
