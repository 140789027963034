<div class="d-flex flex-column w-100">
    <div class="intake-stepper">
        <div *ngIf="showSubHeader()" class="contravention-sub-header">
            <stepper></stepper>
        </div>
    </div>
    <div class="d-flex justify-content-end pt-2">
        <a class="cancel-link submissionMain" *ngIf="showCancelLink()" [routerLink]="['/']">CANCEL</a>
    </div>
    <div class="d-flex justify-content-center pb-2 pb-lg-5">
        <router-outlet></router-outlet>
    </div>    
</div>