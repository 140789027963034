


export class StandardizedFieldSobrietyTest {
    public standardizedFieldSobrietyTestId: number = 0;
    public testDemandTime: string;
    public testStartedTime: string;
    public testResultTypeId: number = 0;

    constructor(init?: Partial<StandardizedFieldSobrietyTest>) {
        
        Object.assign(this, init);
    }
}
