<div class="stepper">   
    <div class="stepper-button-container">
        <ng-container *ngFor="let route of getMappedRoutes(); index as i;">
            <ng-container *ngIf="hasSection(route)">
                <div *ngIf="+i > 0" [ngClass]="getStepperLineClass(route, i)"></div>
                <div [ngClass]="getStepperButtonClass(route, i)"  [routerLink]="getStepperButtonClass(route, i) == 'stepper-state-complete' ? route.url : null">
                    <span *ngIf="i >= routeIndex && i < +mappedRoutes.length-1">{{i + 1}}</span>
                </div>
            </ng-container>
        </ng-container>               
    </div>

    <div class="stepper-label-container">
        <ng-container *ngFor="let route of getMappedRoutes(); index as i;">  
            <ng-container *ngIf="hasSection(route)">                      
                <div class="stepper-label" >
                    <div class="stepper-label-text">{{availableTSA && route.label == "Recipient Information"?"Recipient / Accused Information":route.label }}</div>
                </div>
            </ng-container>
        </ng-container>
    </div>             
</div>
