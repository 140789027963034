

import { Vehicle } from './vehicle.model';
import { Recipient } from './recipient.model';
import { Document } from './document.model';
import { Review } from './review.model';
import { Event } from './event.model';
import { PoliceServiceInformation } from './police-service-information.model';
import { StopInformation } from './stop-information.model';
import { NoticeCancellationRequestMapping } from './notice-cancellation-request-mapping.model';
import { ReviewItem } from './review-item.model';
import { LateReviewRequestItem } from './late-review-request-item.model';
import { OccurrenceLocation } from './occurrence-location.model';
import { JudicialReview } from './judicial-review.model';


export class VehicleSeizure {
    public vehicleSeizureId: number = 0;
    public policeFileNumber: string;
    public recipientId: number;
    public seizureNumber: string;
    public secondarySeizureNumber: string;
    public seizureTypeId: number = 0;
    public secondaryReliefDecisionTypeId: number = 0;
    public seizureStatusTypeId: number = 0;
    public seizureDate: Date;
    public seizureTime: string;
    public seizureDuration: number = 0;
    public seizureDurationType: string;
    public vehicleReleaseDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    public locationTypeId: number = 0;
    public additionalLocationDetails: string;
    public vehicleId: number = 0;
    public contraventionId: number;
    public towCompanyName: string;
    public towLotAddress: string;
    public towLotCity: string;
    public towLotPostalCode: string;
    public towLotPhoneNumber: string;
    public towLotFaxNumber: string;
    public towLotRepresentative: string;
    public towLotEmailAddress: string;
    public policeOfficerFullName: string;
    public submissionDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    public submissionTime: string;
    public isTrueInformation: boolean = false;
    public officerSubmissionDeclarationTypeId: number = 0;
    public reviewRequestExtensionDate: Date;
    public isReServeRequired: boolean;
    public reServeReason: string;
    public reServeDate: Date;
    public reServeMethodTypeId: number;
    public additionalNotes: string;
    public vehicle: Vehicle;
    public recipient: Recipient;
    public documents: Document[] = [];
    public reviewItems: ReviewItem[] = [];
    public lateReviewRequestItems: LateReviewRequestItem[] = [];
    public noticeCancellationRequestMappings: NoticeCancellationRequestMapping[] = [];
    public events: Event[] = [];
    public policeServiceInformation: PoliceServiceInformation[] = [];
    public contraventionNumber: string;
    public review: Review;
    public reviewState: number = 0;
    public reviewDeadLine: Date;
    public relief: Review;
    public reliefState: number = 0;
    public reliefDeadLine: Date;
    public isAnotherSeizureExists: boolean;
    public anotherSeizureTypeId: number;
    public anotherSeizureStatusTypeId: number;
    public isRecipientYouth: boolean = false;
    public driversLicenceStatusTypeName: string;
    public locationTypeName: string;
    public seizureStatusTypeName: string;
    public stopInformationId: number = 0;
    public contraventionTypeId: number = 0;
    public isPaymentInProgress: boolean = false;
    public stopSubmissionVersion: string;
    public occurrenceLocation: OccurrenceLocation;
    public isJudicialReviewServed: boolean;
    public judicialReviews: JudicialReview[] = [];
    public issueDate: Date;
    public officerAdditionalDetailsDeclarationTypeId: number;
    public additionalDetailsDate: Date;

    constructor(init?: Partial<VehicleSeizure>) {
        
        Object.assign(this, init);
    }
}
