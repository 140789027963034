

import { RecipientContact } from './recipient-contact.model';
import { MovesRecipientContact } from './moves-recipient-contact.model';
import { RecipientIdentification } from './recipient-identification.model';

export class Recipient {
    public recipientId: number = 0;
    public recipientTypeId: number;
    public firstName: string;
    public lastName: string;
    public otherName: string;
    public recipientContactId: number;
    public movesRecipientContactId: number = null;
    public isNoLicenceProduced: boolean = false;
    public recipientIdentificationId: number = 0;
    public isYouth: boolean;
    public nationalSafetyNumber: string;
    public eyeColor: string;
    public hairColor: string;
    public weight: string;
    public height: string;
    public movesLookupDate: string;
    public recipientContact: RecipientContact;
    public movesRecipientContact: MovesRecipientContact;
    public recipientIdentification: RecipientIdentification;

    constructor(init?: Partial<Recipient>) {
        
        Object.assign(this, init);
    }
}
