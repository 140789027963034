import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@apis/shared/shared.module"
import { VehicleSeizureComponent } from './vehicle-seizure/vehicle-seizure.component';
import { ContraventionSelectionComponent } from './contravention-selection/contravention-selection.component';
import { VehicleInformationComponent } from './vehicle-information/vehicle-information.component';
import { PrintAndIssueComponent } from './print-and-issue/print-and-issue.component';
import { PrintConfirmationComponent } from './print-confirmation/print-confirmation.component';
import { OfflinePrintConfirmationComponent } from './print-confirmation/offline-print-confirmation.component';
import { RecipientInformationComponent } from './recipient-information/recipient-information.component'; 
import { ContraventionChargeSelectionComponent } from '../../../shared/components/contravention-charge-selection/contravention-charge-selection.component';
import { CancelContraventionModalComponent } from './print-confirmation/modals/cancel-contravention-modal/cancel-contravention-modal.component';
import { OfflinePrintNapComponent } from './print-confirmation/printing/offline-print-nap/offline-print-nap.component';
import { OfflinePrintSeizureComponent } from './print-confirmation/printing/offline-print-seizure/offline-print-seizure.component';
import { OfflinePrintMobileNapComponent } from './print-confirmation/printing/offline-print-nap/offline-print-mobile-nap.component';
import { OfflinePrintMobileSeizureComponent } from './print-confirmation/printing/offline-print-seizure/offline-print-mobile-seizure.component';
import { OfflinePrintRoadsideAppealComponent } from './print-confirmation/printing/offline-print-roadside-appeal/offline-print-roadside-appeal.component';
import { ReissueContraventionModalComponent } from './print-confirmation/modals/reissue-contravention-modal/reissue-contravention-modal.component';
import { NgxMaskDirective } from 'ngx-mask';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  declarations: [
    RecipientInformationComponent,
    ContraventionSelectionComponent,
    VehicleInformationComponent,
    VehicleSeizureComponent,
    PrintAndIssueComponent,
    PrintConfirmationComponent,
    OfflinePrintNapComponent,
    OfflinePrintMobileNapComponent,
    OfflinePrintSeizureComponent,
    OfflinePrintMobileSeizureComponent,
    OfflinePrintConfirmationComponent,
    OfflinePrintRoadsideAppealComponent,
    ContraventionChargeSelectionComponent,
    CancelContraventionModalComponent,
    ReissueContraventionModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    NgxMaskDirective,
    AgGridModule
  ]
})
export class ContraventionSubmissionModule { }
