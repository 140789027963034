import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ContraventionComponent } from './contravention.component';
import { ContraventionSubmissionModule } from './submission/contravention-submission.module';
import { ContraventionAddDetailsModule } from './add-details/contravention-add-details.module';
import { SharedModule } from '@apis/shared/shared.module';

@NgModule({
  declarations: [
    ContraventionComponent    
  ],
  imports: [      
    CommonModule,  
    SharedModule,
    ContraventionSubmissionModule,
    ContraventionAddDetailsModule
  ]
})
export class ContraventionModule { }
