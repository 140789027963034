import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportService } from '../../shared/services/report.service';
import * as fileSaver from "file-saver";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(
    private readonly reportService: ReportService, 
    private readonly spinner: NgxSpinnerService
    ) { }

  ngOnInit(): void {
  }

  downloadUsersList()
  {
    this.spinner.show();
      this.reportService.downloadUserListReport()
        .subscribe((result: HttpEvent<Blob>) => {       
          if (result.type == HttpEventType.Response) {
            this.spinner.hide();
            fileSaver.saveAs(result.body, "user-list.csv");          
          }        
        }, (error: any) => this.spinner.hide());            
  }
}
