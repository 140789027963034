import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
 
@Injectable({
  providedIn: 'root',
})
export class RsgAuthGuard  {
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router) {
  }

  canActivate() {
    if (!this.localStorageService.getRegistryAgentProfile()){
      this.router.navigate(['/unauthorized']);
    }
    return !!this.localStorageService.getRegistryAgentProfile()
  }

}