<ng-container *ngIf="stopInformation.seatingTypeId == PassengerSeatingTypes.TwoRows || stopInformation.seatingTypeId == PassengerSeatingTypes.ThreeRows">
    <div class="row">
        <div class="col-6 px-1">
            <input disabled type="checkbox" id="frontLeftPosition" name="frontLeftPosition" class="passenger-positions-checkbox-disabled" checked [(ngModel)]="positions[0]" (change)="generatePositions()">
        </div>
        <div class="col-6 px-1">
            <input [disabled]="isReadOnly" type="checkbox" id="frontRightPosition" name="frontRightPosition" class="passenger-positions-checkbox" [(ngModel)]="positions[1]" (change)="generatePositions()">
        </div>
    </div>
    <div class="row pt-2">
        <div class="col-4 px-1">
            <input [disabled]="isReadOnly" type="checkbox" id="backLeftPosition" name="backLeftPosition" class="passenger-positions-checkbox" [(ngModel)]="positions[2]" (change)="generatePositions()">
        </div>
        <div class="col-4 px-1">
            <input [disabled]="isReadOnly" type="checkbox" id="backMiddlePosition" name="backMiddlePosition" class="passenger-positions-checkbox" [(ngModel)]="positions[3]" (change)="generatePositions()">
        </div>
        <div class="col-4 px-1">
            <input [disabled]="isReadOnly" type="checkbox" id="backRightPosition" name="backRightPosition" class="passenger-positions-checkbox" [(ngModel)]="positions[4]" (change)="generatePositions()">
        </div>
    </div>
</ng-container>

<div class="row pt-2" *ngIf="stopInformation.seatingTypeId == PassengerSeatingTypes.ThreeRows">
    <div class="col-4 px-1">
        <input [disabled]="isReadOnly" type="checkbox" id="rearLeftPosition" name="rearLeftPosition" class="passenger-positions-checkbox" [(ngModel)]="positions[5]" (change)="generatePositions()">
    </div>
    <div class="col-4 px-1">
        <input [disabled]="isReadOnly" type="checkbox" id="rearMiddlePosition" name="rearMiddlePosition" class="passenger-positions-checkbox" [(ngModel)]="positions[6]" (change)="generatePositions()">
    </div>
    <div class="col-4 px-1">
        <input [disabled]="isReadOnly" type="checkbox" id="rearRightPosition" name="rearRightPosition" class="passenger-positions-checkbox" [(ngModel)]="positions[7]" (change)="generatePositions()">
    </div>
</div>

<ng-container *ngIf="stopInformation.seatingTypeId == PassengerSeatingTypes.MotorcycleOrATV">
    <div class="row">
        <div class="col-6 px-1">
            <input disabled type="checkbox" id="frontPosition" name="frontPosition" class="passenger-positions-checkbox-disabled" checked [(ngModel)]="positions[0]" (change)="generatePositions()">
        </div>
    </div>
    <div class="row pt-2">
        <div class="col-6 px-1">
            <input [disabled]="isReadOnly" type="checkbox" id="rearPosition" name="rearPosition" class="passenger-positions-checkbox" [(ngModel)]="positions[1]" (change)="generatePositions()">
        </div>
    </div>
</ng-container>
