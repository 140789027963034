import { Component, OnInit, EventEmitter, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgModel, Validators } from '@angular/forms';
import { EventTypes, DocumentTypes, } from '@apis/shared/enums/app.enum';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import * as fileSaver from 'file-saver';
import { Guid } from 'guid-typescript';
import { Document } from '@apis/shared/models/document.model';
import { Event } from '@apis/shared/models/event.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { TicketWithdrawalReasonType } from '@apis/shared/models/types/ticket-withdrawal-reason-type.model';
import { TicketWithdrawalRequest } from '@apis/shared/models/ticket-withdrawal-request.model';
import { DisclosureDocument } from '@apis/shared/models/disclosure-document.model';

@Component({
  selector: 'app-withdraw-traffic-tickets-modal',
  templateUrl: './withdraw-traffic-tickets-modal.component.html',
  styleUrls: ['./withdraw-traffic-tickets-modal.component.scss']
})
export class WithdrawTrafficTicketsModalComponent implements OnInit, AfterViewInit {
  ticketWithdrawalRequest: TicketWithdrawalRequest;
  isSubmitClicked: boolean = false;
  maxCharactersAllowed: number = 5000;
  remainingCharacters: number = this.maxCharactersAllowed;
  bodyElement: JQuery<HTMLElement>;
  withdrawalReason: string = "";
  errorMessage: string;
  errorMessageHeader: string;
  confirmButton: JQuery<HTMLElement>;
  cancellationDocument: DisclosureDocument;
  additionalDocumentTypes: DocumentType[];

  withdrawalReasonTextarea: JQuery<HTMLElement>;
  documents: Document[] = [];
  ticketWithdrawalReasonTypes: TicketWithdrawalReasonType[];
  
  username: string;
  confirmModalOverlay: JQuery<HTMLElement>;
  
  isRequestSubmitted: boolean = false;
  isSingleTicket: boolean = false;
  
  close: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("withdrawalReasonModel") withdrawalReasonModel: NgModel;

  constructor( private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService,
    private readonly intakeService: IntakeService,
    private readonly documentService: DocumentService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {    
    this.withdrawalReasonModel.control.setValidators(Validators.required);
    this.withdrawalReasonTextarea = $("#cancellationReasonTextarea");
    this.confirmButton = $(".confirm-button");
  }

  ngOnInit(): void {   
    this.additionalDocumentTypes = this.localStorageService.getDocumentTypes().filter(x => x.id == DocumentTypes.CancellationDocumentation);
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.confirmModalOverlay = $(".confirm-modal-overlay");

    this.ticketWithdrawalReasonTypes = this.localStorageService.getTicketWithdrawalReasonTypes();
    this.ticketWithdrawalRequest.tempFileFolder = Guid.create().toString();          
    this.username = this.localStorageService.getUser().displayName;

    this.isSingleTicket = this.ticketWithdrawalRequest.tickets?.length == 1;

    this.cancellationDocument = new DisclosureDocument({
      uploadedBy: this.username,
      documentRefTypeNumber: this.ticketWithdrawalRequest.tempFileFolder,
      type: this.additionalDocumentTypes[0].name
    })
  }

  onCloseClick(): void {
    this.bodyElement.removeClass("overflow-hidden");
    this.close.emit(this.isRequestSubmitted);
  }
 
  onWithdrawalTextAreaInput(ev: any): void {
    this.remainingCharacters = this.maxCharactersAllowed - ev.target.value.length;    
  }

  onConfirmWithdrawalClick(isValid: boolean) {
    if (this.confirmButton.hasClass("disabled")) {
      return;
    }

    this.isSubmitClicked = true;
    
    if (isValid 
        && this.remainingCharacters >= 0) {
          this.showHideConfirmModal(true);
    }
  } 

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  onConfirmClick()
  {
    this.spinner.show();
     
    this.ticketWithdrawalRequest.documents = [];
    if (this.cancellationDocument != null && this.cancellationDocument.name != null && this.cancellationDocument.name != undefined && this.cancellationDocument.name != '') {
      this.ticketWithdrawalRequest.documents.push(this.cancellationDocument);
    }
    
    this.intakeService.createTicketWithdrawalRequestAsync(this.ticketWithdrawalRequest)
      .subscribe(() => {
        this.isRequestSubmitted = true;
        this.errorMessage = ""; 
        this.showHideConfirmModal(false);
        this.spinner.hide();
      }, (error: any) => {
        if (error.status == 401 || error.status == 403) {
          this.errorMessage = "You are unauthorized to perform this operation";
        }          
        else  {
          this.showErrors(error);
        }                    
        this.showHideConfirmModal(false);
        this.spinner.hide();
      });
  }

  onCancelDcoument(document: DisclosureDocument)
  {
    
  }

  showHideConfirmModal(show?: boolean): void {
    if (show) {
      this.confirmModalOverlay.addClass("modal-show");
      this.changeDetectorRef.detectChanges();
    } 
    else 
    {
      this.confirmModalOverlay.removeClass("modal-show");
      this.changeDetectorRef.detectChanges();
    }
  }
}
