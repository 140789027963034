

import { CountryType } from './types/country-type.model';

export class MovesVehicle {
    public movesVehicleId: number = 0;
    public licencePlateNumber: string;
    public licencePlateExpiryYear: number;
    public vehicleIdentificationNumber: string;
    public vehicleRegistrationStatusTypeId: number = null;
    public clientTypeId: number = null;
    public licensedCountryId: number = null;
    public licensedProvinceId: number = null;
    public licensedProvince: string;
    public year: number;
    public colour: string;
    public make: string;
    public model: string;
    public licencePlateExpiryDate: Date;
    public licencePlateClass: string;
    public vehicleStyle: string;
    public vehicleWeight: string;
    public vehicleSeatingCapacity: string;
    public vehicleStatusTypeId: number;
    public lastServiceType: string;
    public lastServiceDate: Date;
    public licensedCountry: CountryType;
    
    constructor(init?: Partial<MovesVehicle>) {
        
        Object.assign(this, init);
    }
}
