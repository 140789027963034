
export class OfflineNumberSearch {
    public contraventionNumber: string;
    public seizureNumber: string;

    constructor(init?: Partial<OfflineNumberSearch>) {
        
        Object.assign(this, init);
    }
}
