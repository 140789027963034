export class DeclarationInformation {
    public declarationInformationId: number;
    public policeOfficerName: string;
    public declarationTypeId: number;
    public declarationDate: Date;
    public updatedOn: Date;
    public updatedBy: string;

    constructor(init?: Partial<DeclarationInformation>) {
        
        Object.assign(this, init);
    }
}
