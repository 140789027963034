

import { UserRole } from './user-role.model';

export class User {
    public displayName: string;
    public token: string;
    public userId: number = 0;
    public userName: string;
    public firstName: string;
    public lastName: string;
    public emailAddress: string;
    public governmentOfAlbertaId: string;
    public userRoles: UserRole[] = [];
    public isDisabled: boolean;

    constructor(init?: Partial<User>) {
        
        Object.assign(this, init);
    }
}
