

import { PaymentRequest } from './payment-request.model';
import { ReviewScheduleRequest } from './review-schedule-request.model';
import { User } from './user.model';
import { Representation } from './representation.model';
import { ReviewOutcome } from './review-outcome.model';
import { Event } from './event.model';
import { Document } from './document.model';
import { ReviewPayment } from './review-payment.model';
import { ReviewApplicant } from './review-applicant.model';
import { Notification } from './notification.model';
import { ContactAddress } from './contact-address.model';
import { ScheduledEvent } from './scheduled-event.model';
import { ReviewItem } from './review-item.model';
import { RegistryAgentInformation } from './registry-agent-information.model';

export class Review {
    public contraventionNumber: string;
    public seizureNumber: string;
    public contraventionIssueDate: Date = new Date();
    public paymentRequest: PaymentRequest;
    public adjudicatorLastName: string;
    public adjudicatorFirstName: string;
    public driverLastName: string;
    public driverFirstName: string;
    public tempFolderName: string;
    public seizureDriverLastName: string;
    public seizureDriverFirstName: string;
    public registeredOwnerLastName: string;
    public registeredOwnerFirstName: string;
    public hasDocumentsRequiringRedaction: boolean = false;
    public hasDocumentsWithPendingSubmissions: boolean = false;
    public applicantLastName: string;
    public applicantFirstName: string;
    public reviewId: number = 0;
    public reviewNumber: string;
    public reviewSequence: number;
    public userId: number;
    public reviewStatusTypeId: number = 0;
    public reviewTypeId: number;
    public isSelfSubmit: boolean = false;
    public representationId: number;
    public reviewApplicantId: number;
    public reviewMethodTypeId: number = 0;
    public reviewFeeAmount: number = 0;
    public additionalRequestNotes: string;
    public recipientRelationship: string;
    public isAccompainedByInterpreter: boolean;
    public isCommercialVehicle: boolean;
    public isVehicleTakenWithoutPermission: boolean;
    public isVehicleStolen: boolean;
    public hasRentalAgreement: boolean;
    public contactMethodTypeId: number = 0;
    public outcome: ReviewOutcome;
    public phoneNumber: string;
    public email: string;
    public contactAddressId: number;
    public requestDate: Date = new Date();
    public reviewDueDate: Date;
    public reviewDate: Date;
    public reviewTime: string;
    public videoConferenceUrl: string;
    public decisionDueDate: Date;
    public decisionDate: Date;
    public linkedThirdPartyReviewNumber: string;
    public reviewScheduleRequest: ReviewScheduleRequest;
    public user: User;
    public representation: Representation;
    public outcomes: ReviewOutcome[] = [];
    public events: Event[] = [];
    public documents: Document[] = [];
    public payments: ReviewPayment[] = [];
    public reviewApplicant: ReviewApplicant;
    public notifications: Notification[] = [];
    public contactAddress: ContactAddress;
    public isCancelAllowed: boolean;
    public isEditAllowed: boolean;
    public isEditReviewGroundsAllowed: boolean;
    public isEditSupportingDocumentsAllowed: boolean;
    public isPhoneContactRequired: boolean;
    public driverEmail: string;
    public termsConditionsVersion: string;
    public reviewDateTimeUtc: Date;
    public scheduledEventId: number;
    public scheduledEvent: ScheduledEvent;
    public isRescheduleAllowed: boolean;
    public reviewItems: ReviewItem[] = [];
    public reviewTypesInSequence: number[] = [];
    public complexity: number;
    public reviewHeldByDate: Date;
    public isLEAAllowedToAddSupportingDocs: boolean;
    public isAllowedToEditReviewGrounds: boolean;
    public isAllowedToAmendSupportingDocs: boolean;
    public isAllowedToEditReviewMethod: boolean;
    public isReReview: boolean;
    public stopInformationId: number;
    public isFileClosureComplete: boolean;
    public isActionRequired: boolean;
    public isReReviewBySameAdjudicator: boolean;
    public failedAttendanceDeclarationTypeId: number;
    public isJudicialReviewServed: boolean;
    public registryAgentInformation: RegistryAgentInformation[] = [];
    public isSubmittedByRegistry: boolean;
    public lastViewedAdjudicatorName: string;
    public lastViewedAdjudicatorDate: Date;

    constructor(init?: Partial<Review>) {
        
        Object.assign(this, init);
    }
}
