<div class="row">
    <div class="col-12 col-sm-6">
        <label for=province{{countryProvince.controlSuffixText}} *ngIf="useAdminIssuingAuthorityLabels">
            <br> <!-- Use the Admin portal's "Issuing Authority" label instead of this component's label. This break is used to maintain alignment with the other input fields on the page. -->
        </label>
        <label for=country{{countryProvince.controlSuffixText}} *ngIf="!useAdminIssuingAuthorityLabels">
            {{countryProvince.countryLabel}}
            <span *ngIf="countryProvince.isCountryRequired" class="label-hint">(Required)</span>
        </label>
        <select [disabled]="countryProvince.isDisabled" [appSelectValidator]="countryProvince.isCountryRequired" #countryControl="ngModel" class="form-control" name=country{{countryProvince.controlSuffixText}} id=country{{countryProvince.controlSuffixText}} [(ngModel)]="countryProvince.countryId" (change)="onCountryChange($event)" [ngClass]="{'is-invalid': countryProvince.isCountryRequired && (countryControl.touched || countryProvince.isSubmitClicked) && countryControl.errors?.defaultSelected}">
            <option [ngValue]="null">Select a Country</option>
            <option *ngFor="let countryType of countryTypes" [ngValue]="countryType.id">{{countryType.name}}</option>            
        </select>
        <div class="invalid-feedback">
            <div>{{countryProvince.countryLabel}} is required</div>
        </div>
    </div>

    <div class="col-12 col-sm-6">
        <label for=province{{countryProvince.controlSuffixText}} *ngIf="useAdminIssuingAuthorityLabels">
            <span *ngIf="countryProvince.isProvinceRequired" class="label-hint">(Required)</span>
            <br *ngIf="!countryProvince.isProvinceRequired"> <!-- The "(Required)" text above pushes the label down. This break is used to maintain alignment when the "(Required)" text is not present. -->
        </label>
        <label for=province{{countryProvince.controlSuffixText}} *ngIf="!useAdminIssuingAuthorityLabels">
            {{countryProvince.provinceLabel}}
            <span *ngIf="countryProvince.isProvinceRequired" class="label-hint">(Required)</span>
        </label>
        <select [disabled]="countryProvince.isDisabled" [appSelectValidator]="countryProvince.isProvinceRequired" #provinceControl="ngModel" *ngIf="countryProvince.countryId == 40 || countryProvince.countryId == 236" class="form-control" name=province{{countryProvince.controlSuffixText}} id=province{{countryProvince.controlSuffixText}} [(ngModel)]="countryProvince.provinceId" (change)="onProvinceChange($event)" [ngClass]="{'is-invalid': countryProvince.isProvinceRequired && (provinceControl.touched || countryProvince.isSubmitClicked) && provinceControl.errors?.defaultSelected}">
            <option [ngValue]="null">Select a Province/State</option>
            <option *ngFor="let provinceType of refreshProvinceDropdown()" [ngValue]="provinceType.id">{{provinceType.name}}</option>            
        </select>
        <input [disabled]="countryProvince.isDisabled || !countryProvince.countryId" [required]="countryProvince.isProvinceRequired" maxlength="30" #otherProvinceControl="ngModel" *ngIf="!(countryProvince.countryId == 40 || countryProvince.countryId == 236)" type="text" [(ngModel)]="countryProvince.province" class="form-control" name=otherprovince{{countryProvince.controlSuffixText}} id=otherprovince{{countryProvince.controlSuffixText}} placeholder="Province/State" (keyup)="onProvinceTextChange($event)" [ngClass]="{'is-invalid': countryProvince.isProvinceRequired && (otherProvinceControl.touched || countryProvince.isSubmitClicked) && otherProvinceControl.invalid}">
        <div class="invalid-feedback">
            <div>{{countryProvince.provinceLabel}} is required</div>
        </div>
    </div>
</div>