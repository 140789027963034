import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';
import { addYears } from 'date-fns';

@Directive({
    selector: '[issuanceDateValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: IssuanceDateValidatorDirective,
        multi: true
    }]
})
export class IssuanceDateValidatorDirective implements Validator {
    @Input('issuanceDateValidator') occurrenceDate: string;
    
    validate(control: AbstractControl) {
      if (control.value == null || this.occurrenceDate == null || this.occurrenceDate.length == 0)
        return null;

      const ocrDate = new Date(+this.occurrenceDate.substring(0, 4), +this.occurrenceDate.substring(5, 7)-1, +this.occurrenceDate.substring(8, 10));
      const chkDate = new Date(new Date(control.value).getFullYear(), new Date(control.value).getMonth(), new Date(control.value).getDate());
      
      // Check if Issuance Date is >365 days from Occurrence Date
      return ((chkDate > addYears(ocrDate, 1)) ? {'MoreThan1Year': true} : null);
    }
}