
<div class="body-style">
    <div class="row">
        <div class="all-columns">
            <span class="column-25percent">
                <img width=153 height=43 id="abLogo" src="assets/images/ablogo.jpg">
            </span>
            <span class="column-75percent">
                <div class="page-header">Notice of Administrative Penalty</div>
            </span>
        </div>
    </div>

    <div class="thin-row horizontal-line"></div>
    <div class="thin-row">
        <div class="all-columns">
            <p>Protected A</p>
        </div>
    </div>
    <div class="row">
        <div class="all-columns">
            <p class="section-header">contravention details:</p>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <span class="column-25percent">
                <p class="label-row">Contravention Number</p>
                <p class="data-row">{{ contravention.contraventionNumber }}</p>
            </span>
            <span class="column-75percent">
                <p class="label-row">TRAFFIC SAFETY ACT (TSA) SECTION</p>
                <p class="data-row">{{ selectedContraventionType.formattedName }}</p>
                <p class="data-row">{{ selectedContraventionType.description }}</p>
            </span>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <span class="column-25percent">
                <p class="label-row">occurrence time</p>
                <p class="data-row">{{ contravention.occurrenceTime }}</p>
                <p class="surcharge-font">&nbsp;</p>
            </span>
            <span class="column-25percent">
                <p class="label-row">occurrence date</p>
                <p class="data-row">{{ contravention.occurrenceDate | date:"mediumDate" }}</p>
                <p class="surcharge-font">&nbsp;</p>
            </span>
            <span class="column-25percent">
                <p class="label-row">occurrence at or near</p>
                <p class="data-row">{{ locationName }}</p>
                <p class="surcharge-font">&nbsp;</p>
            </span>
            <span class="column-25percent">
                <p class="label-row">fine</p>
                <p class="data-row">{{ getFineAmount() }}</p>
                <p class="surcharge-font">Includes {{ surchargePercentage }}% victim fine surcharge</p>
            </span>
        </div>
    </div>

    <div *ngIf="contravention.speedingTicket!=null && !stop.isIRSSelected" class="row">
        <div class="all-columns">
            <span class="column-25percent">&nbsp;</span>
            <span class="column-25percent">
                <p class="label-row">speed limit</p>
                <p class="data-row">{{ contravention.speedingTicket?.speedLimit }} km/hr</p>
            </span>
            <span class="column-25percent">
                <p class="label-row">ticketed speed</p>
                <p class="data-row">{{ contravention.speedingTicket?.ticketedSpeed }} km/hr</p>
            </span>
            <span class="column-25percent">&nbsp;</span>
        </div>
    </div>

    <div *ngIf="stop.isIRSSelected && hasVehicleSeizure() && contravention.speedingTicket==null" class="row">
        <div class="all-columns">
            <span class="column-25percent">&nbsp;</span>
            <span class="column-25percent">
                <p class="label-row">Immediate licence suspension<sup>*</sup></p>
                <p class="data-row">{{ licenceSuspensionDurationText() }}</p>
            </span>
            <span class="column-25percent">
                <p class="label-row">driver’s licence<sup>*</sup></p>
                <p class="data-row">{{ licenceDestroyedText() }}</p>
            </span>
            <span class="column-25percent">
                <p class="label-row">vehicle seizure</p>
                <p class="data-row">{{ vehicleSeizureDurationText() }}</p>
            </span>
        </div>
    </div>
    <div *ngIf="stop.isIRSSelected && hasVehicleSeizure() && contravention.speedingTicket==null" class="row">
        <div class="all-columns">
            <span class="column-25percent">&nbsp;</span>
            <span class="column-75percent">
                <p class="additional-suspension-text">*There may be an additional suspension and conditions for reinstatement of your licence.</p>
            </span>
        </div>
    </div>

    <div *ngIf="stop.isIRSSelected && hasVehicleSeizure() && contravention.speedingTicket==null" class="row">
        <div class="all-columns your-rights-row">
            <span class="your-rights-text">You have the right to a roadside appeal of your blood alcohol/blood drug concentration</span>
        </div>
    </div>

    <!-- <div class="thin-row horizontal-line"></div> -->
    <div class="row">
        <div class="all-columns">
            <br /><p class="section-header">issued to:</p>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <span class="column-50percent">
                <p class="label-row">recipient name</p>
                <p class="data-row">{{ contravention.recipient?.firstName }} {{
                    contravention.recipient?.lastName }}</p>
            </span>
            <span class="column-25percent">
                <p class="label-row">date of birth</p>
                <p class="data-row">{{ contravention.recipient?.recipientIdentification?.dateOfBirth |
                    date:"mediumDate"}}</p>
            </span>
            <span class="column-25percent">
                <p class="label-row">issuance date</p>
                <p class="data-row">{{stop.issueDate | date:"mediumDate"}}</p>
            </span>
        </div>
    </div>

    <div class="thin-row horizontal-line"></div>
    <div class="row">
        <div class="all-columns">
            <p class="section-header">your options:</p>
            <p class="data-row-normal">&nbsp;&nbsp;-&nbsp;You can pay your fine. It must be paid <span style="font-weight: bold;">within 90 days</span> from issuance date (see above).</p>
            <p class="data-row-normal">&nbsp;&nbsp;-&nbsp;You can ask for more time to pay your fine</p>
            <p class="data-row-normal">&nbsp;&nbsp;-&nbsp;If you disagree with this Notice of Administrative Penalty, you have the right
            to a review through SafeRoads Alberta. <span style="font-weight: bold;">You have </span><span style="font-weight: bold; font-size: larger;">7 days</span><span style="font-weight: bold;"> from the
            date you received this notice to request a review.</span></p>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <span class="column-75percent">
                <p class="data-row-normal"><br />To see the details of this Notice, details of your suspension, pay your fine, request time to pay, or request a review, visit:</p>
                <p><span class="safe-roads-url">SafeRoads.com </span><span class="qr-text-note">Or use your phone to scan this QR code.</span></p>
                <p class="footer-large">If you are unable to access the website, go to any Alberta Registries for
                    assistance. You must
                    bring this Notice with you, along with a piece of government-issued photo ID.
                </p>
            </span>
            <span class="column-25percent qrcode-image">
                <img width=94 height=94 id="qrCode" src="assets/images/qrcode.jpg"
                    alt="Qr code Description automatically generated">
            </span>
        </div>
    </div>

    <div class="thin-row horizontal-line"></div>

    <div class="row">
        <div class="all-columns">
            <span class="column-33percent">
                <p class="label-row">Issuing Officer</p>
                <p class="data-row">{{ officerNameText() }}</p>
            </span>
            <span class="column-33percent">
                <p class="label-row">Regimental Number</p>
                <p class="data-row">{{ regimentalNumberText() }}</p>
            </span>
            <span class="column-33percent">
                <p class="label-row">Police File Number</p>
                <p class="data-row">{{ policeFileNumberText() }}</p>
            </span>
        </div>
    </div>
    <div class="row">
        <div class="all-columns">
            <p class="label-row">Police Service</p>
            <p class="data-row-normal">{{ agencyText() }}, {{ detachmentText() }}</p>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <p class="footer-small">The personal
                information on this form is collected under the authority of the <i>Traffic
                    Safety Act</i> and section 33 of the <i>Freedom of Information and Protection
                    of Privacy (FOIP) Act </i>and will be used for the administration of programs
                under the <i>Traffic Safety Act</i>. Inquiries may be directed to the Alberta
                Transportation Contact Centre, Alberta Transportation, Main Floor, Twin Atria
                Building, 4999-98 Avenue NW, Edmonton, Alberta T6B 2X3, at telephone number
                780-427-8230 (Edmonton and area) and toll free 310-0000 (in Alberta).
            </p>
        </div>
    </div>
    <div class="row">
        <div class="all-columns">
            <p class="footer-small">
               *If your driver's licence was seized, you must retrieve your licence within 7 days following the expiry of the suspension. Visit <span style="font-weight: bold;">www.saferoads.com</span> for important details.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="all-columns">
            <p class="footer-large">
                {{ regulationText() }}
            </p>
        </div>
    </div>

</div>
<p *ngIf="insertPageBreak" style="float: none !important; break-after: page !important;">&nbsp;</p>