export class RegistryAgentInformation {
    public registryAgentInformationId: number = 0;
    public contraventionPaymentId: number;
    public reviewId: number;
    public lateReviewRequestId: number;
    public paymentTimeRequestId: number;
    public paymentAdditionalTimeRequestId: number;
    public userName: string;
    public organizationCode: string;
    public isPaidByRecipient: boolean;
    public payerFirstName: string;
    public payerLastName: string;
    public payerMVID: string;
    
    constructor(init?: Partial<RegistryAgentInformation>) {
        Object.assign(this, init);
    }
}
