const { version } = require('../../../../package.json');

export const environment = {
  name: "Development - Sandbox Environment",
  production: false,
  apiV1: "/api/v1",  
  apiUrl: "https://saferoads-intake-api-dev.beta.alberta.ca",
  paymentMerchantID: "300208200",
  paymentPassCode: "59F303f6E19d4a9EB5D276D676614D12",
  authUrl: "https://access-uat.alberta.ca/auth",
  authRealm: "APIS",
  authClientId: "apis-intake-web",
  clientVersion: version,
  availableOffline: false,
  availableTSA: true,
  availableBlobUpload: true
};