<!-- Driver Portal View -->
<div *ngIf="!isRSGPortal">
    <label for="failedAttendanceDeclaration" class="required">You have requested an oral review. If you fail to attend the review hearing, how do you elect to proceed?</label>
    <div class="mt-2">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="abandonReview" name="failedAttendanceDeclaration"
                required #failedAttendanceDeclaration="ngModel" [disabled]="isDisabled"
                [value]="DeclarationTypes.FailedAttendanceAbandonReview.toString()"
                [(ngModel)]="review.failedAttendanceDeclarationTypeId"
                [ngClass]="{'is-invalid': review.failedAttendanceDeclarationTypeId == null && isFormSubmitted}">
            <label class="custom-control-label lbl-light" for="abandonReview" [innerHTML]="firstDeclarationHTML"></label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="continueReview" name="failedAttendanceDeclaration"
                required [disabled]="isDisabled"
                [value]="DeclarationTypes.FailedAttendanceContinueReview.toString()"
                [(ngModel)]="review.failedAttendanceDeclarationTypeId"
                [ngClass]="{'is-invalid': review.failedAttendanceDeclarationTypeId == null && isFormSubmitted}">
            <label class="custom-control-label lbl-light" for="continueReview" [innerHTML]="secondDeclarationType.formattedDescription"></label>
        </div>
        <div>
            <div class="text-danger mb-2" *ngIf="isFormSubmitted && failedAttendanceDeclaration.errors?.required">You must elect how to proceed if you fail to attend the oral review.</div>
        </div>
    </div>
    <div>
        <label>
            Please note that you may change your election at any time until 11:59 PM the day before your scheduled review.
        </label>
    </div>
</div>

<!-- Registry Portal View -->
<div *ngIf="isRSGPortal">
    <label for="failedAttendanceDeclaration" class="required">The client has requested an oral review. Ask the client how they elect to proceed if they fail to attend the review hearing.</label>
    <div class="mt-2">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="abandonReview" name="failedAttendanceDeclaration"
                required #failedAttendanceDeclaration="ngModel" [disabled]="isDisabled"
                [value]="DeclarationTypes.FailedAttendanceAbandonReview.toString()"
                [(ngModel)]="review.failedAttendanceDeclarationTypeId"
                [ngClass]="{'is-invalid': review.failedAttendanceDeclarationTypeId == null && isFormSubmitted}">
            <label class="custom-control-label lbl-light" for="abandonReview" [innerHTML]="firstDeclarationHTML"></label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" class="custom-control-input" id="continueReview" name="failedAttendanceDeclaration"
                required [disabled]="isDisabled"
                [value]="DeclarationTypes.FailedAttendanceContinueReview.toString()"
                [(ngModel)]="review.failedAttendanceDeclarationTypeId"
                [ngClass]="{'is-invalid': review.failedAttendanceDeclarationTypeId == null && isFormSubmitted}">
            <label class="custom-control-label lbl-light" for="continueReview" [innerHTML]="secondDeclarationType.formattedDescription"></label>
        </div>
        <div>
            <div class="text-danger mb-2" *ngIf="isFormSubmitted && failedAttendanceDeclaration.errors?.required">Please elect how to proceed if the client fails to attend the oral review.</div>
        </div>
    </div>
    <div>
        <label>
            Advise the client they may change their election at any time until 11:59 PM the day before their scheduled review.
        </label>
    </div>
</div>