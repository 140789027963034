import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive } from '@angular/core';
import { DateUtil } from '../helpers/date-util';
import { LocalStorageService } from '../services/local-storage.service';

@Directive({
    selector: '[isWorkingDay]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: WorkingdayValidatorDirective,
        multi: true
    }]
})
export class WorkingdayValidatorDirective implements Validator {
  constructor(private localStorageService: LocalStorageService) {}
  validate(control: AbstractControl) {
    if (control.value == null)
      return null;
 
    // Check for weekend
    if ((new Date(control.value).getDay() === 0 || new Date(control.value).getDay() === 6))
      return {'Weekend': true}; 

    // Check for stat holiday
    if (DateUtil.isStatHoliday(new Date(control.value), this.localStorageService.getTsaHolidayList()))
      return {'Holiday': true}; 

    return null;
  }
}