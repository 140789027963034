
export class RsgAgentProfile {
    public userName: string;
    public organization: string;
    public transactionId: string;
    public accessToken: string;
    
    constructor(init?: Partial<RsgAgentProfile>) {
        Object.assign(this, init);
    }
}
