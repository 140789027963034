import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { ReportService } from 'apps/intake/src/shared/services/report.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import * as fileSaver from "file-saver";
import * as moment from 'moment';
import { DateUtil } from '@apis/shared/helpers/date-util';

@Component({
  selector: 'app-cancelled-tickets-report',
  templateUrl: './cancelled-tickets-report.component.html',
  styleUrls: ['./cancelled-tickets-report.component.scss']
})
export class CancelledTicketsReportComponent implements OnInit {
  isDateFilter: boolean;
  startDate: Date;
  endDate: Date;
  fromDate: string;
  toDate: string;
  selectedYear: number=0;
  datePickerConfig: Partial<BsDatepickerConfig>;
  yearList: any;
  errorMessage: string="";
  
  constructor(
    private readonly reportService: ReportService, 
    private readonly spinner: NgxSpinnerService
    ) 
  {
    this.datePickerConfig = Object.assign({}, 
    {
      containerClass: 'theme-dark-blue', 
      showWeekNumbers: false,
      dateInputFormat: 'YYYY/MM/DD',
      isAnimated: true,
      customTodayClass: 'custom-today-class'
    }); 
  }

  ngOnInit(): void {
    this.yearList = CommonUtil.getYearList();
  }

  onDownloadClick(isValid: boolean)
  {
    if(isValid && this.isValidDateRange(isValid))
    {
      if (this.isDateFilter)
      {
        this.fromDate = (moment(this.startDate)).format('YYYY-MM-DD');
        this.toDate = (moment(this.endDate)).format('YYYY-MM-DD');
      }
      else
      {
        this.fromDate = `${this.selectedYear}-01-01`;
        this.toDate = `${this.selectedYear}-12-31`;
      }

      this.spinner.show();
      this.reportService.downloadCancelledTicketsReport(this.fromDate, this.toDate)
        .subscribe((result: HttpEvent<Blob>) => {       
          if (result.type == HttpEventType.Response) {
            this.spinner.hide();
            fileSaver.saveAs(result.body, "cancelled-tickets.csv");          
          }        
        }, () => this.spinner.hide());            
      }
  }

  isValidDateRange(isValid: boolean) : boolean
  {
    if (!isValid || !this.isDateFilter || DateUtil.addDays(this.startDate, 365) >= this.endDate)
    {
      this.errorMessage = "";
      return true;
    }
    else
    {
      this.errorMessage = "Date range must be 1 year or less";
      return false
    }
  }

  onFilterTypeChange()
  {
    this.errorMessage = "";
    
    if(this.isDateFilter)
      this.selectedYear = 0;
    else
    {
      this.startDate = null;
      this.endDate = null;
    }
  }
}
