<ng-container *ngIf="sectionDetails.length > 0">
    <div class="heading">{{sectionTitle}}</div>
    <div class="row">
        <div *ngFor="let sectionDetail of sectionDetails" [ngClass]="sectionDetail.isFullLineText? 'col-12 col-sm-12': 'col-12 col-sm-4'">
            <div class="label">{{sectionDetail.labelText}}</div>
            <div class="value">{{(sectionDetail.valueText == null || sectionDetail.valueText?.trim() == "")
                ? sectionDetail.isEmptyPlaceHolder
                    ? "" : "-"
                : sectionDetail.valueText?.trim()}}
            </div>
        </div>
    </div>
</ng-container>

