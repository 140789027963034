import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
@Directive({
  selector: '[appTitleCase]'
})
export class TitleCaseDirective {

  constructor(
    public ngControl: NgControl,
    public titleCase: TitleCasePipe
  ) { }

  // whenever user types something in the input field, this function triggers to apply Title Case to the value.
  @HostListener('ngModelChange', ['$event']) onInputChange(value) {
   const titleCaseStr = this.titleCase.transform(value);
   this.ngControl.valueAccessor.writeValue(titleCaseStr);
}

}