

import { DisclosureDocument } from './disclosure-document.model';
import { TicketViewModel } from './ticket-view.model';

export class TicketWithdrawalRequest {
    public ticketWithdrawalReasonTypeId: number = null;
    public ticketWithdrawalReasonNotes: string;
    public requestDate: Date = new Date();
    public requestingOfficerName: string;
    public requestingOfficerEmail: string;
    public documents: DisclosureDocument[] = [];
    public tickets: TicketViewModel[] = [];
    public tempFileFolder: string;

    constructor(init?: Partial<TicketWithdrawalRequest>) {
        Object.assign(this, init);
    }
}
