import { Component, OnInit, ViewChild } from '@angular/core';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AbstractControl, NgModel, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { SeizureType } from '@apis/shared/models/types/seizure-type.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { Vehicle } from '@apis/shared/models/vehicle.model';
import { TowCompany } from '@apis/shared/models/tow-company.model';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LocationType } from '@apis/shared/models/types/location-type.model';
import { TowCompanyService } from '@apis/shared/services/tow-company.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContraventionTypes, IntakeAppProcess, SeizureStatusTypes, SeizureTypes, SubmissionProgrssStates } from '@apis/shared/enums/app.enum';
import { TitleCasePipe } from '@angular/common';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { ConnectivityService } from 'apps/intake/src/shared/services/connectivity.service';
import { environment } from 'apps/intake/src/environments/environment';
import { TicketService } from 'apps/intake/src/shared/services/ticket.service';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-vehicle-seizure',
  templateUrl: './vehicle-seizure.component.html',
  styleUrls: ['./vehicle-seizure.component.scss']
})
export class VehicleSeizureComponent implements OnInit {
  stopInformation: StopInformation;
  contravention: Contravention;
  vehicleSeizure: VehicleSeizure;
  contravention2: Contravention;
  vehicleSeizure2: VehicleSeizure
  
  selectedSeizureType: SeizureType = null;
  selectedSeizureType2: SeizureType = null;
  isNoIRSSeizureMade: boolean = false;
  isNoSDPSeizureMade: boolean = false;

  locationControl = new UntypedFormControl();
  locationTypes: any;
  selectedLocationType: LocationType;
  filteredLocations: Observable<string[]>;
  seizureTypes: SeizureType[];
  seizureTypes2: SeizureType[];

  towCompanyControl = new UntypedFormControl();
  selectedTowCompanyType: TowCompany[];
  seizureDatePickerConfig: Partial<BsDatepickerConfig>;
  isSubmitClicked: boolean = false;
  towCompanies: TowCompany[];
  filteredTowCompanies: Observable<TowCompany[]>;
  selectedTowCompany: TowCompany = null;
  
  vehicleSearch: Vehicle = new Vehicle();
  errorMessage: string;

  seizureDate: Date;
  seizureTime: string;
  currentDateTime: Date;
  isFutureSeizureDateTime: boolean = false;
  towCompanyName: string;
  towLotAddress: string;
  towLotCity: string;
  towLotPostalCode: string;
  towLotPhoneNumber: string;
  towLotFaxNumber: string;
  towLotRepresentative: string;
  towLotEmailAddress: string

  Constants = Constants;
  DateUtil = DateUtil;
  availableTSA: boolean = false;

  @ViewChild("seizureDateControl") seizureDateControl: NgModel;
  @ViewChild("seizureTimeControl") seizureTimeControl: NgModel;

  constructor(private intakeService: IntakeService,
              private towCompanyService: TowCompanyService,
              private localStorageService: LocalStorageService,
              private readonly spinner: NgxSpinnerService,
              private readonly connectivityService: ConnectivityService,
              private router: Router,
              public titleCasePipe: TitleCasePipe,
              private ticketService: TicketService) {
                //Check if TSA feature is allowed or not
                this.availableTSA = environment.availableTSA && this.ticketService.hasTrafficTicketPermissions();

                this.seizureDatePickerConfig = Object.assign({}, 
                  {
                    containerClass: 'theme-dark-blue', 
                    showWeekNumbers: false,
                    dateInputFormat: 'YYYY/MM/DD',
                    minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
                    maxDate: new Date(2099,11,31), // 2099/12/31 - Month is 0 based index
                    isAnimated: true,
                    customTodayClass: 'custom-today-class'
                  });  
                //Get Location Types
                this.locationTypes = this.localStorageService.getLocationTypes();

                this.locationControl.setValidators(this.validateLocations(this.locationTypes))
               }

  ngOnInit(): void {

    // for offline, set the current Intake App status
    this.localStorageService.setIntakeAppProcess(IntakeAppProcess.CreatingContravention);

    //Get Types
    this.locationTypes = this.localStorageService.getLocationTypes();
    this.filteredLocations = this.locationControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.connectivityService.getOnlineStatusAsync().then( isOnline => {
      if (isOnline) {
        this.towCompanyService.getTowCompaniesAsync()
        .subscribe(towCompanies => {
          this.towCompanies = towCompanies;
          this.filteredTowCompanies = this.towCompanyControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filterTow(value))
          );
        });
      } else {
        this.towCompanies = null;
      }
    });

    //Get stop Information object
    this.stopInformation = this.intakeService.getStopInformationModel();

    //Check if this step in the process is allowed or not.
    if (!this.stopInformation?.progressId || this.stopInformation.progressId < SubmissionProgrssStates.VehicleSeizure || this.stopInformation.stopInformationId > 0  || (!this.stopInformation.isIRSSelected && !this.stopInformation.isSDPSelected))
      this.router.navigateByUrl('/contravention/submission/recipient-information');
    
    if (this.stopInformation.isIRSSelected) // IRS Contravention
    {
      //Get Contravention Object
      var contraventionTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isIRS).map(s => s.id);
      this.contravention = this.stopInformation?.contraventions.find(x=> contraventionTypeIds.includes(x.contraventionTypeId));
      
      if ([3, 4, 5].includes(this.contravention.contraventionTypeId) && this.contravention.isNoVehicleSeizureMade == null)
      {
        this.contravention.isNoVehicleSeizureMade = true;
      }

      if (this.contravention.vehicleSeizure == null && !this.contravention.isNoVehicleSeizureMade) {
        this.contravention.vehicleSeizure = new VehicleSeizure();
     
        //Set Defaults
        this.contravention.vehicleSeizure.vehicleReleaseDate = null; 
        this.contravention.vehicleSeizure.seizureStatusTypeId = SeizureStatusTypes.Seized;
        this.contravention.vehicleSeizure.seizureDate = this.contravention.occurrenceDate;
        this.contravention.vehicleSeizure.locationTypeId = this.stopInformation?.occurrenceLocation?.locationTypeId;
        this.contravention.vehicleSeizure.additionalLocationDetails = this.stopInformation?.occurrenceLocation?.additionalLocationDetails;
      }

      this.vehicleSeizure = this.contravention.vehicleSeizure;
      this.isNoIRSSeizureMade = this.contravention.isNoVehicleSeizureMade;

      //Override Seizure Type Name with IRS Contravention Type
      if (!this.isNoIRSSeizureMade)
      {
        this.seizureTypes = this.localStorageService.getSeizureTypes().filter(x => x.id == 1) // IRS Contravention  
        this.seizureTypes[0].name = `[${this.localStorageService.getContraventionTypes().find(x => x.id == this.contravention.contraventionTypeId)?.name}]`;
        this.vehicleSeizure.seizureTypeId = this.seizureTypes[0].id;
        this.selectedSeizureType = this.seizureTypes[0];
        this.onSeizureTypeChange(this.selectedSeizureType);
        this.loadSeizureInformation(this.vehicleSeizure);
      }
    }

    if (this.stopInformation.isSDPSelected) // Suspended Driver Seizure
    {
      //Get Contravention Object
      var contraventionTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isSDP).map(s => s.id);
      this.contravention2 = this.stopInformation?.contraventions.find(x=> contraventionTypeIds.includes(x.contraventionTypeId));
      
      if (this.contravention2.vehicleSeizure == null) {
        this.contravention2.vehicleSeizure = new VehicleSeizure();
     
        //Set Defaults
        this.contravention2.vehicleSeizure.vehicleReleaseDate = null; 
        this.contravention2.vehicleSeizure.seizureStatusTypeId = SeizureStatusTypes.Seized;
        this.contravention2.vehicleSeizure.seizureDate = this.contravention2.occurrenceDate;
        this.contravention2.vehicleSeizure.locationTypeId = this.stopInformation?.occurrenceLocation?.locationTypeId;
        this.contravention2.vehicleSeizure.additionalLocationDetails = this.stopInformation?.occurrenceLocation?.additionalLocationDetails;
      }

      this.vehicleSeizure2 = this.contravention2.vehicleSeizure;
      this.seizureTypes2 = this.localStorageService.getSeizureTypes().filter(x => x.id > 1) // All except IRS Contravention
      this.isNoSDPSeizureMade = this.contravention2.isNoVehicleSeizureMade;
      
      //Get seizure type
      if (this.vehicleSeizure2.seizureTypeId > 0)
        this.selectedSeizureType2 = this.seizureTypes2.find(x => x.id == this.vehicleSeizure2.seizureTypeId);
      
      this.loadSeizureInformation(this.vehicleSeizure2);  
    }

    //Initialize the selected location type
    if(this.stopInformation?.occurrenceLocation?.locationTypeId != null && this.stopInformation.occurrenceLocation.locationTypeId > 0)
      this.selectedLocationType = this.locationTypes.find(x => x.id == this.stopInformation.occurrenceLocation.locationTypeId);

    if (!this.stopInformation.isIRSSelected)
      this.vehicleSeizure = this.vehicleSeizure2;
    
    if (!this.stopInformation.isSDPSelected)
      this.vehicleSeizure2 = this.vehicleSeizure;  

    // Get the current date and time, for seizure date/time validation
    if (this.connectivityService.isUserOnline()) {
      this.intakeService.getCurrentMSTDateAsync().subscribe((receivedDateTime) => {
        this.currentDateTime = typeof receivedDateTime === 'string' ? new Date(receivedDateTime): receivedDateTime;
      });
    }

    //scroll to top with tab change
    window.scroll(0,0);
  }

  loadSeizureInformation(vehicleSeizure: VehicleSeizure) {
    this.seizureDate = vehicleSeizure.seizureDate;
    this.seizureTime = vehicleSeizure.seizureTime;
    this.towCompanyName = vehicleSeizure.towCompanyName;
    this.towLotAddress = vehicleSeizure.towLotAddress;
    this.towLotCity = vehicleSeizure.towLotCity;
    this.towLotPostalCode = vehicleSeizure.towLotPostalCode;
    this.towLotPhoneNumber = vehicleSeizure.towLotPhoneNumber;
    this.towLotFaxNumber = vehicleSeizure.towLotFaxNumber;
    this.towLotRepresentative = vehicleSeizure.towLotRepresentative;
    this.towLotEmailAddress = vehicleSeizure.towLotEmailAddress;
  }

  onSeizureTypeChange(seizureType: SeizureType)
  {
    if (seizureType == null) {
      this.vehicleSeizure.seizureTypeId = null;
      this.vehicleSeizure.seizureDuration = 0
      this.vehicleSeizure.seizureDurationType = "";
      this.vehicleSeizure.vehicleReleaseDate = null;
    }
    else 
    {
      this.vehicleSeizure.seizureTypeId = seizureType.id;
      if (this.contravention.contraventionTypeId > 0)
      {
        this.vehicleSeizure.seizureDuration = this.localStorageService.getContraventionTypes().find(x=> x.id == this.contravention.contraventionTypeId).vehicleSeizureDuration;
        this.vehicleSeizure.seizureDurationType = this.localStorageService.getContraventionTypes().find(x=> x.id == this.contravention.contraventionTypeId).vehicleSeizureDurationType;
        this.vehicleSeizure.vehicleReleaseDate = this.addPeriod(this.vehicleSeizure.seizureDate, this.vehicleSeizure.seizureDuration, this.vehicleSeizure.seizureDurationType);
      }
      else
      {
        this.vehicleSeizure.seizureDuration = 0
        this.vehicleSeizure.seizureDurationType = "";
        this.vehicleSeizure.vehicleReleaseDate = null;
      }
    }
  }

  onSeizureType2Change(seizureType: SeizureType)
  {
    if (seizureType == null) {
      this.vehicleSeizure2.seizureTypeId = null;
      this.vehicleSeizure2.seizureDuration = 0
      this.vehicleSeizure2.seizureDurationType = "";
      this.vehicleSeizure2.vehicleReleaseDate = null;
    }
    else { //Suspended Driver's Program
      this.vehicleSeizure2.seizureTypeId = seizureType.id;
      this.vehicleSeizure2.seizureDuration = seizureType.seizureDuration;
      this.vehicleSeizure2.seizureDurationType = seizureType.seizureDurationType;
      this.vehicleSeizure2.vehicleReleaseDate = this.addPeriod(this.vehicleSeizure2.seizureDate, seizureType.seizureDuration, seizureType.seizureDurationType);
    }
  }

  onSeizureDateChange()
  {
    if (this.vehicleSeizure)
    {
      this.vehicleSeizure.seizureDate = this.seizureDate;
      this.vehicleSeizure.vehicleReleaseDate = this.addPeriod(this.vehicleSeizure.seizureDate, this.vehicleSeizure.seizureDuration, this.vehicleSeizure.seizureDurationType);
    }

    if (this.vehicleSeizure2)
    {
      this.vehicleSeizure2.seizureDate = this.seizureDate;
      this.vehicleSeizure2.vehicleReleaseDate = this.addPeriod(this.vehicleSeizure2.seizureDate, this.vehicleSeizure2.seizureDuration, this.vehicleSeizure2.seizureDurationType);  
    }

    this.validateSeizureDateTime();
  }

  validateSeizureDateTime()
  {
    if (!this.seizureDate || !this.seizureTime || !this.currentDateTime) {
      return;
    }

    const inputTimeArray = this.seizureTime.split(':'); //seizureTime is the time in string format, "XX:XX"
    const inputHour = inputTimeArray[0];
    const inputMinute = inputTimeArray[1];
    const inputDateTime = new Date(new Date(this.seizureDate).getFullYear(), new Date(this.seizureDate).getMonth(), new Date(this.seizureDate).getDate(), parseInt(inputHour), parseInt(inputMinute));

    this.isFutureSeizureDateTime = inputDateTime > this.currentDateTime;
  }

  addPeriod(date: Date, duration: number, durationType: string): Date {
    if(date == null || duration == null || durationType == null) return null;
    if(durationType == "hours") duration = duration/24; //Convert hours to days
    var newDate: Date = new Date(date);
    newDate.setDate(date.getDate() + duration);
    return newDate;
  }

  private _filter(value: string): string[] {
    const filterValue = String(value).toLowerCase();
    return this.locationTypes.filter(option => 
      option.name.toLowerCase().includes(filterValue));
  }

  private _filterTow(value: string): TowCompany[] {
    const filterValue = String(value).toLowerCase();
    return this.towCompanies.filter(option => 
      option.towCompanyName.toLowerCase().includes(filterValue));
  }
  onLocationTypeChange(selectedLocationTypeValue: LocationType)
  {
    this.stopInformation.occurrenceLocation.locationTypeId = selectedLocationTypeValue.id;
  }

  displaytowingCompany(subject){
    return subject? subject.towCompanyName : undefined;
  }

  displayLocation(subject){
    return subject? subject.name : undefined;
  }

  onNoIRSSeizureMadeChange(value: any)
  {
    this.isNoIRSSeizureMade = value.currentTarget.checked;
    this.contravention.isNoVehicleSeizureMade = value.currentTarget.checked;
    this.contravention.noVehicleSeizureDetails = this.isNoIRSSeizureMade? this.contravention.noVehicleSeizureDetails : "";
    this.intakeService.saveStopInformationContext();
    this.ngOnInit();
  }

  onNoSDPSeizureMadeChange(value: any)
  {
    this.isNoSDPSeizureMade = value.currentTarget.checked;
    this.contravention2.isNoVehicleSeizureMade = value.currentTarget.checked;
    this.contravention2.noVehicleSeizureDetails = this.isNoSDPSeizureMade? this.contravention2.noVehicleSeizureDetails : "";
    this.intakeService.saveStopInformationContext();
    this.ngOnInit();
  }

  onTowLotChange(towCompany: TowCompany)
  {
    if (towCompany == null)
    {
      this.towCompanyName = "";
      this.towLotAddress = "";
      this.towLotCity = "";
      this.towLotPostalCode = "";
      this.towLotPhoneNumber = "";
      this.towLotFaxNumber = "";
      this.towLotEmailAddress = "";
    }
    else
    {
      this.towCompanyName = towCompany.towCompanyName;
      this.towLotAddress = towCompany.towLotAddress;
      this.towLotCity = towCompany.city;
      this.towLotPostalCode = towCompany.postalCode===null || towCompany.postalCode===undefined ? "" : towCompany.postalCode;
      this.towLotPhoneNumber = towCompany.phoneNumber;
      this.towLotFaxNumber = towCompany.faxNumber;
      this.towLotEmailAddress = towCompany.towLotEmailAddress;
    }
  }

  onSubmitClick(isValid: boolean)
  {
    this.isSubmitClicked = true;

    this.refreshSeizureInformation()
    
    //Check if vehicle seizure was made or not

    // the db schema vehicle seizure postal code is NOT NULL but it isn't a required field in the UI   
    if (!this.isNoIRSSeizureMade)
    {   
      if (this.vehicleSeizure?.towLotPostalCode)
        this.vehicleSeizure.towLotPostalCode = this.vehicleSeizure.towLotPostalCode.toUpperCase();
      else
        this.vehicleSeizure.towLotPostalCode = "";

      if (this.vehicleSeizure2?.towLotPostalCode)
        this.vehicleSeizure2.towLotPostalCode = this.vehicleSeizure2.towLotPostalCode.toUpperCase();
      else
        this.vehicleSeizure2.towLotPostalCode = "";
    }
 
    if (isValid
      && !this.isFutureSeizureDateTime
      && (this.locationControl.valid
        || (this.isNoIRSSeizureMade
          && (!this.stopInformation.isSDPSelected
            || this.isNoSDPSeizureMade
    ))))
    {
      if (this.vehicleSeizure)
      {
        //Trim the time from seizure date and release date fields
        this.vehicleSeizure.seizureDate.setHours(0, 0, 0, 0);
        if (this.vehicleSeizure.vehicleReleaseDate)
          this.vehicleSeizure.vehicleReleaseDate.setHours(0, 0, 0, 0);
      }

      if (this.vehicleSeizure2)
      {
        //Trim the time from seizure date and release date fields
        this.vehicleSeizure2.seizureDate.setHours(0, 0, 0, 0);
        if (this.vehicleSeizure2.vehicleReleaseDate)
          this.vehicleSeizure2.vehicleReleaseDate.setHours(0, 0, 0, 0);
      }

      this.stopInformation.progressId = SubmissionProgrssStates.PrintAndIssue;
      this.intakeService.saveStopInformationContext();
      this.router.navigateByUrl('/contravention/submission/print-and-issue');
    }
    else
      window.scroll(0,0);  
  }
  
  refreshSeizureInformation() {
    if (this.stopInformation.isIRSSelected)
    {
      if (this.isNoIRSSeizureMade)
      {
        this.contravention.vehicleSeizure = null;
      }
      else
      {
        this.vehicleSeizure.seizureDate = this.seizureDate;
        this.vehicleSeizure.seizureTime = this.seizureTime;
        this.vehicleSeizure.towCompanyName = this.towCompanyName;
        this.vehicleSeizure.towLotAddress = this.towLotAddress;
        this.vehicleSeizure.towLotCity = this.towLotCity;
        this.vehicleSeizure.towLotPostalCode = this.towLotPostalCode;
        this.vehicleSeizure.towLotPhoneNumber = this.towLotPhoneNumber;
        this.vehicleSeizure.towLotFaxNumber = this.towLotFaxNumber;
        this.vehicleSeizure.towLotRepresentative = this.towLotRepresentative;
        this.vehicleSeizure.towLotEmailAddress = this.towLotEmailAddress;
        this.contravention.noVehicleSeizureDetails = null;
        this.vehicleSeizure.vehicle = this.stopInformation.vehicle;
      }
    }

    if (this.stopInformation.isSDPSelected)
    {
      if (this.isNoSDPSeizureMade)
      {
        this.contravention2.vehicleSeizure = null;
      }
      else
      {
        this.vehicleSeizure2.seizureDate = this.seizureDate;
        this.vehicleSeizure2.seizureTime = this.seizureTime;
        this.vehicleSeizure2.towCompanyName = this.towCompanyName;
        this.vehicleSeizure2.towLotAddress = this.towLotAddress;
        this.vehicleSeizure2.towLotCity = this.towLotCity;
        this.vehicleSeizure2.towLotPostalCode = this.towLotPostalCode;
        this.vehicleSeizure2.towLotPhoneNumber = this.towLotPhoneNumber;
        this.vehicleSeizure2.towLotFaxNumber = this.towLotFaxNumber;
        this.vehicleSeizure2.towLotRepresentative = this.towLotRepresentative;
        this.vehicleSeizure2.towLotEmailAddress = this.towLotEmailAddress;
        this.vehicleSeizure2.vehicle = this.stopInformation.vehicle;
        this.vehicleSeizure2.recipient = this.stopInformation.recipient;
      }
    }
  }

  validateLocations(locationNames: LocationType[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = locationNames.findIndex(x => {
        return (new RegExp('\^' + x.name + '\$')).test(control.value?.name);
      });
      return index < 0 ? { 'forbiddenLocations': { value: control.value } } : null;
    };
  }

  isCommercialContravention(): boolean {
    var irsContraventionTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isIRS).map(s => s.id);
    var irsContravention: Contravention = this.stopInformation.contraventions.find(x => irsContraventionTypeIds.includes(+x.contraventionTypeId));
    if (irsContravention!=null) {
      if( irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial1st ||
          irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial2nd ||
          irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial3rd) {
            return true;
      }
    }
    return false;
  }
}
