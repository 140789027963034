<div *ngIf="indexId>0">
    <div class="heading-underline"></div>
    <div class="form-group row">
        <div class=""></div>
        <div class="col-12 col-sm-12">
            CONTRAVENTION #{{indexId+1}}
        </div>
    </div>
</div>
<div class="form-group row">
    <div class="col-12 col-sm-12">
        <label for="contraventionType{{indexId}}">Traffic Safety Act Section <span class="label-hint">(Required)</span></label>
        <input required type="search" [title]="selectedContraventionType?.formattedName??''" class="form-control" id="section{{indexId}}" name="section{{indexId}}" 
        [formControl]="sectionControl" [matAutocomplete]="autoSections" placeholder="Search by TSA offence number or keyword" [(ngModel)]="selectedContraventionType"  
        [ngClass]="{'is-invalid': (sectionControl.touched || isSubmitClicked) && sectionControl.invalid}"> 
        <mat-autocomplete #autoSections="matAutocomplete" [displayWith]="displaySection" (optionSelected)="onSectionTypeChange($event.option.value, 1)">
            <mat-option *ngFor="let sectionType of filteredSections | async" [value]="sectionType" [disabled]="sectionType.isDisabled">
                {{displaySection(sectionType)}}
            </mat-option>
        </mat-autocomplete>
        <mat-error class="validation-message" *ngIf="(sectionControl.touched || isSubmitClicked) && sectionControl.hasError('required')">
            Traffic Safety Act Section is required
        </mat-error>
        <mat-error class="validation-message" *ngIf="(sectionControl.touched || isSubmitClicked) && sectionControl.hasError('forbiddenSections') && !sectionControl.hasError('required')">
            Invalid Traffic Safety Act Section
        </mat-error>
    </div>
</div>

<div class="form-group row ml-4" *ngIf="secondaryContraventionTypes?.length>0">
    <div class="col-12 col-sm-12">
        <label for="secondarySection{{indexId}}">Secondary Charge <span class="label-hint">(Required)</span></label>
        <input required type="search" [title]="selectedSecondaryContraventionType?.formattedName??''" class="form-control" id="secondarySection{{indexId}}" name="secondarySection{{indexId}}" 
        [formControl]="secondarySectionControl" [matAutocomplete]="autoSecondarySections" placeholder="Search by TSA offence number or keyword" [(ngModel)]="selectedSecondaryContraventionType"  
        [ngClass]="{'is-invalid': (secondarySectionControl.touched || isSubmitClicked) && secondarySectionControl.invalid}"> 
        <mat-autocomplete #autoSecondarySections="matAutocomplete" [displayWith]="displaySection" (optionSelected)="onSectionTypeChange($event.option.value, 2)">
            <mat-option *ngFor="let sectionType of secondaryFilteredSections | async" [value]="sectionType">
                {{displaySection(sectionType)}}
            </mat-option>
        </mat-autocomplete>
        <mat-error class="validation-message" *ngIf="(secondarySectionControl.touched || isSubmitClicked) && secondarySectionControl.hasError('required')">
            Traffic Safety Act Section is required
        </mat-error>
        <mat-error class="validation-message" *ngIf="(secondarySectionControl.touched || isSubmitClicked) && secondarySectionControl.hasError('forbiddenSections') && !secondarySectionControl.hasError('required')">
            Invalid Traffic Safety Act Section
        </mat-error>
    </div>
</div>

<div class="form-group row ml-4" *ngIf="tertiaryContraventionTypes?.length>0">
    <div class="col-12 col-sm-12">
        <label for="tertiarySection{{indexId}}">Tertiary Charge <span class="label-hint">(Required)</span></label>
        <input required type="search" [title]="selectedTertiaryContraventionType?.formattedName??''" class="form-control" id="tertiarySection{{indexId}}" name="tertiarySection{{indexId}}" 
        [formControl]="tertiarySectionControl" [matAutocomplete]="autoTertiarySections" placeholder="Search by TSA offence number or keyword" [(ngModel)]="selectedTertiaryContraventionType"  
        [ngClass]="{'is-invalid': (tertiarySectionControl.touched || isSubmitClicked) && tertiarySectionControl.invalid}"> 
        <mat-autocomplete #autoTertiarySections="matAutocomplete" [displayWith]="displaySection" (optionSelected)="onSectionTypeChange($event.option.value, 3)">
            <mat-option *ngFor="let sectionType of tertiaryFilteredSections | async" [value]="sectionType">
                {{displaySection(sectionType)}}
            </mat-option>
        </mat-autocomplete>
        <mat-error class="validation-message" *ngIf="(tertiarySectionControl.touched || isSubmitClicked) && tertiarySectionControl.hasError('required')">
            Traffic Safety Act Section is required
        </mat-error>
        <mat-error class="validation-message" *ngIf="(tertiarySectionControl.touched || isSubmitClicked) && tertiarySectionControl.hasError('forbiddenSections') && !tertiarySectionControl.hasError('required')">
            Invalid Traffic Safety Act Section
        </mat-error>
    </div>
</div>

<ng-container *ngIf="finallySelectedContraventionType && !finallySelectedContraventionType?.isAvailable">
    <div class="form-group row">
        <div class="col-12">
            <div class="alert-message">This charge cannot be issued electronically at this time. You must issue a handwritten ticket for this charge outside of APIS.</div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="finallySelectedContraventionType?.isIRS && finallySelectedContraventionType?.isAvailable">
    <div class="form-group row" *ngIf="contravention.contraventionTypeId == 1 || contravention.contraventionTypeId == 9 || contravention.contraventionTypeId == 10 || contravention.contraventionTypeId == 11">
        <div class="col-sm-12">
            <label for="contraventionDetailsMultiselect{{indexId}}">Contravention Details <span class="label-hint">(Required)</span></label>
            <mat-select [appSelectValidator]="true" required #contraventionDetailsMultiControl="ngModel"  class="form-control-multiselect" multiple id="contraventionDetailsMultiselect{{indexId}}" name="contraventionDetailsMultiselect{{indexId}}" placeholder="Select the Contravention Details" [(ngModel)]="selectedContraventionDetails" (selectionChange)="onContraventionDetailsChange($event.value)" [ngClass]="{'is-invalid':(contraventionDetailsMultiControl.touched || isSubmitClicked) && contraventionDetailsMultiControl.invalid}">
                <mat-option class="mat-primary" *ngFor="let contraventionDetailType of filterContraventionDetailTypes(finallySelectedContraventionType.id)" [value]="contraventionDetailType.id">{{contraventionDetailType.name}}</mat-option>
            </mat-select>
            <div class="invalid-feedback">
                <div>Contravention Details are required</div>
            </div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-12">
            <label for="contraventionDetailsText{{indexId}}">Contravention Details</label>
            <textarea readonly class="form-control" id="contraventionDetailsText{{indexId}}" name="contraventionDetailsText{{indexId}}" rows="5" [value]="contraventionDetailsText">
            </textarea>
        </div>
    </div>	

    <div class="form-group row">
        <div class="col-sm-6">
            <label for="contraventionFine{{indexId}}">Contravention Fine</label>
            <input readonly type="text" class="form-control" id="contraventionFine{{indexId}}" name="contraventionFine{{indexId}}" value="{{contravention.contraventionTypeId == 0  ? '' : '$' + finallySelectedContraventionType.fineAmount + (finallySelectedContraventionType.fineAmount == 0? '': ' (Plus Victim Fine Surcharge)')}}">
        </div>
    </div>			

    <div class="form-group row">
        <div class="col-sm-6">
            <label for="suspensionDuration{{indexId}}">Suspension Duration</label>
            <input readonly type="text" class="form-control" id="suspensionDuration{{indexId}}" name="suspensionDuration{{indexId}}" value="{{ contravention.contraventionTypeId == 0  ? '' : (finallySelectedContraventionType.licenceSuspensionDuration + ' ' + finallySelectedContraventionType.licenceSuspensionDurationType )}}"> 
        </div>
        <div class="col-12 col-sm-6">
            <label for="seizeOrDestroy{{indexId}}">Seize or Destroy Licence</label>
            <input readonly type="text" class="form-control" id="seizeOrDestroy{{indexId}}" name="seizeOrDestroy{{indexId}}" value="{{ contravention.contraventionTypeId == 0 || contravention.recipient?.recipientIdentification?.issuingProvinceId != 1 ? '' : (finallySelectedContraventionType.isLicenseDestroyed ? 'Destroy' : ( finallySelectedContraventionType.isLicenseSeized ? 'Seize' : ''))}}">
        </div>
    </div>

</ng-container>

<ng-container *ngIf="finallySelectedContraventionType?.isAvailable && (finallySelectedContraventionType?.isNonTSASDP || (finallySelectedContraventionType?.isSDP  && !availableTSA))">
    <div class="card card-alert my-4">
        <div class="container">
            <div class="row callout">
                <div class="col-md-1 callout-logo">   
                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                    </svg>
                </div>
                <div class="col-md-11 mt-4 mb-4">   
                    <h5>Issue Seizure Notice only through APIS</h5>
                    <div class="mt-2">
                        <p>1. You must issue a <span class="bold-text">seizure notice</span> through APIS. Proceed to the next screen.</p>
                        <p>2. You must issue a <span *ngIf="!availableTSA" class="bold-text">violation ticket or other </span><span class="bold-text">charging document as appropriate </span>for the following Suspended Driver Vehicle Seizure Program charges outside of APIS:</p>
                        <p class="mb-0 mt-0 pl-4" *ngIf="!availableTSA">•  TSA 94 (2) - Drive while unauthorized</p>
                        <p class="mb-0 mt-0 pl-4" *ngIf="!availableTSA">•  TSA 94.1 (1) - Drive while suspended or disqualified under immediate roadside sanction</p>
                        <p class="mb-0 mt-0 pl-4">•  Criminal Code (Canada) 320.18</p>
                        <p class="mb-0 mt-0 pl-4">•  NDA 130</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!finallySelectedContraventionType?.isIRS && !finallySelectedContraventionType?.isSDP && finallySelectedContraventionType?.isAvailable">
    <ng-container *ngIf="finallySelectedContraventionType?.isSpeedingOffence">
        <div class="form-group row">
            <div class="col-sm-6" *ngIf="finallySelectedContraventionType?.isFixedSpeedLimitCharge; else notFixedSpeedLimitCharge">
                <label for="speedLimit{{indexId}}">Speed Limit <span class="label-hint">(Required)</span></label>
                <input type="text" readonly class="form-control speed-control" [value]="contravention.speedingTicket.speedLimit">
            </div>
            <ng-template #notFixedSpeedLimitCharge>
                <div class="col-sm-6">
                    <label for="speedLimit{{indexId}}">Speed Limit <span class="label-hint">(Required)</span></label>
                    <input required type="number" #speedLimitControl="ngModel" class="form-control speed-control" id="speedLimit{{indexId}}" name="speedLimit{{indexId}}" placeholder="Enter Speed Limit" [(ngModel)]="contravention.speedingTicket.speedLimit" [ngClass]="{'is-invalid': (speedLimitControl.touched || isSubmitClicked) && speedLimitControl.invalid}" (ngModelChange)="calculateSpeedingFine()">
                    <div class="invalid-feedback">
                        <div>Speed Limit is required</div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="form-group row">
            <div class="col-sm-6">
                <label for="actualSpeed{{indexId}}">Actual Speed <span class="label-hint">(Required)</span></label>
                <input required type="number" isLessThan="{{contravention.speedingTicket.speedLimit+1}}" #actualSpeedControl="ngModel" class="form-control speed-control" id="actualSpeed{{indexId}}" name="actualSpeed{{indexId}}" placeholder="Enter Actual Speed" [(ngModel)]="contravention.speedingTicket.recipientVehicleSpeed" [ngClass]="{'is-invalid': (actualSpeedControl.touched || isSubmitClicked) && actualSpeedControl.invalid}" (ngModelChange)="autofillTicketedSpeed()">
                <div class="invalid-feedback">
                    <div *ngIf="actualSpeedControl.errors?.required">Actual Speed is required</div>
                    <div *ngIf="!actualSpeedControl.errors?.required && actualSpeedControl.hasError('LessThanMinNumber')">Actual Speed cannot be less than or equal to Speed Limit</div>
                </div>
            </div>
            <div class="col-sm-6">
                <label for="ticketedSpeed{{indexId}}">Ticketed Speed <span class="label-hint">(Required)</span></label>
                <input required type="number" isLessThan="{{contravention.speedingTicket.speedLimit+1}}" isGreaterThan="{{contravention.speedingTicket.recipientVehicleSpeed}}"
                #ticketedSpeedControl="ngModel" class="form-control speed-control" id="ticketedSpeed{{indexId}}" name="ticketedSpeed{{indexId}}" placeholder="Enter Ticketed Speed" [(ngModel)]="contravention.speedingTicket.ticketedSpeed" [ngClass]="{'is-invalid': (ticketedSpeedControl.touched || isSubmitClicked) && ticketedSpeedControl.invalid}" (ngModelChange)="calculateSpeedingFine()">
                <div class="invalid-feedback">
                    <div *ngIf="ticketedSpeedControl.errors?.required">Ticketed Speed is required</div>
                    <div *ngIf="!ticketedSpeedControl.errors?.required && ticketedSpeedControl.hasError('LessThanMinNumber')">Ticketed Speed cannot be less than or equal to Speed Limit</div>
                    <div *ngIf="!ticketedSpeedControl.errors?.required && !ticketedSpeedControl.hasError('LessThanMinNumber') && ticketedSpeedControl.hasError('GreaterThanMaxNumber')">Ticketed Speed cannot be greater than Actual Speed</div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-6">
                <label for="ticketMethodType{{indexId}}">Ticket Method <span class="label-hint">(Required)</span></label>
                <select [appSelectValidator]="true" #ticketMethodTypeControl="ngModel" class="form-control" name="ticketMethodType{{indexId}}" 
                id="ticketMethodType{{indexId}}" [(ngModel)]="contravention.speedingTicket.ticketMethodCode"
                [ngClass]="{'is-invalid':(ticketMethodTypeControl.touched || isSubmitClicked) && ticketMethodTypeControl.errors?.defaultSelected}">
                    <option value="">Select a Ticket Method</option>
                    <option *ngFor="let ticketMethodType of ticketMethodTypes" [value]="ticketMethodType.code">{{ticketMethodType.name}}</option>
                </select>
                <div class="invalid-feedback">
                    <div>Ticket Method is required</div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="form-group row">
        <div class="col-sm-6" *ngIf="!finallySelectedContraventionType.isFineAmountEditable">
            <label for="contraventionFine{{indexId}}">Fine Amount</label>
            <input readonly type="text" class="form-control" id="contraventionFine{{indexId}}" name="contraventionFine{{indexId}}" value="{{contravention.offenceTypeId == OffenceTypes.Part2SummonsMandatoryCourt ? 'N/A' : (contravention.contraventionTypeId == 0  ? '' : '$' + this.fine.fineAmount + (this.fine.fineAmount == 0? '': ' (Plus Victim Fine Surcharge)'))}}">
        </div>
        <div class="col-sm-6" *ngIf="finallySelectedContraventionType.isFineAmountEditable">
            <label for="contraventionFine{{indexId}}">Fine Amount <span class="label-hint">(Required)</span></label>
            <input required type="number" pattern="[0-9]+$" class="form-control" id="contraventionFine{{indexId}}" 
                    name="contraventionFine{{indexId}}" #fineAmountControl="ngModel" [(ngModel)]="this.fine.fineAmount" 
                    placeholder="Enter Fine Amount" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    [ngClass]="{'is-invalid': (fineAmountControl.touched || isSubmitClicked) && fineAmountControl.invalid}" 
                    (ngModelChange)="calculateOffenceType()" max="25000" maxlength="5" 
                    onKeyPress="if(this.value.length==5) return false;">
            <div class="invalid-feedback">
                <div *ngIf="fineAmountControl?.errors?.required">Fine Amount is required</div>
                <div *ngIf="fineAmountControl?.errors?.max">Fine Amount can not be greater than $25000</div>
                <div *ngIf="!fineAmountControl?.errors?.required && !fineAmountControl?.errors?.max">Invalid Fine Amount</div>
            </div>  
            <label class="amount-label">{{finalFineAmountText}}</label>  
        </div>
        <div class="col-sm-6">
            <label for="contraventionDemerits{{indexId}}">Demerits</label>
            <input readonly type="text" class="form-control" id="contraventionDemerits{{indexId}}" name="contraventionDemerits{{indexId}}" value="{{ contravention.demerits }}">
        </div>
    </div>

    <div class="form-group row" *ngIf="(!this.finallySelectedContraventionType?.isSpeedingOffence && this.finallySelectedContraventionType?.offenceTypeId == OffenceTypes.Part3OffenceNotice) || this.contravention.speedingTicket?.isPart3Offence; else notPart3">
        <div class="col-sm-6">
            <label for="offenceType{{indexId}}">Offence Type <span class="label-hint">(Required)</span></label>
            <select [appSelectValidator]="true" #offenceTypeControl="ngModel" class="form-control" name="offenceType{{indexId}}" id="offenceType{{indexId}}" 
            [(ngModel)]="contravention.offenceTypeId" (ngModelChange)="onOffenceTypeChange()"
            [ngClass]="{'is-invalid':(offenceTypeControl.touched || isSubmitClicked) && offenceTypeControl.errors?.defaultSelected}">
                <option value=0>Select an Offence Type</option>
                <option *ngFor="let offenceType of offenceTypes" [value]="offenceType.id">{{offenceType.name}}</option>
            </select>
            <div class="invalid-feedback">
                <div>Offence Type is required</div>
            </div>
        </div>
    </div>

    <ng-template #notPart3>
        <div class="form-group row">
            <div class="col-sm-6">
                <label for="offenceType{{indexId}}">Offence Type <span class="label-hint">(Required)</span></label>
                <input readonly type="text" class="form-control" id="offenceTypeReadonly{{indexId}}" name="offenceTypeReadonly{{indexId}}" [value]="localStorageService.getTypeItemDescriptionById(contravention.offenceTypeId, TypeTable.OffenceType)">
            </div>
        </div>
    </ng-template>
</ng-container>


<div *ngIf="indexId>0">
    <div class="form-group row">
        <div class="col-12">
            <button class="btn btn-link text-danger" (click)="onCancelClick()">
                Cancel
            </button>
        </div>
    </div>
</div>
