


export class ApprovedScreeningDeviceTest {
    public approvedScreeningDeviceTestId: number = 0;
    public testModelTypeId: number = 0;
    public testModelOther: string;
    public testDemandTime: string;
    public testStartedTime: string;
    public testResultTypeId: number = 0;

    constructor(init?: Partial<ApprovedScreeningDeviceTest>) {
        
        Object.assign(this, init);
    }
}
