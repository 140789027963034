<form #PrintConfirmationForm="ngForm">
    <div class="d-flex flex-column">
        <div class="row">
            <div class="col-12 col-sm-10">        
                <h1>
                    {{screenName}}
                </h1>
            </div>
            <div *ngIf="!isProcessing && !stopInformation.isEmergencySubmission && !stopInformation.isPaperSubmission && !isOnlyTSA" class="col-12 col-sm-2 d-flex align-self-end justify-content-end">
                <div class="float-right">
                    <div class="print-one-icon" (click)="downloadOneNotice()"></div>
                </div>
                <div class="float-right">
                    <div class="print-icon" (click)="downloadNotices()"></div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!isProcessing">
            <!-- Common issuance response block -->
            <ng-container *ngIf="ticketResponse && ticketResponse.length > 0">
                <div class="card border-0">
                    <ng-container *ngIf="okTicketResponse && okTicketResponse.length > 0">
                        <div class="row">
                            <div class="col-12">										
                                <h6>The following Offence {{okTicketResponse.length > 1?"Notices have":"Notice has"}} been issued. Click to download and print.</h6>
                            </div>
                        </div>
                        <div class="form-group row mt-2" *ngFor="let response of okTicketResponse">
                            <div class="col-12">
                                <a href="{{response.violationTicketUrl}}" target="_blank">{{response.ticketNumber}}</a> - {{response.ticketDescription}}
                            </div>
                        </div>
                        <div class="form-group row mt-2" *ngIf="!isOnlyTSA">
                            <div class="col-12">										
                                <h6>You can add disclosure to {{isMultipleTickets?"these Offence Notices":"this Offence Notice"}} from your <a [routerLink]="['/']">dashboard</a>.</h6>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="errorTicketResponse && errorTicketResponse.length > 0">
                        <div class="form-group row mt-2">
                            <div class="col-12">										
                                <h6>The following Offence {{okTicketResponse.length > 1?"Notices":"Notice"}} submission has failed.</h6>
                            </div>
                        </div>
                        <div class="form-group row mt-2" *ngFor="let response of errorTicketResponse">
                            <div class="col-12">
                                <label>{{response.ticketDescription}}</label>
                            </div>
                            <div class="col-12">
                                {{response.errorDetails?response.errorDetails:"Something went wrong with the ticket issuance"}} - {{response.responseMessage}} 
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="!stopInformation.isEmergencySubmission">
                <ng-container *ngIf="isImmediatelyCancelled">
                    <div class="card border-0">
                        <div class="row">
                            <div class="col-12">
                                <h6>This Notice has been submitted and immediately cancelled because it was submitted more than 7 days from issuance.</h6>
                            </div>
                            <div class="col-12">
                                <h6>This contravention/seizure cannot be re-issued.</h6>
                            </div>
                            <div class="col-12">
                                <h6>If a vehicle was seized along with this contravention, SafeRoads Alberta will contact the tow company to authorize the release of the vehicle on the next business day, as well as notify the driver of the cancellation. If your agency wishes to release the vehicle prior to the next business day, please contact the tow lot and advise the registered owner of the release. Please advise SafeRoads Alberta of such at <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a>.</h6>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isImmediatelyCancelled">
                    <ng-container *ngIf="stopInformation.isIRSSelected && !stopInformation.isOnlySDPActive && !this.isRefusal && (irsContravention?.contraventionTypeId != ContraventionTypes.IRS24 || isIRSReIssued())">
                        <div class="card mt-4">
                            <div class="card-header">
                                <div>Roadside Appeal</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Was the driver presented with Roadside Appeal Information? <span class="label-hint">(Required)</span></label>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isReadOnly()">
                                    <div class="col-sm-12">
                                        <label>{{roadsideAppeal.isRecipientPresentedWithInformation?'Yes':'No'}}</label>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isReadOnly()">
                                    <div class="col-sm-12">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input required type="radio" class="custom-control-input" id="appealInfoYes" name="appealRadio" #appealInfoYesControl="ngModel" [value]="true" [(ngModel)]="roadsideAppeal.isRecipientPresentedWithInformation" (change)="onAppealPresentedChange()" [ngClass]="{'is-invalid': roadsideAppeal.isRecipientPresentedWithInformation == null && (appealInfoYesControl.touched || appealInfoNoControl.touched || isSubmitClicked)}">
                                            <label class="custom-control-label" for="appealInfoYes">Yes</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input required type="radio" class="custom-control-input" id="appealInfoNo" name="appealRadio"  #appealInfoNoControl="ngModel" [value]="false" [(ngModel)]="roadsideAppeal.isRecipientPresentedWithInformation" (change)="onAppealPresentedChange()" [ngClass]="{'is-invalid': roadsideAppeal.isRecipientPresentedWithInformation == null && (appealInfoYesControl.touched || appealInfoNoControl.touched || isSubmitClicked)}">
                                            <label class="custom-control-label" for="appealInfoNo">No</label>
                                        </div>
                                        <input required type="radio" style="display:none;" id="appealInfoHidden" name="appealRadio"  #appealInfoHiddenControl="ngModel" [value]="null" [(ngModel)]="roadsideAppeal.isRecipientPresentedWithInformation" [ngClass]="{'is-invalid': roadsideAppeal.isRecipientPresentedWithInformation == null && (appealInfoYesControl.touched || appealInfoNoControl.touched || isSubmitClicked)}">
                                        <div class="invalid-feedback">
                                            <div>Roadside Appeal Information is required</div>
                                        </div>
                                    </div>
                                </div>			
                                <div class="form-group row" *ngIf="roadsideAppeal.isRecipientPresentedWithInformation == false">
                                    <div class="col-12">
                                        <label for="appealExplain">If No, Explain?  <span class="label-hint">(Required)</span></label>
                                        <input [readonly]="isReadOnly()" required #appealExplainControl="ngModel" maxlength="200" type="text" class="form-control" id="appealExplain" name="appealExplain" placeholder="Explain Why Roadside Appeal Options Were Not Provided" [(ngModel)]="roadsideAppeal.appealOptionsNotPresentedReason" [ngClass]="{'is-invalid':(appealExplainControl.touched || isSubmitClicked) && appealExplainControl.invalid}">
                                        <div class="invalid-feedback">
                                            <div>Explanation is required</div>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="form-group row" *ngIf="roadsideAppeal.isRecipientPresentedWithInformation == true">
                                    <div class="col-12">										
                                        <label class="w-100">Did the driver request a Roadside Appeal? <span class="label-hint">(Required)</span></label>
                                        <div *ngIf="isReadOnly()">
                                            <label>{{roadsideAppeal.hasDriverRequestedRoadsideAppeal?'Yes':'No'}}</label>
                                        </div>
                                        <div *ngIf="!isReadOnly()">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input required type="radio" class="custom-control-input" id="appealRequestedYes" name="appealRequestedRadio" #appealRequestedYesControl="ngModel" [value]="true" [(ngModel)]="roadsideAppeal.hasDriverRequestedRoadsideAppeal" (change)="onAppealRequestChange()" [ngClass]="{'is-invalid': roadsideAppeal.hasDriverRequestedRoadsideAppeal == null && (appealRequestedYesControl.touched || appealRequestedNoControl.touched || isSubmitClicked)}">
                                                <label class="custom-control-label" for="appealRequestedYes">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input required type="radio" class="custom-control-input" id="appealRequestedNo" name="appealRequestedRadio" #appealRequestedNoControl="ngModel" [value]="false" [(ngModel)]="roadsideAppeal.hasDriverRequestedRoadsideAppeal" (change)="onAppealRequestChange()" [ngClass]="{'is-invalid': roadsideAppeal.hasDriverRequestedRoadsideAppeal == null && (appealRequestedYesControl.touched || appealRequestedNoControl.touched || isSubmitClicked)}">
                                                <label class="custom-control-label" for="appealRequestedNo">No</label>
                                            </div>
                                            <input required type="radio" style="display:none;" id="appealRequestedHidden" name="appealRequestedRadio" #appealRequestedHiddenControl="ngModel" [value]="null" [(ngModel)]="roadsideAppeal.hasDriverRequestedRoadsideAppeal"  [ngClass]="{'is-invalid': roadsideAppeal.hasDriverRequestedRoadsideAppeal == null && (appealRequestedYesControl.touched || appealRequestedNoControl.touched || isSubmitClicked)}">
                                            <div class="invalid-feedback">
                                                <div>Roadside Appeal Request Information is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-4" *ngIf="roadsideAppeal.hasDriverRequestedRoadsideAppeal == true && !stopInformation.isPaperSubmission">
                            <div class="card-header">
                                <div>Roadside Appeal Results</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 mb-2" *ngFor="let roadsideAppealOutcomeType of roadsideAppealOutcomeTypes">	
                                        <div *ngIf="!isReadOnly()" class="custom-control custom-radio custom-control-inline">
                                            <input required type="radio" class="custom-control-input" id="appealResult{{roadsideAppealOutcomeType.id}}" name="appealResultRadio" value="{{roadsideAppealOutcomeType.id}}" [(ngModel)]="roadsideAppeal.roadsideAppealOutcomeTypeId">
                                            <label class="custom-control-label" for="appealResult{{roadsideAppealOutcomeType.id}}"></label>
                                            <span class="label-message">{{roadsideAppealOutcomeType.description.substring(0, roadsideAppealOutcomeType.description.indexOf(roadsideAppealOutcomeType.name))}} <span class="bold-text">{{roadsideAppealOutcomeType.name}}</span> {{roadsideAppealOutcomeType.description.substring(roadsideAppealOutcomeType.description.indexOf(roadsideAppealOutcomeType.name) + roadsideAppealOutcomeType.name.length)}}</span>
                                        </div>
                                        <div *ngIf="isReadOnly() && roadsideAppeal.roadsideAppealOutcomeTypeId == roadsideAppealOutcomeType.id">
                                            <span class="label-message">{{roadsideAppealOutcomeType.description.substring(0, roadsideAppealOutcomeType.description.indexOf(roadsideAppealOutcomeType.name))}} <span class="bold-text">{{roadsideAppealOutcomeType.name}}</span> {{roadsideAppealOutcomeType.description.substring(roadsideAppealOutcomeType.description.indexOf(roadsideAppealOutcomeType.name) + roadsideAppealOutcomeType.name.length)}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="error" *ngIf="!roadsideAppeal.roadsideAppealOutcomeTypeId && isSubmitClicked">
                                    <div>Roadside Appeal Result Information is required</div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card mt-4" *ngIf="roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.EqualToOrExceeded || (roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LessThanFirstTest && isIRSReIssued()) || roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.RefusedOrFailedToComply || roadsideAppeal.isRecipientPresentedWithInformation == false || roadsideAppeal.hasDriverRequestedRoadsideAppeal == false || stopInformation.isPaperSubmission">
                            <div class="card-header">
                                <div>Add Details</div>
                            </div>
                            <div class="card-body">
                                <div class="form-group row">
                                    <div class="col-12">										
                                        <span class="label-message">You can <span class="bold-text">add details</span> to your notice(s) now, or later on from your <a [routerLink]="['/']">dashboard</a>.</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">										
                                        <span class="label-message">If a correction or assistance is needed on this contravention or a previously submitted contravention, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-4" *ngIf="roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LessThanFirstTest && !isIRSReIssued()">
                            <div class="card-header">
                                <div>Re-issue Contravention</div>
                            </div>
                            <div class="card-body">
                                <div class="form-group row">
                                    <div class="col-12">										
                                        <span class="label-message">The Roadside Appeal test result showed less than the first test result.</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">										
                                        <span class="label-message">You must <span class="bold-text">update</span> the IRS Type and <span class="bold-text">re-issue</span> this Notice.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-4" *ngIf="roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LestThanAnyConcentration">
                            <div *ngIf="!isIRSCancelled()">
                                <div class="card-header">
                                    <div>Cancel Contravention</div>
                                </div>
                                <div class="card-body">
                                    <div class="form-group row">
                                        <div class="col-12">										
                                            <span class="label-message">The Roadside Appeal test result showed less than any prohibited drug or alcohol concentration.</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">										
                                            <span class="label-message">You must <span class="bold-text">cancel</span> this Notice of Administrative Penalty.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isIRSCancelled()">
                                <div class="card-header">
                                    <div>Contravention Cancelled</div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">										
                                            <span class="label-message">This Notice of Administrative Penalty has been successfully <span class="bold-text">cancelled</span>.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-4" *ngIf="roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.Pending">
                            <div class="card-header">
                                <div>Results Pending</div>
                            </div>
                            <div class="card-body">
                                <div class="form-group row">
                                    <div class="col-12">										
                                        <span class="label-message">When you receive the test results, you must add that information to the contravention. Access notices from your <a [routerLink]="['/']">dashboard</a> to make any updates.</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">										
                                        <span class="label-message">If a correction or assistance is needed on this notice or a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </ng-container>

                    <ng-container *ngIf="!isOnlyTSA && (!stopInformation.isIRSSelected || stopInformation.isOnlySDPActive || this.isRefusal || (irsContravention?.contraventionTypeId == ContraventionTypes.IRS24 && !isIRSReIssued()))">
                        
                        <!-- Only single seizure -->
                        <ng-container *ngIf="seizuresCount == 1 && contraventionsCount == 0"> 
                            <div class="card border-0">
                                <div class="row">
                                    <div class="col-12">										
                                        <h6>Seizure Notice has been {{ stopInformation.isPaperSubmission ? 'submitted' : 'issued'}}.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <span class="label-message">If a correction or assistance is needed on this notice or a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container> 

                        <!-- Only single contravention -->
                        <ng-container *ngIf="seizuresCount == 0 && contraventionsCount == 1"> 
                            <div class="card border-0">
                                <div class="row">
                                    <div class="col-12">										
                                        <h6>Your Notice of Administrative Penalty has been {{ stopInformation.isPaperSubmission ? 'submitted' : 'issued'}}.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <h6>You can add details to this notice now, or later on from your <a [routerLink]="['/']">dashboard</a>.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <span class="label-message">If a correction or assistance is needed on this notice or a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container> 

                        <!-- Seizure and Other contraventions -->
                        <ng-container *ngIf="seizuresCount > 0 && contraventionsCount > 0"> 
                            <div class="card border-0">
                                <div class="row">
                                    <div class="col-12">										
                                        <h6>Seizure Notice and Notice(s) of Administrative Penalty have been {{ stopInformation.isPaperSubmission ? 'submitted' : 'issued'}}.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <span class="label-message">If a correction or assistance is needed on any of these notices or on a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container> 

                        <!-- Multiple contraventions -->
                        <ng-container *ngIf="seizuresCount == 0 && contraventionsCount > 1"> 
                            <div class="card border-0">
                                <div class="row">
                                    <div class="col-12">										
                                        <h6>Your Notices of Administrative Penalty have been {{ stopInformation.isPaperSubmission ? 'submitted' : 'printed'}}.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <h6>You can add details to these notice now, or later on from your <a [routerLink]="['/']">dashboard</a>.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <span class="label-message">If a correction or assistance is needed on any of these notices or on a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container> 
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="stopInformation.isEmergencySubmission">
                <div class="card border-0">
                    <div class="row">
                        <div class="col-12">										
                            <h6>Your IRS 24 emergency suspension has been submitted to APIS.</h6>
                        </div>
                        <div class="col-12">										
                            <h4>Next steps:</h4>
                        </div>
                        <div class="col-12">										
                            <h6 class="mt-0 ml-2">1. You must scan and upload the original paper copy of the Notice of Administrative Penalty and, if the vehicle was seized, the Seizure Notice. You can do this now, or later on from your <a [routerLink]="['/']">dashboard</a>.</h6>
                        </div>
                        <div class="col-12">										
                            <h6 class="mt-0 ml-2">2. Depending on the level of impairment of the driver at the time of the stop, you may need to issue another IRS Notice of Administrative Penalty and Vehicle Seizure Notice to the driver for the correct IRS type. If that is the case, click on Return to Dashboard.</h6>
                        </div>
                        <div class="col-12">										
                            <span class="label-message">If a correction or assistance is needed on any of these notices or on a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- <ng-container *ngIf="isOnlyTSA">
                <div class="card border-0">
                    <div class="row">
                        <div class="col-12">										
                            <h6>Your Offence {{isMultipleTickets?"Notices have":"Notice has"}} been printed.</h6>
                        </div>
                        <div class="col-12">										
                            <h6>You can add disclosure to {{isMultipleTickets?"these Offence Notices":"this Offence Notice"}} by accessing your <a [routerLink]="['/']">dashboard</a>.</h6>
                        </div>
                    </div>
                </div>
            </ng-container> -->
            <ng-container *ngIf="isOnlyTSA">
                <div *ngIf="!isPoliceNotesAttached">
                    <div class="card">
                        <div class="card-header">
                            Officer Narrative
                        </div>
                        <div class="card-body">
                            <div class="row mt-2">
                                <div class="col-sm-12">
                                    <textarea #notesControl="ngModel" class="form-control" id="notes" name="notes" rows="7" [(ngModel)]="policeNotes"></textarea>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12 col-sm-6"></div>
                                <div class="col-12 col-sm-6 text-right" *ngIf="isOnlyTSA && !isPoliceNotesAttached">
                                    <button class="btn button-primary btn-block" (click)="onTicketSubmitClick()">Submit Narrative</button>    
                                </div>
                            </div>
                        </div>    
                    </div>
                    <div class="error">
                        {{errorMessage}}
                    </div>
                    <div class="mt-2">
                        If you need to skip this step, you can upload a document later from your dashboard. 
                    </div>
                    <div class="row">
                        <div class="col-12">										
                            <h6>You can add disclosure to {{isMultipleTickets?"these Offence Notices":"this Offence Notice"}} from your <a [routerLink]="['/']">dashboard</a>.</h6>
                        </div>
                    </div>
                </div>
                <div *ngIf="isPoliceNotesAttached">
                    <div class="card">
						<div class="container justify-content-left">
                            <div class="row callout">
                                <div class="col-md-1 callout-logo-success d-flex align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                    </svg>
                                </div>
                                <div class="col-md-11 mt-4 mb-2">
                                    <h4>
                                        Your narrative has been attached
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </ng-container>
        </ng-container>
        
        <div class="form-group mt-4">
            <div class="row pt-3">
                <div class="col-12 col-sm-6">                
                    <a class="btn button-secondary btn-block" [routerLink]="['/']">Return to Dashboard</a>
                </div>
                <div class="col-12 col-sm-6 text-right" *ngIf="!isProcessing && !isImmediatelyCancelled && !isOnlyTSA">                
                    <button *ngIf="isAddDetailsButtonRequired()" class="btn button-primary btn-block" (click)="onSubmitClick(PrintConfirmationForm.valid)">Add Details to Notice(s)</button>    
                    <button *ngIf="roadsideAppeal?.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LessThanFirstTest && !isIRSReIssued()" class="btn button-primary btn-block" (click)="showReissueContraventionModal(PrintConfirmationForm.valid)">Re-issue Notice</button>
                    <button *ngIf="roadsideAppeal?.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LestThanAnyConcentration && !isIRSCancelled()" class="btn button-primary btn-block" (click)="showCancelContraventionModal(PrintConfirmationForm.valid)">Cancel Notice</button>
                </div>
            </div>
        </div>        
    </div>
</form>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>