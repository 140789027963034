<form #seizureNoticesForm="ngForm">
    <div class="d-flex justify-content-center pt-2 pb-2 pt-lg-5 pb-lg-5">
        <div class="d-flex flex-column">
            <div class="mb-4">
                <a class="lnk-back" [routerLink]="['../']">Back to Reports</a>
            </div>
            <h1>Download Seizure Data</h1>  
            <div class="card mt-2 info-card header-text">
                Download an extract of all Seizure Notices issued within the selected period. Please note larger date ranges will take longer to extract and download.
            </div>
            <div class="card mt-4">
                <div class="card-header">
                    Seizure Notices
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input required type="radio" class="custom-control-input" id="dateFilter" name="filterType" #dateFilterControl="ngModel" [value]="true" [(ngModel)]="isDateFilter" (change)="onFilterTypeChange()" [ngClass]="{'is-invalid': isDateFilter == null && (dateFilterControl.touched || monthFilterControl.touched || seizureNoticesForm.submitted)}">
                                <label class="custom-control-label" for="dateFilter">Filter by Seizure Date Range <span class="label-hint">(maximum 1 year)</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row ml-2">
                        <div class="col-12 col-sm-6">
                            <label for="startDate">Start Date <span *ngIf="isDateFilter" class="label-hint">(Required)</span></label>
                            <input [required]="isDateFilter" [disabled]="!isDateFilter" #startDateControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig" maxlength="10" 
                                    class="form-control" id="startDate" name="startDate" placeholder="yyyy/mm/dd" [(ngModel)]="startDate" (blur)="isValidDateRange(seizureNoticesForm.valid)"
                                    [ngClass]="{'is-invalid':(startDateControl.touched || seizureNoticesForm.submitted) && startDateControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="startDateControl.hasError('required')">Start Date is required</div>
                                <div *ngIf="!startDateControl.hasError('required')">Invalid Start Date</div>
                            </div>
                            <div class="error" *ngIf="errorMessage.length>0">
                                <span>{{errorMessage}}</span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <label for="endDate">End Date <span *ngIf="isDateFilter" class="label-hint">(Required)</span></label>
                            <input [required]="isDateFilter" [disabled]="!isDateFilter" isLessThanStartDate="{{startDate | date:'yyyy-MM-dd'}}" #endDateControl="ngModel" type="text" bsDatepicker 
                                    [bsConfig]="datePickerConfig" maxlength="10" class="form-control" id="endDate" name="endDate" placeholder="yyyy/mm/dd" [(ngModel)]="endDate" (blur)="isValidDateRange(seizureNoticesForm.valid)"
                                    [ngClass]="{'is-invalid':(endDateControl.touched || seizureNoticesForm.submitted) && endDateControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="endDateControl.hasError('required')">End Date is required</div>
                                <div *ngIf="!endDateControl.hasError('required') && endDateControl.hasError('EarlierDate')">End Date can't be earlier than the Start Date</div>
                                <div *ngIf="!endDateControl.hasError('required') && !endDateControl.hasError('EarlierDate')">Invalid End Date</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-12">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input required type="radio" class="custom-control-input" id="monthFilter" name="filterType" #monthFilterControl="ngModel" [value]="false" [(ngModel)]="isDateFilter" (change)="onFilterTypeChange()" [ngClass]="{'is-invalid': isDateFilter == null && (dateFilterControl.touched || monthFilterControl.touched || seizureNoticesForm.submitted)}">
                                <label class="custom-control-label" for="monthFilter">Filter by Year/Month</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row ml-2">
                        <div class="col-12 col-sm-4">
                            <label for="year">Year <span *ngIf="isDateFilter==false" class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="isDateFilter==false" [disabled]="isDateFilter!=false" #yearSelectControl="ngModel" class="form-control" id="year" name="year" [(ngModel)]="selectedYear" [ngClass]="{'is-invalid':(yearSelectControl.touched || seizureNoticesForm.submitted) && yearSelectControl.errors?.defaultSelected}">
                                <option value=0>Select Year</option>
                                <option *ngFor="let year of yearList" [value]="year">{{year}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Year is required</div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <label for="month">Month <span *ngIf="isDateFilter==false" class="label-hint">(Optional)</span></label>
                            <select [disabled]="isDateFilter!=false" #monthSelectControl="ngModel" class="form-control" id="month" name="month" [(ngModel)]="selectedMonth">
                                <option value=0>Select Month</option>
                                <option *ngFor="let month of monthList" [value]="month.id">{{month.value}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-12">
                            <input required type="radio" style="display:none;" id="filterTypeHidden" name="filterType" #filterTypeHiddenControl="ngModel" [value]="null" [(ngModel)]="isDateFilter" (change)="onFilterTypeChange()" [ngClass]="{'is-invalid': isDateFilter == null && (dateFilterControl.touched || monthFilterControl.touched || seizureNoticesForm.submitted)}">
                            <div class="pl-4 invalid-feedback">
                                <div>Report Filter Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6"></div>   
                    <div class="col-12 col-sm-6">                
                        <button class="btn button-primary btn-block" (click)="onDownloadClick(seizureNoticesForm.valid)">Download</button>             
                    </div>
                </div>
            </div>        
        </div>    
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white"> Please be patient while APIS generates your report... </p></ngx-spinner>         
</form>

