import { TestType } from "./types/test-type.model";

export class ImpairmentScreeningModel {
    public ImpairmentScreeningMethodId: number = 0;
    public isImpairmentScreeningMethodSelectionRequired: boolean = false;
    public impairmentScreeningCardLabel: string;
    public impairmentScreeningResultCardLabel: string;
    public selectionMessageLabel: string;
    public noSelectionMessageLabel: string;
    public controlIndex: number = 0;    
    public controlSuffixText: string = "";
    public isSubmitClicked: boolean = false;
    public possibleTestTypes: TestType[];
    
    constructor(init?: Partial<ImpairmentScreeningModel>) {
        Object.assign(this, init);
    }
}