

import { ApprovedInstrumentTest } from './approved-instrument-test.model';
import { ApprovedScreeningDeviceTest } from './approved-screening-device-test.model';
import { BloodSampleTest } from './blood-sample-test.model';
import { DrugRecognitionEvaluationTest } from './drug-recognition-evaluation-test.model';
import { DrugScreeningEquipmentTest } from './drug-screening-equipment-test.model';
import { StandardizedFieldSobrietyTest } from './standardized-field-sobriety-test.model';
import { UrineSampleTest } from './urine-sample-test.model';

export class ContraventionTest {
    public contraventionTestId: number = 0;
    public impairmentScreeningInformationId: number;
    public roadsideAppealId: number;
    public testSequence: number;
    public testTypeId: number = 0;
    public approvedInstrumentTestId: number;
    public approvedScreeningDeviceTestId: number;
    public bloodSampleTestId: number;
    public drugRecognitionEvaluationTestId: number;
    public drugScreeningEquipmentTestId: number;
    public standardizedFieldSobrietyTestId: number;
    public urineSampleTestId: number;
    public hasDriverRefusedToTest: boolean;
    public refusalTime: string;
    public appealNotProvidedReason: string;
    public approvedInstrumentTest: ApprovedInstrumentTest;
    public approvedScreeningDeviceTest: ApprovedScreeningDeviceTest;
    public bloodSampleTest: BloodSampleTest;
    public drugRecognitionEvaluationTest: DrugRecognitionEvaluationTest;
    public drugScreeningEquipmentTest: DrugScreeningEquipmentTest;
    public standardizedFieldSobrietyTest: StandardizedFieldSobrietyTest;
    public urineSampleTest: UrineSampleTest;
    public driverDeclinedToTestTypeId: number;

    constructor(init?: Partial<ContraventionTest>) {

        Object.assign(this, init);
    }
}
