<div *ngIf="environmentName.length > 0 && environmentName != 'Production'" class="environment-name">
    {{environmentName}}
</div>
<div *ngIf="environmentName.length > 0 && environmentName != 'Production'" class="mb-4"></div>         
<div id="header" class="header d-flex align-items-center row">
    <div class="logo-container col-2"> 
        <div class="ab-logo" (click)="onDashboardClick()"></div>
    </div>
    <div class="maintenance-message-section col-6">
        <div *ngIf="maintenanceMessage" class="maintenance-message-box"> 
            <div class="maintenance-message-alert">{{ maintenanceMessageHeader }}</div>
            <div class="maintenance-message">{{ maintenanceMessage }} </div>
        </div>
    </div>
    <div *ngIf="userName" class="log-out d-flex align-items-center col-4">
        <div class="toggle-container">
            <label class="toggle-control" title="Change to {{(darkMode$ | async)?'Day':'Night'}} Mode">
                <input type="checkbox" [checked]="darkMode$ | async" (change)="onToggle()">
                <span class="control"></span>
            </label>
        </div>
        <div *ngIf="isOnline" class="username-icon" (click)="onUserNameClick()"  title="{{ isOnlineMessage }}">
            <i class="far fa-user-circle fa-lg online-user"></i>
        </div>
        <div *ngIf="!isOnline" class="username-icon-offline" title="{{ isOnlineMessage }}">
            <i class="far fa-user-circle fa-lg offline-user"></i>
        </div>
        <div>
            <ul>
            <li class="nav-item dropdown">
                <a *ngIf="isOnline" class="nav-link-tight dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-expanded="false">{{userName}}</a>
                <a *ngIf="!isOnline" class="nav-link-tight dropdown-toggle offline-user-text" data-toggle="dropdown" href="#" role="button" aria-expanded="false">{{userName}} (Offline)</a>
                <div class="dropdown-menu">
                    <app-access-control [Resource]="Resource.POLICE_INTAKE_DASHBOARD" [Permission]="Permission.VIEW_INTAKE_DASHBOARD">
                        <a class="dropdown-item" href="javascript:void(0);" (click)="onDashboardClick()">Dashboard</a>
                    </app-access-control>
                    <a class="dropdown-item" href="javascript:void(0);" (click)="onUserNameClick()">Profile</a>
                    <app-access-control [Resource]="Resource.POLICE_INTAKE_REPORTS" [Permission]="Permission.DOWNLOAD_REPORTS">
                        <a class="dropdown-item" href="javascript:void(0);" (click)="onReportsClick()">Reports</a>
                    </app-access-control>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript:void(0);">Manage Tow Companies</a>
                    <a class="dropdown-item" href="javascript:void(0);" (click)="onHelpAndResourcesClick()">Help and Resources</a>
                </div>
            </li>
            </ul>
        </div>
        <button class="btn-link" (click)="onLogoutClick()">
            Logout
        </button>
    </div>
    
</div>

<div class="profile-modal-overlay">
    <div class="profile-modal">
        <div class="card mb-2">
            <div class="card-header">
                Profile Update
            </div>
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <label for="userIdModal">User Id </label>
                            <input type="text" class="form-control" id="userIdModal" name="userIdModal" value ="{{officer?.emailAddress}}" readonly>
                        </div>
                        <div class="col-12 col-sm-6">
                            <label for="regimentalNumberModal">Regimental Number </label>
                            <input type="text" class="form-control" #regimentalNumberModalControl="ngModel" pattern="^[0-9]{0,6}$" maxlength="6" id="regimentalNumberModal" name="regimentalNumberModal" [(ngModel)] ="officer.regimentalNumber" placeholder="Regimental Number" [ngClass]="{'is-invalid': (regimentalNumberModalControl.touched || isSubmitClicked) && regimentalNumberModalControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Invalid Regimental Number. Only digits are allowed.</div>
                            </div>
                        </div>
                    </div> 
                </div>           
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <label for="officerFirstName">First Name </label>
                            <input required type="text" class="form-control" id="officerFirstName" name="officerFirstName" #fName="ngModel" maxlength="50" [(ngModel)]="officer.firstName" [ngClass]="{'is-invalid': (fName.touched || isSubmitClicked) && fName.invalid}">
                            <div class="invalid-feedback">
                                <span>First Name is required</span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <label for="officerLastName">Last Name </label>
                            <input required type="text" class="form-control" id="officerLastName" name="officerLastName" #lName="ngModel" maxlength="50" [(ngModel)]="officer.lastName" [ngClass]="{'is-invalid': (lName.touched || isSubmitClicked) && lName.invalid}">
                            <div class="invalid-feedback">
                                <span>Last Name is required</span>
                            </div>
                        </div>
                    </div> 
                </div>           
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <label for="agencyModal">Agency/Service</label>
                            <select class="form-control" name="agencyModal" id="agencyModal" [(ngModel)]="officer.agencyId">
                                <option [ngValue]="null">Select an Agency/Service</option>
                                <option *ngFor="let agency of agencies" [value]="agency.agencyId">{{agency.agencyName}}</option>            
                            </select>
                       </div>
                       <div class="col-12 col-sm-6">
                        <label for="detachmentModal">Detachment/Employer</label>
                        <select class="form-control" name="detachmentModal" id="detachmentModal" [(ngModel)]="officer.detachmentId">
                            <option [ngValue]="null">Select a Detachment/Employer</option>
                            <option *ngFor="let detachment of detachments | detachmentsFilter:officer.agencyId" [value]="detachment.detachmentId">{{detachment.detachmentName}}</option>            
                        </select>
                    </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <label for="defaultMunicipality">Default Municipality</label>
                            <select class="form-control" name="defaultMunicipality" id="defaultMunicipality" [(ngModel)]="officer.defaultMunicipalityLocationId">
                                <option [ngValue]="null">Select a Municipality</option>
                                <option *ngFor="let location of locations" [value]="location.id">{{location.name}}</option>            
                            </select>
                       </div>
                       <div class="col-12 col-sm-6">
                            <label for="defaultCourtLocation">Default Court Location</label>
                            <select class="form-control" name="defaultCourtLocation" id="defaultCourtLocation" [(ngModel)]="officer.defaultCourtLocationId">
                                <option [ngValue]="null">Select a Court Location</option>
                                <option *ngFor="let location of courtLocations" [value]="location.id">{{location.name}}</option>            
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-flex align-items-end justify-content-end">
                        <div *ngIf="!isProfileUpdateRequired" id="btnCancelModal" class="cancel-button" (click)="onProfileCancelClick()" >Cancel</div>
                        <div id="btnSaveModal" class="add-button" (click)="onProfileSaveClick(!regimentalNumberModalControl.invalid && fName.valid && lName.valid)">Save</div>
                    </div>
                </div>
                <div class="form-group row">
                    <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row pl-4 pb-4">  
                        <span class="error">{{errorMessage}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      
</div>

