import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from "apps/intake/src/environments/environment";
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Constants } from '@apis/shared/helpers/constants';

@Injectable({ providedIn: 'root' })
export class ConnectivityService {

  private healthCheckUrl: string = `${environment.apiUrl}/`;
  private availableOffline: boolean = environment.availableOffline;

  public onlineStatusObservable = new BehaviorSubject(true);
  isOnlineStatus = this.onlineStatusObservable.asObservable();
  isOnlineInternalFlag: boolean = true;

  public constructor(public http: HttpClient, private localStorageService: LocalStorageService) {
      this.onlineStatusObservable.next(true);
  }

  public isUserOnline() {

    // option to disable the offline capability
    if(!this.availableOffline) {
      return true;
    }
    
    // return the current status of online based on the last time we physically checked
    return this.isOnlineInternalFlag; 
  }

  public async getOnlineStatusAsync(): Promise<boolean> {

    // option to disable the offline capability
    if (!this.availableOffline) {
      return true;
    }

    // if the lookups will need loading... then we must assume we are online (probably logging in)
    if (this.localStorageService.get(Constants.Settings.LOOKUP_VERSION)==null) {
      return true;
    }

    let route = 'healthcheck';
    let healthCheckTimeout = +this.localStorageService.get(Constants.Settings.HEALTH_CHECK_TIMEOUT_LENGTH);
    if (healthCheckTimeout<=0) {
      healthCheckTimeout = 10000; // 10 second default if not in the settings
    }
    
    try
    {
      let resp = await this.http.get(this.healthCheckUrl + route, {responseType: 'text'}).pipe(timeout(healthCheckTimeout)).toPromise().catch((err)=>
      {
        this.isOnlineInternalFlag = false;
        this.onlineStatusObservable?.next(false);
        return false;
      });

      // no error has occurred, are we 'healthy'?
      if (resp.toString().toLowerCase().trim() == Constants.Status.HEALTHCHECK_HEALTHY_TEXT){
        // if we are already online, we don't need to notify subscribers
        if (this.isOnlineInternalFlag == false) {
          this.isOnlineInternalFlag = true;
          this.onlineStatusObservable?.next(true);
        }
      } else {
        // if we are already offline, we don't need to notify subscribers
        if (this.isOnlineInternalFlag == true) {
          this.isOnlineInternalFlag = false;
          this.onlineStatusObservable?.next(false);
        }
      }
      return this.isOnlineInternalFlag;

    } catch (error) {
      this.isOnlineInternalFlag = false;
      this.onlineStatusObservable?.next(false);
      return false;
    }
  }

}