import { AdditionalDocumentCategory } from "../enums/additional-document-category.enum";

export class FileUploadModel {
    public tempFileFolder: string;
    public isReadOnly: boolean;
    public isUploadPeriodExpired: boolean;
    public additionalDocumentsCategory: number = AdditionalDocumentCategory.Intake;
    public documentRefTypeName: string;
    public documentRefTypeNumber: string;
    public isReServeRequired: boolean = false;

    constructor(init?: Partial<FileUploadModel>) {
        Object.assign(this, init);
    }
}
