import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Component({
  selector: 'app-access-control',
  templateUrl: './access-control.component.html',
  styleUrls: ['./access-control.component.scss']
})
export class AccessControlComponent implements OnInit {

  hasPermission:boolean=false;
  constructor(readonly localStorageService: LocalStorageService) { }

  @Input() Resource: string;
  @Input() Permission: string;
  ngOnInit(): void {
    this.localStorageService.hasLocalStorageReady$.subscribe(val => {
      this.hasPermission=this.localStorageService.hasPermission(this.Resource, this.Permission);
    });
  }

}
