

export enum DecisionTypes {
    Confirm = 1,
    Cancel = 2,
    CancelledByRecipient = 3,
    VaryAndConfirm = 4
}
export enum DeclarationTypes {
    SupportingDocumentsIssuingOfficer = 1,
    SupportingDocumentsNonIssuingOfficer = 2,
    FailedAttendanceAbandonReview = 3,
    FailedAttendanceContinueReview = 4,
    SupportingDocumentsIssuingOfficerV2 = 5,
    OfficerSubmissionDeclaration = 6,
    OfficerSubmissionDeclarationV2 = 7,
    SupportingDocumentsNonIssuingOfficerV2 = 8
}
export enum RequestDecisionTypes {
    Approved = 1,
    Denied = 2
}
export enum SearchResultType {
    Exists = 0,
    NotFound = 1,
    CriteriaMisMatch = 2
}
export enum ContraventionStatusTypes {
    Unpaid = 1,
    InReview = 2,
    Overdue = 3,
    Paid = 4,
    Cancelled = 5,
    Issued = 6,
    Stay = 7,
    Uncollectable = 8
}
export enum ContraventionTrigger {
    AmountDueChanged = 0,
    DueDateChanged = 1,
    ReviewCancelled = 2,
    ReviewDecisionConfirmed = 3,
    ReviewDecisionCancelled = 4,
    StayOrderAdded = 5,
    StayOrderExpired = 6,
    StayOrderRevoked = 7,
    StayOrderCancelled = 8
}
export enum SeizureStatusTypes {
    Seized = 1,
    InReview = 2,
    Released = 3,
    Cancelled = 4
}
export enum SeizureTrigger {
    ReviewCancelled = 0,
    ReviewDecisionConfirmed = 1,
    ReviewDecisionCancelled = 2,
    ReliefCancelled = 3,
    ReliefDecisionConfirmed = 4,
    ReliefDecisionCancelled = 5,
    SeizureDatesChanged = 6
}
export enum RequestTypes {
    AdditionalTimetoPay = 1,
    LateReview = 2,
    LateSeizureReview = 3,
    LEACancellationRequest = 4
}
export enum RequestStatusTypes {
    New = 1,
    PendingDecision = 2,
    Complete = 3,
    PaymentPending = 4,
    Cancelled = 5
}
export enum ReviewStatusTypes {
    New = 1,
    Prep = 2,
    Reschedule = 3,
    PendingReview = 4,
    PendingDecision = 5,
    Complete = 6,
    Cancelled = 7,
    PaymentPending = 8
}
export enum ReviewSubStatusTypes {
    AbandonedLateEvidence = 1,
    AbandonedOther = 2
}
export enum ReviewTypes {
    IrsReview = 1,
    SuspendedDriverVehicleSeizureReview = 2,
    ThirdPartySeizureReview = 3,
    TrafficReview = 4,
    AteReview = 5
}
export enum ReviewMethodTypes {
    Written = 1,
    Oral = 2
}
export enum TransactionMethodTypes {
    Online = 1,
    MotorVehicleDepartment = 2
}
export enum TransactionTypes {
    FinePayment = 1,
    ReviewFee = 2,
    LateReviewRequestFee = 3,
    RefundFinePayment = 4,
    RefundReviewFee = 5,
    RefundLateReviewRequestFee = 6,
    VictimFineSurcharge = 7,
    RefundVictimFineSurcharge = 8
}
export enum TransactionStatusTypes {
    InProgress = 1,
    Success = 2,
    Error = 3
}
export enum ReviewState {
    ReviewAvailable = 1,
    ReviewUnAvailable = 2,
    ReviewRequested = 3,
    ReviewScheduled = 4,
    ReviewCancelled = 5,
    ReviewDecisionAvailable = 6,
    LateReviewAvailable = 7,
    LateReviewRequested = 8,
    LateReviewDenied = 9,
    LateReviewApproved = 10,
    LateReviewUnAvailable = 11,
    ReviewPendingDecision = 12,
    LateReviewAvailableAfterCancellation = 13,
    ReviewPaymentPending = 14,
    LateReviewAvailableToMakeLateEvidence = 15,
    ReviewAbandoned = 16,
    ReviewAlreadyApplied = 17
}
export enum TimeToPayState {
    TimeToPayAvailable = 1,
    TimeToPayRequested = 2,
    TimeToPayUnAvailable = 3,
    AdditionalTimeToPayAvailable = 4,
    AdditionalTimeToPayRequested = 5,
    AdditionalTimeToPayApproved = 6,
    AdditionalTimeToPayDenied = 7,
    AdditionalTimeToPayUnAvailable = 8,
    AdditionalTimeToPayEligible = 9
}
export enum SeizureGroupTypes {
    IRSContraventionSeizure = 1,
    SuspendedDriversProgramSeizure = 2,
    AffectedThirdPartySeizure = 3
}
export enum EventTypes {
    ContraventionSubmitted = 1,
    AppliedForReview = 2,
    PaymentExtended = 3,
    NoteAdded = 4,
    ApplicationSubmitted = 5,
    AdjudicatorAssigned = 6,
    AdjudicatorReassigned = 7,
    ReviewDateScheduled = 8,
    ReviewDateChanged = 9,
    ReviewStatusChanged = 10,
    ContactMethodChanged = 11,
    ReviewMethodChanged = 12,
    ReviewRescheduled = 13,
    DecisionDueDateChanged = 14,
    ReviewAdjourned = 15,
    ReviewCancelled = 16,
    RequestReceived = 17,
    RequestStatusChanged = 18,
    RequestCompleted = 19,
    ReviewConfirmed = 20,
    ReviewDecisionIssued = 21,
    ReviewComplete = 22,
    ReviewCancelledByRecipient = 23,
    ReviewDecisionCancelled = 24,
    ContraventionCancelled = 25,
    SeizureCancelled = 26,
    DataEdited = 27,
    ReviewOutcomeChanged = 28,
    ReviewRequestExtension = 29,
    LateReviewReEnabled = 30,
    ImmediatelyCancelled = 31,
    DecisionDeleted = 32,
    VehicleReleased = 33,
    ReviewRescheduledByRecipient = 34,
    OutOfProvinceNotification = 35,
    ContraventionReIssued = 36,
    RequestCancelled = 37,
    JudicialReviewServed = 38,
    JudicialReviewPackageDownloaded = 39,
    StayOrderAdded = 40,
    StayOrderExpired = 41,
    StayOrderRevoked = 42,
    JudicialReviewDecisionIssued = 43,
    ReReviewGranted = 44,
    StayOrderCancelled = 45,
    FileClosureComplete = 46,
    StayOrderExpiryChanged = 47,
    ReviewCancelledAdministratively = 48,
    RepresentationChanged = 49,
    DocumentPublished = 50,
    DocumentUnpublished = 51,
    RefundLogged = 52,
    DocumentUploaded = 53,
    FineUncollectable = 54,
    ReviewEnabled = 55
}
export enum ActivityType {
    TimeToPayRequested = 1,
    TimeToPayApproved = 2,
    AdditionalTimeToPayRequested = 3,
    AdditionalTimeToPayDecisionUploaded = 4,
    AppliedForReview = 5,
    ReviewDecisionUploaded = 6,
    ReviewCancelledByRecipient = 7,
    LateReviewRequested = 8,
    LateReviewDecisionUploaded = 9,
    AdditionalTimeToPayApproved = 10,
    JudicialReviewServed = 11,
    StayOrderAdded = 12,
    StayOrderExpired = 13,
    StayOrderRevoked = 14,
    JudicialReviewDecisionIssued = 15,
    ReReviewGranted = 16
}
export enum ContactMethodTypes {
    Email = 1,
    Text = 2,
    Phone = 3
}
export enum DocumentTypes {
    OriginalPaperContravention = 1,
    OriginalPaperSeizure = 2,
    SFSTCheckSheet = 3,
    SFSTNarrative = 4,
    PictureOfDevice = 5,
    ResultsPrintOut = 6,
    DREAdministrationForm = 7,
    DrugInfluenceEvaluationFaceSheet = 8,
    DRENarrative = 9,
    IntoxOperationalChecksheet = 10,
    IntoxSubjectTest = 11,
    CertificateOfAnnualInspection = 12,
    AlcoholStandardChangeForm = 13,
    IntoxSupervisorTest = 14,
    IntoxQuickTest = 15,
    CertificateOfAnalyst1 = 16,
    CertificateOfAnalyst2 = 17,
    CertificateOfQualifiedTechnician = 18,
    LaboratoryResults = 19,
    PoliceNarrative = 20,
    Photo = 21,
    AudioVideo = 22,
    WitnessStatements = 23,
    PoliceNotes = 24,
    OtherOfficerAccount = 25,
    TechnicalMaterial = 26,
    RecipientSubmission = 27,
    RecipientsConsentOfRepresentation = 28,
    ReviewDecisionLetter = 29,
    RequestDecisionLetter = 30,
    SwornAffidavit = 31,
    PoliceReport = 32,
    RentalAgreement = 33,
    AdditionalDecisionDocument = 34,
    ReviewUploads = 35,
    ViolationTicket = 36,
    Other = 37,
    DriverAbstract = 38,
    Note = 39,
    ContraventionCancellationAuthorization = 40,
    ContraventionCancellationAdditional = 41,
    ReServeSubmission = 42,
    RoadsideAppeal = 43,
    VehicleSeizureCancellationAuthorization = 44,
    VehicleSeizureCancellationAdditional = 45,
    OverdueFineNotice = 46,
    WrittenSubmission = 47,
    UrineLaboratoryResults = 48,
    AffidavitOfService = 49,
    NAPDocument = 50,
    InternalValidationReport = 51,
    InternalValidationAffidavit = 52,
    ExternalValidationReport = 53,
    ExternalValidationAffidavit = 54,
    TechnicalInformationReport = 55,
    TechnicalInformationAffidavit = 56,
    OperatorsReport = 57,
    SpeedometerCertificate = 58,
    AircraftCertificate = 59,
    SpeedDeviceTestOrOtherOfficerReport = 60,
    SeizureNoticePoliceCopy = 61,
    SeizureNoticeDriverCopy = 62,
    SeizureNoticeRegisteredOwnerCopy = 63,
    SeizureNoticeTowLotCopy = 64,
    IssuersReport = 65,
    Exhibit1 = 66,
    Exhibit2 = 67,
    Exhibit3 = 68,
    Exhibit4 = 69,
    SeizurePoliceNarrative = 70,
    OriginatingApplication = 71,
    NoticeToProduceRecords = 72,
    NoticeOfConstitutionalQuestion = 73,
    CourtDecision = 74,
    CourtOrder = 75,
    JudicialReviewUploads = 76,
    DeclarationForm = 77,
    HospitalRecords = 78,
    PictureOfDeviceV2 = 79,
    OriginalPaperContraventionBackSide = 80,
    SafeRoadsAlbertaDocumentation = 117,
    DisclosurePackage = 118,
    PictureOfDeviceADSE = 119,
    OfficerNotes = 120,
    OfficerNarrative = 121,
    BodycamVideo = 122,
    CertificateOfSuspension = 123,
    RegisteredOwnerCertificate = 124,
    CertificateOfRegistration = 125,
    MotorVehicleCertificate = 126,
    CancellationDocumentation = 127,
    CollisionReport = 128
}
export enum VehicleInterestTypes {
    RegisteredOwner = 1,
    Lessor = 2,
    CivilEnforcementAgency = 3,
    Other = 4
}
export enum NotificationTypes {
    ReviewScheduled = 1,
    ReviewRescheduled = 2,
    ReviewIsApproaching = 3,
    ReviewDecisionIsAvailable = 4,
    ReviewIsCancelled = 5,
    DecisionMailed = 6,
    RequestDecisionIsAvailable = 7,
    ReviewDocumentAvailable = 8,
    RequestIsCancelled = 9,
    ReviewAbandoned = 10,
    ReReviewAvailable = 11
}
export enum ContraventionTypes {
    IRS24 = 1,
    IRSZeroNovice = 2,
    IRSZeroCommercial1st = 3,
    IRSZeroCommercial2nd = 4,
    IRSZeroCommercial3rd = 5,
    IRSWarn1st = 6,
    IRSWarn2nd = 7,
    IRSWarn3rd = 8,
    IRSFail1st = 9,
    IRSFail2nd = 10,
    IRSFail3rd = 11
}
export enum Roles {
    DirectorOperationsManager = 1,
    SeniorAdjudicator = 2,
    Adjudicator = 3,
    CaseCoordinator = 4,
    CaseAdministrator = 5,
    BusinessAnalyst = 6,
    DFM = 7,
    ContactCentre = 8,
    CVS = 9,
    BusinessSuperUser = 10
}
export enum RepresentationTypes {
    Agent = 1,
    Lawyer = 2,
    Other = 4,
    ParentGuardian = 5,
    SelfRepresentation = 99
}
export enum TestTypes {
    SFST = 1,
    ASD = 2,
    ADSE = 3,
    DRE = 4,
    AI = 5,
    Blood = 6,
    NoScreening = 7,
    NoAppeal = 8,
    Urine = 9
}
export enum TestResultTypes {
    NoResult = 6,
    THC = 7,
    Cocaine = 8,
    Methamphetamine = 9,
    Other = 10
}
export enum SeizureTypes {
    IRSContraventionSelected = 1,
    SuspendedDriversProgramFirstOccurrence = 2,
    SuspendedDriversProgramSecondOccurrence = 3
}
export enum SeizureUserType {
    Driver = 1,
    RegisteredOwner = 2
}
export enum SuspicionTypes {
    Observation = 1,
    Odour = 2,
    AdmissionofRecentConsumption = 3,
    WitnesstoRecentConsumption = 4,
    Other = 5
}
export enum VehicleRegistrationStatusTypes {
    Registered = 1,
    Unregistered = 2
}
export enum ReviewOutcomeTypes {
    DeemedAbandoned = 1,
    Converted = 2,
    Adjourned = 3,
    Completed = 4,
    AbandonedLateEvidence = 5,
    AbandonedOther = 6,
    AbandonedNoConsent = 7,
}
export enum ReServeMethodTypes {
    InPerson = 1,
    Mail = 2
}
export enum ContraventionGroundsTypes {
    Yes = 1,
    No = 2
}
export enum ObservedByTypes {
    ObservedSubjectDirectly = 1,
    WitnessObservedSubject = 2,
    CareAndControl = 3,
    OtherOfficerObservedSubject = 4,
    MultipleOfficerOperation = 5
}
export enum JobStatus {
    Unknown = 0,
    Running = 1,
    Completed = 2,
    Failed = 3
}
export enum RecordType {
    Contravention = 1,
    Seizure = 2
}
export enum Permission {
    ActivityNote = 1,
    AddAdditionalDocument = 2,
    UploadDocument = 3,
    ApproveOrDenyRequest = 4,
    AssignAdjudicator = 5,
    AttendReview = 6,
    CancelReview = 8,
    ChangeContactMethod = 9,
    ChangeDecisionDueDate = 10,
    ChangeReviewMethod = 11,
    ChangeReviewStatus = 12,
    CONTRAVENTIONS = 13,
    DataEdits = 14,
    DecisionAndUpload = 15,
    DeleteDecision = 16,
    DeleteDocument = 17,
    DownloadDecision = 18,
    EnableLateReview = 19,
    publicList = 20,
    ImmediateCancel = 21,
    PaymentHistory = 22,
    ReassignAdjudicator = 23,
    REPORTS = 24,
    REQUESTS = 25,
    RescheduleReview = 26,
    ReviewActivity = 27,
    ReviewOutcome = 28,
    ReviewRequestExtension = 29,
    REVIEWS = 30,
    ReviewUploads = 31,
    ScheduleReview = 32,
    SEIZURES = 33,
    UpdateContactMethod = 34,
    UploadRedatedDocument = 35,
    USERS = 36,
    ViewSupportingDocuments = 37,
    UpdateDocument = 38,
    DownloadDocument = 39,
    ListOrphanDocument = 40,
    ListDocument = 41
}
export enum MeetingTypes {
    IRSWrittenReview = 1,
    IRSOralReview = 2,
    SeizureReview = 3,
    TSAWrittenReview = 4,
    TSAOralReview = 5,
    ATEWrittenReview = 6,
    ATEOralReview = 7,
    LateReview = 8
}
export enum AvailabilityTypes {
    AdminTime = 1,
    UnAvailable = 2
}
export enum SeizeorDestroyLicence {
    SEIZE = 1,
    DESTROY = 2
}
export enum NumberSystemTypes {
    NSAIP = 1,
    NSAEN = 2,
    NSPTS = 3,
    SNAIP = 4,
    WNAIP = 5,
    NCRN = 6,
    SDFSN = 7,
    NRAN = 8,
    NLRRN = 9,
    ONAIP = 10,
    JRN = 11,
    JTN = 12
}
export enum IssueServiceTypes {
    InPerson = 1,
    Mailed = 2
}
export enum IdentificationTypes {
    DriversLicence = 1,
    Passport = 2,
    MilitaryServiceId = 3,
    PossessionAndAcquisitionLicence = 4,
    IndigenousStatusCard = 5,
    Verbal = 6,
    Other = 7
}
export enum OfficerTransportationTypes {
    InMarkedPatrolVehicle = 1,
    InUnMarkedPatrolVehicle = 2,
    OnFoot = 3,
    OnBike = 4,
    Other = 5
}
export enum OfficerUniformTypes {
    FullUniform = 1,
    Civilian = 2,
    Other = 3
}
export enum OfficerVehicleTypes {
    Car = 1,
    Truck = 2,
    SUV = 3,
    Motorcycle = 4
}
export enum SpeedingDeviceTypes {
    Lidar = 1,
    Radar = 2
}
export enum RecipientTypes {
    Driver = 1,
    RegisteredOwner = 2,
    Passenger = 3,
    NotInVehicle = 4
}
export enum DriverLicenceClassification {
    Alberta = 1,
    OutOfProvince = 2,
    NoHistryFound = 3
}
export enum LicencePresentedClassification {
    LicencePresented = 1,
    LicenceFoundViaLookup = 2,
    Other = 3
}
export enum ClientTypes {
    Joint = 1,
    Organization = 2,
    Individual = 3
}
export enum TicketBatchStatus {
    New = 1,
    Running = 2,
    Completed = 3,
    Failed = 4,
    Terminated = 5,
    Retry = 6
}
export enum TicketBatchSubStatus {
    Duplicate = 1
}
export enum TicketBatchStep {
    Ingest = 1,
    Process = 2,
    Report = 3
}
export enum TicketBatchRecordStatus {
    New = 1,
    Pending = 2,
    Success = 3,
    Warning = 4,
    Error = 5
}
export enum ContraventionCancellationReason {
    ResultOfReview = 1,
    ImmediateCancellation = 2
}
export enum IntakeAppProcess {
    ViewingDashboard = 1,
    ViewingOfflineDashboard = 2,
    CreatingContravention = 3
}
export enum SubmissionProgrssStates {
    RecipientInformation = 1,
    ContraventionSelection = 2,
    VehicleDetails = 3,
    VehicleSeizure = 4,
    PrintAndIssue = 5,
    NoticeIssued = 6
}
export enum RoadsideAppealOutcomeTypes {
    EqualToOrExceeded = 1,
    LessThanFirstTest = 2,
    LestThanAnyConcentration = 3,
    RefusedOrFailedToComply = 4,
    Pending = 5
}
export enum PassengerSeatingTypes {
    TwoRows = 1,
    ThreeRows = 2,
    MotorcycleOrATV = 3,
    Other = 4
}
export enum DriverDeclinedToTestTypes {
    StraightRefusal = 1,
    FailedToComply = 2,
    No = 3
}
export enum RoadConditionTypes {
    NoUnusualCondition = 1,
    UnderConstructionOrMaintenance = 2,
    HoleOrRutsOrBumps = 3,
    SlipperyAndWet = 4,
    OilyPavement = 5,
    SoftOrSharpShoulder = 6,
    Unknown = 7,
    Other = 8
}
export enum RoadSurfaceTypes {
    Dry = 1,
    Wet = 2,
    SlushOrSnowOrIce = 3,
    LooseSurfaceMaterials = 4,
    Muddy = 5,
    Unknown = 6,
    Other = 7
}
export enum WeatherConditionTypes {
    Clear = 1,
    Rain = 2,
    HailOrSleet = 3,
    Snow = 4,
    FogOrSmogOrSmokeOrDust = 5,
    HighWind = 6,
    Unknown = 7,
    Other = 8
}
export enum VisibilityConditionTypes {
    Daylight = 1,
    Darkness = 2,
    Sunglare = 3,
    FogOrSmokeOrDust = 4,
    Unknown = 5,
    Other = 6,
    Sunrise = 7,
    Sunset = 8
}
export enum ContraventionDetailTypes {
    IRS24MedicalCondition = 1,
    IRS24DrugOrAlcohol = 2,
    IRSFail1stImpaired = 3,
    IRSFail1st80mgOver = 4,
    IRSFail1stOverBDC = 5,
    IRSFail1stOverCombo = 6,
    IRSFail1stRefusal = 7,
    IRSFail2ndImpaired = 8,
    IRSFail2nd80mgOver = 9,
    IRSFail2ndOverBDC = 10,
    IRSFail2ndOverCombo = 11,
    IRSFail2ndRefusal = 12,
    IRSFail3rdImpaired = 13,
    IRSFail3rd80mgOver = 14,
    IRSFail3rdOverBDC = 15,
    IRSFail3rdOverCombo = 16,
    IRSFail3rdRefusal = 17,
    IRS24Fatigue = 18
}
export enum NoticeCancellationReasonTypes {
    OfficerEmergencyServiceMemberInExecutionOfDuty = 1,
    VehicleOrLicencePlateStolenOrIncorrect = 2,
    IdentityOfRecipientIncorrect = 3,
    IncorrectContravention = 4,
    UnableToIssueMailContravention = 5,
    IncorrectOrUnreliableTestResults = 6,
    Other = 7
}
export enum ReviewComplexity {
    Low = 1,
    Medium = 2,
    High = 3
}
export enum PaperSubmissionOptions {
    Off = 0,
    Emergency = 1,
    Paper = 2,
    EmergencyAndPaper = 3
}
export enum RequestDenyReasonTypes {
    RequestDoesntMeetPolicyRequirements = 1,
    NotEnoughJustificationInformationProvided = 2,
    Other = 3
}
export enum JudicialReviewDecisionTypes {
    DiscontinuedWithoutHearing = 1,
    DismissedAtHearing = 2,
    ContraventionCancelled = 3,
    CourtOrderedReReview = 4,
    ConsentToReReview = 5,
    SeizureCancelled = 6,
    ContraventionCancelledByCourtOrder = 7,
    ContraventionCancelledByConsentOrder = 8
}
export enum StayOrderStatusTypes {
    Active = 1,
    Expired = 2,
    Revoked = 3,
    Cancelled = 4
}
export enum DriversLicenceStatusTypes {
    Deceased = 1,
    Suspended = 2,
    Active = 3,
    Inactive = 4,
    Expired = 5,
    Restricted = 6,
    Cancelled = 7,
    Surrendered = 8,
    Unknown = 9
}
export enum RefusalOrFailedClassification {
    BreathSample = 1,
    DREProcess = 2,
    ToxicologicalSample = 3
}
export enum SampleClassification {
    Blood = 1,
    Urine = 2
}
export enum BloodSampleTypes {
    BloodDemand = 1,
    BloodWarrant = 2,
    SearchWarrant = 3,
    HospitalRecords = 4
}
export enum HighwayCharacteristicsTypes {
    Divided = 1,
    Undivided = 2,
    SingleLane = 3,
    MultiLane = 4,
    Straight = 5,
    Curved = 6,
    Flat = 7,
    Downhill = 8,
    Uphill = 9,
    Rural = 10,
    Residential = 11,
    Shoulder = 12,
    Noshoulder = 13,
    Other = 14
}
export enum OffenceTypes {
    Part3OffenceNotice = 1,
    Part2SummonsVoluntaryPaymentOption = 2,
    Part2SummonsMandatoryCourt = 3
}
export enum TicketTypes {
    ContraventionsAndSeizures = 1,
    Violations = 2
}
export enum ReviewDocumentsEditState
{
    EditAllowed = 1,
    DeadlinePassed = 2,
    ReviewCompleted = 3,
    ReviewCancelled = 4
}