import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[isLessThanStartDate]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: StartDateValidatorDirective,
        multi: true
    }]
})
export class StartDateValidatorDirective implements Validator {
    @Input('isLessThanStartDate') startDate: string;
    
    validate(control: AbstractControl) {
      if (control.value == null || this.startDate == null || this.startDate.length == 0)
        return null;

      const stDate = new Date(+this.startDate.substring(0, 4), +this.startDate.substring(5, 7)-1, +this.startDate.substring(8, 10));
      const chkDate = new Date(control.value);

      return ((chkDate < stDate) ? {'EarlierDate': true} : null);
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('startDate' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }

}