<div class="d-flex flex-column">
    <div class="form-group row">
        <div class="col-12 col-sm-12">
           <h1 class="pl-2 pl-md-0">Review Disclosure</h1>
        </div>
    </div>

    <div class="card my-2">
        <div class="container">
            <div class="row callout">
                <div class="col-md-1 callout-logo d-flex align-items-center">
                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2"
                        fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                        <path
                            d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                    </svg>
                </div>
                <div class="col-md-11 mt-4 mb-2">
                    <h4>
                        These documents will be attached to the following Violation Ticket Number:
                    </h4>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-2 mt-lg-4">
        <div class="card-header">
            Violation Ticket
        </div>
        <div class="card-body form-group">
            <ng-template [ngIf]="sharedDisclosureDocumentsUploadRequest?.ticketView != null" [ngIfElse]="multiTickets">
                <div class="row">
                    <div class="col">
                        <div>Ticket Number</div>
                        <div class="value">{{sharedDisclosureDocumentsUploadRequest?.ticketView.ticketNumber}}</div>
                    </div>
                    <div class="col">
                        <div>Charge</div>
                        <div class="value">{{sharedDisclosureDocumentsUploadRequest?.ticketView.ticketDescription}}</div>
                    </div>
                    <div class="col">
                        <div>Accused Name</div>
                        <div class="value">{{sharedDisclosureDocumentsUploadRequest?.ticketView.accusedName}}</div>
                    </div>
                    <div class="col">
                        <div>Offence Date</div>
                        <div class="value">{{sharedDisclosureDocumentsUploadRequest?.ticketView.offenceDate | date:'mediumDate'}}</div>
                    </div>
                </div>
            </ng-template>
            <ng-template #multiTickets>
                <label>
                    {{sharedDisclosureDocumentsUploadRequest.ticketNumbers.join(", ") }}
                </label>         
            </ng-template>
        </div>
    </div>
    
    <div class="card mt-2 mt-lg-4">
        <div class="card-header">
            Disclosures
        </div>
        <div class="card-body">
            <div class="form-group row">
                <div class="col-12 col-sm-12">
                    <div class="form-check mb-3">
                        <input #chkDisclosurePreviouslyProvided="ngModel" [ngModel]="sharedDisclosureDocumentsUploadRequest.isDisclosurePreviouslyProvided"
                            type="checkbox" id="chkDisclosurePreviouslyProvided" name="chkDisclosurePreviouslyProvided" readonly disabled
                            class="form-check-input mb-0">
                        <label class="form-check-label mb-0" for="chkDisclosurePreviouslyProvided">
                            Check if disclosure was previously provided manually
                        </label>
                    </div>
                    <div *ngIf="sharedDisclosureDocumentsUploadRequest && sharedDisclosureDocumentsUploadRequest?.ticketNumbers?.length > 0">
                        <div>
                            <table class="table">
                                <thead>
                                    <th>Document</th>
                                    <th>File Name</th>
                                    <th>Uploaded By</th>
                                    <th>Date Uploaded</th>
                                </thead>
                                <tr *ngFor="let d of sortedDisclosureDocuments">
                                    <td [ngClass]="{'font-weight-bold': d.tempUrl}">{{d.type}}</td>
                                    <td>
                                        <a href="{{d.url ? d.url : d.tempUrl}}" target="_blank">
                                            {{d.originalName}}
                                        </a>
                                    </td>
                                    <td>{{d.uploadedBy}}</td>
                                    <td>{{d.uploadedDate | date:'mediumDate'}}</td>
                                </tr>
                                <tr *ngIf="sharedDisclosureDocumentsUploadRequest?.disclosureDocuments.length == 0">
                                    <td colspan="6">No disclosure found for this Violation Ticket.</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            Police Service Information
        </div>            
        <div class="card-body">                    
            <app-section-details sectionTitle="" [sectionDetails]="policeServiceInformation"></app-section-details>
        </div>
    </div>

    <div class="form-group row mt-4">
        <div class="col-12 col-sm-8">
            <label for="policeOfficerFullName">Full Name of Submitter <span class="label-hint">(Required)</span></label>
            <input readonly type="text" class="form-control" id="policeOfficerFullName" name="policeOfficerFullName" 
            [value]="policeOfficerFullName">
        </div>
        <div class="col-12 col-sm-4">
            <label for="submissionDate">Date of Submission</label>
            <input type="text" class="form-control" id="submissionDate" readonly value="{{submissionDate | date:'dd MMM, yyyy'}}">
        </div>                
    </div>

    <div class="form-group">
        <div class="row pt-3">
            <div class="col-12 col-sm-6">                
                <a class="btn button-secondary btn-block" [routerLink]="['/ticket/disclosure/upload-disclosures']">Back to Disclosure Upload</a>
            </div>
            <div class="col-12 col-sm-6 text-right">                
                <button class="btn button-primary btn-block" (click)="onSubmitClick()">Submit</button>   
            </div>
        </div>
    </div>
    
</div>