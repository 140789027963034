<div>
  <div class="row">
    <div class="all-columns">
      <img width=153 height=43 id="abLogo" src="assets/images/ablogo.jpg">
    </div>
  </div>
  <div class="row">
    <div class="all-columns">
      <span class="page-header">Seizure Notice</span>
    </div>
  </div>
  <div class="row">
    <div class="all-columns">
      <span class="copy-type column-50percent">{{ copyTypeText }}</span>
    </div>
  </div>
</div>

<div class="body-style">

  <div class="thin-row horizontal-line"></div>
  <div class="thin-row">
    <div class="all-columns">
      <p>Protected A</p>
    </div>
  </div>

  <!-- Seizure Details -->
  <div *ngIf="printBlock1">
    <div class="row">
      <div class="all-columns">
        <p class="section-header"><b>SEIZURE DETAILS:</b></p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
          <p class="label-row">seizure number</p>
          <p class="data-row">{{ vehicleSeizure.seizureNumber }}</p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <p class="label-row">seizure type</p>
        <p class="data-row">{{ getSeizureType() }}</p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">seizure date</p>
          <p class="data-row">{{ vehicleSeizure.seizureDate | date:"mediumDate" }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">seizure time</p>
          <p class="data-row">{{ vehicleSeizure.seizureTime }}</p>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">Immediate seizure duration</p>
          <p class="data-row">{{ vehicleSeizureDurationText() }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">vehicle release date</p>
          <p class="data-row">{{ vehicleSeizure.vehicleReleaseDate | date:"mediumDate" }}</p>
        </span>
      </div>
    </div>
  </div>

  <!-- Driver Information -->
  <div *ngIf="printBlock2">
    <div class="row">
      <div class="all-columns">
        <p class="section-header"><b>Driver Information:</b></p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">recipient name</p>
          <p class="data-row">{{ stop.recipient?.firstName }} {{ stop.recipient?.lastName }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">Date of Birth</p>
          <p class="data-row">{{ recipientIdentification.dateOfBirth | date:"mediumDate" }}</p>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">Drivers Licence</p>
          <p class="data-row">{{ recipientIdentification.identificationNumber }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">MVID</p>
          <p class="data-row">{{ getMvidNumber() }}</p>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="printBlock3">
    <div class="row">
      <div class="all-columns">
        <p class="label-row">Address</p>
        <p class="data-row">{{ formatAddress(stop.recipient?.recipientContact) }}</p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <p class="label-row">Telephone Number</p>
        <p class="data-row">{{ formatDriverPhoneNumber(stop.recipient?.recipientContact) }}</p>
      </div>
    </div>
  </div>

  <!-- Registered Owner Information -->
  <div *ngIf="printBlock4">
    <div class="row">
      <div class="all-columns">
        <p class="section-header"><b>Registered Owner Information:</b></p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">recipient name</p>
          <p class="data-row">{{ RORecipientName }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">Date of Birth</p>
          <p class="data-row">{{ RODateOfBirth }}</p>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">Driver's Licence</p>
          <p class="data-row">{{ ROLicenceNumber }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">MVID</p>
          <p class="data-row">{{ ROMvid }}</p>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
          <p class="label-row">Address</p>
          <p class="data-row">{{ ROAddress }}</p>
      </div>
    </div>
    <div class="row">          
      <p class="label-row">Telephone Number</p>
      <p class="data-row">{{ ROPhoneNumber }}</p>
    </div>
  </div>

  <!-- Vehicle Information -->
  <div *ngIf="printBlock5">
    <div class="row">
      <div class="all-columns">
        <p class="section-header"><b>vehicle information:</b></p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">licence plate number</p>
          <p class="data-row">{{ vehicleSeizure.vehicle?.licencePlateNumber }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">vin</p>
          <p class="data-row">{{ vehicleSeizure.vehicle?.vehicleIdentificationNumber }}</p>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">Country</p>
          <p class="data-row">{{ vehicleCountry }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">licenced state/prov</p>
          <p class="data-row">{{ vehicleProv }}</p>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">make</p>
          <p class="data-row">{{ vehicleSeizure.vehicle?.make }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">model</p>
          <p class="data-row">{{ vehicleSeizure.vehicle?.model }}</p>
        </span>
      </div>
    </div>
    <div class="row">      
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">year</p>
          <p class="data-row">{{ vehicleSeizure.vehicle?.year }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">colour</p>
          <p class="data-row">{{ vehicleSeizure.vehicle?.colour }}</p>
        </span>
      </div>
    </div>
  </div>

  <!-- Tow Lot Information -->
  <div *ngIf="printBlock6">
    <div class="row">
      <div class="all-columns">
        <p class="section-header"><b>tow lot information:</b></p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <p class="label-row">towing company</p>
        <p class="data-row">{{ vehicleSeizure.towCompanyName }}</p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <p class="label-row">tow lot address</p>
        <p class="data-row">{{ vehicleSeizure.towLotAddress }}, {{ vehicleSeizure.towLotCity }}, {{ vehicleSeizure.towLotPostalCode }}</p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <p class="label-row">Telephone number</p>
        <p class="data-row">{{ vehicleSeizure.towLotPhoneNumber }}</p>
      </div>
    </div>
  </div>

  <!-- Tow Lot Received Signiture Block -->
  <div *ngIf="printBlock7">
    <div class="row">
      <div class="all-columns">
        <span class="signiture-header"><b>Received by:</b></span>
        <div class="thin-row thin-horizontal-line"></div>
        <p class="signiture-footer-description"><i>Towing Company Representative must write in their name on the <b>Police Copy</b></i></p>
      </div>
    </div>
  </div>

  <!-- Your Options Block -->
  <div *ngIf="printBlock8">
    <div class="thin-row horizontal-line"></div>
    <div class="row">
      <div class="all-columns">
        <p class="section-header"><b>your options:</b></p>
        <p *ngIf="isDriver">If you disagree with this Seizure Notice, you have the right to a review through SafeRoads
          Alberta. <b>You have until the day before the vehicle release date to request a review of this seizure
            notice.</b></p>
        <p *ngIf="isRo">If you are not the recipient of a Notice of Administrative Penalty, but are the registered owner
          of the vehicle seized, or other affected person as defined under the Traffic Safety Act, you may apply to
          SafeRoads Alberta for the release of your vehicle.</p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <span class="column-75percent">        
          <p><span class="safe-roads-url">SafeRoads.com</span><br /><span class="qr-text-note">Or use your phone to scan this QR code.</span></p>
          <p class="footer-large">If you are unable to access the website, go to any Alberta Registries for
              assistance. You must
              bring this Notice with you, along with a piece of government-issued photo ID.
          </p>
        </span>
        <span class="column-25percent qrcode-image">
            <img width=94 height=94 id="qrCode" src="assets/images/qrcode.jpg"
                alt="Qr code Description automatically generated">
        </span>
      </div>
    </div>
  </div>

  <!-- Issuing Officer Block-->
  <div *ngIf="printBlock9">
    <div class="thin-row horizontal-line"></div>
    <div class="row">
      <div class="all-columns">
        <span class="column-50percent">
          <p class="label-row">Issuing Officer</p>
          <p class="data-row">{{ officerNameText() }}</p>
        </span>
        <span class="column-50percent">
          <p class="label-row">Regimental Number</p>
          <p class="data-row">{{ regimentalNumberText() }}</p>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <p class="label-row">Police Service</p>
        <p class="data-row">{{ agencyText() }}, {{ detachmentText() }}</p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <p class="label-row">Police File Number</p>
        <p class="data-row">{{ policeFileNumberText() }}</p>
      </div>
    </div>
    <div class="row">
      <div class="all-columns">
        <p class="footer-small">The personal
          information on this form is collected under the authority of the <i>Traffic
            Safety Act</i> and section 33 of the <i>Freedom of Information and Protection
            of Privacy (FOIP) Act </i>and will be used for the administration of programs
          under the <i>Traffic Safety Act</i>. Inquiries may be directed to the Alberta
          Transportation Contact Centre, Alberta Transportation, Main Floor, Twin Atria
          Building, 4999-98 Avenue NW, Edmonton, Alberta T6B 2X3, at telephone number
          780-427-8230 (Edmonton and area) and toll free 310-0000 (in Alberta).
        </p>
      </div>
    </div>
  </div>

</div>

<p *ngIf="insertPageBreak"><br /><br /><br /><br /></p>