

import { CountryType } from './types/country-type.model';
import { RegisteredOwner } from './registered-owner.model';
import { VehicleDriver } from './vehicle-driver.model';
import { MovesVehicle } from './moves-vehicle.model';

export class Vehicle {
    public vehicleId: number = 0;
    public registeredOwnerId: number;
    public licencePlateNumber: string;
    public licencePlateExpiryYear: number;
    public isAlbertaLicencePlate: boolean = false;
    public isStolenPlate: boolean = false;
    public isNoPlate: boolean = false;
    public vehicleIdentificationNumber: string;
    public vehicleRegistrationStatusTypeId: number = null;
    public clientTypeId: number = null;
    public licensedCountryId: number = null;
    public licensedProvinceId: number = null;
    public licensedProvince: string;
    public year: number;
    public colour: string;
    public make: string;
    public model: string;
    public movesVehicleId: number = null;
    public licencePlateExpiryDate: Date;
    public licencePlateClass: string;
    public vehicleStyle: string;
    public vehicleWeight: string;
    public vehicleSeatingCapacity: string;
    public vehicleStatusTypeId: number = null;
    public lastServiceType: string;
    public lastServiceDate: Date;
    public movesLookupDate: string;
    public isVinNotAvailable: boolean;
    public isTrailerInvolved: boolean = false;
    public trailerLicencePlateNumber: string;
    public additionalTrailerInformation: string;
    public isSecondTrailerInvolved: boolean = false;
    public secondTrailerLicencePlateNumber: string;
    public additionalSecondTrailerInformation: string;
    public licensedCountry: CountryType;
    public registeredOwner: RegisteredOwner;
    public vehicleDriver: VehicleDriver;
    public movesVehicle: MovesVehicle;
    public isNoRegisteredOwner: boolean = false;
    constructor(init?: Partial<Vehicle>) {
        
        Object.assign(this, init);
    }
}
