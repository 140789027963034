

import { Agency } from './agency.model';
import { Detachment } from './detachment.model';
import { LocationType } from './types/location-type.model';

export class Officer {
    public officerId: number = 0;
    public agencyId: number;
    public detachmentId: number;
    public regimentalNumber: string;
    public firstName: string;
    public lastName: string;
    public otherName: string;
    public supervisorBadgeNumber: string;
    public unitName: string;
    public titleName: string;
    public titleIndicator: string;
    public phoneNumber: string;
    public emailAddress: string;
    public extension: string;
    public agency: Agency;
    public detachment: Detachment;
    public defaultMunicipalityLocationId: number;
    public defaultCourtLocationId: number;
    public defaultMunicipalityLocation: LocationType;
    public defaultCourtLocation: LocationType;

    public offlineStartNumber?: number = 0;
    public offlineEndNumber?: number = 0;
    
    constructor(init?: Partial<Officer>) {
        
        Object.assign(this, init);
    }
}
