


export class BloodSampleTest {
    public bloodSampleTestId: number = 0;
    public bloodSampleTime: string;
    public labTypeId: number = null;
    public bloodSampleResult: string;
    public isBloodDemand: boolean = false;
    public bloodSampleDemandTime: string;
    public isBloodWarrant: boolean = false;
    public bloodWarrantSampleTime: string;
    public bloodWarrantLabTypeId: number = null;
    public isSearchWarrant: boolean = false;
    public isHospitalRecord: boolean = false;

    constructor(init?: Partial<BloodSampleTest>) {
        
        Object.assign(this, init);
    }
}
