<div class="d-flex flex-row">
    <div class="mr-auto">
        <h3>{{activeWeekStartDate | date:'MMM dd, yyyy'}} - {{activeWeekEndDate | date:'MMM dd, yyyy'}}</h3>
    </div>
    <div class="align-self-center">
        <button (click)="onPrevWeekClick()" class="mr-1 m-0 p-0 goa-button button-icon" [disabled]="weekIndex === 0">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
              </svg>
        </button>
        <button (click)="onNextWeekClick()" class="m-0 p-0 goa-button button-icon" [disabled]="weekIndex === 1">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
        </button>
    </div>
</div>

<table class="table table-bordered calendar">
    <tr class="d-flex calendar-light">
        <td class="col-lg"></td>
        <td class="col-lg p-1 py-2" *ngFor="let day of activeWeekDays; index as i;">
            <div>{{ day | date:'EEE' }}</div>
            <div>{{ day | date:'MMM dd'}}</div>
        </td>
    </tr>
    <tr class="d-flex">
        <td class="col-lg">
            <div><b>Morning</b></div>
            <div>9am - 12pm</div>
        </td>
        <ng-template ngFor let-day [ngForOf]="activeWeekDays" let-i="index">
            <ng-container *ngIf="isAvailableForSchedule(day); then calendar; else blockday">
            </ng-container>
            <ng-template #calendar>
                <td class="col-lg p-1">
                    <input type="checkbox" class="calendar-checkbox" 
                        [checked]="selectedTimeSlots[i + (weekIndex * 7)][0]"
                        (change)="updateAvailability(i + (weekIndex * 7), 0, $event.target.checked)">
                </td>
            </ng-template>
            <ng-template #blockday>
                <td class="col-lg p-1 block-day">
                </td>
            </ng-template>
        </ng-template>
    </tr>
    <tr class="calendar-seperator">
        <td colspan="*"></td>
    </tr>
    <tr class="d-flex">
        <td class="col-lg border-bottom-0">
            <div><b>Early Afternoon</b></div>
            <div>1pm - 2pm</div>
        </td>
        <ng-template ngFor let-day [ngForOf]="activeWeekDays" let-i="index">
            <ng-container *ngIf="isAvailableForSchedule(day); then calendar; else blockday">
            </ng-container>
            <ng-template #calendar>
                <td class="col-lg p-1 border-bottom-0">
                    <input type="checkbox" class="calendar-checkbox" 
                        [checked]="selectedTimeSlots[i + (weekIndex * 7)][1]"
                        (change)="updateAvailability(i + (weekIndex * 7), 1, $event.target.checked)">
                </td>
            </ng-template>
            <ng-template #blockday>
                <td class="col-lg p-1 border-bottom-0 block-day">
                </td>
            </ng-template>
        </ng-template>
    </tr>
    <tr class="d-flex">
        <td class="col-lg border-top-0">
            <div><b>Late Afternoon</b></div>
            <div>3pm - 4pm</div>
        </td>
        <ng-template ngFor let-day [ngForOf]="activeWeekDays" let-i="index">
            <ng-container *ngIf="isAvailableForSchedule(day); then calendar; else blockday">
            </ng-container>
            <ng-template #calendar>
                <td class="col-lg p-1 border-top-0">
                    <input type="checkbox" class="calendar-checkbox" 
                        [checked]="selectedTimeSlots[i + (weekIndex * 7)][2]"
                        (change)="updateAvailability(i + (weekIndex * 7), 2, $event.target.checked)">
                </td>
            </ng-template>
            <ng-template #blockday>
                <td class="col-lg p-1 border-top-0 block-day">
                </td>
            </ng-template>
        </ng-template>
    </tr>
</table>