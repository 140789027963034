


export class ReviewApplicant {
    public reviewApplicantId: number = 0;
    public firstName: string;
    public lastName: string;
    public otherName: string;
    public vehicleInterestTypeId: number = 0;
    public otherInterestType: string;

    constructor(init?: Partial<ReviewApplicant>) {
        
        Object.assign(this, init);
    }
}
