import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "transactionid"
})
export class TransactionIdPipe implements PipeTransform {

    constructor() {}

    transform(val: number): string {
        return this.leftPad(val.toString(), 10, '0');
    }

    leftPad(str: string, len: number = 0, ch = ' ') {
        len = len - str.length + 1;
        return len > 0 ? new Array(len).join(ch) + str : str;
      }
}