import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduled-maintenance',
  templateUrl: './scheduled-maintenance.component.html',
  styleUrls: ['./scheduled-maintenance.component.scss']
})
export class ScheduledMaintenanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
