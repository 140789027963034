


export class DrugScreeningEquipmentTest {
    public drugScreeningEquipmentTestId: number = 0;
    public testModelTypeId: number = 0;
    public testModelOther: string;
    public testDemandTime: string;
    public testStartedTime: string;
    public testResultTypeId: string;
    public testResultOther: string;

    constructor(init?: Partial<DrugScreeningEquipmentTest>) {
        
        Object.assign(this, init);
    }
}
