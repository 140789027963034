import { DisclosureDocument } from './disclosure-document.model';
import { TicketViewModel } from './ticket-view.model';

export class SharedDisclosureDocumentsUploadRequest {
    public ticketNumbers: string[] = [];
    public isApisIssuedTicket: boolean;
    public isDisclosurePending: number;
    public disclosureDocuments: DisclosureDocument[] = [];
    public ticketView: TicketViewModel;
    public isDisclosurePreviouslyProvided: boolean;
        
    constructor(init?: Partial<SharedDisclosureDocumentsUploadRequest>) {
        Object.assign(this, init);
    }
}
