

import { GenderType } from './types/gender-type.model';
import { ProvinceType } from './types/province-type.model';
import { CountryType } from './types/country-type.model';
import { IdentificationType } from './types/identification-type.model';

export class RecipientIdentification {
    public recipientIdentificationId: number = 0;
    public issuingProvinceId: number = null;
    public driversLicenceClassTypeId: number = null;
    public driversLicenceClass: string;
    public driversLicenceStatusTypeId: number = null;
    public identificationNumber: string;
    public issuingProvinceOther: string;
    public issuingCountryId: number = null;
    public identificationExpiryDate: Date = null;
    public motorVehicleIdentificationNumber: string;
    public dateOfBirth: Date = null;
    public isGDLDriver: boolean;
    public genderId: number = null;
    public identificationTypeId: number = null;
    public identificationOther: string;
    public additionalIdentificationInformation: string;
    public gender: GenderType;
    public issuingProvince: ProvinceType;
    public issuingCountry: CountryType;
    public identificationType: IdentificationType;
    public licencePresentedClassificationId: number = null;
    public driversLicenceClassificationId: number = null;
    public conditionCodes: string;
    public restrictions: string;

    constructor(init?: Partial<RecipientIdentification>) {
        
        Object.assign(this, init);
    }
}
