<div *ngIf="document" class="card mb-4" appDragDrop (fileDropped)="onFileDropped($event)">
    <div *ngIf="isAdditionalDocument && (documentState != DocumentState.Uploaded || documentType == null)" class="card-header">
        <div class="row">
            <div class="col-12 col-sm-6 mb-0">
                Add New Supporting Document
            </div>
            <div *ngIf="documentState != DocumentState.Uploading" class="col-12 col-sm-6 mb-0">
                <div class="float-right">
                    <button class="btn btn-link text-danger" (click)="CancelDocument()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column flex-md-row p-4">        
        <div *ngIf="!isAdditionalDocument || (documentState == DocumentState.Uploaded && documentType != null)" class="mr-auto mb-2 mb-md-0">
            <div class="boldtext">{{document.documentDescription == null ? '' : document.documentDescription + ' - ' + getTestName(documentType.id) }}</div>            
            <div [ngClass]="{'required': !document.isOptional }">{{ documentType.name }}</div>
            <div class="max-file-label mt-2" *ngIf="maxFileSize">Maximum file size allowed is {{maxFileSize}}</div>
            <div class="mt-2" *ngIf="document.documentName">
                <button class="btn btn-link" (click)="DownloadDocument()">
                    {{document.documentName}}
                </button>
            </div>
            <div *ngIf="documentType.isSubmittableAtLaterDate && !document.documentName">
                <div class="form-check mt-1">
                    <input class="form-check-input" type="checkbox" id="consent" [(ngModel)]="document.isSubmitLater">
                    <label class="form-check-label" for="consent">
                        Will submit at later date
                    </label>
                </div>            
            </div>
        </div>
        <div *ngIf="isAdditionalDocument && (documentState != DocumentState.Uploaded || documentType == null)" class="col-12 col-md-7 p-0">
            <label for="additionalDocumentType">File Category <span class="label-hint">(Required)</span></label>
            <select [disabled]="documentState == DocumentState.Uploading || documentState == DocumentState.Error" [appSelectValidator]="true" #additionalDocumentTypeControl="ngModel" [compareWith]="compareFn"
            class="form-control" id="additionalDocumentType" name="additionalDocumentType" [(ngModel)]="documentType" (ngModelChange)="onFileCtegoryChange($event)" [ngClass]="{'is-invalid':(additionalDocumentTypeControl.touched || isSubmitClicked) && additionalDocumentTypeControl.errors?.defaultSelected}">
                <option [ngValue]="null">Select a File Category</option>
                <option *ngFor="let documentType of additionalDocumentTypes" [ngValue]="documentType">{{documentType.name}}</option>
            </select>
            <div class="invalid-feedback">
                <div>File Category is required</div>
            </div>
            <div class="max-file-label mt-2" *ngIf="maxFileSize">Maximum file size allowed is {{maxFileSize}}</div>
        </div>
        <div class="col-12 col-md-5 p-0 d-flex justify-content-md-end">
            <ng-template [ngIf]="documentState == DocumentState.NotUploaded">
                <div class="align-self-center">
                    <div class="dropzone">
                        <input type="file" #fileDropRef id="fileDropRef" [disabled]="isDisabled" (change)="fileBrowseHandler($event.target.files)" />
                    </div>
                    <button class="btn" [disabled]="isDisabled" (click)="triggerFileUpload()">
                        <div class="d-flex">
                            <div class="upload-label">
                                <span>Drag and drop or click to upload</span>
                            </div>
                            <div class="align-self-center">
                                <svg width='1.5em' height='1.5em' viewBox='0 0 16 16' class='bi bi-upload' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                                    <path fill-rule='evenodd' d='M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8zM5 4.854a.5.5 0 0 0 .707 0L8 2.56l2.293 2.293A.5.5 0 1 0 11 4.146L8.354 1.5a.5.5 0 0 0-.708 0L5 4.146a.5.5 0 0 0 0 .708z'/>
                                    <path fill-rule='evenodd' d='M8 2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 2z'/>
                                </svg>
                            </div>
                        </div>
                    </button>
                </div>
            </ng-template>
            <ng-template [ngIf]="documentState == DocumentState.Uploading">
                <div class="align-self-center">
                    <div class="progress doc-progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" 
                            [attr.aria-valuenow]="uploadProgress" [style.width]="uploadProgress +'%'" aria-valuemin="0" aria-valuemax="100">{{uploadProgress +'%'}}</div>
                    </div>       
                </div>
                
                <div class="ml-4 align-self-center">
                    <button class="btn btn-link text-danger" (click)="CancelUpload()">
                        Cancel
                    </button>
                </div>
            </ng-template>
            <ng-template [ngIf]="documentState == DocumentState.Error">
                <div class="align-self-center">
                    <div class="progress doc-progress">
                        <div class="progress-bar bg-danger" role="progressbar" 
                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">Error</div>
                    </div>       
                </div>
                
                <div class="ml-4 align-self-center">
                    <button class="btn btn-link text-danger" (click)="CancelUpload()">
                        Cancel
                    </button>
                </div>
            </ng-template>
            <ng-template [ngIf]="documentState == DocumentState.Uploaded">            
                <div class="mt-2 mt-md-0 align-self-md-center">
                    <div class="progress doc-progress">
                        <div class="progress-bar bg-success" role="progressbar" 
                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">Uploaded</div>
                    </div>            
                </div>
                <div class="ml-md-4 align-self-md-center">
                    <button *ngIf="!document.documentId" class="btn btn-link text-danger" [disabled]="isDisabled" (click)="RemoveDocument()">Remove File</button>
                    <button *ngIf="document.documentId > 0" class="btn btn-link text-danger" data-toggle="modal" [attr.data-target]="'#confirmDeleteModal' + document.documentId">
                        Remove File
                    </button>
                </div>            
            </ng-template>
        </div>
    </div>
    <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row pl-4 pb-4">  
        <span class="error">{{errorMessage}}</span>
    </div>

    <div *ngIf="document.documentId > 0" class="modal" 
        [attr.id]="'confirmDeleteModal' + document.documentId" tabindex="-1" 
        aria-labelledby="confirmDeleteModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content p-4">
                <div class="modal-header border-0 pt-2">
                    <span class="modal-title h4" id="confirmDeleteModalLabel">
                        Confirm Deletion?
                    </span>
                </div>
                <div class="modal-body py-0">
                    <p>Are you sure you want to remove this file? The file will be immediately deleted and removed from your review.</p>
                </div>
                <div class="modal-footer border-0 d-flex justify-content-right">
                    <button type="button" class="goa-button goa--secondary confirm-btn mt-2" data-dismiss="modal" (click)="RemoveDocument()">Yes</button>
                    <button type="button" class="goa-button confirm-btn mt-2" data-dismiss="modal" >No</button>
                </div>
            </div>
        </div>
    </div>
</div>