

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Injectable({
    providedIn: 'root'
})

export class SettingService extends ApiService{

    constructor(http: HttpClient, localStorage: LocalStorageService){
        super(http, localStorage);
    }

    getSettings(): Observable<any> {
        return this.get('settings', null);
    }
}