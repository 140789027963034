<div class="container">
    <div class="row">
        <h1>Help & Resources</h1>
    </div>
</div>

<div class="text">Below are resources to assist you with submitting an administrative penalty into APIS.</div>

<div class="text">If a correction or assistance is needed on a previously submitted contravention, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</div>

<br />

<a href="assets/APIS Police Portal Registration Guide.pdf" target="_blank">APIS Police Portal Registration Guide</a>

<br />

<br />

<a href="assets/APIS System Guide for Law Enforcement Agencies.pdf" target="_blank">APIS System Guide</a>

<br />

<br />

<a href="assets/APIS Super User Guide - Notice Cancellation Process.pdf" target="_blank">APIS Super User Guide - Notice Cancellation Process</a>

<br />

<br />

<a href="assets/Supporting Documents Declaration Form.pdf" target="_blank">Supporting Documents Declaration Form</a>

<br />

<br />