

import { Vehicle } from './vehicle.model';

export class VehicleDriver {
    public vehicleDriverId: number = 0;
    public vehicleId: number = 0;
    public firstName: string;
    public lastName: string;
    public otherName: string;
    public driversLicenceNumber: string;
    public motorVehicleIdentificationNumber: string;

    constructor(init?: Partial<VehicleDriver>) {
        
        Object.assign(this, init);
    }
}
