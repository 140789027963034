<div class="stepper">   
    <div class="stepper-button-container">
        <ng-container *ngFor="let stepLabel of stepLabels; index as i;">
                <div *ngIf="+i > 0" [ngClass]="getStepperLineClass(stepLabel, i)"></div>
                
                <div 
                    [ngClass]="getStepperButtonClass(stepLabel, i)">
                    <span *ngIf="i >= this.activeStepIndex && i < this.stepLabels.length-1">{{i + 1}}</span>
                </div>
        </ng-container>               
    </div>

    <div class="stepper-label-container">
        <ng-container *ngFor="let stepLabel of stepLabels; index as i;">  
                <div class="stepper-label" >
                    <div class="stepper-label-text">{{stepLabel}}</div>
                </div>
        </ng-container>
    </div>             
</div>
