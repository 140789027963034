import { AfterViewInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CountdownConfig, CountdownEvent, CountdownComponent } from 'ngx-countdown';
import { CountdownState } from '../../enums/countdown-state.enum';

const DEFAULT_LIFE_TIME = 600;

@Component({
  selector: 'countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements AfterViewInit {
  @Input() key: string;
  @Input() lapseMessage: string;
  @Output() countdownLapsed = new EventEmitter();

  _countdown: CountdownComponent;

  @ViewChild('cd', { static: false }) set content(countdown: CountdownComponent) {
    if (countdown) {
      this._countdown = countdown;
      countdown.begin();      
    }
  }

  state: CountdownState = CountdownState.NotStarted;

  config: CountdownConfig = { format: 'mm:ss', leftTime: DEFAULT_LIFE_TIME, notify: 0 };
  
  CountdownState = CountdownState;

  constructor() { 
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.continueCountdownIfStarted();
    });
  }

  handleEvent(ev: CountdownEvent) {
    if (ev.action == 'notify') {
      // Save current value
      localStorage.setItem(this.key, `${ev.left / 1000}`);

      if (ev.left <= 0) {
        this.state = CountdownState.Lapsed;
      }
    }
  }

  beginCountdown(key: string) {
    this.key = key;
    localStorage.setItem(this.key, `${DEFAULT_LIFE_TIME}`);
    this.state = CountdownState.Started;
  }

  stopCountdown() {
    if (this._countdown){
      this._countdown.stop();
    }
    
    localStorage.removeItem(this.key);
    this.state = CountdownState.NotStarted;
  }

  continueCountdownIfStarted() {
    let value = localStorage.getItem(this.key);

    if (value == null) {
      this.state = CountdownState.NotStarted;
      return;
    } else if (+value <= 0) {
      this.state = CountdownState.Lapsed;
      this.countdownLapsed.emit();
      return;
    }

    this.config = { ...this.config, leftTime: +value };
    this.state = CountdownState.Started;
  }
}
