import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { DisclosureComponent } from './disclosure.component';
import { SharedModule } from '@apis/shared/shared.module';
import { TrafficDisclosureReviewAndConfirmComponent } from './traffic-disclosure-review-and-confirm/traffic-disclosure-review-and-confirm.component';
import { TrafficDisclosureUploadComplete } from './traffic-disclosure-upload-complete/traffic-disclosure-upload-complete.component';
import { DirectiveModule } from '../../shared/directives/directive.module';

@NgModule({
  declarations: [
    DisclosureComponent,
    TrafficDisclosureReviewAndConfirmComponent,
    TrafficDisclosureUploadComplete
  ],
  imports: [      
    CommonModule,  
    SharedModule,
    DirectiveModule
  ]
})
export class DisclosureModule { }
