import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-saferoads-modal',
  templateUrl: './contact-saferoads-modal.component.html',
  styleUrls: ['./contact-saferoads-modal.component.scss']
})
export class ContactSaferoadsModalComponent implements OnInit {
  constructor() { }
  ngOnInit(): void { }
}