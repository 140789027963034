import { DeclarationInformation } from './declaration-information.model';


import { Detachment } from './detachment.model';

export class PoliceServiceInformation {
    public policeServiceInformationId: number = 0;
    public detachmentId: number;
    public contraventionId: number;
    public vehicleSeizureId: number;
    public declarationInformationId: number;
    public regimentalNumber: string;
    public policeOfficerName: string;
    public updatedBy: string;
    public issuingOfficerId: number;
    public detachment: Detachment;
    public declarationInformation: DeclarationInformation;

    constructor(init?: Partial<PoliceServiceInformation>) {
        
        Object.assign(this, init);
    }
}
