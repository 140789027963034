import { Component, OnInit, EventEmitter, AfterViewInit } from '@angular/core';
import { ContraventionStatusTypes, EventTypes } from '@apis/shared/enums/app.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Event } from '@apis/shared/models/event.model';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';

@Component({
  selector: 'app-cancel-contravention-modal',
  templateUrl: './cancel-contravention-modal.component.html',
  styleUrls: ['./cancel-contravention-modal.component.scss']
})
export class CancelContraventionModalComponent implements OnInit, AfterViewInit {
  contravention: Contravention;
  contraventionNumber: string;
  isSubmitClicked: boolean = false;
  bodyElement: JQuery<HTMLElement>;
  cancellationReason: string = "";
  isContraventionCancelled: boolean = false;
  errorMessage: string;
  confirmButton: JQuery<HTMLElement>;
  username: string;
  confirmModalOverlay: JQuery<HTMLElement>;
  additionalNotesTextArea: JQuery<HTMLElement>;

  close: EventEmitter<Contravention> = new EventEmitter<Contravention>();

  constructor( private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService,
    private readonly intakeService: IntakeService) { }

  ngAfterViewInit(): void {    
    this.confirmButton = $(".confirm-button");
    this.additionalNotesTextArea = $("#additionalNotes");
    if (this.isContraventionCancelled) {            
      this.additionalNotesTextArea.attr("disabled", ""); 
    }
  }

  ngOnInit(): void {        
    this.bodyElement = $(document.body);
    this.bodyElement.addClass("overflow-hidden");
    this.confirmModalOverlay = $(".confirm-modal-overlay");

    this.contraventionNumber = this.contravention.contraventionNumber;
        
    var user = this.localStorageService.getUser();
    this.username = `${user?.firstName} ${user?.lastName}`;
    
    if (+this.contravention.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled) {
      this.cancellationReason = this.contravention?.events.find(x => +x.eventTypeId == EventTypes.ContraventionCancelled)?.eventDetails;
      this.isContraventionCancelled = true; 
      this.errorMessage = "Contravention cancelled";
    }    
    else
    {
      this.cancellationReason = 'Roadside Appeal test result showed less than any prohibited drug or alcohol concentration';
    }
  }
  
  onCancelClick(): void {
    this.bodyElement.removeClass("overflow-hidden");
    this.close.emit(null);
  }

  onConfirmCancellationClick() {
    if (this.confirmButton.hasClass("disabled")) {
      return;
    }

    this.isSubmitClicked = true;
    
    if (!this.isContraventionCancelled) {
      this.showHideConfirmModal(true);
    }
  } 

  showErrors(error: any) {
    if (error?.error && Array.isArray(error?.error))
      this.errorMessage = error.error[0];
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errorMessage = error.error;
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errorMessage = error.error.error;
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errorMessage = error.error.errors;  
    else    
      this.errorMessage = "Something went wrong. Please try again later.";
  }

  onConfirmClick()
  {
    this.spinner.show();
     
    this.contravention.events.push(new Event({
      eventTypeId: EventTypes.ContraventionCancelled,
      eventDetails: this.cancellationReason,
      userName: this.username
    }));

    this.intakeService.updateContraventonRoadsideAppealInfoAsync(this.contravention)
      .subscribe((contravention: Contravention) => {
        this.isContraventionCancelled = true;
        this.errorMessage = "This notice has been cancelled";
        this.additionalNotesTextArea.attr("disabled", ""); 
        this.showHideConfirmModal();
        this.bodyElement.removeClass("overflow-hidden");                    
        this.spinner.hide();
        this.close.emit(contravention);
      }, (error: any) => {
        if (error.status == 401 || error.status == 403) {
          this.errorMessage = "You are unauthorized to perform this operation";
        }          
        else {
          this.showErrors(error);
        }

        this.showHideConfirmModal();
        this.bodyElement.removeClass("overflow-hidden");                    
        this.spinner.hide();
      });
  }

  showHideConfirmModal(show?: boolean): void {
    if (show) {
      this.confirmModalOverlay.addClass("modal-show");
    } 
    else 
    {
      this.confirmModalOverlay.removeClass("modal-show");
    }
  }
}
