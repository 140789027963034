import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appMVIDNumberValidator]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: MVIDNumberValidatorDirective,
        multi: true
    }]
})
export class MVIDNumberValidatorDirective implements Validator {
    @Input('appMVIDNumberValidator') isRequired: boolean;
    
    validate(control: AbstractControl) {
        if (!this.isRequired || !control.value)  
            return null;
       
        var mvidNumber = control.value.replace(/-/g, "");    

        if (mvidNumber.length<9) // Length check
            return {'invalidMvidNumberLength': true};  

        if (!/^\d+$/.test(mvidNumber))
            return {'invalidMvidNumberFormat': true};

        var mvidNumberDigits = mvidNumber.slice(0, -1);
        var checkDigit: number = +mvidNumber.substring(mvidNumber.length-1);
        var oddPositionValue: string = "";
        var oddPositionValueNumber: number = 0;
        var sumOfOddValue: number = 0;
        var sumOfEvenValue: number = 0;
                
        for (var i = 0; i < mvidNumberDigits.length; i++) {
            if (i%2 == 0) //Even Position
                sumOfEvenValue += (+mvidNumberDigits.charAt(i));
            else //Odd Position
                oddPositionValue = `${oddPositionValue}${mvidNumberDigits.charAt(i)}`;
        }

        // Multiply odd position value by 2
        oddPositionValueNumber = ((+oddPositionValue) * 2);

        // Add all the digits in the product
        while (oddPositionValueNumber)
        {
            sumOfOddValue += oddPositionValueNumber % 10;
            oddPositionValueNumber = Math.floor(oddPositionValueNumber / 10);
        }

        var calculatedCheckDigit: number = (sumOfOddValue + sumOfEvenValue) % 10 == 0? 0 : (10 - (sumOfOddValue + sumOfEvenValue) % 10);

        // Subtract the final result from the next highest 10's value and compare it with check digit
        return checkDigit == calculatedCheckDigit? null : {'invalidCheckDigit': true};
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('isRequired' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }

}