<div>
    <div class="report-title">Submission Export</div>
    <div class="report-number">{{reportNumber}}</div>
    <div class="card mt-4" *ngIf="isDriverSectionVisible">
        <div class="card-header">
            <div class="section-header">Driver Information</div>                                
        </div>            
        <div class="card-body">                    
            <app-report-section-details sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Driver's Licence Information" [sectionDetails]="recipientIdentificationInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Driver Contact Information" [sectionDetails]="recipientContactInformation"></app-report-section-details>
        </div>
    </div>

    <div class="card mt-4" *ngIf="isContraventionSectionVisible">
        <div class="card-header">
            <div class="section-header">Contravention Details</div>                                
        </div>            
        <div class="card-body">                    
            <app-report-section-details sectionTitle="Contravention Information" [sectionDetails]="contraventionInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Contravention Type" [sectionDetails]="contraventionType"></app-report-section-details>
            <app-report-section-details sectionTitle="Stop Information" [sectionDetails]="stopInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Witness Information" [sectionDetails]="witnessInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Contravention Grounds" [sectionDetails]="contraventionGrounds"></app-report-section-details>
            <app-report-section-details sectionTitle="Impairment Screening Information" [sectionDetails]="impairmentScreeningInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Impairment Screening Details" [sectionDetails]="impairmentScreeningDetails"></app-report-section-details>
            <div *ngFor="let additionalTest of additionalScreeningInformation; let index = index;">
                <app-report-section-details sectionTitle="Additional Screening Method/Evidentiary Test {{index+1}}" [sectionDetails]="additionalTest"></app-report-section-details>
            </div>
            <app-report-section-details sectionTitle="Reasonable Grounds to Believe" [sectionDetails]="reasonableGrounds"></app-report-section-details>
            <app-report-section-details *ngIf="contravention?.roadsideAppeal" sectionTitle="Roadside Appeal Information" [sectionDetails]="roadsideAppealInformation"></app-report-section-details>
            <app-report-section-details *ngIf="contravention?.roadsideAppeal" sectionTitle="Roadside Appeal Details" [sectionDetails]="roadsideAppealDetails"></app-report-section-details>
            <app-report-section-details sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-report-section-details>
        </div>
    </div>

    <div class="card mt-4" *ngIf="isVehicleSeizureSectionVisible">
        <div class="card-header">
            <div class="section-header">Vehicle Seizure Details</div>                                
        </div>            
        <div class="card-body">                    
            <app-report-section-details sectionTitle="Seizure Information" [sectionDetails]="seizureInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Registered Owner Information" [sectionDetails]="registeredOwnerInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Vehicle Location & Tow Lot Information" [sectionDetails]="towLotInformation"></app-report-section-details>
            <app-report-section-details *ngIf="issuerSubmission?.isSuspendedDriversProgramVehicleSeizure" sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-report-section-details>
        </div>
    </div>

    <div class="card mt-4" *ngIf="isSupportingDocumentsSectionVisible">
        <div class="card-header">
            <div class="section-header">Supporting Documents</div>
        </div>
        <div class="card-body">
            <div *ngFor="let document of supportingDocuments">
                <div *ngIf="canShowDocument(document)" class="row">
                    <div class="col-12 col-sm-12 mb-0 pb-0">
                        <label [ngClass]="{'required': !document.isOptional}">{{getDocumentTypeName(document.documentTypeId)}} </label>
                    </div>
                    <div class="col-12 col-sm-12 mb-4">
                        <div *ngIf="document.documentName != null" class="doc-style">
                            {{document.documentName}} - Submitted {{document.uploadedDate | date:"dd MMM, yyyy"}}
                        </div>
                        <div *ngIf="document.documentName == null && document.isSubmitLater" class="doc-style">
                            Will submit at later date
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-4" *ngIf="isPoliceServiceSectionVisible">
        <div class="card-header">
            <div class="section-header">Police Service Information</div>                                
        </div>            
        <div class="card-body">                    
            <app-report-section-details sectionTitle="" [sectionDetails]="policeServiceInformation"></app-report-section-details>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            <div class="section-header">Declaration</div>                                
        </div>            
        <div class="card-body">                    
            <app-report-section-details sectionTitle="" [sectionDetails]="declarationInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Re-Service Information" [sectionDetails]="reServiceInformation"></app-report-section-details>
        </div>
    </div>
</div>