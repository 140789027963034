


export class Fine {
    public fineId: number = 0;
    public contraventionId: number = 0;
    public fineAmount: number = 0;
    public victimFineSurchargeAmount: number = 0;
    public dueDate: Date = new Date();
    public latePenaltyAmount: number = 0;
    public latePenaltyOverride: boolean = false;
    public originalDueDate: Date = new Date();
    public extendedDueDate: Date;
    public additionallyExtendedDueDate: Date;
    public originalAmount: number = 0;
    public amountPaid: number = 0;
    public amountDue: number = 0;

    constructor(init?: Partial<Fine>) {
        
        Object.assign(this, init);
    }
}
