
<div class="body-style">
    
    <div *ngIf="!isMobilePrinter" class="row">
        <div class="all-columns">
            <span class="column-25percent">
                <img width=153 height=43 id="abLogo" src="assets/images/ablogo.jpg">
            </span>
            <span class="column-75percent">
                <div>
                    <span class="page-header">Roadside Appeal</span>
                    <span class="section-sub-header">Immediate Roadside Sanction</span>
                </div>
            </span>
        </div>
    </div>

    <div *ngIf="isMobilePrinter" class="row">
        <div class="all-columns">
        <img width=153 height=43 id="abLogo" src="assets/images/ablogo.jpg">
        </div>
    </div>
    <div *ngIf="isMobilePrinter" class="row">
        <div class="all-columns">
        <span class="page-header-nopadding">Roadside Appeal</span>
        </div>
    </div>
    <div *ngIf="isMobilePrinter" class="row">
        <div class="all-columns">
            <span class="section-sub-header-nofloat">Immediate Roadside Sanction</span>
        </div>
    </div>

    <div class="thin-row horizontal-line"></div>
    <div class="thin-row">
        <div class="all-columns">
            <p>Protected A</p>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <p class="section-header">effective immediately:</p>
            <p class="data-row-normal">&nbsp;&nbsp;-&nbsp; Your privilege to operate a motor vehicle in the province of Alberta has been suspended/disqualified,</p>
            <p class="data-row-normal">&nbsp;&nbsp;-&nbsp; Your vehicle may be seized as a result of your suspension/disqualification,</p>
            <p class="data-row-normal">&nbsp;&nbsp;-&nbsp; You are subject to a fine plus victim fine surcharge,</p>
            <p class="data-row-normal">&nbsp;&nbsp;-&nbsp; There may be additional conditions for reinstatement of your licence, and</p>
            <p class="data-row-normal">&nbsp;&nbsp;-&nbsp; You may be subject to further administrative and criminal sanctions and penalties</p>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <p class="normal-text-row">A peace officer has required that you surrender 
                your driver’s licence. Refusal or failure to surrender 
                your driver’s licence does not prevent the 
                suspension/disqualification from driving from taking 
                effect.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="all-columns your-rights-row">
            <span class="your-rights-text">You have the right to a roadside appeal of your blood alcohol/blood drug concentration</span>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <p class="section-header">roadside appeal:</p>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <p class="normal-text-row">The choice to request a roadside appeal is 
                completely <span style="font-weight: bold; text-decoration: underline;">voluntary</span>. If you choose to have a 
                roadside appeal, you may be required to 
                immediately accompany the peace officer for that 
                purpose.
            </p><br />
        </div>
    </div>

    <div class="row">
        <div class="all-columns roadside-results-row">
            <span style="font-weight: bold;">Roadside Appeal Results</span>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <p class="data-row-normal">&nbsp;&nbsp;-&nbsp; If the results of your roadside appeal is less than any prohibited drug/alcohol concentration, your contravention will be CANCELLED.</p>
            <p class="data-row-normal">&nbsp;&nbsp;-&nbsp; If the result of your roadside appeal is equal to or greater than any prohibited drug/alcohol concentration, your contravention will be CONFIRMED</p>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <p ><span style="font-weight: bold; text-decoration: underline;">YOU MUST IMMEDIATELY INDICATE TO THE PEACE OFFICER YOUR CHOICE TO HAVE A ROADSIDE APPEAL.</span></p>
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <br /><br />
        </div>
    </div>

    <div class="row">
        <div class="all-columns">
            <p class="data-row">I, _______________________________________,
                am requesting and do voluntarily consent to the 
                taking of a sample that will be used to confirm the 
                concentration of alcohol and drugs (if any) in my 
                blood. I will accompany the peace officer for that 
                purpose and I understand that the result of this test 
                will be used to prove my blood alcohol and drug 
                concentration (if any) at the time of driving in any 
                proceeding arising from this event.
            </p>
        </div>
    </div>


    <div class="row">
        <div class="all-columns">
            <br /><br /><br />
        </div>
    </div>

    <div *ngIf="!isMobilePrinter" class="row">
        <div class="all-columns">
            <span class="column-50percent">
                <p class="data-row-sig">_______________________________________________</p>
                <p class="data-row-small">Signature</p>
            </span>
            <span class="column-50percent">
                <p class="data-row-sig">______________________________</p>
                <p class="data-row-small">Date (yyyy-mm-dd)</p>
            </span>
        </div>
    </div>

    <div *ngIf="isMobilePrinter" class="row">
        <div class="all-columns">
            <p class="data-row-sig">_______________________________________________</p>
            <p class="data-row-small">Signature</p>
        </div>
    </div>

    <div *ngIf="isMobilePrinter" class="row">
        <div class="all-columns">
            <p class="data-row-sig">______________________________</p>
            <p class="data-row-small">Date (yyyy-mm-dd)</p>
        </div>
    </div>

</div>
<p *ngIf="insertPageBreak" style="float: none !important; break-after: page !important;">&nbsp;</p>