import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[isBirthDate]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: BirthDateValidatorDirective,
        multi: true
    }]
})
export class BirthDateValidatorDirective implements Validator {
  validate(control: AbstractControl) {
    if (control.value == null)
      return null;
    
    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()); 
    const chkDate = new Date(new Date(control.value).getFullYear(), new Date(control.value).getMonth(), new Date(control.value).getDate()); 
    
    if (chkDate <= currentDate){
      return ((new Date(control.value).getFullYear() < 1900 || new Date(control.value).getFullYear() > 2099)? {'InvalidYear': true} : null);
    }
    else {
      return {'FutureDate': true};
    }
  }
}