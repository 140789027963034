import { ContactMethodTypes } from "../enums/app.enum";


// Angular 14 upgrade change
// as per https://github.com/microsoft/TypeScript/issues/45612
// the following was added to fix the error of dropping ie support for this method
//
declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blob: Blob) => void
  }
}

// Angular 14 upgrade change


export class CommonUtil {
  static getContactMethodTypeName(contactMethodTypeId: any) : string {
    switch (contactMethodTypeId.toString()) {
      case ContactMethodTypes.Phone.toString():
        return 'Phone';
      case ContactMethodTypes.Text.toString():
        return 'Text/SMS'
      case ContactMethodTypes.Email.toString():
        return 'Email';
      default:
        return '-';
    }
  }

  static getRepresentationTitle(representationTypeId: number): string {
    const repTitles = ['', 'Agent ', 'Lawyer ', 'Clerk ', '', 'Parent/Guardian '];
    return repTitles[representationTypeId || 0];
  }

  static getDocumentSize(file: File): string {
    if (file == null) {
      return "";
    }

    let size = file.size;

    if (size < 1024) {
      return `${size} bytes`;
    }

    if (size >= 1024 && size < 1048576) {
      return `${(size/1024).toFixed(2)}kb`;
    }

    if (size >= 1048576 && size < 1073741824) {
      return `${((size/1024)/1024).toFixed(2)}mb`;
    }

    if (size >= 1073741824) {
      return `${(((size/1024)/1024)/1024).toFixed(2)}gb`;
    }
  }

  static formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static openPdfDocument(blob, title: string = ""){
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], {type: "application/pdf"})

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    const url = window.URL.createObjectURL(newBlob);
    var newTab = window.open(url, "_blank");
    
    if (newTab && title.length>0)
    {
      setTimeout(function () {
        newTab.document.title = title;
      }, 1000);
    }
  }

  static openCsvDocument(blob){
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], {type: "application/csv"})

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    const url = window.URL.createObjectURL(newBlob);
    window.open(url, "_blank");
  }

  static getMonthList(): any {
    var monthList =
    [
      { id: 1, value: 'January' },
      { id: 2, value: 'February' },
      { id: 3, value: 'March' },
      { id: 4, value: 'April' },
      { id: 5, value: 'May' },
      { id: 6, value: 'June' },
      { id: 7, value: 'July' },
      { id: 8, value: 'August' },
      { id: 9, value: 'September' },
      { id: 10, value: 'October' },
      { id: 11, value: 'November' },
      { id: 12, value: 'December' }
    ];

    return monthList;
  }

  static getYearList(): any {
    var startingYear = 2020;
    var currentYear = new Date().getFullYear();
    var yearList = [];

    for (let index = startingYear; index <= currentYear; index++) {
      yearList.push(index);
    }

    return yearList;
  }

  static isValidSeizureNumber(seizureNumber: string): boolean {
    if (seizureNumber.length != 17 || seizureNumber.substring(0, 5) != "10000")
      return false;

    //Check Digit Logic
    var startingNumber: string = "10000";
    var endingNumber: string = seizureNumber.substring(6);
    var checkDigit: number = +seizureNumber.substring(5, 6);
    var oddPositionSum: number = 0;
    var evenPositionSum: number = 0;
    var oddPositionNumber: number;
    var oddPositions: string = '';
    var finalNumber: Number;
    var checkNumber: Number;

    var seizureNumberDigits = `${startingNumber}${endingNumber}`;
    for(let index = 0; index < seizureNumberDigits.length; index++) { 
      //Even position  
      if(index % 2 === 0)  
          evenPositionSum += +seizureNumberDigits[index];
        else {
          oddPositions = `${oddPositions}${seizureNumberDigits[index]}`;
        }
    }

    oddPositionNumber = +oddPositions;
    oddPositionNumber = oddPositionNumber * 2;
    oddPositionSum = this.sumDigits(oddPositionNumber);
    finalNumber = evenPositionSum + oddPositionSum; // Add both odd and even totals
    checkNumber = (+finalNumber % 10) == 0 ? 0 : 10 - (+finalNumber % 10);
    
    return checkDigit == checkNumber;
  }

  static sumDigits(number){
    let numArr = number.toString().split("");

    let sum = numArr.reduce(function(a, b){
        return parseInt(a) + parseInt(b);
    }, 0);
    
    return sum;
  }
}
