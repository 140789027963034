import { NapViewModel } from './nap-view.model';
import { Document } from './document.model';

export class SharedDocumentsUploadRequest {
    public tempFileFolder: string;
    public contraventionIds: number[] = [];
    public vehicleSeizureIds: number[] = [];
    public documents: Document[] = [];
    public naps: NapViewModel[] = [];
        
    constructor(init?: Partial<SharedDocumentsUploadRequest>) {
        Object.assign(this, init);
    }
}
