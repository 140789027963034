

import { Representation } from './representation.model';
import { TransactionLineItem } from './transaction-line-item.model';

export class PaymentRequest {
    public orderNumber: string;
    public isSelfSubmit: boolean = false;
    public representation: Representation;
    public paymentDate: Date = new Date();
    public paymentMethod: number = 0;
    public cardHolderName: string;
    public paymentAmount: number = 0;
    public paymentToken: string;
    public recordNumber: string;
    public transactionId: string;
    public cardLastFour: string;
    public recipientEmail: string;
    public skuCode: string;
    public issueDate: string;
    public movesInterfaceDate: Date = new Date();
    public movesBatchId: string;
    public lineItems: TransactionLineItem[] = [];

    constructor(init?: Partial<PaymentRequest>) {
        
        Object.assign(this, init);
    }
}
