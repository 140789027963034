import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from "@angular/router";

@Component({
  selector: 'app-contravention',
  templateUrl: './contravention.component.html',
  styleUrls: ['./contravention.component.scss']
})
export class ContraventionComponent implements OnInit {  
  
  constructor(private readonly activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {   
    
  }

  showSubHeader(): boolean {
    var showSubHeader = true;
    var lastIndex = window.location.pathname.lastIndexOf("/") + 1;    
    var routePath = window.location.pathname.substring(lastIndex);
    
    this.activatedRoute.routeConfig.children.forEach((route: Route) => {          
      if ((route.data == null && route.path.split('/').includes(routePath)) || routePath=="issuer-submission-types") {        
        showSubHeader = false;
      }
    });  
  
    return showSubHeader;
  }

  showCancelLink(): boolean {
    var lastIndex = window.location.pathname.lastIndexOf("/") + 1;    
    var routePath = window.location.pathname.substring(lastIndex);

    return routePath!="print-confirmation" && routePath!="submission-complete";
  }
}
