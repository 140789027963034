import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[isBasicDate]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: BasicDateValidatorDirective,
        multi: true
    }]
})
export class BasicDateValidatorDirective implements Validator {
  validate(control: AbstractControl) {
    if (control.value == null)
      return null;
 
    return ((new Date(control.value).getFullYear() < 1900 || new Date(control.value).getFullYear() > 2099)? {'InvalidYear': true} : null);
  }
}