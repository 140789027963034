import { NgModule } from '@angular/core';
import { IntakeDragDropDirective } from './intake-dragdrop.directive';

@NgModule({
    declarations: [
        IntakeDragDropDirective
    ],
    exports: [
        IntakeDragDropDirective
    ]
  })
  export class DirectiveModule { }
  