<form #vehicleInfoForm="ngForm">
    <div class="d-flex flex-column">
        <h1>Vehicle Details</h1>
        <div class="card mt-4">
            <div class="card-header">
                <div>Vehicle Information</div>
            </div>
            <div class="card-body">
                <div class="row form-group">
                    <div class="col-12 col-sm-6">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-check float-left">
                                    <input class="form-check-input" type="checkbox" id="albertaLicencePlate" name="albertaLicencePlate" [(ngModel)]="vehicle.isAlbertaLicencePlate" (ngModelChange)="onAlbertaLicenceChecked($event)">
                                    <label class="form-check-label" for="albertaLicencePlate">
                                        Alberta Licence Plate
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-8">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="stolenPlate" name="stolenPlate" [(ngModel)]="vehicle.isStolenPlate" (ngModelChange)="onStolenPlateChange()">
                                    <label class="form-check-label" for="stolenPlate">
                                        Plate Doesn't Match/Stolen Plate
                                    </label>
                                </div>
                            </div>
        
                            <div class="col-12 col-sm-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="noPlate" name="noPlate" [(ngModel)]="vehicle.isNoPlate" (ngModelChange)="onNoPlateChange()">
                                    <label class="form-check-label" for="noPlateContravention">
                                        No Plate
                                    </label>
                                </div>
                            </div>      
                        </div>         
                    </div>
                </div>
               
                <div class="form-group row" *ngIf="!vehicle.isNoPlate">
                    <div class="col-lg-6">
                        <label for="licencePlateNumber">Licence Plate Number <span *ngIf="!vehicle.isStolenPlate && !vehicle.isNoPlate" class="label-hint">(Required)</span></label>
                        <input [required]="!vehicle.isStolenPlate && !vehicle.isNoPlate" #licencePlateNumberControl="ngModel" maxlength="10" type="text" 
                        class="form-control text-uppercase" id="licencePlateNumber" name="licencePlateNumber" placeholder="Licence Plate Number" [(ngModel)]="vehicle.licencePlateNumber" 
                        [ngClass]="{'is-invalid':(licencePlateNumberControl.touched || isSubmitClicked) && licencePlateNumberControl.invalid}"
                        (blur)="onBlurCallMoves($event.target.value)">
                        <div class="invalid-feedback">
                            <div>Licence Plate Number is required</div>
                        </div>
                    </div>
                </div>

                <div class="row mb-4" *ngIf="isVehicleReadOnly()">
                    <div class="col-12">
                        <label>All vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>
                    </div>
                </div>

                <ng-container *ngIf="stopInformation.isIRSSelected || stopInformation.isSDPSelected; else onlyTSACharge">
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="vehicleIdentificationNumber">Vehicle Identification Number <span *ngIf="(vehicle.isStolenPlate || vehicle.isNoPlate) && !vehicle.isVinNotAvailable" class="label-hint">(Required)</span></label>
                            <input [required]="(vehicle.isStolenPlate || vehicle.isNoPlate) && !vehicle.isVinNotAvailable" #vehicleIdentificationNumberControl="ngModel" 
                            maxlength="20" type="text" class="form-control text-uppercase" id="vehicleIdentificationNumber" name="vehicleIdentificationNumber" 
                            placeholder="Vehicle Identification Number" [(ngModel)]="vehicle.vehicleIdentificationNumber" [readonly]="isVehicleReadOnly()"
                            [ngClass]="{'is-invalid':(vehicleIdentificationNumberControl.touched || isSubmitClicked) && vehicleIdentificationNumberControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Vehicle Identification Number is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6" *ngIf="!isMovesSectionVisible()">
                            <label for="vehicleRegistrationStatus">Vehicle Registration Status <span *ngIf="!vehicle.isStolenPlate && !vehicle.isNoPlate" class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="!vehicle.isStolenPlate && !vehicle.isNoPlate" #vehicleRegistrationStatusControl="ngModel" 
                            class="form-control" id="vehicleRegistrationStatus" name="vehicleRegistrationStatus" [(ngModel)]="vehicle.vehicleRegistrationStatusTypeId" 
                            (ngModelChange)="onVehcleRegistrationStatusChange($event)" [disabled]="(!!this.vehicle.movesLookupDate) && !this.vehicle.isStolenPlate"
                            [ngClass]="{'is-invalid':(vehicleRegistrationStatusControl.touched || isSubmitClicked) && vehicleRegistrationStatusControl.errors?.defaultSelected}">
                                <option [ngValue]="null">Select a Status</option>
                                <option *ngFor="let vehicleRegistrationStatusType of vehicleRegistrationStatusTypes" [ngValue]="vehicleRegistrationStatusType.id">{{vehicleRegistrationStatusType.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Vehicle Registration Status is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6 d-flex align-items-center" *ngIf="isMovesSectionVisible()">
                            <div class="form-check float-left mt-4">
                                <input class="form-check-input" type="checkbox" id="vinNotAvailable" name="vinNotAvailable" [(ngModel)]="vehicle.isVinNotAvailable" (ngModelChange)="onVINNotAvailableChecked()">
                                <label class="form-check-label" for="vinNotAvailable">
                                    VIN not available
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <app-country-province (onChangeEvent)="refreshVehicleCountryProvince($event)" [countryProvince]="vehicleCountryProvince"></app-country-province>
                    </div>
                    
                    <div class="form-group row" *ngIf="!isMovesSectionVisible()">
                        <div class="col-lg-6">
                            <label for="clientType">Client Type <span *ngIf="!vehicle.isStolenPlate && !vehicle.isNoPlate" class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="!vehicle.isStolenPlate && !vehicle.isNoPlate" #clientTypeControl="ngModel" 
                            class="form-control" id="clientType" name="clientType" [(ngModel)]="vehicle.clientTypeId" [disabled]="isVehicleReadOnly()"
                            [ngClass]="{'is-invalid':(clientTypeControl.touched || isSubmitClicked) && clientTypeControl.errors?.defaultSelected}">
                                <option [ngValue]="null">Select a Client Type</option>
                                <option *ngFor="let clientType of clientTypes" [ngValue]="clientType.id">{{clientType.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Client Type is required</div>
                            </div>
                        </div>
                    
                        <div class="col-lg-6">
                            <label for="vehicleMake">Make <span class="label-hint">(Required)</span></label>
                            <input required #vehicleMakeControl="ngModel" maxlength="20" type="text" class="form-control text-uppercase" 
                            id="vehicleMake" name="vehicleMake" placeholder="Make" [(ngModel)]="vehicle.make" [readonly]="isVehicleReadOnly()"
                            [ngClass]="{'is-invalid':(vehicleMakeControl.touched || isSubmitClicked) && vehicleMakeControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Make is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="isMovesSectionVisible()">
                        <div class="col-lg-4">
                            <label for="clientType">Client Type <span *ngIf="!vehicle.isStolenPlate && !vehicle.isNoPlate" class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="!vehicle.isStolenPlate && !vehicle.isNoPlate" #clientTypeControl="ngModel" 
                            class="form-control" id="clientType" name="clientType" [(ngModel)]="vehicle.clientTypeId"
                            [ngClass]="{'is-invalid':(clientTypeControl.touched || isSubmitClicked) && clientTypeControl.errors?.defaultSelected}">
                                <option [ngValue]="null">Select a Client Type</option>
                                <option *ngFor="let clientType of clientTypes" [ngValue]="clientType.id">{{clientType.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Client Type is required</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleRegistrationStatus">Vehicle Registration Status <span *ngIf="!vehicle.isStolenPlate && !vehicle.isNoPlate" class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="!vehicle.isStolenPlate && !vehicle.isNoPlate" #vehicleRegistrationStatusControl="ngModel" 
                            class="form-control" id="vehicleRegistrationStatus" name="vehicleRegistrationStatus" [(ngModel)]="vehicle.vehicleRegistrationStatusTypeId" 
                            (ngModelChange)="onVehcleRegistrationStatusChange($event)" 
                            [ngClass]="{'is-invalid':(vehicleRegistrationStatusControl.touched || isSubmitClicked) && vehicleRegistrationStatusControl.errors?.defaultSelected}">
                                <option [ngValue]="null">Select a Status</option>
                                <option *ngFor="let vehicleRegistrationStatusType of vehicleRegistrationStatusTypes" [ngValue]="vehicleRegistrationStatusType.id">{{vehicleRegistrationStatusType.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Vehicle Registration Status is required</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleMake">Make <span class="label-hint">(Required)</span></label>
                            <input required #vehicleMakeControl="ngModel" maxlength="20" type="text" class="form-control text-uppercase" 
                            id="vehicleMake" name="vehicleMake" placeholder="Make" [(ngModel)]="vehicle.make" [readonly]="isVehicleReadOnly()"
                            [ngClass]="{'is-invalid':(vehicleMakeControl.touched || isSubmitClicked) && vehicleMakeControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Make is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="vehicleModel">Model <span *ngIf="!vehicle.isStolenPlate && !vehicle.isNoPlate" class="label-hint">(Required)</span></label>
                            <input [required]="!vehicle.isStolenPlate && !vehicle.isNoPlate" #vehicleModelControl="ngModel" [readonly]="isVehicleReadOnly()"
                            maxlength="50" type="text" class="form-control text-uppercase" id="vehicleModel" name="vehicleModel" placeholder="Model" 
                            [(ngModel)]="vehicle.model"  [ngClass]="{'is-invalid':(vehicleModelControl.touched || isSubmitClicked) && vehicleModelControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Model is required</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleYear">Year <span *ngIf="!vehicle.isStolenPlate && !vehicle.isNoPlate" class="label-hint">(Required)</span></label>
                            <input [required]="!vehicle.isStolenPlate && !vehicle.isNoPlate" #vehicleYearControl="ngModel" type="text" 
                            mask="{{Constants.Mask.YEAR_MASK}}" pattern="^(19|[2-9][0-9])\d{2}$" class="form-control" id="vehicleYear" name="vehicleYear"
                            placeholder="Year" [(ngModel)]="vehicle.year" [readonly]="isVehicleReadOnly()"
                            [ngClass]="{'is-invalid':(vehicleYearControl.touched || isSubmitClicked) && vehicleYearControl.invalid}"> 
                            <div class="invalid-feedback">
                                <div>Year is required</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleColour">Colour <span class="label-hint">(Required)</span></label>
                            <input required #vehicleColourControl="ngModel" maxlength="20" type="text" class="form-control" id="vehicleColour" 
                            name="vehicleColour" placeholder="Colour" [readonly]="isVehicleReadOnly()"
                            [(ngModel)]="vehicle.colour" (ngModelChange)="vehicle.colour=this.moddedTitleCasePipe.transform($event)"
                            [ngClass]="{'is-invalid':(vehicleColourControl.touched || isSubmitClicked) && vehicleColourControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Color is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4" *ngIf="!isVehicleReadOnly()">
                            <label for="plateExpiryDate">Plate Expiry Date</label>
                            <input maxlength="10" #plateExpiryDateControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig"
                            class="form-control" id="plateExpiryDate" name="plateExpiryDate" placeholder="yyyy/mm/dd" [(ngModel)]="vehicle.licencePlateExpiryDate" 
                            [ngClass]="{'is-invalid':(plateExpiryDateControl.touched || isSubmitClicked) && plateExpiryDateControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Invalid Plate Expiry Date</div>
                            </div>
                        </div>
                        <div class="col-lg-4" *ngIf="isVehicleReadOnly()">
                            <label for="plateExpiryDate">Plate Expiry Date</label>
                            <input readonly required type="text" class="form-control" id="plateExpiryDate" name="plateExpiryDate" [value]="vehicle.licencePlateExpiryDate | date:'yyyy/MM/dd'">
                        </div>

                        <ng-container *ngIf="isVehicleReadOnly()">
                            <div class="col-lg-4">
                                <label for="plateClass">Class of Plate</label>
                                <input type="text" class="form-control" id="plateClass" [readonly]="isVehicleReadOnly()"
                                name="plateClass" placeholder="Class of Plate" [(ngModel)]="vehicle.licencePlateClass"> 
                            </div>
                            <div class="col-lg-4">
                                <label for="vehicleStyle">Vehicle Style</label>
                                <input type="text" class="form-control" id="vehicleStyle" [readonly]="isVehicleReadOnly()"
                                name="vehicleStyle" placeholder="Vehicle Style" [(ngModel)]="vehicle.vehicleStyle"> 
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="isVehicleReadOnly()">
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label for="vehicleWeight">Gross Vehicle Weight</label>
                                <input type="text" class="form-control" id="vehicleWeight" [readonly]="isVehicleReadOnly()"
                                name="vehicleWeight" placeholder="Vehicle Weight" [(ngModel)]="vehicle.vehicleWeight"> 
                            </div>
                            <div class="col-lg-4">
                                <label for="seatingCapacity">Seating Capacity</label>
                                <input type="text" class="form-control" id="seatingCapacity" [readonly]="isVehicleReadOnly()"
                                name="seatingCapacity" placeholder="Seating Capacity" [(ngModel)]="vehicle.vehicleSeatingCapacity"> 
                            </div>
                            <div class="col-lg-4">
                                <label for="vehicleStatus">Vehicle Status</label>
                                <select class="form-control" id="vehicleStatus" name="vehicleStatus" [disabled]="isVehicleReadOnly()" [(ngModel)]="vehicle.vehicleStatusTypeId">
                                    <option [ngValue]="null">Select a Vehicle Status</option>
                                    <option *ngFor="let vehicleStatusType of vehicleStatusTypes" [ngValue]="vehicleStatusType.id">{{vehicleStatusType.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label for="lastServiceType">Last Service Type</label>
                                <input type="text" class="form-control" id="lastServiceType" [readonly]="isVehicleReadOnly()"
                                name="lastServiceType" placeholder="Last Service Type" [(ngModel)]="vehicle.lastServiceType"> 
                            </div>
                            <div class="col-lg-4" *ngIf="!isVehicleReadOnly()">
                                <label for="lastServiceDate">Last Service Date</label>
                                <input maxlength="10" #lastServiceDateControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig" 
                                class="form-control" id="lastServiceDate" name="lastServiceDate" placeholder="yyyy/mm/dd" [(ngModel)]="vehicle.lastServiceDate" 
                                [ngClass]="{'is-invalid':(lastServiceDateControl.touched || isSubmitClicked) && lastServiceDateControl.invalid}">
                                <div class="invalid-feedback">
                                    <div>Invalid Last Service Date</div>
                                </div>
                            </div>
                            <div class="col-lg-4" *ngIf="isVehicleReadOnly()">
                                <label for="lastServiceDate">Last Service Date</label>
                                <input readonly required type="text" class="form-control" id="lastServiceDate" name="lastServiceDate" [value]="vehicle.lastServiceDate | date:'yyyy/MM/dd'">
                            </div>
                        </div>
                    </ng-container>

                <!--MOVES Returned vehicle information in readonly format-->
                <ng-container *ngIf="isMovesSectionVisible()">
                    <div class="row mb-4">
                        <div class="col-12">
                            <label>All vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>
                        </div>
                    </div>
    
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="vehicleIdentificationNumberMoves">Vehicle Identification Number</label>
                            <input type="text" class="form-control text-uppercase" id="vehicleIdentificationNumberMoves" name="vehicleIdentificationNumberMoves" 
                            [value]="movesVehicle.vehicleIdentificationNumber" readonly>
                        </div>
                        <div class="col-lg-6">
                            <label for="vehicleRegistrationStatusMoves">Vehicle Registration Status</label>
                            <select class="form-control" id="vehicleRegistrationStatusMoves" name="vehicleRegistrationStatusMoves" 
                            [(ngModel)]="movesVehicle.vehicleRegistrationStatusTypeId" disabled>
                                <option [ngValue]="null">Select a Status</option>
                                <option *ngFor="let vehicleRegistrationStatusType of vehicleRegistrationStatusTypes" [ngValue]="vehicleRegistrationStatusType.id">{{vehicleRegistrationStatusType.name}}</option>
                            </select>
                        </div>
                    </div>
    
                    <div class="form-group">
                        <app-country-province [countryProvince]="vehicleCountryProvinceMoves"></app-country-province>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label for="clientTypeMoves">Client Type</label>
                            <select class="form-control" id="clientTypeMoves" name="clientTypeMoves" 
                            [(ngModel)]="movesVehicle.clientTypeId" disabled>
                                <option [ngValue]="null">Select a Client Type</option>
                                <option *ngFor="let clientType of clientTypes" [ngValue]="clientType.id">{{clientType.name}}</option>
                            </select>
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleMakeMoves">Make</label>
                            <input type="text" class="form-control text-uppercase" id="vehicleMakeMoves" name="vehicleMakeMoves" 
                            [value]="movesVehicle.make" readonly>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="vehicleModelMoves">Model</label>
                            <input readonly type="text" class="form-control text-uppercase" id="vehicleModelMoves" name="vehicleModelMoves" 
                            [value]="movesVehicle.model">
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleYearMoves">Year</label>
                            <input type="text" class="form-control" id="vehicleYearMoves" name="vehicleYearMoves" [value]="movesVehicle.year" readonly> 
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleColourMoves">Colour</label>
                            <input type="text" class="form-control" id="vehicleColourMoves" 
                            name="vehicleColourMoves" readonly [value]="movesVehicle.colour">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="plateExpiryDateMoves">Plate Expiry Date</label>
                            <input type="text" readonly class="form-control" id="plateExpiryDateMoves" name="plateExpiryDateMoves" 
                            value="{{movesVehicle.licencePlateExpiryDate | date:'yyyy/MM/dd'}}">
                        </div>
                        <div class="col-lg-4">
                            <label for="plateClassMoves">Class of Plate</label>
                            <input type="text" class="form-control" id="plateClassMoves" readonly
                            name="plateClassMoves" value="{{movesVehicle.licencePlateClass}}"> 
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleStyleMoves">Vehicle Style</label>
                            <input type="text" class="form-control" id="vehicleStyleMoves" readonly
                            name="vehicleStyleMoves" [value]="movesVehicle.vehicleStyle"> 
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="vehicleWeightMoves">Commercial Vehicle Weight</label>
                            <input type="text" class="form-control" id="vehicleWeightMoves" readonly
                            name="vehicleWeightMoves" [value]="movesVehicle.vehicleWeight"> 
                        </div>
                        <div class="col-lg-4">
                            <label for="seatingCapacityMoves">Seating Capacity</label>
                            <input type="text" class="form-control" id="seatingCapacityMoves" readonly
                            name="seatingCapacityMoves" [value]="movesVehicle.vehicleSeatingCapacity"> 
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleStatusMoves">Vehicle Status</label>
                            <select class="form-control" id="vehicleStatusMoves" name="vehicleStatusMoves" disabled [(ngModel)]="movesVehicle.vehicleStatusTypeId">
                                <option [ngValue]="null">Select a Vehicle Status</option>
                                <option *ngFor="let vehicleStatusType of vehicleStatusTypes" [ngValue]="vehicleStatusType.id">{{vehicleStatusType.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="lastServiceTypeMoves">Last Service Type</label>
                            <input type="text" class="form-control" id="lastServiceTypeMoves" readonly
                            name="lastServiceTypeMoves" [value]="movesVehicle.lastServiceType"> 
                        </div>
                        <div class="col-lg-4">
                            <label for="lastServiceDateMoves">Last Service Date</label>
                            <input type="text" readonly class="form-control" id="lastServiceDateMoves" name="lastServiceDateMoves" 
                            value="{{movesVehicle.lastServiceDate| date:'yyyy/MM/dd'}}">
                        </div>
                    </div>
                </ng-container>

                    <div class="form-group row">
                        <div class="col-12">
                            <div class="form-check float-left">
                                <input class="form-check-input" type="checkbox" id="trailerInvolved" name="trailerInvolved" [(ngModel)]="vehicle.isTrailerInvolved" (change)="onTrailerInvolvedChange()">
                                <label class="form-check-label" for="trailerInvolved">
                                    Trailer Involved
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="vehicle.isTrailerInvolved">
                        <div class="col-lg-6">
                            <label for="trailerLicencePlateNumber">Trailer Licence Plate Number <span class="label-hint">(Required)</span></label>
                            <input required #trailerLicencePlateNumberControl="ngModel" maxlength="10" type="text" class="form-control text-uppercase" 
                            id="trailerLicencePlateNumber" name="trailerLicencePlateNumber" placeholder="Enter Trailer Plate Number" [(ngModel)]="vehicle.trailerLicencePlateNumber" 
                            [ngClass]="{'is-invalid':(trailerLicencePlateNumberControl.touched || isSubmitClicked) && trailerLicencePlateNumberControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Trailer Plate Number is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="additionalTrailerInfo">Additional Trailer Information</label>
                            <input #additionalTrailerInfoControl="ngModel" type="text" class="form-control" id="additionalTrailerInfo" name="additionalTrailerInfo" 
                            placeholder="e.g. Size, Type, Load" [(ngModel)]="vehicle.additionalTrailerInformation"> 
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="vehicle.isTrailerInvolved && !vehicle.isSecondTrailerInvolved">
                        <div class="col-12">
                            <a [routerLink]="[]" (click)="onAddAdditionalTrailerClick()">Add additional trailer</a>
                        </div>
                    </div> 
                    <div class="form-group row" *ngIf="vehicle.isTrailerInvolved && vehicle.isSecondTrailerInvolved">
                        <div class="col-lg-6">
                            <label for="secondTrailerLicencePlateNumber">Trailer Licence Plate Number <span class="label-hint">(Required)</span></label>
                            <input required #secondTrailerLicencePlateNumberControl="ngModel" maxlength="10" type="text" class="form-control text-uppercase" 
                            id="secondTrailerLicencePlateNumber" name="secondTrailerLicencePlateNumber" placeholder="Enter Trailer Plate Number" [(ngModel)]="vehicle.secondTrailerLicencePlateNumber" 
                            [ngClass]="{'is-invalid':(secondTrailerLicencePlateNumberControl.touched || isSubmitClicked) && secondTrailerLicencePlateNumberControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Trailer Plate Number is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="additionalSecondTrailerInfo">Additional Trailer Information</label>
                            <input #additionalSecondTrailerInfoControl="ngModel" type="text" class="form-control" id="additionalSecondTrailerInfo" name="additionalSecondTrailerInfo" 
                            placeholder="e.g. Size, Type, Load" [(ngModel)]="vehicle.additionalSecondTrailerInformation"> 
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="vehicle.isTrailerInvolved && vehicle.isSecondTrailerInvolved">
                        <div class="col-12">
                            <div class="float-right">
                                <a class="remove-link" [routerLink]="[]" (click)="onRemoveAdditionalTrailerClick()">Remove</a>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #onlyTSACharge>
                    <div class="form-group">
                        <app-country-province (onChangeEvent)="refreshVehicleCountryProvince($event)" [countryProvince]="vehicleCountryProvince"></app-country-province>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="vehicleMake">Make <span class="label-hint">(Required)</span></label>
                            <input required #vehicleMakeControl="ngModel" maxlength="20" type="text" class="form-control text-uppercase" 
                            id="vehicleMake" name="vehicleMake" placeholder="Make" [(ngModel)]="vehicle.make" [readonly]="isVehicleReadOnly()"
                            [ngClass]="{'is-invalid':(vehicleMakeControl.touched || isSubmitClicked) && vehicleMakeControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Make is required</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleModel">Model <span *ngIf="!vehicle.isStolenPlate && !vehicle.isNoPlate" class="label-hint">(Required)</span></label>
                            <input [required]="!vehicle.isStolenPlate && !vehicle.isNoPlate" #vehicleModelControl="ngModel" [readonly]="isVehicleReadOnly()"
                            maxlength="50" type="text" class="form-control text-uppercase" id="vehicleModel" name="vehicleModel" placeholder="Model" 
                            [(ngModel)]="vehicle.model"  [ngClass]="{'is-invalid':(vehicleModelControl.touched || isSubmitClicked) && vehicleModelControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Model is required</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label for="vehicleYear">Year <span *ngIf="!vehicle.isStolenPlate && !vehicle.isNoPlate" class="label-hint">(Required)</span></label>
                            <input [required]="!vehicle.isStolenPlate && !vehicle.isNoPlate" #vehicleYearControl="ngModel" type="text" 
                            mask="{{Constants.Mask.YEAR_MASK}}" pattern="^(19|[2-9][0-9])\d{2}$" class="form-control" id="vehicleYear" name="vehicleYear"
                            placeholder="Year" [(ngModel)]="vehicle.year" [readonly]="isVehicleReadOnly()"
                            [ngClass]="{'is-invalid':(vehicleYearControl.touched || isSubmitClicked) && vehicleYearControl.invalid}"> 
                            <div class="invalid-feedback">
                                <div>Year is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="vehicleColour">Colour <span class="label-hint">(Required)</span></label>
                            <input required #vehicleColourControl="ngModel" maxlength="20" type="text" class="form-control" id="vehicleColour" 
                            name="vehicleColour" placeholder="Colour" [readonly]="isVehicleReadOnly()"
                            [(ngModel)]="vehicle.colour" (ngModelChange)="vehicle.colour=this.moddedTitleCasePipe.transform($event)"
                            [ngClass]="{'is-invalid':(vehicleColourControl.touched || isSubmitClicked) && vehicleColourControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Color is required</div>
                            </div>
                        </div>
                        <div class="col-lg-4" *ngIf="!isVehicleReadOnly()">
                            <label for="plateExpiryDate">Plate Expiry Date</label>
                            <input maxlength="10" #plateExpiryDateControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig"
                            class="form-control" id="plateExpiryDate" name="plateExpiryDate" placeholder="yyyy/mm/dd" [(ngModel)]="vehicle.licencePlateExpiryDate" 
                            [ngClass]="{'is-invalid':(plateExpiryDateControl.touched || isSubmitClicked) && plateExpiryDateControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Invalid Plate Expiry Date</div>
                            </div>
                        </div>
                        <div class="col-lg-4" *ngIf="isVehicleReadOnly()">
                            <label for="plateExpiryDate">Plate Expiry Date</label>
                            <input readonly required type="text" class="form-control" id="plateExpiryDate" name="plateExpiryDate" [value]="vehicle.licencePlateExpiryDate | date:'yyyy/MM/dd'">
                        </div>
                        <div class="col-lg-4">
                            <label for="clientType">Client Type <span *ngIf="!vehicle.isStolenPlate && !vehicle.isNoPlate" class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="!vehicle.isStolenPlate && !vehicle.isNoPlate" #clientTypeControl="ngModel" 
                            class="form-control" id="clientType" name="clientType" [(ngModel)]="vehicle.clientTypeId" [disabled]="isVehicleReadOnly()"
                            [ngClass]="{'is-invalid':(clientTypeControl.touched || isSubmitClicked) && clientTypeControl.errors?.defaultSelected}">
                                <option [ngValue]="null">Select a Client Type</option>
                                <option *ngFor="let clientType of clientTypes" [ngValue]="clientType.id">{{clientType.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Client Type is required</div>
                            </div>
                        </div>
                    </div>
                </ng-template> 
            </div>
        </div>
        
        <div class="card mt-4" *ngIf="(recipient.recipientTypeId==RecipientTypes.Driver && (stopInformation.isIRSSelected || stopInformation.isSDPSelected)) || recipient.recipientTypeId==RecipientTypes.RegisteredOwner">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 col-sm-6 mb-0">
                        Registered Owner Information
                    </div>
                    <div class="col-12 col-sm-6 mb-0" *ngIf="vehicle.isStolenPlate && vehicle.isVinNotAvailable">
                        <div class="form-check float-right">
                            <input class="form-check-input mb-0" type="checkbox" value="" id="noRegisteredOwner" name="noRegisteredOwner"
                            [(ngModel)]="vehicle.isNoRegisteredOwner" (ngModelChange)="onNoRegisteredOwnerChecked($event)">
                            <label class="form-check-label mb-0" for="noRegisteredOwner">
                                Unable to determine Registered Owner
                            </label>
                        </div>
                    </div>
                </div>    
            </div>
            <div class="card-body" *ngIf="!vehicle.isNoRegisteredOwner">
                <div class="form-group" *ngIf="recipient.recipientTypeId == RecipientTypes.Driver">
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Same as Driver? <span class="label-hint">(Required)</span></label>
                            <div >
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input required type="radio" class="custom-control-input" id="sameAsDriverYes" name="sameAsDriver" #sameAsDriverYesControl="ngModel" [value]="true" [(ngModel)]="registeredOwner.isSameAsDriver" (change)="onSameAsDriverChange()" [ngClass]="{'is-invalid': registeredOwner.isSameAsDriver==null && (sameAsDriverYesControl.touched || sameAsDriverNoControl.touched || isSubmitClicked)}">
                                    <label class="custom-control-label" for="sameAsDriverYes">Yes</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input required type="radio" class="custom-control-input" id="sameAsDriverNo" name="sameAsDriver" #sameAsDriverNoControl="ngModel" [value]="false" [(ngModel)]="registeredOwner.isSameAsDriver" (change)="onSameAsDriverChange()" [ngClass]="{'is-invalid': registeredOwner.isSameAsDriver==null && (sameAsDriverYesControl.touched || sameAsDriverNoControl.touched || isSubmitClicked)}">
                                    <label class="custom-control-label" for="sameAsDriverNo">No</label>
                                </div>
                                <input required type="radio" style="display:none" id="sameAsDriverHidden" name="sameAsDriver" #sameAsDriverHiddenControl="ngModel" [value]="null" [(ngModel)]="registeredOwner.isSameAsDriver" [ngClass]="{'is-invalid': registeredOwner.isSameAsDriver==null && (sameAsDriverYesControl.touched || sameAsDriverNoControl.touched || isSubmitClicked)}">
                                <div class="invalid-feedback">
                                    <div>Selection is required</div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </div>

                <div class="row mb-4" *ngIf="isRegisteredOwnerReadOnly()">
                    <div class="col-12">
                        <label>All registered owner information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>
                    </div>
                </div>

                <ng-container *ngIf="vehicle.clientTypeId != ClientTypes.Organization">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-lg-6 d-md-flex align-items-md-center">
                                <div class="section-header">Licence Number or M.V.I.D.</div>
                                <span *ngIf="(recipient.recipientTypeId != RecipientTypes.RegisteredOwner || vehicle.licensedProvinceId == 1) 
                                && vehicle.clientTypeId != 1 
                                && vehicle.clientTypeId != 2 
                                && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered 
                                && !(registeredOwnerContact.provinceId!=1 && vehicle.clientTypeId==3)
                                && !(registeredOwner.isSameAsDriver == true 
                                && (recipient.recipientIdentification.licencePresentedClassificationId == 3 || recipient.recipientIdentification.driversLicenceClassificationId == 3))" class="section-hint">(One Required)</span>                   
                            </div>
                        </div>            
                        <div class="row">
                            <div class="col-lg-6">
                                <label for="driversLicenceNumber" class="section-label">Driver's Licence Number</label>
                                <input [appAlbertaLicence]="registeredOwnerContact.provinceId == 1" [appAlbertaLicenceValidator]="registeredOwnerContact.provinceId == 1"  
                                [readonly]="isRegisteredOwnerReadOnly()"
                                [required]= "(recipient.recipientTypeId != RecipientTypes.RegisteredOwner || vehicle.licensedProvinceId == 1) 
                                && vehicle.clientTypeId != 1 
                                && vehicle.clientTypeId != 2 
                                && !registeredOwner.motorVehicleIdentificationNumber 
                                && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered 
                                && !(registeredOwnerContact.provinceId!=1 && vehicle.clientTypeId==3)
                                && !(registeredOwner.isSameAsDriver == true && (recipient.recipientIdentification.licencePresentedClassificationId == 3 || recipient.recipientIdentification.driversLicenceClassificationId == 3))" maxlength="16" #driversLicenceNumberControl="ngModel" type="text" [(ngModel)]="registeredOwner.driverLicenceNumber" class="form-control" id="driverLicenceNumber" name="driverLicenceNumber" placeholder="Driver's Licence Number" [ngClass]="{'is-invalid':(driversLicenceNumberControl.touched || isSubmitClicked) && driversLicenceNumberControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="driversLicenceNumberControl.errors?.required">Driver's Licence Number or M.V.I.D is required</div>
                                    <div *ngIf="driversLicenceNumberControl.errors?.invalidLicenceNumberLength && !driversLicenceNumberControl.errors?.required">Atleast 4 digits are required</div>
                                    <div *ngIf="driversLicenceNumberControl.errors?.invalidLicenceNumberFormat && !driversLicenceNumberControl.errors?.required">Only digits are allowed</div>
                                    <div *ngIf="driversLicenceNumberControl.errors?.invalidCheckDigit && !driversLicenceNumberControl.errors?.required">Invalid Alberta Driver's Licence Number.</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="motorVehicleID" class="section-label">M.V.I.D</label>
                                <input [appMVIDNumber]="registeredOwnerContact.provinceId == 1" [appMVIDNumberValidator]="registeredOwnerContact.provinceId == 1" 
                                [readonly]="isRegisteredOwnerReadOnly()"
                                [required]= "(recipient.recipientTypeId != RecipientTypes.RegisteredOwner || vehicle.licensedProvinceId == 1) 
                                && vehicle.clientTypeId != 1 
                                && vehicle.clientTypeId != 2 
                                && !registeredOwner.driverLicenceNumber 
                                && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered 
                                && !(registeredOwnerContact.provinceId!=1 && vehicle.clientTypeId==3)
                                && !(registeredOwner.isSameAsDriver == true && (recipient.recipientIdentification.licencePresentedClassificationId == 3 || recipient.recipientIdentification.driversLicenceClassificationId == 3))" maxlength="10" #motorVehicleIDControl="ngModel" type="text" [(ngModel)]="registeredOwner.motorVehicleIdentificationNumber" class="form-control" id="motorVehicleID" name="motorVehicleID" placeholder="M.V.I.D Number" [ngClass]="{'is-invalid':(motorVehicleIDControl.touched || isSubmitClicked) && motorVehicleIDControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="motorVehicleIDControl.errors?.required">Driver's Licence Number or M.V.I.D is required</div>
                                    <div *ngIf="motorVehicleIDControl.errors?.invalidMvidNumberLength && !motorVehicleIDControl.errors?.required">M.V.I.D Number must be 10 digits long</div>
                                    <div *ngIf="motorVehicleIDControl.errors?.invalidMvidNumberFormat && !motorVehicleIDControl.errors?.required">Only digits are allowed</div>
                                    <div *ngIf="motorVehicleIDControl.errors?.invalidCheckDigit && !motorVehicleIDControl.errors?.required">Invalid M.V.I.D Number.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="surname">Surname <span *ngIf="vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered" class="label-hint">(Required)</span></label>
                            <input [required]="vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered" [readonly]="isRegisteredOwnerReadOnly()"
                            #surnameControl="ngModel" maxlength="50" type="text" class="form-control" id="surname" name="surname" placeholder="Surname" 
                            [(ngModel)]="registeredOwner.lastName" (ngModelChange)="registeredOwner.lastName=this.moddedTitleCasePipe.transform($event)"
                            [ngClass]="{'is-invalid':(surnameControl.touched || isSubmitClicked) && surnameControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Surname is required</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label for="firstName">First Name <span *ngIf="vehicle.clientTypeId != 1 && vehicle.clientTypeId != 2 && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered" class="label-hint">(Required)</span></label>
                            <input [required]="vehicle.clientTypeId != 1 && vehicle.clientTypeId != 2 && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered" #firstNameControl="ngModel" maxlength="50" type="text" class="form-control" id="firstName" name="firstName" placeholder="First Name" 
                            [(ngModel)]="registeredOwner.firstName" (ngModelChange)="registeredOwner.firstName=this.moddedTitleCasePipe.transform($event)" [readonly]="isRegisteredOwnerReadOnly()"
                            [ngClass]="{'is-invalid':(firstNameControl.touched || isSubmitClicked) && firstNameControl.invalid}">
                            <div class="invalid-feedback">
                                <div>First Name is required</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label for="middleName">Middle Name</label>
                            <input type="text" class="form-control" id="middleName" maxlength="50" name="middleName" placeholder="Middle Name" [readonly]="isRegisteredOwnerReadOnly()"
                            [(ngModel)]="registeredOwner.otherName" (ngModelChange)="registeredOwner.otherName=this.moddedTitleCasePipe.transform($event)">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="gender">Gender <span *ngIf="vehicle.clientTypeId != 1 && vehicle.clientTypeId != 2 && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered" class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="vehicle.clientTypeId != 1 && vehicle.clientTypeId != 2 && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered" 
                            #genderControl="ngModel" class="form-control" id="gender" name="gender" [(ngModel)]="registeredOwner.genderId" [disabled]="isRegisteredOwnerReadOnly()"
                            [ngClass]="{'is-invalid':(genderControl.touched || isSubmitClicked) && genderControl.errors?.defaultSelected}">
                                <option value="null">Select a Gender</option>
                                <option *ngFor="let genderType of genderTypes" [ngValue]="genderType.id">{{genderType.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Gender is required</div>
                            </div>
                        </div>
                        <div class="col-lg-4" *ngIf="!isRegisteredOwnerReadOnly()">
                            <label for="dateOfBirth">Date of Birth <span *ngIf="vehicle.clientTypeId != 1 && vehicle.clientTypeId != 2 && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered && registeredOwnerContact.provinceId == 1" class="label-hint">(Required)</span></label>
                            <input [required]="vehicle.clientTypeId != 1 && vehicle.clientTypeId != 2 && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered && registeredOwnerContact.provinceId == 1" 
                            isBirthDate  maxlength="10" #dateOfBirthControl="ngModel" type="text" bsDatepicker [bsConfig]="dateOfBirthPickerConfig" 
                            class="form-control" id="dateOfBirth" name="dateOfBirth" placeholder="yyyy/mm/dd" [(ngModel)]="registeredOwner.dateOfBirth" 
                            [ngClass]="{'is-invalid':(dateOfBirthControl.touched || isSubmitClicked) && dateOfBirthControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="dateOfBirthControl.errors?.required">Date of Birth is required</div>
                                <div *ngIf="!dateOfBirthControl.errors?.required && dateOfBirthControl.hasError('FutureDate')">Date of Birth can't be a future date</div>
                                <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate') && dateOfBirthControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate')  && !dateOfBirthControl.hasError('InvalidYear')">Invalid Date of Birth</div>
                            </div>
                        </div>
                        <div class="col-lg-4" *ngIf="isRegisteredOwnerReadOnly()">
                            <label for="dateOfBirth">Date of Birth <span *ngIf="vehicle.clientTypeId != 1 && vehicle.clientTypeId != 2 && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered && registeredOwnerContact.provinceId == 1" class="label-hint">(Required)</span></label>
                            <input readonly required type="text" class="form-control" id="dateOfBirth" name="dateOfBirth" [value]="registeredOwner.dateOfBirth | date:'yyyy/MM/dd'">
                        </div>
                        <div class="col-lg-4">
                            <label for="telephoneNumber">Telephone Number</label>
                            <input type="text" class="form-control" maxlength="15" id="telephoneNumber" name="telephoneNumber" placeholder="+1 (XXX)-XXX-XXXX" 
                            [(ngModel)]="registeredOwnerContact.phoneNumber1" [readonly]="isRegisteredOwnerReadOnly()">
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="vehicle.clientTypeId == ClientTypes.Organization">
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label for="orgMotorVehicleID">M.V.I.D <span *ngIf="vehicle.licensedProvinceId == 1 
                                && !(registeredOwnerContact.provinceId!=1 && vehicle.clientTypeId==2)
                                && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered" class="label-hint">(Required)</span></label>
                            <input [appMVIDNumber]="registeredOwnerContact.provinceId == 1" [appMVIDNumberValidator]="registeredOwnerContact.provinceId == 1" 
                            [readonly]="isRegisteredOwnerReadOnly()"
                            [required]="vehicle.licensedProvinceId == 1 
                            && !(registeredOwnerContact.provinceId!=1 && vehicle.clientTypeId==2)
                            && vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered" maxlength="10" #orgMotorVehicleIDControl="ngModel" 
                            type="text" [(ngModel)]="registeredOwner.motorVehicleIdentificationNumber" class="form-control" id="orgMotorVehicleID" name="orgMotorVehicleID" 
                            placeholder="M.V.I.D Number" [ngClass]="{'is-invalid':(orgMotorVehicleIDControl.touched || isSubmitClicked) && orgMotorVehicleIDControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="orgMotorVehicleIDControl.errors?.required">M.V.I.D is required</div>
                                <div *ngIf="orgMotorVehicleIDControl.errors?.invalidMvidNumberLength && !orgMotorVehicleIDControl.errors?.required">M.V.I.D Number must be 10 digits long</div>
                                <div *ngIf="orgMotorVehicleIDControl.errors?.invalidMvidNumberFormat && !orgMotorVehicleIDControl.errors?.required">Only digits are allowed</div>
                                <div *ngIf="orgMotorVehicleIDControl.errors?.invalidCheckDigit && !orgMotorVehicleIDControl.errors?.required">Invalid M.V.I.D Number.</div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <label for="orgName">Organization <span *ngIf="vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered" class="label-hint">(Required)</span></label>
                            <input [required]="vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered" #orgNameControl="ngModel" maxlength="50" 
                            type="text" class="form-control" id="orgName" name="orgName" placeholder="Enter company or organization name" [readonly]="isRegisteredOwnerReadOnly()"
                            [(ngModel)]="registeredOwner.lastName" (ngModelChange)="registeredOwner.lastName=this.moddedTitleCasePipe.transform($event)"
                            [ngClass]="{'is-invalid':(orgNameControl.touched || isSubmitClicked) && orgNameControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Organization is required</div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="form-group row">
                    <div class="col-lg-6">
                        <label for="address1">Address 1</label>
                        <input type="text" maxlength="44" class="form-control" id="address1" name="address1" [readonly]="isRegisteredOwnerReadOnly()"
                        placeholder="Ex. 123 Street or PO Box, Range Road or Rural Route" [(ngModel)]="registeredOwnerContact.addressLine1">
                    </div>
                    <div class="col-lg-6">
                        <label for="address2">Address 2</label>
                        <input type="text" maxlength="44" class="form-control" id="address2" name="address2" [readonly]="isRegisteredOwnerReadOnly()"
                        placeholder="Ex. Unit Number" [(ngModel)]="registeredOwnerContact.addressLine2">
                    </div>
                </div>
                
                <div class="form-group">
                    <app-country-province (onChangeEvent)="refreshRegisteredOwnerCountryProvince($event)" [countryProvince]="registeredOwnerCountryProvince"></app-country-province>
                </div>

                <div class="form-group row">
                    <div class="col-lg-4">
                        <label for="city">City/Town/Village <span *ngIf="vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered && !(registeredOwner.isSameAsDriver == true && recipient.recipientContact.isNoFixedAddress == true)" class="label-hint">(Required)</span></label>
                        <input [required]="vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered && !(registeredOwner.isSameAsDriver == true && recipient.recipientContact.isNoFixedAddress == true)" #cityControl="ngModel" maxlength="30" type="text" class="form-control" id="city" name="city" placeholder="City/Town/Village" 
                        [(ngModel)]="registeredOwnerContact.city" (ngModelChange)="registeredOwnerContact.city=this.moddedTitleCasePipe.transform($event)"
                        [ngClass]="{'is-invalid':(cityControl.touched || isSubmitClicked) && cityControl.invalid}" [readonly]="isRegisteredOwnerReadOnly()">
                        <div class="invalid-feedback">
                            <div>City/Town/Village is required</div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label for="postalCode">Postal Code <span *ngIf="vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered && !(registeredOwner.isSameAsDriver == true && recipient.recipientContact.isNoFixedAddress == true)" class="label-hint">(Required)</span></label>
                        <input [required]="vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered && !(registeredOwner.isSameAsDriver == true && recipient.recipientContact.isNoFixedAddress == true)" 
                        #postalCodeControl="ngModel" maxlength="10" type="text" class="form-control text-uppercase" id="postalCode" name="postalCode" [readonly]="isRegisteredOwnerReadOnly()"
                        [(ngModel)]="registeredOwnerContact.postalCode" [ngClass]="{'is-invalid':(postalCodeControl.touched || isSubmitClicked) && postalCodeControl.invalid}">
                        <div class="invalid-feedback">
                            <div>Postal Code is required</div>
                        </div>
                    </div>
                    <div class="col-lg-4" *ngIf="vehicle.clientTypeId == ClientTypes.Organization">
                        <label for="orgTelephoneNumber">Telephone Number</label>
                        <input type="text" class="form-control" maxlength="15" id="orgTelephoneNumber" name="orgTelephoneNumber" placeholder="+1 (XXX)-XXX-XXXX" 
                        [(ngModel)]="registeredOwnerContact.phoneNumber1" [readonly]="isRegisteredOwnerReadOnly()">
                    </div>
                </div>
            </div>        
        </div>

        <div class="card mt-4" *ngIf="recipient.recipientTypeId==RecipientTypes.Passenger && (stopInformation.isIRSSelected || stopInformation.isSDPSelected)">
            <div class="card-header">
                Driver Information
            </div>

            <div class="card-body">
                <div class="form-group row">
                    <div class="col-lg-4">
                        <label for="surname">Surname <span class="label-hint">(Required)</span></label>
                        <input required #surnameControl="ngModel" maxlength="50" type="text" class="form-control" id="surname" name="surname" placeholder="Surname" 
                        [(ngModel)]="vehicleDriver.lastName" (ngModelChange)="vehicleDriver.lastName=this.moddedTitleCasePipe.transform($event)"
                        [ngClass]="{'is-invalid':(surnameControl.touched || isSubmitClicked) && surnameControl.invalid}">
                        <div class="invalid-feedback">
                            <div>Surname is required</div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label for="firstName">First Name <span class="label-hint">(Required)</span></label>
                        <input required #firstNameControl="ngModel" maxlength="50" type="text" class="form-control" id="firstName" name="firstName" placeholder="First Name" 
                        [(ngModel)]="vehicleDriver.firstName" (ngModelChange)="vehicleDriver.firstName=this.moddedTitleCasePipe.transform($event)"
                        [ngClass]="{'is-invalid':(firstNameControl.touched || isSubmitClicked) && firstNameControl.invalid}">
                        <div class="invalid-feedback">
                            <div>First Name is required</div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label for="middleName">Middle Name</label>
                        <input type="text" class="form-control" id="middleName" maxlength="50" name="middleName" placeholder="Middle Name" 
                        [(ngModel)]="vehicleDriver.otherName" (ngModelChange)="vehicleDriver.otherName=this.moddedTitleCasePipe.transform($event)">
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-6 d-md-flex align-items-md-center">
                            <div class="section-header">Licence Number or M.V.I.D.</div>
                        </div>
                    </div>            
                    <div class="row">
                        <div class="col-lg-6">
                            <label for="driversLicenceNumber" class="section-label">Driver's Licence Number</label>
                            <input maxlength="16" #driversLicenceNumberControl="ngModel" type="text" [(ngModel)]="vehicleDriver.driversLicenceNumber" class="form-control" id="driverLicenceNumber" name="driverLicenceNumber" placeholder="Driver's Licence Number">
                        </div>
                        <div class="col-lg-6">
                            <label for="motorVehicleID" class="section-label">M.V.I.D</label>
                            <input maxlength="10" #motorVehicleIDControl="ngModel" type="text" [(ngModel)]="vehicleDriver.motorVehicleIdentificationNumber" class="form-control" id="motorVehicleID" name="motorVehicleID" placeholder="M.V.I.D Number">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="row pt-3">
                <div class="col-12 col-sm-6">                
                    <a class="btn button-secondary btn-block" [routerLink]="['/contravention/submission/contravention-selection']">Back to Contravention Selection</a>
                </div>
                <div class="col-12 col-sm-6 text-right">                
                    <button class="btn button-primary btn-block" (click)="onSubmitClick(vehicleInfoForm.valid)"> {{((this.stopInformation.isIRSSelected && !isCommercialContravention()) || this.stopInformation.isSDPSelected) ?'Vehicle Seizure Details':'Proceed to Print'}}</button>   
                </div>
            </div>
        </div>        
    </div>
</form>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Searching for licence plate information in MOVES. Please wait... </p></ngx-spinner>      
