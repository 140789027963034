import { NapViewModel } from './nap-view.model';

export class NoticeCancellationRequestMapping {
    public noticeCancellationRequestMappingId: number = 0;
    public noticeCancellationRequestId: number = 0;
    public contraventionId: number;
    public contravention: NapViewModel;
    public vehicleSeizureId: number;
    public vehicleSeizure: NapViewModel;
    public recordNumber: string;

    // Non-DB field:
    public noticeCancellationRequestNumber: string;

    constructor(init?: Partial<NoticeCancellationRequestMapping>) {
        
        Object.assign(this, init);
    }
}
