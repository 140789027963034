import * as moment from "moment";

export class DateUtil {

  static addDays(date: Date, days: number) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }

  static addBusinessDays(date: Date, days: number, holidayList: any) {
    let businessDays = 0;
    let copy = new Date(Number(date))

    while (businessDays < days) {
      copy = this.addDays(copy, 1);
      var holiday: boolean = this.isStatHoliday(copy, holidayList);
      if ((copy.getDay() % 6) && !holiday)
        businessDays++;
    }
    return copy
  }

  static today(): Date {
    let date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static getNextBusinessDay(date: Date, holidayList: any): Date {
    let next = new Date(+date);

    // If weekend move to next week day
    do {
      next.setDate(next.getDate() + 1);
    } while (!(next.getDay() % 6))

    // If stat holiday move to next day
    if (this.isStatHoliday(next, holidayList)) {
      next = this.getNextBusinessDay(next, holidayList);
    }

    return next;
  }

  static isStatHoliday(date: Date, holidayList: any): boolean {
    let dateToCompare = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return holidayList?.find(h => +new Date(h.holidayDate) === +dateToCompare) != null;
  }

  static isPastDate(date: Date, daysToCheck: number = 0) {
    if (date != null) {
      const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      const chkDate = new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate());
      if ((chkDate < currentDate && this.getDifferenceInDays(chkDate, currentDate) >= daysToCheck)) {
        return true;
      }
    }
    return false;
  }

  static getDifferenceInDays(date1: any, date2: any) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  static getCurrentMSTDate() : Date {
    return moment().local().toDate();
  }

  static isYouth(birthDate: Date, asOfDate: Date = null) : boolean {
    if (birthDate == null)
      return false;
    
    //Check age with current date if asOfDate is not available
    if (asOfDate == null)
    {
      var currentDate = this.getCurrentMSTDate();
      asOfDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());  
    }
    
    //Get adult age date to check with current date
    const adultAgeDate = new Date(birthDate.getFullYear() + 18, birthDate.getMonth(), birthDate.getDate());  

    return asOfDate < adultAgeDate;  
  }
}