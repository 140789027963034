<div class="page-not-found">
    <div class="col-12">
        
        <h2>Sorry, the page could not be found</h2>

        <div class="page-not-found-message">The URL you entered in your web browser might be wrong or the page may have been moved.</div>
        <div>
            
            <div class="border-0 d-flex flex-row-reverse">
                <button type="button" class="btn button-primary" 
                    [routerLink]="['/']">Back to Dashboard</button>
            </div>
        </div>
    </div>    
</div>
