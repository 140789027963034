import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { LookupService } from '@apis/shared/services/lookup.service';
import { RolePermission } from '@apis/models/role-permission.model';
import { ConnectivityService } from '../services/connectivity.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class IntakeAuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloakService: KeycloakService,
    protected readonly lookupService: LookupService,
    protected readonly connectivityService: ConnectivityService,
    protected readonly localStorageService: LocalStorageService, 
  ) {
    super(router, keycloakService);
  }


  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloakService.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (route.data.permission == undefined && route.data.roles == undefined) {
      return true;
    }
    
    // Get Pemission From Local Storage if offline, otherwise from DB
    var requiredRoles:RolePermission[];
    let isOnline: boolean;

    await this.connectivityService.getOnlineStatusAsync().then( response => {
      isOnline = response;
    });

    requiredRoles = isOnline
      ? await this.lookupService.getUserRolePermission().toPromise()
      : this.localStorageService.getRolePermissions();   

    // Check user has permission to navigate selected page or default landing page.
    if(requiredRoles?.find(x=>x.permissionName == route.data.permission) == null)
    {
      // redirect account activation pending URL if no role is defined otherwise redirect to unauthorized page
      if (requiredRoles?.length <= 0)
        this.router.navigateByUrl('/account-activation-pending');
      else
        this.router.navigateByUrl('/unauthorized');  

      return false;
    } 
    else 
    {
      return true;
    }
  }
}
