export class OperatorInformation {
    public operatorNumber: string;
    public class: string;
    public licenseStatus: string;
    public expiry: Date = new Date();
    public conditionCodes: any; 
    public ccTexts: any;
    public restrictTexts: any;
    public mvid: string;
    public type: string;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public gender: string;
    public dob: Date = new Date();
    public issuingCountry: string;
    public issuingProvince: string;
    public gdlDriver: boolean = false;
    public irsWarn: number = 0;
    public irsFail: number = 0;
    public irsCommercial: number = 0;
    public addressLine1: string;
    public addressLine2: string;
    public city: string;
    public province: string;
    public postalCode: string;
    public country: string;
    public businessPhone: string;
    public homePhone: string;
    public height: string;
    public weight: string;
    public hairColor: string;
    public eyeColor: string;
    public errorMessage: string;

    private hairColourMap:Map<string, string>;
    private eyeColourMap:Map<string, string>;
    private jurisdictionMap: Map<string, string[]>;

    constructor(init?: Partial<OperatorInformation>) {
        
        Object.assign(this, init);
        this.errorMessage = "";
        this.lastName = "";

        this.initialiseMaps();
    }

    InitilizebyAAMVABarcode(barcode: string){

        if(barcode && barcode.length > 0 && barcode.includes("\n"))
        {
            var barcodeLines = barcode.split("\n", 30);

            var issuerIdHeader = barcodeLines[0].length>0 ? barcodeLines[0] : barcodeLines[1];
            if(issuerIdHeader.length > 0)
            {    
                let issuerId = issuerIdHeader.indexOf("DL") > 20 || issuerIdHeader.indexOf("DL") < 0 ? issuerIdHeader.substring(issuerIdHeader.indexOf("ID")-12, issuerIdHeader.indexOf("ID")-6) : issuerIdHeader.substring(issuerIdHeader.indexOf("DL")-12, issuerIdHeader.indexOf("DL")-6);
                if(issuerId && issuerId.length > 0)
                {
                    this.issuingCountry = (this.jurisdictionMap.has(issuerId)) ? this.jurisdictionMap.get(issuerId)[1] : "";
                    this.issuingProvince = (this.jurisdictionMap.has(issuerId)) ? this.jurisdictionMap.get(issuerId)[0] : "";
                }
            }

            this.operatorNumber = (barcodeLines.findIndex(i=>i.startsWith("DCF")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DCF"))].substring(3).trim() : "";
              
            this.mvid = (barcodeLines.findIndex(i=>i.startsWith("DAQ")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAQ"))].substring(3).trim() : "";
            this.lastName = (barcodeLines.findIndex(i=>i.startsWith("DCS")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DCS"))].substring(3).trim() : "";
            this.firstName = (barcodeLines.findIndex(i=>i.startsWith("DAC")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAC"))].substring(3).trim() : "";
            this.middleName = (barcodeLines.findIndex(i=>i.startsWith("DAD")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAD"))].substring(3).trim() : "";
            if(this.middleName.toLowerCase() == "none" || this.middleName.toLowerCase() == "unavl")
                this.middleName = "";
            var expiryData = (barcodeLines.findIndex(i=>i.startsWith("DBA")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DBA"))].substring(3).trim() : "";         
            if(expiryData.length > 0){
                const pattern = /(\d{4})(\d{2})(\d{2})/;
                const [year, month, day] = expiryData.match(pattern).slice(1)
          
                var expiryDate = [year, month, day].join('/')
                this.expiry = new Date(expiryDate);
              }

            var dateOfBirthData = (barcodeLines.findIndex(i=>i.startsWith("DBB")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DBB"))].substring(3).trim() : "";
            if(dateOfBirthData.length > 0){
                const pattern = /(\d{4})(\d{2})(\d{2})/;
                const [year, month, day] = dateOfBirthData.match(pattern).slice(1)
          
                var dateOfBirthDate = [year, month, day].join('/')
                this.dob = new Date(dateOfBirthDate);
              }

            
            this.gender = (barcodeLines.findIndex(i=>i.startsWith("DBC")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DBC"))].substring(3).trim() : "";

            if(this.gender === "1"){
                this.gender = "M";
            }
            else if(this.gender === "2"){
                this.gender = "F";
            }
            else {
                this.gender = "X";
            }

            var licenceclassheader = (barcodeLines.findIndex(i=>i.includes("DLDCA")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.includes("DLDCA"))] : "";            
            if(licenceclassheader.length > 0)
            {    
                this.class = licenceclassheader.substring(licenceclassheader.indexOf("DLDCA")+5, licenceclassheader.indexOf("DLDCA") + 6);
            }

            
            var eyecolourCode = (barcodeLines.findIndex(i=>i.startsWith("DAY")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAY"))].substring(3).trim() : "";
            this.eyeColor = (this.eyeColourMap.has(eyecolourCode)) ? this.eyeColourMap.get(eyecolourCode) : eyecolourCode;

            var haircolourCode = (barcodeLines.findIndex(i=>i.startsWith("DAZ")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAZ"))].substring(3).trim() : "";
            this.hairColor = (this.hairColourMap.has(haircolourCode)) ? this.hairColourMap.get(haircolourCode) : haircolourCode;

            this.weight = (barcodeLines.findIndex(i=>i.startsWith("DAX")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAX"))].substring(3, 6).trim() : "";
            this.weight = this.weight.replace(/^0+/, '');

            this.height = (barcodeLines.findIndex(i=>i.startsWith("DAU")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAU"))].substring(3).trim() : "";
            this.addressLine1 = (barcodeLines.findIndex(i=>i.startsWith("DAG")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAG"))].substring(3).trim() : "";
            this.addressLine2 = (barcodeLines.findIndex(i=>i.startsWith("DAR")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAR"))].substring(3).trim() : "";
            this.country = (barcodeLines.findIndex(i=>i.startsWith("DCG")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DCG"))].substring(3).trim() : "";
            this.province = (barcodeLines.findIndex(i=>i.startsWith("DAJ")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAJ"))].substring(3).trim() : "";
            this.city = (barcodeLines.findIndex(i=>i.startsWith("DAI")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAI"))].substring(3).trim() : "";
            this.postalCode = (barcodeLines.findIndex(i=>i.startsWith("DAK")) !== -1) ? barcodeLines[barcodeLines.findIndex(i=>i.startsWith("DAK"))].substring(3).trim() : "";
        }
    }
    
    initialiseMaps()
    {
        this.hairColourMap = new Map<string, string>();
        this.eyeColourMap = new Map<string, string>();
        
        this.jurisdictionMap = new Map<string, string[]>();
        //Issue Identification Numbers IIN
        //provience_type and country_type mapping. for ex. USA: code US, Canada: code CA; Alberta: AB, etc.
        this.jurisdictionMap.set("604426", ["PE", "CA"]);
        this.jurisdictionMap.set("604428", ["QC", "CA"]);
        this.jurisdictionMap.set("604429", ["YT", "CA"]);
        this.jurisdictionMap.set("604432", ["AB", "CA"]);
        this.jurisdictionMap.set("604433", ["NU", "CA"]);
        this.jurisdictionMap.set("604434", ["NT", "CA"]);
        this.jurisdictionMap.set("636000", ["VA", "US"]);
        this.jurisdictionMap.set("636001", ["NY", "US"]);
        this.jurisdictionMap.set("636002", ["MA", "US"]);
        this.jurisdictionMap.set("636003", ["MD", "US"]);
        this.jurisdictionMap.set("636004", ["NC", "US"]);
        this.jurisdictionMap.set("636005", ["SC", "US"]);
        this.jurisdictionMap.set("636006", ["CT", "US"]);
        this.jurisdictionMap.set("636007", ["LA", "US"]);
        this.jurisdictionMap.set("636008", ["MT", "US"]);
        this.jurisdictionMap.set("636009", ["NM", "US"]);
        this.jurisdictionMap.set("636010", ["FL", "US"]);
        this.jurisdictionMap.set("636011", ["DE", "US"]);
        this.jurisdictionMap.set("636012", ["ON", "US"]);
        this.jurisdictionMap.set("636013", ["NS", "CA"]);
        this.jurisdictionMap.set("636014", ["CA", "US"]);
        this.jurisdictionMap.set("636015", ["TX", "US"]);
        this.jurisdictionMap.set("636016", ["NL", "CA"]);
        this.jurisdictionMap.set("636017", ["NB", "CA"]);
        this.jurisdictionMap.set("636018", ["IA", "US"]);
        this.jurisdictionMap.set("636020", ["CO", "US"]);
        this.jurisdictionMap.set("636021", ["AR", "US"]);
        this.jurisdictionMap.set("636022", ["KS", "US"]);
        this.jurisdictionMap.set("636023", ["OH", "US"]);
        this.jurisdictionMap.set("636025", ["PA", "US"]);
        this.jurisdictionMap.set("636026", ["AZ", "US"]);
        this.jurisdictionMap.set("636028", ["BC", "CA"]);
        this.jurisdictionMap.set("636029", ["OR", "US"]);
        this.jurisdictionMap.set("636030", ["MO", "US"]);
        this.jurisdictionMap.set("636031", ["WI", "US"]);
        this.jurisdictionMap.set("636032", ["MI", "US"]);
        this.jurisdictionMap.set("636033", ["AL", "US"]);
        this.jurisdictionMap.set("636034", ["ND", "US"]);
        this.jurisdictionMap.set("636035", ["IL", "US"]);
        this.jurisdictionMap.set("636036", ["NJ", "US"]);
        this.jurisdictionMap.set("636037", ["IN", "US"]);
        this.jurisdictionMap.set("636038", ["MN", "US"]);
        this.jurisdictionMap.set("636039", ["NH", "US"]);
        this.jurisdictionMap.set("636040", ["UT", "US"]);
        this.jurisdictionMap.set("636041", ["ME", "US"]);
        this.jurisdictionMap.set("636042", ["SD", "US"]);
        this.jurisdictionMap.set("636044", ["SK", "CA"]);
        this.jurisdictionMap.set("636045", ["WA", "US"]);
        this.jurisdictionMap.set("636046", ["KY", "US"]);
        this.jurisdictionMap.set("636047", ["HI", "US"]);
        this.jurisdictionMap.set("636048", ["MB", "CA"]);
        this.jurisdictionMap.set("636049", ["NV", "US"]);
        this.jurisdictionMap.set("636050", ["ID", "US"]);
        this.jurisdictionMap.set("636051", ["MS", "US"]);
        this.jurisdictionMap.set("636052", ["RI", "US"]);
        this.jurisdictionMap.set("636053", ["TN", "US"]);
        this.jurisdictionMap.set("636054", ["NE", "US"]);
        this.jurisdictionMap.set("636055", ["GA", "US"]);
        this.jurisdictionMap.set("636058", ["OK", "US"]);
        this.jurisdictionMap.set("636059", ["AK", "US"]);
        this.jurisdictionMap.set("636060", ["WY", "US"]);
        this.jurisdictionMap.set("636061", ["WV", "US"]);

        

        //these maps to be moved to database and ui controls to be mapped to the list after reviewing the data standars used by various jurisdictions
        
        this.hairColourMap.set("BLK", "BLACK");
        this.hairColourMap.set("BLN", "BLOND OR STRAWBERRY");
        this.hairColourMap.set("BRO", "BROWN");
        this.hairColourMap.set("DBR", "DARK BROWN");
        this.hairColourMap.set("GRY", "GRAY OR PARTIALLY GRAY");
        this.hairColourMap.set("LBR", "LIGHT BROWN");
        this.hairColourMap.set("RED", "RED OR AUBURN");
        this.hairColourMap.set("SDY", "SANDY");
        this.hairColourMap.set("WHI", "WHITE");
        this.hairColourMap.set("XXX", "UNKNOWN OR COMPLETELY BALD");

        this.eyeColourMap.set("BLK", "BLACK");
        this.eyeColourMap.set("BLU", "BLUE");
        this.eyeColourMap.set("BRO", "BROWN");
        this.eyeColourMap.set("GRN", "GREEN");
        this.eyeColourMap.set("GRY", "GRAY");
        this.eyeColourMap.set("HAZ", "HAZEL");
        this.eyeColourMap.set("MAR", "MAROON");
        this.eyeColourMap.set("MUL", "MULTICOLORED");
        this.eyeColourMap.set("PNK", "PINK");
        this.eyeColourMap.set("XXX", "UNKNOWN");
    }
}
