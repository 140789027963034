import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class SpinnerHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    try {
      var isDocumentDownload = request.url.includes("api/v1/document") && request.method == "GET";
      
      if (isDocumentDownload) {
        this.spinner.show("downloadDocumentSpinner");
      }
    }

    catch (error) {
      console.log("Spinner HTTP Interceptor - " + error);
    }

    return next.handle(request).pipe(
      finalize(() => {
        // Hide the spinner when the request completes
        this.spinner.hide("downloadDocumentSpinner");
      }));
  }
}