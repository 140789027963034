import { Component, OnInit, Input } from '@angular/core';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';

@Component({
  selector: 'app-report-section-details',
  templateUrl: './report-section-details.component.html',
  styleUrls: ['./report-section-details.component.scss']
})
export class ReportSectionDetailsComponent implements OnInit {
@Input() sectionTitle: string;
@Input() sectionDetails: SectionDetailsModel[];

  constructor() { }

  ngOnInit(): void {
  }

}
