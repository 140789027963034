<form #ReissueModalForm="ngForm">
    <div class="reissue-modal-overlay">
        <div class="reissue-modal">
            <div class="reissue-modal-header">
                <div class="reissue-modal-title">Re-issue this IRS Notice</div>
                <div class="close-icon" (click)="onCancelClick()">
                    <i class="far fa-times-circle fa-lg"></i>
                </div>
            </div>
            <div class="reissue-modal-body">
                <div class="form-group row">
                    <div class="col-12">
                        <label for="contraventionType">Contravention Type <span class="label-hint">(Required)</span></label>
                        <select [appSelectValidator]="true" #contraventionTypeControl="ngModel" class="form-control" id="contraventionType" name="contraventionType" [(ngModel)]="selectedContraventionType" (ngModelChange)="onContraventionTypeChange($event)" [ngClass]="{'is-invalid':(contraventionTypeControl.touched || isSubmitClicked) && contraventionTypeControl.errors?.defaultSelected}">
                            <option [ngValue]="null">Select a Contravention Type</option>
                            <option [disabled]="contraventionType.isDisabled" *ngFor="let contraventionType of contraventionTypes" [ngValue]="contraventionType">{{contraventionType.formattedName}}</option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Contravention Type is required</div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-12">
                        <label for="contraventionDetailsText">Contravention Details</label>
                        <textarea readonly class="form-control" id="contraventionDetailsText" name="contraventionDetailsText" rows="5" value="{{ contravention.contraventionTypeId == 0  ? '' : selectedContraventionType?.description}}">
                        </textarea>
                    </div>
                </div>	
                
                <div class="form-group row" *ngIf="contravention.contraventionTypeId == 1">
                    <div class="col-sm-12">
                        <label for="contraventionDetails">Contravention Details <span class="label-hint">(Required)</span></label>
                        <select [appSelectValidator]="true" #contraventionDetailsControl="ngModel" class="form-control" id="contraventionDetails" name="contraventionDetails" [(ngModel)]="contravention.contraventionDetails" [ngClass]="{'is-invalid':(contraventionDetailsControl.touched || isSubmitClicked) && contraventionDetailsControl.errors?.defaultSelected}">
                            <option [ngValue]="null">Select the Contravention Details</option>
                            <option *ngFor="let contraventionDetailType of filterContraventionDetailTypes(selectedContraventionType.id)" [value]="contraventionDetailType.id">{{contraventionDetailType.name}}</option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Contravention Details are required</div>
                        </div>
                    </div>
                </div>
                
                <div class="form-group row" *ngIf="selectedContraventionType && (contravention.contraventionTypeId == 9 || contravention.contraventionTypeId == 10 || contravention.contraventionTypeId == 11)">
                    
                    <div class="col-sm-12">
                        <label for="contraventionDetailsMultiselect">Contravention Details <span class="label-hint">(Required)</span></label>
                        <mat-select required #contraventionDetailsMultiControl="ngModel"  class="form-control-multiselect" multiple id="contraventionDetailsMultiselect" name="contraventionDetailsMultiselect" placeholder="Select the Contravention Details" [(ngModel)]="selectedContraventionDetails" (selectionChange)="onContraventionDetailsChange($event.value)" [ngClass]="{'is-invalid':(contraventionDetailsMultiControl.touched || isSubmitClicked) && contraventionDetailsMultiControl.invalid}">
                            <mat-option class="mat-primary" *ngFor="let contraventionDetailType of filterContraventionDetailTypes(selectedContraventionType.id)" [value]="contraventionDetailType.id">{{contraventionDetailType.name}}</mat-option>
                        </mat-select>
                        <div class="invalid-feedback">
                            <div>Contravention Details are required</div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="contraventionFine">Contravention Fine</label>
                        <input readonly type="text" class="form-control" id="contraventionFine" name="contraventionFine" value="{{ contravention.contraventionTypeId == 0 || !selectedContraventionType ? '' : '$' + selectedContraventionType.fineAmount + (selectedContraventionType.fineAmount == 0? '': ' (Plus Victim Fine Surcharge)')}}">
                    </div>
                </div>			

                <div class="row">
                    <div class="col-sm-6">
                        <label for="suspensionDuration">Suspension Duration</label>
                        <input readonly type="text" class="form-control" id="suspensionDuration" name="suspensionDuration" value="{{ contravention.contraventionTypeId == 0 || !selectedContraventionType ? '' : (selectedContraventionType.licenceSuspensionDuration + ' ' + selectedContraventionType.licenceSuspensionDurationType )}}"> 
                    </div>
                    <div class="col-12 col-sm-6">
                        <label for="seizeOrDestroy">Seize or Destroy Licence</label>
                        <input readonly type="text" class="form-control" id="seizeOrDestroy" name="seizeOrDestroy" value="{{ contravention.contraventionTypeId == 0 || !selectedContraventionType || contravention.recipient?.recipientIdentification?.issuingProvinceId != 1 ? '' : (selectedContraventionType.isLicenseDestroyed ? 'Destroy' : ( selectedContraventionType.isLicenseSeized ? 'Seize' : ''))}}">
                    </div>
                </div>
            </div>
            <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row mt-4">  
                <span class="error">{{errorMessage}}</span>
            </div>
            <div class="button-container">
                <div id="btnReissueModalCancel" class="cancel-button" (click)="onCancelClick()">Close</div>
                <button id="btnReissueModalConfirm" [disabled]="isNoticeReissued" class="confirm-button" (click)="onConfirmChangeClick(ReissueModalForm.valid)">Confirm Change</button>
            </div>
        </div>
    </div>
</form>
<div class="confirm-modal-overlay">
    <div class="confirm-modal">
        <div class="reissue-modal-header">
            <div class="reissue-modal-title">{{isNoticeReissued?"Notice Re-issued":"Confirm change in IRS type"}}</div>
            <div *ngIf="!isNoticeReissued" class="close-icon" (click)="showHideConfirmModal(false)">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="reissue-modal-body">
            <ng-container *ngIf="!isNoticeReissued">
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <label>You are about to change this Notice from:</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <input type="text" readonly class="form-control" value="{{originalContraventionType?.formattedName}}">
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <label>To:</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <input type="text" readonly class="form-control" value="{{selectedContraventionType?.formattedName}}">
                        </div>
                    </div>
                </div>
            
                <div class="reissue-alert">
                    <div class="alert-icon">                
                        <i class="fas fa-exclamation-triangle fa-lg"></i>
                    </div>
                    <div class="reissue-alert-text">
                        <span class="bold-text">Are you sure you want to re-issue this Notice?</span>
                    </div>                       
                </div>
                <div class="button-container">
                    <button id="btnGoBack" class="btn btn-cancel" (click)="showHideConfirmModal(false)">Cancel</button>
                    <button id="btnConfirm" class="btn btn-confirm" (click)="onConfirmClick()">Re-issue Notice</button>
                </div>
            </ng-container>
            <ng-container *ngIf="isNoticeReissued">
                <div class="form-group row">
                    <div class="col-12">
                        <label>Print the new Notice and provide to driver. There is no further Roadside Appeal on this Notice.</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-12">
                        <label>Next, submit this contravention to APIS by adding your Contravention Details.</label>
                    </div>
                </div>
                <div class="button-container">
                    <button id="btnPrint" class="btn btn-confirm" (click)="onPrintClick()">Print</button>
                </div>
            </ng-container>
        </div>
    </div>
</div>

