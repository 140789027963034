import { Injectable } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval } from "rxjs";
import { filter } from "rxjs/operators";
import { Constants } from "../helpers/constants";
import * as alertifyjs from 'alertifyjs';

@Injectable()
export class AppUpdateService {
    constructor(public swUpdate: SwUpdate) {
        const appRefreshInterval$ = interval(Constants.Configuration.APP_REFRESH_INTERVAL_HOURS * 60 * 60 * 1000);
        
        if(!swUpdate.isEnabled) {
            console.log('Service worker is not enabled');
            return;
        }
  
        console.log('Service worker is enabled. Subscribing for updates');
    
        this.swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(() => {
            //Reload the page as new updates are available
            alertifyjs.alert("System Update","A new application version is available. System reload is required.")
                        .set({'label':'Reload','Modal':true,'transition':'zoom','movable':false,'closable':false,'onok':function() {
                            localStorage.clear();
                            document.location.reload();
                        }});
        });
        
        appRefreshInterval$.subscribe(() => {
            this.checkForUpdates();
        });
    }
  
    public checkForUpdates(): void {
      this.swUpdate.checkForUpdate().then(() => { 
        console.log('Application updates checked at: ' + (new Date()).toLocaleString())
      })
    }
}  
