

import { CountryType } from './types/country-type.model';

export class MovesRecipientContact {
    public movesRecipientContactId: number = 0;
    public addressLine1: string;
    public addressLine2: string;
    public addressLine3: string;
    public city: string;
    public provinceId: number = null;
    public province: string;
    public countryId: number = null;
    public postalCode: string;
    public phoneNumber1: string;
    public phoneNumber2: string;
    public phoneNumber3: string;
    public faxNumber: string;
    public emailAddress: string;
    public country: CountryType;

    constructor(init?: Partial<MovesRecipientContact>) {
        
        Object.assign(this, init);
    }
}
