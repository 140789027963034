import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[isLessThan]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: MinNumberValidatorDirective,
        multi: true
    }]
})
export class MinNumberValidatorDirective implements Validator {
    @Input('isLessThan') minNumber: number;
    
    validate(control: AbstractControl) {
      if (control.value == null || this.minNumber == null)
        return null;

      return ((+control.value < +this.minNumber) ? {'LessThanMinNumber': true} : null);
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
    private _onChange: () => void;
  
    ngOnChanges(changes: SimpleChanges): void {
   
      if ('minNumber' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }

}