
export enum TypeTable {
    CircumstanceType,
    ClientType,
    ContactMethodType,
    ContraventionDetailType,
    ContraventionGroundsType,
    ContraventionStatusType,
    ContraventionType,
    CountryType,
    DecisionType,
    DocumentType,
    DriversLicenceClassType,
    DriversLicenceStatusType,
    EventType,
    ExtensionReasonType,
    GenderType,
    HearingType,
    ImpairmentScreeningType,
    LabType,
    LocationType,
    ObservedByType,
    ProvinceType,
    RequestType,
    RequestDecisionType,
    RequestStatusType,
    ReviewMethodType,
    ReviewOutcomeType,
    ReviewStatusType,
    ReviewType,
    SeizureType,
    SeizureGroupType,
    SeizureStatusType,
    SuspectedImpairmentType,
    SuspicionType,
    TestModelType,
    TestResultType,
    TestType,
    VehicleRegistrationStatusType,
    VehicleInterestType,
    NotificationType,
    ReServeMethodType,
    RoadsideAppealOutcomeType,
    DirectionType,
    IssueServiceType,
    DriverDeclinedToTestType,
    RoadConditionType,
    RoadSurfaceType,
    WeatherConditionType,
    VisibilityConditionType,
    PayCentreType,
    NoticeCancellationReasonType,    
    RequestDenyReasonType,
    JudicialReviewDecisionType,
    StayOrderStatusType,
    ConditionCodeType,
    VehicleStatusType,
    DeclarationType,
    RepresentationType,
    HighwayCharacteristicsType,
    CourtType,
    OffenceType,
    TicketMethodType,
    TicketWithdrawalReasonType
}
