

import { ContraventionTest } from './contravention-test.model';

export class ImpairmentScreeningInformation {
    public impairmentScreeningInformationId: number = 0;
    public impairmentScreeningTypeId: number;
    public suspicionTypes: string;
    public suspicionOther: string;
    public suspectedImpairmentTypeId: number;
    public impairmentTests: ContraventionTest[] = [];

    constructor(init?: Partial<ImpairmentScreeningInformation>) {
        
        Object.assign(this, init);
    }
}
