import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OperatorInformation } from '@apis/shared/models/operator-information.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ApiService } from './api.service';
import { VehicleRegisteredOwnerInformation } from '@apis/shared/models/vehicle-registered-owner-information.model';


@Injectable({
    providedIn: 'root'
})
export class MovesService extends ApiService{
    
    constructor(http: HttpClient, localStorage: LocalStorageService){
        super(http, localStorage);
    }
    
    public getOperatorAsync(operatorNumber: string): Observable<OperatorInformation> {
        return this.get('getoperator', { operatorNumber: operatorNumber });
    }

    public getRegisteredOwnerAsync(licencePlateNumber: string, vac: string): Observable<VehicleRegisteredOwnerInformation> {
        return this.get('getregisteredowner', { plateNumber: licencePlateNumber, vac: vac });
    }
}
