import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
    name: "time"
})
export class TimePipe implements PipeTransform {

    constructor() {}

    transform(time: string, format?: string): string {
        if(!time) return null;
        let today = new Date();
        
        if (typeof(time) == "string" && time != null) {
            let timeSplit = time.split(":");
            today.setHours(parseInt(timeSplit[0]));
            today.setMinutes(parseInt(timeSplit[1]));                       
            today.setSeconds(0);           
            if (timeSplit.length > 2) {
                today.setSeconds(parseInt(timeSplit[2]));
            }            
        }
        if(format == "shortTime24h")
        {
            format="HH:mm"; 
        } 
        else if (!["shortTime", "mediumTime", "longTime", "fullTime"].includes(format)) {
            format = "shortTime";
        }
                
        return new DatePipe("en-US").transform(today, format);
    }    
}