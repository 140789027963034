import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OfficerSearch } from '@apis/shared/models/officer-search.model';
import { NoticeAfterPrint } from '@apis/shared/models/notice-after-print.model';
import { ApiService } from './api.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Officer } from '@apis/shared/models/officer.model';
import { OfflineNumberSearch } from '@apis/shared/models/offline-number-search.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { KeycloakProfile } from 'keycloak-js';

@Injectable({
    providedIn: 'root'
})

export class OfficerService extends ApiService{
   
    constructor(http: HttpClient, public localStorage: LocalStorageService){
        super(http, localStorage);

    }

    public getOfficerAsync(officerSearch: OfficerSearch): Observable<Officer> {
       return this.get('officer/find', {officerSearch});
    }

    public getOfficerByEmailIdAsync(officerEmailId: string): Observable<Officer> {
        return this.get('officer/{officerEmailId}', {officerEmailId});
    }

    public getOfficerOfflineIdsAsync(officerId: number): Observable<any> {
        return this.get('officer/offlinenumbers', {officerId});
    }

    public getOfficerOfflineSeizureIdsAsync(officerId: number): Observable<any> {
        return this.get('officer/offlineseizurenumbers', {officerId});
    }

    public setOfficerOfflineNumberInactiveAsync(stop: StopInformation): Observable<any> {

        let errors = 0;
        // if there are contraventions, then mark those contravention numbers as assigned
        //
        stop.contraventions.forEach( c => {
            if (c.contraventionNumber !== null && c.contraventionNumber !== undefined && c.contraventionNumber !== "") {
                let criteria: OfflineNumberSearch = new OfflineNumberSearch();
                criteria.contraventionNumber = c.contraventionNumber;
                this.patch('officer/setnumberinactive', {criteria}).subscribe( r => {

                    // if there is a vehicle seizure, mark the seizure number as assigned
                    if (c.vehicleSeizure!==null && c.vehicleSeizure!==undefined) {
                        if (c.vehicleSeizure.seizureNumber !== null && c.vehicleSeizure.seizureNumber !== undefined && c.vehicleSeizure.seizureNumber === "") {
                            let criteria: OfflineNumberSearch = new OfflineNumberSearch();
                            criteria.seizureNumber = c.vehicleSeizure.seizureNumber;
                            this.patch('officer/setseizurenumberinactive', {criteria}).subscribe( r => {
                            },
                            (error: any) => {                
                                console.log('Error occured when marking contravention seizure number as assigned: ', c.contraventionNumber);
                                errors++;
                            });
                        }
                    }

                    // if there are SDP seizures, then mark those seizure numbers as assigned
                    //
                    if (stop.vehicleSeizures!==null  && stop.vehicleSeizures!==undefined) {
                        stop.vehicleSeizures.forEach( v => {
                            if (v.seizureNumber !== null && v.seizureNumber !== undefined && v.seizureNumber === "") {
                                let criteria: OfflineNumberSearch = new OfflineNumberSearch();
                                criteria.seizureNumber = v.seizureNumber;
                                this.patch('officer/setseizurenumberinactive', {criteria}).subscribe( r => {
                                },
                                (error: any) => {                
                                    console.log('Error occured when marking SDP seizure number as assigned: ', c.contraventionNumber);
                                    errors++;
                                });
                            }
                        });
                    }
                },
                (error: any) => {                
                    console.log('Error occured when marking contravention number as assigned: ', c.contraventionNumber);
                    errors++;
                });
            }
        });
  
        return of(errors);
    }

    public saveOfficerAsync(officer: Officer): Observable<Officer> {
        return this.post('officer', {officer});
    }
   
    public updateKeyCloakUserProfile(keyCloakUserProfile: KeycloakProfile): Observable<Boolean> {
        return this.put('profile', {keyCloakUserProfile});
    }

}
