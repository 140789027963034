<div class="withdraw-modal-overlay">
    <div class="withdraw-modal">
        <div class="withdraw-modal-header">
            <div class="withdraw-modal-title">{{isRequestSubmitted?"Request for withdrawal received":"Request to withdraw ticket(s)"}}
                <span class="error">{{errorMessageHeader}}</span>
            </div>
            <div class="close-icon" (click)="onCloseClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        
        <div class="withdraw-modal-body" *ngIf="!isRequestSubmitted">
            <label for="withdrawalReasonType" class="bold-text">Select a reason for withdrawal: <span class="label-hint">(Required)</span></label>
            <select class="form-control" [appSelectValidator]="true" #withdrawalReasonTypeControl="ngModel"  id="withdrawalReasonType" name="withdrawalReasonType" [(ngModel)]="ticketWithdrawalRequest.ticketWithdrawalReasonTypeId" [ngClass]="{'is-invalid':(withdrawalReasonTypeControl.touched || isSubmitClicked) && withdrawalReasonTypeControl.errors?.defaultSelected}">
                <option [ngValue]="null">Select a reason</option>
                <option *ngFor="let ticketWithdrawalReasonType of ticketWithdrawalReasonTypes" [value]="ticketWithdrawalReasonType.id">{{ticketWithdrawalReasonType.name}}</option>
            </select>
            <div class="invalid-feedback" [ngClass]="{ 'show': (withdrawalReasonTypeControl.touched || isSubmitClicked) && withdrawalReasonTypeControl.errors?.defaultSelected }">
                <div>Reason for withdrawal is required</div>
            </div>
            <label for="additionalNotes" class="bold-text mt-2">Additional Notes about this withdrawal request: <span class="label-hint">(Required)</span></label>
            <textarea id="withdrawalReasonTextarea" name="additionalNotes"
                class="withdraw-reason-textarea" 
                [(ngModel)]="ticketWithdrawalRequest.ticketWithdrawalReasonNotes"
                #withdrawalReasonModel="ngModel"                
                (input)="onWithdrawalTextAreaInput($event)"
                [ngClass]="{'alert-border': isSubmitClicked && withdrawalReasonModel.invalid }"
                ></textarea>
            <span class="max-characters" [ngClass]="{ 'text-danger': remainingCharacters < 0 }">Max characters: {{remainingCharacters}}</span>
            <div class="invalid-feedback"
                [ngClass]="{ 'show': withdrawalReasonModel.invalid && isSubmitClicked }">
                <div>Additional notes are required.</div>
            </div>  

            <div class="documents-section">
                <div class="documents-section-header">Documents</div>
                <div class="document-container">
                    <intake-disclosure-upload [document]="cancellationDocument" [additionalDocumentTypes]="additionalDocumentTypes"
                        [isHeaderDisabled]="true" (onCancelEvent)="onCancelDcoument($event)"></intake-disclosure-upload>
                </div>
            </div>                  
        </div>
        <div class="withdraw-modal-body" *ngIf="isRequestSubmitted">
            <label class="bold-text">Your request to withdraw the ticket(s) has been received.</label>
            <label class="bold-text">A prosecutor will review the request and notify you with the decision.</label>
        </div>
        
        <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row mt-4">  
            <span class="error">{{errorMessage}}</span>
        </div>
        <div class="button-container" *ngIf="!isRequestSubmitted">
            <div id="btnWithdrawModalCancel" class="withdraw-button" (click)="onCloseClick()">Cancel</div>
            <div id="btnWithdrawModalConfirm" class="confirm-button" 
                [ngClass]="{'disabled': remainingCharacters < 0 }" 
                (click)="onConfirmWithdrawalClick(withdrawalReasonModel.valid)">Confirm Request</div>
        </div>
        <div class="button-container" *ngIf="isRequestSubmitted">
            <div id="btnWithdrawModalCancel" class="withdraw-button" (click)="onCloseClick()">Close</div>
        </div>
    </div>
</div>
<div class="confirm-modal-overlay">
    <div class="confirm-modal">
        <div class="withdraw-modal-header">
            <div class="withdraw-modal-title">Confirm withdrawal</div>
            <div class="close-icon" (click)="showHideConfirmModal(false)">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="withdraw-modal-body">
            <div class="withdrawal-alert" *ngIf="ticketWithdrawalRequest.tickets?.length > 5">
                <div class="alert-icon">                
                    <i class="fas fa-exclamation-triangle fa-lg"></i>
                </div>
                <div class="withdrawal-alert-text">
                    <span class="bold-text">Are you sure you want to withdraw the selected tickets?</span>
                </div>                       
            </div>

            <div *ngIf="ticketWithdrawalRequest.tickets?.length <= 5">
                <div class="form-group">
                    <span class="bold-text">Are you sure you want to withdraw {{isSingleTicket?"this ticket":"these tickets"}}?</span>
                </div>
                <div *ngFor="let ticket of ticketWithdrawalRequest.tickets">
                    <div class="form-group row">
                        <div class="col-3">
                            <span class="bold-text">{{ticket.ticketNumber}}</span>
                        </div>
                        <div class="col-3">
                            {{ticket.ticketDescription}}
                        </div>
                        <div class="col-6">
                            {{ticket.accusedName}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="!isSingleticket" class="heading-underline"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-container">
                <button id="btnGoBack" class="btn btn-cancel" (click)="showHideConfirmModal(false)">Back</button>
                <button id="btnConfirm" class="btn btn-confirm" (click)="onConfirmClick()">Submit</button>
            </div>
        </div>
    </div>
</div>

