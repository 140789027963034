import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges } from '@angular/core';
import { Document } from '../../models/document.model';
import { DocumentState } from '../../enums/document-state.enum';
import { DocumentType } from '../../models/types/document-type.model';
import { LocalStorageService } from '../../services/local-storage.service';
import { Guid } from 'guid-typescript';
import { DocumentService } from '@apis/shared/services/document.service';
import { FileUploadModel } from '../../models/file-upload.model';
import { ReviewDocumentsEditState } from '../../enums/app.enum';
import * as fileSaver from 'file-saver';
import { AdditionalDocumentCategory } from '@apis/shared/enums/additional-document-category.enum';
import { Constants } from '@apis/shared/helpers/constants';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { Observable, Subscription } from 'rxjs';
import * as tus from "tus-js-client";

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  outputs: ['onCancelEvent', 'onRemoveEvent']
})
export class FileUploadComponent implements OnInit, OnChanges {
  @Input() document: Document;
  @Input() fileUploadModel: FileUploadModel;
  @Input() isPrimaryDocument: boolean;
  @Input() isDisabled: boolean; // Not a complete implementation. Currently only disables functions that can be accessed from the admin portal.
  @Input() onDocumentBeforeUpload: (document: Document) => Observable<any>;
  @Input() onDocumentUploaded: (document: Document) => Observable<any>;
  @Input() onDocumentBeforeRemove: () => Observable<any>;
  @Input() isDisplayMaxFileSize: boolean;
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  onCancelEvent = new EventEmitter<Document>();
  onRemoveEvent = new EventEmitter<Document>();

  DocumentState = DocumentState;
  documentState = DocumentState.NotUploaded;
  documentType: DocumentType = null;
  additionalDocumentTypes: DocumentType[];
  isRequiredDocument: boolean = true;
  isAdditionalDocument: boolean = false;
  uploadProgress: number = 0;
  uploadProgressStyle: string;
  errorMessage: string = "";
  validExtensions: string[];
  tusUpload: tus.Upload;
  uploadSubscription: Subscription;

  maxFileSize: string;

  constructor(private localStorageService: LocalStorageService,
              private documentService: DocumentService) { }

  ngOnInit(): void {
    //Get Dcoument Types
    switch (this.fileUploadModel.additionalDocumentsCategory) 
    {
      case AdditionalDocumentCategory.Intake:
        this.additionalDocumentTypes = this.localStorageService.getDocumentTypes().filter(x => x.isSupplemental && [20,21,22,23,24,25].includes(x.id));
        break;
      case AdditionalDocumentCategory.ContraventionReview: case AdditionalDocumentCategory.VehicleSeizureReview: 
        this.additionalDocumentTypes = this.localStorageService.getDocumentTypes().filter(x => x.isSupplemental 
          && Constants.Driver.SupplementalDocuments.includes(x.id));
        break;  
      case AdditionalDocumentCategory.LateReview:
        this.additionalDocumentTypes = this.localStorageService.getDocumentTypes().filter(x => x.isSupplemental && [27,28].includes(x.id));
        break;
    }
        
    //Check if it is a supplemental document
    if (this.document.documentTypeId == null)
      this.isAdditionalDocument = true;
    else {
      this.documentType = this.localStorageService.getDocumentTypes().find(x => x.id == this.document.documentTypeId);
      this.isAdditionalDocument = this.isPrimaryDocument ? false : this.documentType.isSupplemental;
      this.validExtensions = this.documentType.acceptedFileTypes.split(',');
    }

    if (this.document.documentName) {
      this.documentState = DocumentState.Uploaded;
    }

    if(this.isDisplayMaxFileSize)
    {
      this.getMaxFileSizeText();
    }
  }

  // When a document is deleted, we may need to assign the "primary document" placeholder status to a different document placeholder.
  ngOnChanges(): void {
    this.documentType = this.localStorageService.getDocumentTypes().find(x => x.id == this.document.documentTypeId);
    if (this.document.forcePrimaryDocument || (this.documentType && !this.documentType.isSupplemental)) {
      this.isAdditionalDocument = false;
    }
    else {
      this.isAdditionalDocument = true;
    }
  }

  getMaxFileSizeText() {
    if (this.documentType?.maximumFileSize)
    {
      this.maxFileSize = this.documentType.maximumFileSize >= 1024? `${(this.documentType.maximumFileSize / 1024).toFixed(0)} GB` : `${this.documentType.maximumFileSize} MB`;
    }
  }
  compareFn(a, b) {
    return (a == null && b == null) || (a && b && a.id == b.id);
  }

  triggerFileUpload(){
    this.fileDropEl.nativeElement.click();
  }

  /**
   * on file drop handler
   */
  onFileDropped(files) {
    if (this.documentState == DocumentState.NotUploaded && files.length > 0) {
      this.UploadDocument(files[0]);
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    if (files.length > 0) {
      this.UploadDocument(files[0]);
    }
  }

  UploadDocument(file) {
    if (this.isDisabled) {
      return;
    }

    var fileExtension = file?.name.split('.').pop();
    this.errorMessage = "";

    switch (this.documentState)
    {
      case DocumentState.Uploaded:
        this.errorMessage = "Remove previous uploaded file to upload a new file.";
        break;
      
      case DocumentState.Uploading: case DocumentState.Error:
        this.errorMessage = "An upload is already in progress. Please cancel that first to upload a new file.";  
        break;

      case DocumentState.NotUploaded:
      {
        if (this.document.documentTypeId == null)
        {
          this.errorMessage = "Please select a file category before uploading the file."
          break;
        }

        if (!this.validExtensions.includes(fileExtension.toLowerCase()))
        {
          this.errorMessage = "This type of file is not allowed. Allowed file types are: " + this.documentType.acceptedFileTypes;
          break;
        }

        if (this.documentType.maximumFileSize != 0 && file.size > (this.documentType.maximumFileSize * 1024 * 1024))
        {
          this.errorMessage = "Invalid file size. The maximum allowed file size is " + this.documentType.maximumFileSize.toString() + "MB"; 
          break;
        }

        if(file.size === 0){
          this.errorMessage = "File appears to be corrupt or not converted properly. Please check file and reupload.";
          break;
        }

        if (this.onDocumentBeforeUpload) {
          this.onDocumentBeforeUpload(this.document).subscribe((result: any) => {
            this.UploadDocumentHelper(file, fileExtension);
          }, (error: any) => {
            this.errorMessage = error;
          });
        }
        else {
          this.UploadDocumentHelper(file, fileExtension);
        }

        break;
      }
    }
  }

  UploadDocumentHelper(file, fileExtension) {
    var newFileName = Guid.create().toString();
    this.document.contentGuid = newFileName;
    this.document.isUploading = true;

    this.documentState = DocumentState.Uploading;

    // Fetch JWT token (only Driver portal)
    var authToken = this.localStorageService.get("access_token");

    // Use Tus uploader only if file size > 500MB (** Configured only for Driver portal **)
    if (file.size > 104857600 && tus.isSupported && authToken) {
      this.tusUpload = new tus.Upload(file, {
        //Set Auth header, since HttpInterceptor wont work for requests orignating from 3rd party components
        headers: {
          Authorization: 'Bearer ' + authToken
        },
        //File server upload endpoint address settings
        endpoint: `${this.documentService.appConfig.apiUrl}/files`,
        //Retrying delay settings
        retryDelays: [0, 3000, 5000, 10000, 20000],
        //ChunkSize 10MB
        chunkSize: 104857600,
        //Metadata required by attachment server
        metadata: {
            name: newFileName,
            extension: fileExtension,
            folder: this.fileUploadModel.tempFileFolder,
            contentType: file.type || 'application/octet-stream'
        },
        //Callback errors that cannot be resolved by retrying
        onError:  (error) => {
            this.errorMessage = "Something went wrong. Please try uploading the document again."
            this.documentState = DocumentState.Error;
            this.tusUpload = null;
            console.log("Failed because: " + error)
        },
        onProgress:  (bytesUploaded, bytesTotal) => {
          let percentage = Math.round((bytesUploaded / bytesTotal * 100));
          this.uploadProgress = percentage;
        },
        onSuccess:  () => {
          this.document.documentName = file?.name;
          this.document.documentExtension = fileExtension;
          this.document.documentSize = CommonUtil.getDocumentSize(file);
          this.document.isSubmitLater = false;
          this.document.isUploading = false;
          this.documentState = DocumentState.Uploaded;
          this.tusUpload = null;
          this.uploadProgress = 0;
          this.errorMessage = "";

          if (this.onDocumentUploaded){
            this.onDocumentUploaded(this.document).subscribe((result: any) => {
              // do nothing
            }, (error: any) => {
              this.errorMessage = error;
              this.documentState = DocumentState.Error;
            });
          }
        }
      });

      this.tusUpload.start();
    } else {
      this.uploadSubscription = this.documentService.uploadDocumentAsync(file, this.fileUploadModel.tempFileFolder, newFileName)
        .subscribe((result: any) => {
          if (+result.type === 1) {
              let percentage = Math.round((parseInt(result.loaded) / parseInt(result.total)) * 100);
              this.uploadProgress = percentage
          } else if (+result.type === 4) {
            this.document.documentName = file?.name;
            this.document.documentExtension = fileExtension;
            this.document.documentSize = CommonUtil.getDocumentSize(file);
            this.document.isSubmitLater = false;
            this.document.isUploading = false;
            this.documentState = DocumentState.Uploaded;
            this.uploadSubscription = null;
            this.uploadProgress = 0;
            this.errorMessage = "";

            if (this.onDocumentUploaded){
              this.onDocumentUploaded(this.document).subscribe((result: any) => {
                // do nothing
              }, (error: any) => {
                this.errorMessage = error;
                this.documentState = DocumentState.Error;
              });
            }
          }
        }, (error: any) => {
          this.errorMessage = "Something went wrong. Please try uploading the document again."
          this.documentState = DocumentState.Error;
          this.uploadSubscription = null;
        });
    }
  }

  DownloadDocument(){
    var tempFileFolder: string;
    var documentRefTypeName: string;
    var documentRefTypeNumber: string;

    if (this.document.documentId == null || this.document.documentId == 0) //Dcoument is at temporary location
    {
      tempFileFolder = this.fileUploadModel.tempFileFolder;
      documentRefTypeName = "";
      documentRefTypeNumber = "";
    }
    else //Document is at permanent location
    {
      tempFileFolder = "";
      documentRefTypeName = this.fileUploadModel.documentRefTypeName;
      documentRefTypeNumber = this.fileUploadModel.documentRefTypeNumber;
    }

    var storageFileName = `${this.document.contentGuid}.${this.document.documentExtension}`;
      this.documentService.downloadDocument(tempFileFolder, documentRefTypeName, documentRefTypeNumber, storageFileName , this.document.documentName)
      .subscribe((result: any) => {
        if (result)
        { 
          fileSaver.saveAs(new Blob([result]), this.document.documentName);
          this.errorMessage = "";
        }
      },
      (error: any) => {
        if (error.status == 400)
        {
          this.errorMessage = error.error.message;
        }
        else
          this.errorMessage = "Unable to download the file. Please try again.";
      });
  }

  CancelUpload(){
    if (this.documentState == DocumentState.Uploading || this.documentState == DocumentState.Error) {
      if (this.tusUpload) {
        this.tusUpload.abort(true);
        this.tusUpload = null;
      }
      if (this.uploadSubscription) {
        this.uploadSubscription.unsubscribe();
        this.uploadSubscription = null;
      }

      this.uploadProgress = 0;
      this.document.isUploading = false;
      this.documentState = DocumentState.NotUploaded;
    }
  }

  RemoveDocument(){
    if (this.onDocumentBeforeRemove) {
      this.onDocumentBeforeRemove().subscribe((result: any) => {
        this.RemoveDocumentHelper();
      }, (error: any) => {
        this.errorMessage = error;
      });
    }
    else {
      this.RemoveDocumentHelper();
    }
  }

  RemoveDocumentHelper() {
    if (this.document.documentId > 0)
    {
      // Permanent document
      this.documentService.deletePermanentDocument(this.document.documentId, this.document.contentGuid)
      .subscribe((result: any) => {
        this.onRemoveEvent.emit(this.document);
        this.document.isUploading = false;
        this.documentState = DocumentState.NotUploaded;
        this.errorMessage = "";
      },
      (error: any) => {
          this.errorMessage = "Unable to remove the file. Please try again.";
      });
    } else {
      // Temporary document
      var fileName = `${this.document.contentGuid}.${this.document.documentExtension}`;
      this.documentService.deleteDocument(fileName, this.fileUploadModel.tempFileFolder)
      .subscribe((result: any) => {
        this.document.uploadedDate = null;
        this.document.documentName = "";
        this.document.documentExtension = "";
        this.document.isUploading = false;
        this.documentState = DocumentState.NotUploaded;
        this.errorMessage = "";
        this.onRemoveEvent.emit(this.document);
      },
      (error: any) => {
        if (error.status == 400)
        {
          this.errorMessage = error.error.message;
          this.document.uploadedDate = null;
          this.document.documentName = "";
          this.document.documentExtension = "";
          this.document.isUploading = false;
          this.documentState = DocumentState.NotUploaded;
          this.onRemoveEvent.emit(this.document);
        }
        else
          this.errorMessage = "Unable to remove the file. Please try again.";
      });
    }
  }

  getTestName(documentTypeId)
  {
    // This logic is applicable only to Impairment Screening Tests
    switch (documentTypeId.toString())
    {
      case "3": case "4": 
        return "SFST";
      case "5": 
        return "ASD";
      case "6": 
        return "ADSE";
      case "7": case "8": case "9":
        return "DRE";
      case "10": case "11": case "12": case "13": case "14": case "15": case "16": case "17": case "18":
        return "AI";  
      case "19": 
        return "Blood Test";
      default:
        return "";  
    }
  }

  CancelDocument()
  {
    this.onCancelEvent.emit(this.document);
  }

  onFileCtegoryChange(fileCategory: DocumentType )
  {
    this.document.documentTypeId = fileCategory?.id;
    this.validExtensions = this.documentType.acceptedFileTypes.split(',');
  }

}
