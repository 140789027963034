import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TowCompany } from '../models/tow-company.model';
import { APP_CONFIG } from "../../app-config";
import { map } from  "rxjs/operators";

@Injectable({
    providedIn: 'root'
})

export class TowCompanyService {
    private apiUrl: string = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;
   
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(APP_CONFIG) public appConfig: any)
    { }

    public getTowCompaniesAsync(): Observable<TowCompany[]> {
       return this.httpClient.get(`${this.apiUrl}/towcompanies`, {})
       .pipe(
            map((response: any) => Array.from(response))
       );
    }

    public searchTowCompaniesAsync(searchText: string): Observable<TowCompany[]> {
        return this.httpClient.get(`${this.apiUrl}/towcompanies/search`, {
            params: {
                "searchText": searchText
            }
        })
        .pipe(
             map((response: any) => Array.from(response))
        );
    }

    public saveTowCompanyAsync(towCompany: TowCompany) : Observable<any> {
        return this.httpClient.post(`${this.apiUrl}/towcompanies`, towCompany, { } );
    }

    public deleteTowCompanyAsync(towCompanyId: number): Observable<any> {
        return this.httpClient.delete(`${this.apiUrl}/towcompanies/${towCompanyId}`, {});
    }
}
