import { AbortController } from '@azure/abort-controller'

export class DisclosureUploadOptions {
    public name: string;
    public fullName: string;
    public metadata: any;
    public tags: any;
    public abortController: AbortController;

    constructor(init?: Partial<DisclosureUploadOptions>) {
        Object.assign(this, init);
    }
}