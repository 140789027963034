<div class="modal-overlay modal-show">
    <div class="modal-container">
        <div class="modal-container-header">
            <div class="modal-title">{{isUpdateMode? "Update note" : "Add a note"}}</div>
            <div class="close-icon" (click)="onCloseIconClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="modal-container-body">
            
            <div class="form-group">                
                <textarea 
                    id="eventNotes" 
                    name="eventNotes"
                    [(ngModel)]="event.eventDetails"
                    class="modal-textarea"                                   
                    (input)="onTextareaInput($event)"></textarea>
                <span class="max-characters" [ngClass]="{ 'text-danger': maxCharacters < 0 }">Max characters: {{maxCharacters}}</span>
            </div>     

            <div class="uploaded-documents-header">Uploaded Documents</div>
            <div class="uploaded-documents">
                <div *ngFor="let document of documents; index as i" class="upload-container">
                    <div class="row">                        
                        <div class="col-12 col-lg-5 d-flex align-items-center" [ngClass]="{ 'marked-to-delete': document.isDeleted }">
                            <img class="download-file-icon" src="assets/images/download-file.png" />
                            <a (click)="onDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                        </div>
                        <div class="col-12 col-lg-5" [ngClass]="{ 'marked-to-delete': document.isDeleted }">
                            <div *ngIf="document.documentId <=0" class="upload-bar">
                                <div id="uploadBarProgress_{{i}}" class="upload-bar-progress"></div>              
                            </div>
                            <div *ngIf="document.documentId>0">{{document.documentSize}}</div>
                        </div>
                        <div class="col-12 col-lg-2">
                            <a id="removeFileLink_{{i}}" class="remove-file-link" [ngClass]="{ 'disabled': this.disableFileUpload || isUploadInProgress() }" (click)="onRemoveFileLinkClick(document)">{{ document.isDeleted? "Cancel": "Remove File" }}</a>
                        </div>                                               
                    </div>                                                           
                </div>
                <div *ngFor="let document of invalidDocuments" class="upload-container">
                    <div class="row">                                                
                        <div class="col-12 col-lg-5">
                            {{document.documentName}}                                
                        </div>
                        <div class="col-12 col-lg-7 file-error-text">
                            {{document["error"]}}
                        </div>                                               
                    </div>                                                           
                </div>
                <div *ngIf="documents?.length == 0 && invalidDocuments?.length == 0" class="no-documents-text">
                    No uploaded documents found.
                </div>  
            </div>
            
        </div>
        <div class="button-container">
            <div class="d-flex flex-column">
                <button class="upload-document-button" [disabled]="this.disableFileUpload" (click)="uploadFile.click()">Upload Document</button>
                <input style="display: none;" type="file" multiple (change)="onUploadFileChanged($event)" #uploadFile>
                <span class="hint-text">Allowed: PDF, maximum file size is 5 MB</span>                
            </div>
            <div class="d-flex">            
                <div id="cancelButton" class="cancel-button" (click)="onCancelClick()" >Cancel</div>
                <div id="addButton" class="add-button" [ngClass]="{'disabled': maxCharacters < 0 || isUploadInProgress() }" (click)="onAddClick($event)">
                    {{isUpdateMode? "Update" : "Add"}}
                    <i class="fas fa-cog fa-spin"></i>
                </div>
            </div>
        </div>
    </div>
</div>
