import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { PassengerSeatingTypes } from '@apis/shared/enums/app.enum';
import { StopInformation } from '@apis/shared/models/stop-information.model';

@Component({
  selector: 'app-passenger-position',
  templateUrl: './passenger-position.component.html',
  styleUrls: ['./passenger-position.component.scss']
})
export class PassengerPositionComponent implements OnInit, DoCheck {
  @Input() stopInformation: StopInformation;
  @Input() isReadOnly: boolean;
  positions: number[];
  PassengerSeatingTypes = PassengerSeatingTypes;
  vehicleOccupants: string;

  constructor() { }

  ngOnInit(): void {
    this.loadSeatingInformation();
  }

  generatePositions()
  {
    this.stopInformation.vehicleOccupants = Array.prototype.map.call(this.positions, function(item) { return item?"1":"0"; }).join("");
  }

  ngDoCheck() {
    if (this.vehicleOccupants != this.stopInformation.vehicleOccupants)
      this.loadSeatingInformation();
  }

  loadSeatingInformation()
  {
    if(this.stopInformation?.seatingTypeId == PassengerSeatingTypes.TwoRows 
      || this.stopInformation?.seatingTypeId == PassengerSeatingTypes.ThreeRows
      || this.stopInformation?.seatingTypeId == PassengerSeatingTypes.MotorcycleOrATV)
    {
      //Check if the positions string is initialized or not
      this.stopInformation.vehicleOccupants = this.stopInformation.vehicleOccupants? this.stopInformation.vehicleOccupants:"10000000";
      this.vehicleOccupants = this.stopInformation.vehicleOccupants;
      this.positions = Array.from(this.stopInformation.vehicleOccupants).map(x=>+x);
    }
  }
}
