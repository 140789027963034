import { HelpAndResourcesComponent } from './help-and-resources/help-and-resources.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { Constants } from '@apis/shared/helpers/constants';
import { IntakeAuthGuard } from '../shared/security/auth.guard';
import { ContraventionAnalyticsComponent } from './reports/contravention-analytics/contravention-analytics.component';
import { ReportsComponent } from './reports/reports.component';
import { UserDashboardComponent } from './dashboard/user-dashboard.component';
import { SeizureNoticesReportComponent } from './reports/seizure-notices/seizure-notices-report.component';
import { CancelledContraventionsReportComponent } from './reports/cancelled-contraventions/cancelled-contraventions-report.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { ScheduledMaintenanceComponent } from './scheduled-maintenance/scheduled-maintenance.component';
import { AccountActivationPendingComponent } from './account-activation-pending/account-activation-pending.component';
import { UnauthorizedComponent } from '@apis/shared/components/unauthorized/unauthorized.component';
import { ContraventionComponent } from './contravention/contravention.component';
import { RecipientInformationComponent } from './contravention/submission/recipient-information/recipient-information.component';
import { ContraventionSelectionComponent } from './contravention/submission/contravention-selection/contravention-selection.component';
import { VehicleInformationComponent } from './contravention/submission/vehicle-information/vehicle-information.component';
import { PrintAndIssueComponent } from './contravention/submission/print-and-issue/print-and-issue.component';
import { VehicleSeizureComponent } from './contravention/submission/vehicle-seizure/vehicle-seizure.component';
import { PrintConfirmationComponent } from './contravention/submission/print-confirmation/print-confirmation.component';
import { OfflinePrintConfirmationComponent } from './contravention/submission/print-confirmation/offline-print-confirmation.component';
import { TsaContraventionDetailsComponent } from './contravention/add-details/tsa-contravention-details/tsa-contravention-details.component';
import { AdditionalDetailsComponent } from './contravention/add-details/additional-details/additional-details.component';
import { SupportingDocumentsComponent } from './contravention/add-details/supporting-documents/supporting-documents.component';
import { ReviewConfirmComponent } from './contravention/add-details/review-confirm/review-confirm.component';
import { SubmissionCompleteComponent } from './contravention/add-details/submission-complete/submission-complete.component';
import { DisclosureComponent } from './disclosure/disclosure.component';
import { TrafficDisclosureUploadComponent } from './disclosure/traffic-disclosure-upload/traffic-disclosure-upload.component';
import { TrafficDisclosureReviewAndConfirmComponent } from './disclosure/traffic-disclosure-review-and-confirm/traffic-disclosure-review-and-confirm.component';
import { TrafficDisclosureUploadComplete } from './disclosure/traffic-disclosure-upload-complete/traffic-disclosure-upload-complete.component';
import { TrafficTicketsReportComponent } from './reports/traffic-tickets/traffic-tickets-report.component';
import { CancelledTicketsReportComponent } from './reports/cancelled-tickets/cancelled-tickets-report.component';

const routes: Routes = [
  { path: "", redirectTo: "/user-dashboard",  pathMatch: "full" },
  { path: "user-dashboard", component: UserDashboardComponent, canActivate: mapToCanActivate([IntakeAuthGuard]), data: { permission: Constants.Permission.VIEW_INTAKE_DASHBOARD } },
  { path: "reports", component: ReportsComponent, canActivate: mapToCanActivate([IntakeAuthGuard]), data: { permission : Constants.Permission.DOWNLOAD_REPORTS } },
  { path: "reports/impaired-notices", component: ContraventionAnalyticsComponent, canActivate: mapToCanActivate([IntakeAuthGuard]), data: { permission : Constants.Permission.DOWNLOAD_REPORTS } },
  { path: "reports/seizure-notices", component: SeizureNoticesReportComponent, canActivate: mapToCanActivate([IntakeAuthGuard]), data: { permission : Constants.Permission.DOWNLOAD_REPORTS } },
  { path: "reports/cancelled-contraventions", component: CancelledContraventionsReportComponent, canActivate: mapToCanActivate([IntakeAuthGuard]), data: { permission : Constants.Permission.DOWNLOAD_REPORTS } },
  { path: "reports/traffic-tickets", component: TrafficTicketsReportComponent, canActivate: mapToCanActivate([IntakeAuthGuard]), data: { permission : Constants.Permission.DOWNLOAD_REPORTS } },
  { path: "reports/cancelled-tickets", component: CancelledTicketsReportComponent, canActivate: mapToCanActivate([IntakeAuthGuard]), data: { permission : Constants.Permission.DOWNLOAD_REPORTS } },
  { path: "help-and-resources", component: HelpAndResourcesComponent },
  { 
    path: "contravention", 
    component: ContraventionComponent,
    children: [      
      { path: "", redirectTo: "submission/recipient-information", pathMatch: "full" },
      { path: "submission", redirectTo: "submission/recipient-information", pathMatch: "full" },
      { path: "submission/recipient-information", component: RecipientInformationComponent, data: { label: "Recipient Information", permission: Constants.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES }, canActivate: mapToCanActivate([IntakeAuthGuard]) },      
      { path: "submission/contravention-selection", component: ContraventionSelectionComponent, data: { label: "Contravention Selection", permission: Constants.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES }, canActivate: mapToCanActivate([IntakeAuthGuard]) },      
      { path: "submission/vehicle-information", component: VehicleInformationComponent, data: { label: "Vehicle Details", permission: Constants.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES }, canActivate: mapToCanActivate([IntakeAuthGuard]) },      
      { path: "submission/vehicle-seizure", component: VehicleSeizureComponent, data: { label: "Vehicle Seizure Details", permission: Constants.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES }, canActivate: mapToCanActivate([IntakeAuthGuard]) },
      { path: "submission/print-and-issue", component: PrintAndIssueComponent, data: { label: "Print & Issue", permission: Constants.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES }, canActivate: mapToCanActivate([IntakeAuthGuard]) },      
      { path: "submission/print-confirmation", component: PrintConfirmationComponent, data: { label: "Print Confirmation", permission: Constants.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES }, canActivate: mapToCanActivate([IntakeAuthGuard]) },      
      { path: "submission/offline-print-confirmation", component: OfflinePrintConfirmationComponent, data: { label: "Offline Print Confirmation", permission: Constants.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES }, canActivate: mapToCanActivate([IntakeAuthGuard]) },      
      { path: "add-details/contravention-details", component: TsaContraventionDetailsComponent, data: { label: "Contravention Details", permission: Constants.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES }, canActivate: mapToCanActivate([IntakeAuthGuard]) },
      { path: "add-details/sdp-notice-details", component: TsaContraventionDetailsComponent, data: { label: "SDP Notice Details", permission: Constants.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES }, canActivate: mapToCanActivate([IntakeAuthGuard]) },
      { path: "add-details/additional-details", component: AdditionalDetailsComponent, data: { label: "Additional Details", permission: Constants.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES }, canActivate: mapToCanActivate([IntakeAuthGuard]) },
      { path: "add-details/supporting-documents", component: SupportingDocumentsComponent, data: { label: "Supporting Documents", permission: Constants.Permission.SUBMIT_SUPPORTING_DOCUMENTS }, canActivate: mapToCanActivate([IntakeAuthGuard]) },
      { path: "add-details/review-confirm", component: ReviewConfirmComponent, data: { label: "Review & Confirm", permission: Constants.Permission.SUBMIT_SUPPORTING_DOCUMENTS }, canActivate: mapToCanActivate([IntakeAuthGuard]) },
      { path: "add-details/submission-complete", component: SubmissionCompleteComponent, data: { label: "Submission Complete", permission: Constants.Permission.SUBMIT_SUPPORTING_DOCUMENTS }, canActivate: mapToCanActivate([IntakeAuthGuard]) },
    ]
  },
  { 
    path: "ticket", 
    component: DisclosureComponent,
    children: [      
      { path: "", redirectTo: "disclosure/upload-disclosures", pathMatch: "full" },
      { path: "disclosure", redirectTo: "upload-disclosures", pathMatch: "full" },
      { path: "disclosure/upload-disclosures", component: TrafficDisclosureUploadComponent, data: { label: "Upload Disclosures", permission: Constants.Permission.SUBMIT_SUPPORTING_DOCUMENTS }, canActivate: mapToCanActivate([IntakeAuthGuard]) },
      { path: "disclosure/review-confirm", component: TrafficDisclosureReviewAndConfirmComponent, data: { label: "Review & Confirm", permission: Constants.Permission.SUBMIT_SUPPORTING_DOCUMENTS }, canActivate: mapToCanActivate([IntakeAuthGuard]) },
      { path: "disclosure/submission-complete", component: TrafficDisclosureUploadComplete, data: { label: "Submission Complete", permission: Constants.Permission.SUBMIT_SUPPORTING_DOCUMENTS }, canActivate: mapToCanActivate([IntakeAuthGuard]) }, 
    ]
  },
  { path: 'page-not-found', component: PageNotFoundComponent},
  { path: 'internal-server-error', component: InternalServerErrorComponent},
  { path: 'scheduled-maintenance', component: ScheduledMaintenanceComponent},
  { path: 'account-activation-pending', component: AccountActivationPendingComponent},
  { path: 'unauthorized', component: UnauthorizedComponent},
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
